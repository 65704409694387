
  <span class="pull-right" style="margin:6px;">Quantity:</span>
  
  <input class="form-control pull-right" style="width:50px;text-align:center"
         id="acc_{{ accessory.BuyOutProfileId|meta:'stripNonAlphaNumeric'}}"
         [(ngModel)]="accessory.metadata.Quantity"
         (keydown)="clearPrice()"
         (ngModelChange)="clearPrice();reprice()" (focus)="selectInputContents($event)" />

  <fa-icon *ngIf="accessory.metadata.Loading" [icon]="d.faSpinner" [spin]="true" style="margin:8px 17px;"></fa-icon>
  <span *ngIf="!accessory.metadata.Loading" class="pull-right" style="font-weight:bold;margin:6px;"
    [innerHTML]="accessory.metadata.pricingSetResult?.NetPriceTotal|currency:'USD':'symbol':'1.2-2'|meta:'pricingError'|safe"
    >
  </span>




  <button class="btn btn-primary pull-right" [ngClass]="{'disabled':addBtnDisabled()}" (click)="addToCart()">
    <fa-icon [icon]="d.faCartPlus"></fa-icon>
    Add To Cart
  </button>
