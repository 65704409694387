import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-std-footer',
  templateUrl: './std-footer.component.html',
  styleUrls: ['./std-footer.component.scss']
})
export class StdFooterComponent implements OnInit {

  constructor(public d: DataService) { }

  ngOnInit() {
  }

}
