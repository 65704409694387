import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  //styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  @Input() panel;
  @Input() semaphore;
  @Input() cartIndex;
  @Output() onUpdateCart = new EventEmitter<boolean>();
  public inConfigure: boolean = false;
  public colors: any;
  public gauges: any;
  public panelId: string;
  private selectedColor: any;

  @ViewChild("striatedWarningModal", { static: true }) striatedWarningModal: TemplateRef<any>;
  striatedWarningModalRef: BsModalRef;

  constructor(public d: DataService, private router: Router) { }

  ngOnInit() {
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if ((window !== undefined)) {
      this.inConfigure = window.location.href.indexOf('configure') > -1;
    }
    if (this.d.logFlags.includes("OFFERING_ID")) this.d.log("panel.component.ts 32");
    this.panelId = this.panel.metadata.product.OFFERING_ID;

    for (let propName in changes) {
      let changedProp = changes[propName];
      //let from = JSON.stringify(changedProp.previousValue);
      let to = JSON.stringify(changedProp.currentValue);
      //console.log(`${propName} changed from ${from} to ${to}`);
      if (propName == "semaphore") {
        if (to != "-1") {
          //console.log("Either color or gauge changed!");
          //ctrl.clearPricing();
          //ctrl.cutlistChanged(null);
        }
      }

    }
  }


  async ngAfterViewInit() {
    this.d.get("/GetColorListSet?offeringId=" + this.panelId, {}).subscribe(async (result) => {
      this.colors = result;

      this.colors.forEach(c => {
        if (this.colorIsUnavailable(c, this.panel)) {
          c.ColorDescription += ' (Striated Only)';
        };
      });

      if (this.d.model.targetPanel == null) {

        if (this.colors.length == 1) {
          //console.log("picking first and only color");
          await this.selectColor(this.colors[0]);
        } else {
          //console.log("Selected color: ", this.panel.metadata.color);
          if (!this.panel.metadata.color.hasOwnProperty('ColorCode')) {
            if ((window !== undefined)) {
              this.d.clickElement("panel-color-select");
            }
          } else {
            var plant = this.d.model.ShipTo.SHIPPINGPLANT;
            var svcUrl = "/GetColorGaugeSet?offeringId=" + this.panelId + "&plant=" + plant + "&colorCode=" + this.panel.metadata.color.ColorCode + "&src=panels1";
            this.gauges = await this.d.inlineGet(svcUrl);
            /*
            this.d.get(svcUrl, {}).subscribe((result) => {
              this.gauges = result;
            });
            */
          }
        }
      } else {
        console.log("picking color from TargetPanel", this.d.model.targetPanel);
        let targetColor = this.colors.find((c: any) => { return c.ColorCode == this.d.model.targetPanel.Colorcode });
        await this.selectColor(targetColor);
        this.panel.metadata.packaging.PackType = this.d.model.targetPanel.PackagingType;
        setTimeout(async () => {
          this.d.model.targetPanel = null;
          await this.d.cutlistRef.pricingSet();
          document.getElementById("cutlistAddCartBtn").focus();
        }, 500);

        //this.panel.metadata.packaging = this.d.model.targetPanel.PackagingType;
      }

    });



  }


  log(msg: string) {
    console.log(msg);
  }

  async selectColor(color) {
    this.selectedColor = color;
    let isUnavailable = this.colorIsUnavailable(this.selectedColor, this.panel);
    console.log("selectColor: " + color.ColorDescription + ", isUnavailable: " + isUnavailable);
    setTimeout(async () => {
      if (isUnavailable) {
        this.striatedWarningModalRef = this.d.showModal(this.striatedWarningModal);
      } else {
        await this.confirmColor();
      }
    }, 100);


  }
  reselectColor() {
    this.striatedWarningModalRef.hide();

    this.d.toast("Please call CSR to order these colors in non-striated option");
    // setTimeout(() => { $("#panel-color-select").click(); }, 500);
  }
  async confirmColor() {

    if (this.striatedWarningModalRef) this.striatedWarningModalRef.hide();
    let color = this.selectedColor;
    /*
    if (this.colorIsUnavailable(color, this.panel)) {
      this.d.toast("The color you have chosen is temporarily unavailable.  Please contact customer service to review other options.");
      return;
    }
    */

    //console.log("selectColor",color);
    if (this.d.has(this.panel, 'metadata.color.ColorCode')) {
      if (color.ColorCode != this.panel.metadata.color.ColorCode) {
        //console.log("color changed! should clear pricing!");
        this.clearPricing();
      } else {
        //console.log("no change! nothing should happen!");
        return;
      }
    }
    this.panel.metadata.color = color;

    //console.log(ctrl.panel);

    this.panel.metadata.gauge = {};
    //$('.dropdown-toggle').removeClass('drop-focus').blur();
    var plant = this.d.model.ShipTo.SHIPPINGPLANT;
    var svcUrl = "/GetColorGaugeSet?offeringId=" + this.panelId + "&plant=" + plant + "&colorCode=" + color.ColorCode + "&src=panels2";
    this.gauges = await this.d.inlineGet(svcUrl);

    if (this.d.model.targetPanel == undefined) {
      if (this.gauges.length == 1) {
        this.selectGauge(this.gauges[0]);
      } else {
        console.log("Gauge:", this.panel.metadata.gauge);
        this.d.clickElement("panel-gauge-select");

        //setTimeout(() => { $("#panel-gauge-select").click().focus(); }, 500);

      }
    } else {
      let targetGauge = this.gauges.find((g: any) => { return g.Gauge == this.d.model.targetPanel.Gauge });
      this.selectGauge(targetGauge);
      delete this.d.model.targetPanel; //it has served it's purpose, and having it here will mess up normal reentrance of this component
    }


  }

  selectGauge(gauge) {
    let ctrl = this;


    if (this.panel.metadata.gauge.hasOwnProperty('Gauge')) {
      if (gauge.Gauge != this.panel.metadata.gauge.Gauge) {
        //console.log("Gauge changed! should clear pricing!");
        this.clearPricing();
      } else {
        //console.log("no change! nothing should happen!");
        return;
      }
    }


    ctrl.panel.metadata.gauge = gauge;
    ctrl.semaphore += 1;
    //ctrl.clearPricing();
    //ctrl.focusInput("cut-qty-0");
    //$('.dropdown-toggle').removeClass('drop-focus');
    //$("#panel-gauge-select").blur();
    //$("#cut-qty-0").click().focus();

  }

  colorIsUnavailable(color, panel) {
    var badCodes = []; //"DGNC,RRDC,SLGC,WCPC".split(",");
    if (
      (badCodes.indexOf(color.ColorCode) > -1)
      && panel.metadata.product.MATERIAL_NUMBER == "SK12_P"
      /* && panel.metadata.product.CATEGORY_ID.indexOf("SA03") > -1*/
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearPricing() {


    if (this.panel.metadata.pricingSetResult.NetPriceTotal == "0.00") return;
    //console.log("panel clearing price!"); 
    this.panel.metadata.pricingSetResult = {
      Colorcode: "",
      Currency: "USD",
      CutlistList: null,
      DeliveryDate: "",
      DiscountAmt: "0.00",
      DiscountPct: "0.00",
      DownhillSlope: "",
      Gauge: "",
      Grade: "",
      Hash: "",
      ItemSubTotal: "0.00",
      ItemTotal: "0.00",
      LLFixExtra: "0.00",
      LLVarExtra: "0.00",
      Materialnumber: "",
      Metal: "",
      NetPrice: "0.00",
      NetPriceTotal: "0.00",
      PackagingType: "",
      PaintSystem: "",
      Perunit: "1",
      ReqQty: "0.000",
      ShipPlant: "",
      ShipTo: "",
      ShortcutExtra: "0.00",
      SoldTo: "",
      Type: "",
      Unitofmeasure: "",
      UphillSlope: "",
      WillCall: ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : "")
    }
  }


}
