import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  //styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements OnInit, OnDestroy {
  panelType: string = "";
  paramsSubscription: any;


  constructor(public d: DataService, private router: Router, private route: ActivatedRoute) {
    this.paramsSubscription = this.route.params.subscribe((result) => {
      this.panelType = result["id"];
    });
  }

  ngOnInit() {
    /*
    //fill panels with artificial data so we can debug it without going through the normal required steps.
    if (!this.d.model.hasOwnProperty("panels")) {
      this.d.model.panels = JSON.parse(localStorage.getItem('panels'));
      this.d.chunkPanels();
      //console.dir(ctrl.d.model.panels);
    }
    */
  }
  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      //console.log("OrdersComponent unsubscribing from route changes!");
      this.paramsSubscription.unsubscribe();
    }
  }

  selectPanel(panel) {

    if (panel.ShippingNotValid == 'X') return this.d.toast('Available in Alaska Only', 2000);

    if (this.d.logFlags.includes("OFFERING_ID")) this.d.log("panels.component.ts 60");
    this.router.navigate(['/configure', panel.OFFERING_ID]);

  }

  mtsPanelsGo(g: any) {
    if (g == '26') {
      if (this.d.model.ShipTo.SHIPPINGPLANT != 'AN03') return this.d.toast('Stock available in Alaska Only,<br><br>Made to order is available instead', 2000);
    }
    //let data = await ctrl.d.inlineGet("/GetBasecolorId?id=" + ctrl.panel.metadata.color.ColorCode);
    //ctrl.panel.metadata.color.BasecolorId = data.BasecolorId;
    this.d.model.srcPanel = { "mtsPanel": true, gauge: g };
    this.router.navigate(['/accessories']);
  }

}
