import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { TdlParser } from 'src/app/tdlParser';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  //styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  //private sub: Subscription;
  constructor(public d: DataService, public router: Router, public route: ActivatedRoute) { }

  public orderType: string;
  public orderId: string;
  public orderList: any[];
  public selectedOrder: any;
  public pdfLink: string;
  public pdfDlLink: string;
  public typeName: string;
  public activeTab: string;
  public addAllActive: boolean = true;
  public paramsSubscription: any;

  public pdfBytes: Blob;
  public pdfUrl: string;
  public pdfReady = false;
  public pdfState = 0; // 0 = loading, 1 = loaded, 2 = not available.

  ngOnInit() {

    this.typeName = "";
    this.activeTab = "summary";

    this.paramsSubscription = this.route.params.subscribe((result) => {
      this.orderType = result["type"];
      this.orderId = result["id"];
      //console.log(this.orderId);
      if (this.orderId !== undefined) {
        this.loadOrder(this.orderId);
      }

      switch (this.orderType) {
        case ('history'): this.typeName = "Order"; break;
        case ('templates'): this.typeName = "Template"; break;
      }

      //if (this.orderType == 'history') $("#btn-recent-orders").click();
      this.d.get("/GetSaleslistSet?custId=" + this.d.model.SoldTo.Soldto + "&orderType=" + this.orderType, {}).subscribe(result => {

        this.orderList = result;
        if (this.orderList.length == 0) {
          return this.d.toast("No " + this.typeName + "s have been created yet");
        }
        //if (this.orderList.length > 0) this.loadOrder(this.orderList[0].Vbeln);
        if (this.orderId === undefined) this.router.navigate(['/orders/' + this.orderType + '/' + this.orderList[0].Vbeln])
      });
    });



  }

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      //console.log("OrdersComponent unsubscribing from route changes!");
      this.paramsSubscription.unsubscribe();
    }
  }
  navChange(e) {
    console.log('navChange: ' + e.nextId)//
    if (e.nextId == 'history') {

    }
    //this.selectTab(e.nextId);
  }

  loadOrder(orderId) {
    console.log("loading order " + orderId);
    this.selectedOrder = null;
    this.pdfReady = false;
    this.pdfBytes = null;
    this.pdfState = 0;
    URL.revokeObjectURL(this.pdfUrl);

    //this.pdfLink = this.d.API_ROOT + "/Endpoint/OrderConfirmationPDF/" + orderId + "/" + this.d.model.SoldTo.Soldto + "/false/ASC_SalesOrder_Confirmation_" + orderId + ".pdf";
    //this.pdfDlLink = this.d.API_ROOT + "/Endpoint/OrderConfirmationPDF/" + orderId + "/" + this.d.model.SoldTo.Soldto + "/true/ASC_SalesOrder_Confirmation_" + orderId + ".pdf";
    this.pdfLink = "/OrderConfirmationPDF/" + orderId + "/" + this.d.model.SoldTo.Soldto + "/false/ASC_SalesOrder_Confirmation_" + orderId + ".pdf";
    this.pdfDlLink = "/OrderConfirmationPDF/" + orderId + "/" + this.d.model.SoldTo.Soldto + "/true/ASC_SalesOrder_Confirmation_" + orderId + ".pdf";


    this.d.get("/GetSalesOrderSet?id=" + orderId + "&custId=" + this.d.model.SoldTo.Soldto, {}).subscribe(result => {
      var data = result;
      this.addAllActive = true;

      data.OrderItemSet.results.forEach((item) => {

        if (item.ValidForImport != "X") this.addAllActive = false;

        let imageUrl = "";
        if (item.OfferingID != '0000000000') {
          //it's a panel
          imageUrl = this.d.API_ROOT + "/cms/images/ProductOffering/" + item.OfferingID + ".gif";
        } else {
          if (item.BuyOutCategoryId != "") {
            //it's an accessory
            //imageUrl = "/images/Trim/BO_" + item.BuyOutCategoryId + ".jpg";
            imageUrl = this.d.API_ROOT + "/cms/images/Category/" + item.BuyOutCategoryId + ".jpg";
          } else {
            //it's a trim
            imageUrl = this.d.API_ROOT + "/cms/images/Trim/" + item.Materialnumber + ".svg";
          }
        }
        item.metadata = {
          product: {
            //ImageUrl: (imageUrl.length == 0 ? "" : this.d.model.imageProxyUrl + "?" + imageUrl)
            ImageUrl: (imageUrl.length == 0 ? "" : imageUrl)
          }
        }
        if (item.hasOwnProperty("TdlData")) {
          if (item.TdlData != null && item.TdlData != "") {
            //console.log("TdlData:");
            //console.dir(item.TdlData);
            item.metadata.TDLData = (item.TdlData.length > 0) ? JSON.parse(item.TdlData) : {};
          } else {
            item.metadata.TDLData = {}
            //item.ValidForImport = "";
            //item.InvalidReason = "Trim Definition is Invalid";
          }
        }

        if (item.Colorcode.length > 0) {
          item.metadata.color = {
            ImageUrl: this.d.API_ROOT + "/cms/images/PaintChips/" + item.Colorcode + ".gif"
          }
        }
        if (item.hasOwnProperty("DrawingNumber")) {
          if (item.DrawingNumber != '0000000000' && !this.d.isNil(item.DrawingNumber) && item.DrawingNumber.length > 0) {

            var svgUrl = `${this.d.API_ROOT}/Endpoint/GetSVG/${item.DrawingNumber}/false/${item.DrawingNumber}.svg`;
            item.metadata.product.ImageUrl = svgUrl;


            //item.metadata.product.ImageUrl = `${this.d.API_ROOT}/Endpoint/GetSVG/${item.DrawingNumber}/false/${item.DrawingNumber}.svg`;
          }
        }
      });

      this.selectedOrder = data;
      this.d.hideFilters();

      if (this.orderType == 'history') {
        this.loadPdf();
      }

    });
  }

  loadPdf() {
    // download the PDF byptes


    this.d.download(this.pdfDlLink).subscribe((result) => {
      console.log("Downloaded PDF!");
      this.pdfBytes = result;
      this.pdfState = 1;
      this.pdfUrl = URL.createObjectURL(this.pdfBytes) + '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';
      this.pdfReady = true;
    }, (error) => {
      this.pdfState = 2;
    });
  }

  downloadPDF() {
    var linkParts = this.pdfDlLink.split('/');
    console.log(linkParts);
    var fileName = linkParts[linkParts.length - 1];
    this.d.saveBlobAs(this.pdfBytes, fileName)
  }

  progressEvent(eventName, event) {
    //console.log('progressEvent,'+eventName, event);

    //alert('Order Confirmation not yet created, please contact support');
  }

  previewPdf() {

    let tempLink = this.pdfLink;
    this.pdfLink = "";
    setTimeout(() => {
      this.pdfLink = tempLink;
    }, 100);
  }
  configurePanel(item) {


    if (item.OfferingID == "0000000000") {
      //this is really a buyout!
      var cleanItem = this.d.deref(item);
      cleanItem.CutlistProvide = "B";
      this.copyToCart(cleanItem);
      return;
    }

    //http://localhost:56865/configure/0000002105
    //console.log(item);

    this.d.model.targetPanel = this.d.deref(item);

    this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], '/configure/' + item.OfferingID);
  }
  displayInvalidReason(item) {

    this.d.toast(item.InvalidReason);
  }


  async addAllToCart() {



    if (!this.d.model.ShipMode.hasOwnProperty('Name') || this.d.model.ShipMode.Name === undefined) {
      //console.log("ShipTo requirement NOT met!");
      this.d.appRef.lastAction = () => { this.addAllToCart(); }

      this.d.appRef.targetAction = "BUYOUT";
      this.selectedOrder.OrderItemSet.results.forEach((item) => {
        if (item.CutlistProvide == "Y") this.d.appRef.targetAction = "CF";
      });
      this.selectedOrder.OrderItemSet.results.forEach((item) => {
        if (item.CutlistProvide == "T") this.d.appRef.targetAction = "TRIM";
      });

      this.d.appRef.changeShipto();
      return;
    }

    await this.d.initXyData();
    await this.d.initFctData();

    for (var i = 0; i < this.selectedOrder.OrderItemSet.results.length; i++) {
      this.d.toast("Adding Item " + (i + 1) + "000...");

      let itemCopy = this.d.deref(this.selectedOrder.OrderItemSet.results[i]);
      itemCopy.ColorCode = itemCopy.Colorcode;
      delete itemCopy.Colorcode;

      //This was a workaround for 2_5C_PANELS, which were returned from SAP with a CutlistProvide: "Y", but SAP will correct the value instead!
      //if (itemCopy.OfferingID == "0000000000") itemCopy.CutlistProvide = "B"; //this is really a buyout! 

      if (itemCopy.CutlistProvide == "Y") {
        //different rules for panels

        //in order for this to work, we have to use the OfferingID to go grab the panel data from
        //
        if (this.d.logFlags.includes("OFFERING_ID")) this.d.log("orders.component.ts 206");
        itemCopy.metadata = {
          panel: {
            metadata: this.d.model.panels.find((p) => p.OFFERING_ID == itemCopy.OfferingID)
          }
        };
        /*
        if (itemCopy.metadata.panel.metadata === undefined){
          this.d.toast("Offering ID " + parseInt(this.d.model.targetPanel.OfferingID,10) + " not available, contact CSR");
          continue;
        }
        */
        //how many incompatible versions of the same model do we need?
        let CutlistList = itemCopy.CutlistSet.results;
        let totalInches = 0;
        CutlistList.forEach((cut) => {
          delete cut.Cutlistnum;
          delete cut.Salesordernumber;
          delete cut.Hash;
          var inches = ((parseInt(cut.Feet, 10) * 12) + (parseInt(cut.Inches, 10) + (parseInt(cut.Fractions, 10) / 16)));
          totalInches += parseInt(cut.Quantity, 10) * inches;

        });

        let reqQty = 0;
        if (itemCopy.SalesUoM == "SQ") {
          reqQty = ((totalInches * Number(itemCopy.ConvFactor)) / 144) / 100;
        } else {
          reqQty = totalInches / 12;
        }



        itemCopy.metadata.pricingPayload = {
          "ReqQty": reqQty.toFixed(3), //itemCopy.Quantity001,
          "SoldTo": this.d.model.SoldTo.Soldto,
          "Materialnumber": itemCopy.Materialnumber, //this.panel.metadata.product.MATERIAL_NUMBER,
          "Colorcode": itemCopy.ColorCode,
          "Gauge": itemCopy.Gauge,
          "PaintSystem": itemCopy.PaintSystem,
          "DownhillSlope": itemCopy.DownhillSlope,
          "UphillSlope": itemCopy.UphillSlope,
          "Metal": itemCopy.Metal,
          "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
          "Type": "O", // Offering: all panels are offerings
          "Grade": itemCopy.Grade,
          "Unitofmeasure": "",
          "Perunit": "0",
          "NetPrice": "0",
          "Currency": "",
          "ShipTo": this.d.model.ShipTo.SHIPTO,
          "DeliveryDate": "",
          "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
          "Hash": "",
          "PackagingType": itemCopy.PackagingType,
          "CutlistList": CutlistList,
          "Ctwt": itemCopy.Ctwt
        }


        itemCopy.metadata.pricingSetResult = await this.d.inlinePost("/GetPricingSet", {}, itemCopy.metadata.pricingPayload, true);

        let orderItem = {
          "OfferingID": "", //always blank unless a panel
          "Itemnumber": "001000", //reindexed in cart
          "Product": itemCopy.Materialnumber, //"CH16_P",
          "Materialnumber": itemCopy.Materialnumber, //"CH16_P",
          "ColorCode": itemCopy.ColorCode, //"RUSC",
          "Gauge": itemCopy.Gauge, //"26",
          "CutlistProvide": itemCopy.CutlistProvide, //"B", //has to be B, T or Y
          "Productname": itemCopy.Productname, //"UPHILL CHIMNEY PTD (CH16_P)",
          "PackagingType": itemCopy.PackagingType,
          "Metal": itemCopy.Metal, //"ZNAL",
          "Ctwt": itemCopy.Ctwt, //"ZNAL",
          "Grade": itemCopy.Grade, //"33",
          "PaintSystem": itemCopy.PaintSystem, //"DTXL",
          "UphillSlope": itemCopy.metadata.pricingPayload.UphillSlope,//"0.5",
          "DownhillSlope": itemCopy.metadata.pricingPayload.DownhillSlope, //"NA",
          "Requesteddelivery": itemCopy.metadata.pricingSetResult.DeliveryDate,//"20170130",
          "Unitofmeasure": itemCopy.metadata.pricingSetResult.Unitofmeasure, //"PC",
          "Quantity001": reqQty.toFixed(3), //itemCopy.Quantity001, //"2.000",
          "Plant": itemCopy.metadata.pricingPayload.ShipPlant, //"SA03",
          "metadata": itemCopy.metadata
        }
        if (this.d.logFlags.includes("OFFERING_ID")) this.d.log("orders.component.ts 290");
        orderItem.metadata.product = this.d.model.panels.find((p) => p.OFFERING_ID == itemCopy.OfferingID);

        let cutlist = [];
        itemCopy.CutlistSet.results.forEach((cut, i) => {
          cut.Linenumber = (i + 1);
          cut.Material = itemCopy.Materialnumber;
          cut.Errors = [];
          cut.TotalInches = "0";

          cut.Quantity = this.d.trimLeadingZeros(cut.Quantity);
          cut.Feet = this.d.trimLeadingZeros(cut.Feet);
          cut.Inches = this.d.trimLeadingZeros(cut.Inches);
          cut.Fractions = this.d.trimLeadingZeros(cut.Fractions);
          cut.TotalInches = this.d.trimLeadingZeros("0" + ((parseInt(cut.Feet, 10) * 12) + parseInt(cut.Inches, 10)));
          delete cut.BundlingNote;
          delete cut.Cutlistnum;
          delete cut.Hash;
          delete cut.Notes;
          delete cut.Salesordernumber;

          cutlist.push(cut);
        });
        //console.dir(cutlist);
        orderItem.metadata.cutlist = cutlist;
        orderItem.metadata.color = this.d.model.colorSet.find((c) => c.ColorId == itemCopy.ColorCode);
        orderItem.metadata.color.ImageUrl = "/images/PaintChips/" + itemCopy.ColorCode + ".gif"
        //console.log("color found:");
        //console.log(orderItem.metadata.color);

        //TODO: sometimes orderItem.metadata.product is undefined?
        //var svcUrl = "/GetColorGaugeSet?offeringId=" + orderItem.metadata.product.OFFERING_ID + "&plant=" + orderItem.Plant + "&colorCode=" + orderItem.ColorCode;
        var svcUrl = "/GetColorGaugeSet?offeringId=" + itemCopy.OfferingID + "&plant=" + orderItem.Plant + "&colorCode=" + orderItem.ColorCode + "&src=orders";
        var colorGaugeSet = await this.d.inlineGet(svcUrl);

        orderItem.metadata.gauge = colorGaugeSet.find((g) => g.Gauge == orderItem.Gauge);;


        orderItem.metadata.packaging = this.d.model.packagingTypeSet.find((p) => p.PackType == itemCopy.PackagingType);


        this.d.addToCartAnim();
        /*
        var requiredMetaData = ["color", "cutlist", "gauge", "packaging", "pricingPayload", "pricingSetResult", "product"];
        _.each(requiredMetaData, (m) => {
          console.log(m, orderItem.metadata[m]);
        });
        */
        this.d.model.cart.OrderItemSet.push(orderItem);



      }
      else //MASS IMPORT TRIMS AND ACCESSORIES
      {

        this.copyToCart(itemCopy);
        /*
        itemCopy.metadata.TDLData = JSON.parse(itemCopy.TdlData);



        //trims and accessories
        itemCopy.metadata.Quantity = (parseInt(itemCopy.Quantity001, 10));
        itemCopy.metadata.pricingPayload = {
          "ReqQty": (parseInt(itemCopy.Quantity001, 10)),
          "SoldTo": this.d.model.SoldTo.Soldto,
          "Materialnumber": itemCopy.Materialnumber,
          "Colorcode": itemCopy.Colorcode, //UNDEFINED
          "Gauge": itemCopy.Gauge,  //UNDEFINED
          "PaintSystem": itemCopy.PaintSystem,  //UNDEFINED
          "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
          "DownhillSlope": itemCopy.DownhillSlope,
          "UphillSlope": itemCopy.UphillSlope,
          "Type": "T", //doesn't matter if it's really a buyout, this value is ignored!
          "Metal": itemCopy.Metal,  //UNDEFINED
          "Grade": itemCopy.Grade,  //UNDEFINED
          "ShipTo": this.d.model.ShipTo.SHIPTO,
          "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
          "Ctwt": itemCopy.Ctwt,  //UNDEFINED

          "Girth": 0.0 + parseFloat(itemCopy.Girth.toString()), //TODO: look up actual values
          "Hems": parseInt(itemCopy.Hems),
          "Strokes": parseInt(itemCopy.Strokes),
          "Pitch": parseInt(itemCopy.Pitch)
        }
        itemCopy.metadata.pricingSetResult = await this.d.inlinePost("/GetPricingSet", {}, itemCopy.metadata.pricingPayload, true);

        let orderItem = {
          "OfferingID": "", //always blank unless a panel
          "Itemnumber": "001000", //reindexed in cart
          "Product": itemCopy.Materialnumber, //"CH16_P",
          "Materialnumber": itemCopy.Materialnumber, //"CH16_P",
          "Colorcode": itemCopy.Colorcode, //"RUSC",
          "Gauge": itemCopy.Gauge, //"26",
          "CutlistProvide": itemCopy.CutlistProvide, //"B", //has to be B, T or Y
          "Productname": itemCopy.Productname, //"UPHILL CHIMNEY PTD (CH16_P)",
          "Metal": itemCopy.Metal, //"ZNAL",
          "Ctwt": itemCopy.Ctwt, //"ZNAL",
          "Grade": itemCopy.Grade, //"33",
          "PaintSystem": itemCopy.PaintSystem, //"DTXL",
          "UphillSlope": itemCopy.metadata.pricingPayload.UphillSlope,//"0.5",
          "DownhillSlope": itemCopy.metadata.pricingPayload.DownhillSlope, //"NA",
          "Requesteddelivery": itemCopy.metadata.pricingSetResult.DeliveryDate,//"20170130",
          "Unitofmeasure": itemCopy.metadata.pricingSetResult.Unitofmeasure, //"PC",
          "Quantity001": (itemCopy.metadata.Quantity + ".000"), //"2.000",
          "Plant": itemCopy.metadata.pricingPayload.ShipPlant, //"SA03",
          "Girth": 0.0 + parseFloat(itemCopy.Girth.toString()),
          "Hems": parseInt(itemCopy.Hems),
          "Strokes": parseInt(itemCopy.Strokes),
          "Pitch": parseInt(itemCopy.Pitch),
          "metadata": itemCopy.metadata
        }
        this.d.addToCartAnim();
        this.d.model.cart.OrderItemSet.push(orderItem);
        */


      }

    }

    this.d.toast("Done adding items to cart");
    this.router.navigate(['/cart']);
  }




  async copyToCart(item) {
    console.log("copyToCart");

    //console.log("copyToCart, initial state:");
    //console.dir(item);

    if (!this.d.model.ShipMode.hasOwnProperty('Name') || this.d.model.ShipMode.Name === undefined) {
      this.d.updateLoader(1);
      //console.log("ShipTo requirement NOT met!");
      this.d.appRef.lastAction = () => { this.copyToCart(item); }

      this.d.appRef.targetAction = "BUYOUT";
      this.selectedOrder.OrderItemSet.results.forEach((item) => {
        if (item.CutlistProvide == "Y") this.d.appRef.targetAction = "CF";
      });
      this.selectedOrder.OrderItemSet.results.forEach((item) => {
        if (item.CutlistProvide == "T") this.d.appRef.targetAction = "TRIM";
      });

      setTimeout(() => {
        //this.d.appRef.changeShipto();
        this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], this.router.url);
      }, 500)
      this.d.updateLoader(-1);
      return this.d.toast("Select a Ship To, then try again");
    }

    this.d.updateLoader(1);
    await this.d.initXyData();
    await this.d.initFctData();

    let itemCopy = this.d.deref(item);

    if (itemCopy.hasOwnProperty('Colorcode')) { //it won't if adding individualy, but will if adding all
      itemCopy.ColorCode = itemCopy.Colorcode;
      delete itemCopy.Colorcode;
    }

    //console.log(itemCopy);
    itemCopy.metadata.Quantity = (parseInt(itemCopy.Quantity001, 10));
    itemCopy.metadata.pricingPayload = {
      "ReqQty": (parseInt(itemCopy.Quantity001, 10)),
      "SoldTo": this.d.model.SoldTo.Soldto,
      "Materialnumber": itemCopy.Materialnumber,
      "Colorcode": itemCopy.ColorCode, //UNDEFINED
      "Gauge": itemCopy.Gauge,  //UNDEFINED
      "PaintSystem": itemCopy.PaintSystem,  //UNDEFINED
      "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
      "DownhillSlope": itemCopy.DownhillSlope,
      "UphillSlope": itemCopy.UphillSlope,
      "Type": "T", //doesn't matter if it's really a buyout, this value is ignored!
      "Metal": itemCopy.Metal,  //UNDEFINED
      "Grade": itemCopy.Grade,  //UNDEFINED
      "Girth": 0.0 + parseFloat(itemCopy.Girth), //must be a float
      "ShipTo": this.d.model.ShipTo.SHIPTO,
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
      "Ctwt": itemCopy.Ctwt,  //UNDEFINED
      "Hems": itemCopy.Hems,
      "Strokes": itemCopy.Strokes,
      "Pitch": itemCopy.Pitch,
      "ShippingModeId": this.d.model.ShipMode.ShippingModeId
    }
    //if (itemCopy.metadata.pricingPayload.Girth === undefined) itemCopy.metadata.pricingPayload.Girth = 0.0;

    this.d.post("/GetPricingSet", {}, itemCopy.metadata.pricingPayload, true).subscribe(async (result) => {
      this.d.updateLoader(1);

      if (result.ItemTotal == "0.00") {
        this.d.toast("Item price cannot be determined. Please contact your Customer Service Representative");
        return;
      }

      itemCopy.metadata.pricingSetResult = result;

      let orderItem: any = {
        "OfferingID": "", //always blank unless a panel
        "Itemnumber": "001000", //reindexed in cart
        "Product": itemCopy.Materialnumber, //"CH16_P",
        "Materialnumber": itemCopy.Materialnumber, //"CH16_P",
        "ColorCode": itemCopy.ColorCode, //"RUSC",
        "Gauge": itemCopy.Gauge, //"26",
        "CutlistProvide": itemCopy.CutlistProvide, //"B", //has to be B, T or Y
        "Productname": itemCopy.Productname, //"UPHILL CHIMNEY PTD (CH16_P)",
        "Metal": itemCopy.Metal, //"ZNAL",
        "Ctwt": itemCopy.Ctwt, //"ZNAL",
        "Grade": itemCopy.Grade, //"33",
        "Girth": 0.0 + parseFloat(itemCopy.Girth), //must be a float
        "PaintSystem": itemCopy.PaintSystem, //"DTXL",
        "UphillSlope": itemCopy.metadata.pricingPayload.UphillSlope,//"0.5",
        "DownhillSlope": itemCopy.metadata.pricingPayload.DownhillSlope, //"NA",
        "Requesteddelivery": itemCopy.metadata.pricingSetResult.DeliveryDate,//"20170130",
        "Unitofmeasure": itemCopy.metadata.pricingSetResult.Unitofmeasure, //"PC",
        "Quantity001": parseInt(itemCopy.metadata.Quantity), //"2.000",
        "Plant": itemCopy.metadata.pricingPayload.ShipPlant, //"SA03",
        "Hems": parseInt(itemCopy.Hems),
        "Strokes": parseInt(itemCopy.Strokes),
        "Pitch": parseInt(itemCopy.Pitch),
        "metadata": itemCopy.metadata
      }
      console.log(1);
      if (itemCopy.TdlData.length > 0) {
        console.log(2);

        //orderItem.metadata.TDLData = JSON.parse(itemCopy.TdlData);


        if (orderItem.metadata.TDLData.flanges.filter((f) => { return f.hasOwnProperty("length"); }).length > 0) {
          this.d.toast("Cannot import beta version custom trims");
          return;
        }
        console.log(3);
        orderItem.metadata.Name = itemCopy.Productname;
        orderItem.metadata.PictureId = itemCopy.Materialnumber;
        orderItem.metadata.svg = await this.d.inlineDownload(itemCopy.metadata.product.ImageUrl);
        console.log('orderItem.metadata.svg', orderItem.metadata.svg);
        orderItem.metadata.mins = this.d.model.TrimFullyCustomSet[0].HasPOPCapability;
        console.log(4);

        try {
          var tdlParser = new TdlParser(this.d, itemCopy, 'trimsvg_xy');
          if (tdlParser.model.punchIntersections.length > 0) {
            orderItem.metadata.cartError = {
              Type: 'PB_COLL_WARN',
              Message: 'Press Brake Collision Warning'
            };
          }
        } catch (e) {
          console.log('TDL Parser Error:');
          console.dir(e);
        }

        console.log('Checking for isFullyCustom...');
        console.dir(orderItem.metadata.TDLData);
        if (orderItem.metadata.TDLData.hasOwnProperty("isFullyCustom") && orderItem.metadata.TDLData.isFullyCustom) {
          console.log('isFullyCustom found');
          //START fully custom trim data retreival
          orderItem.metadata.TrimFullyCustomSet = this.d.deref(this.d.model.TrimFullyCustomSet);

          delete orderItem.metadata.color;
          var colors = await this.d.inlineGet('/GetTrimFullyCustomGetFilterBaseColor?GaugeId=' + itemCopy.Gauge, {});
          colors.forEach((color) => {
            //console.log("BasecolorId 1");
            //console.log("color", color);
            var baseColor = this.d.model.colorSet.find((c) => c.BasecolorId == color.BasecolorId);
            //console.log("baseColor", baseColor);
            if (baseColor !== undefined) {
              color.ImageUrl = "/images/PaintChips/" + baseColor.ColorId + ".gif"
              color.ColorId = baseColor.ColorId;
              color.RGB = baseColor.RGB;
              //console.log("BasecolorId 2");
              color.BasecolorId = baseColor.BasecolorId;

            } else {
              color.ImageUrl = "";
            }
          });
          orderItem.metadata.TrimXYGroupFilterBaseColor = colors;
          let baseColor = this.d.model.colorSet.find((c) => c.ColorId == itemCopy.ColorCode);
          //console.log("looking in colorset: ", this.d.model.colorSet, ", for item with ColorId equal to this ColorCode: ", itemCopy.ColorCode);
          //console.log(baseColor);
          orderItem.metadata.color = orderItem.metadata.TrimXYGroupFilterBaseColor.find((c) => c.BasecolorId == baseColor.BasecolorId);
          //console.log("BasecolorId 3");
          orderItem.metadata.materialType = (orderItem.metadata.color.BasecolorId == 1) ? "B" : "P";

          switch (itemCopy.metadata.TDLData.paintedSide) {
            case "L":
              itemCopy.metadata.lColor = itemCopy.metadata.color.RGB;
              itemCopy.metadata.rColor = "#A9B0B7";
              break;
            case "R":
              itemCopy.metadata.lColor = "#A9B0B7";
              itemCopy.metadata.rColor = itemCopy.metadata.color.RGB;
              break;
            default:
              //console.log("TDLData.paintedSide: " + itemCopy.metadata.TDLData.paintedSide);
              itemCopy.metadata.lColor = "#A9B0B7";
              itemCopy.metadata.rColor = "#A9B0B7";
          }


          //END fully custom trim data retreival
        } else {
          console.log('isFullyCustom NOT found, so this is standard XY');
          //START standard XY data retreival
          orderItem.metadata.TrimXYGroupId = itemCopy.Materialnumber.split("_")[0];
          orderItem = await this.d.retreiveTrimMetadata(orderItem);
          orderItem.metadata.TrimXYGroupFilterGauge.forEach(async (item) => {
            item.selected = item.GaugeId == itemCopy.Gauge;
            if (item.selected) {
              var TRIMXYGROUPID = orderItem.metadata.TrimXYGroupId;
              var colors = await this.d.inlineGet('/GetTrimXYGroupFilterBaseColor?TRIMXYGROUPID=' + TRIMXYGROUPID + '&GAUGEID=' + item.GaugeId, {});
              colors.forEach(async (color) => {
                var baseColor = this.d.model.colorSet.find((p) => p.BasecolorId == color.BasecolorId);
                //console.log("BasecolorId 4");
                if (color.BasecolorId == itemCopy.metadata.color.BasecolorId) {
                  //console.log("Found color.BasecolorId: " + color.BasecolorId);

                  itemCopy.metadata.color.BasecolorDescription = color.BasecolorDescription;

                  //console.log(itemCopy.metadata.color);
                  //console.log("BasecolorId 5");
                  var coatingId = (color.BasecolorId == 1) ? "B" : "P";
                  itemCopy.metadata.TrimXY = itemCopy.metadata.TrimXYSet.find((t) => t.CoatingId == coatingId);

                  itemCopy.metadata.hems = {
                    startHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }],
                    endHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }]
                  }

                  itemCopy.metadata.TrimXY.TrimXYSlopeCombinedSet = await this.d.inlineGet('/GetTrimXYSlopeCombinedSet?TrimXYId=' + itemCopy.metadata.TrimXY.TrimXYId, {});
                  itemCopy.metadata.TrimXY.TrimXYSlopeCombinedSet.sort((a, b) => { return a.Degree - b.Degree; });

                  switch (itemCopy.metadata.TDLData.paintedSide) {
                    case "L":
                      itemCopy.metadata.lColor = itemCopy.metadata.color.RGB;
                      itemCopy.metadata.rColor = "#A9B0B7";
                      break;
                    case "R":
                      itemCopy.metadata.lColor = "#A9B0B7";
                      itemCopy.metadata.rColor = itemCopy.metadata.color.RGB;
                      break;
                    default:
                      //console.log("TDLData.paintedSide: " + itemCopy.metadata.TDLData.paintedSide);
                      itemCopy.metadata.lColor = "#A9B0B7";
                      itemCopy.metadata.rColor = "#A9B0B7";
                  }


                }
                if (baseColor !== undefined) {
                  color.ImageUrl = "/images/PaintChips/" + baseColor.ColorId + ".gif"
                  color.ColorId = baseColor.ColorId;
                  color.RGB = baseColor.RGB;
                } else {
                  color.ImageUrl = "";
                }
              });
              orderItem.metadata.TrimXYGroupFilterBaseColor = colors;
            }

          });
          orderItem.metadata.color = this.d.model.colorSet.find((c) => c.ColorId == itemCopy.ColorCode);
          //END standard XY data retreival
        }



        orderItem.metadata.color.ImageUrl = "/images/PaintChips/" + itemCopy.ColorCode + ".gif"

      }
      //console.log("copyToCart, final state:");
      //console.dir(orderItem);
      this.d.addToCartAnim();
      this.d.model.cart.OrderItemSet.push(orderItem);
      this.d.toast("Item repriced and added to cart");
      this.d.updateLoader(-1);
    });
    this.d.updateLoader(-1);
  }


}
