import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';


@Pipe({ name: 'demoprice', pure: false })
export class DemoPricePipe implements PipeTransform {
	constructor(private dataSvc: DataService) { }
	transform(value: string): any {
		if (!value) return value;
		if (this.dataSvc.demoMode) {
			return '$-.--';
		} else {
			return value;
		}
	}
}