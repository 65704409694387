import { Component, ContentChild, DebugElement, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DataService } from 'src/app/data.service';
import { ShipTo } from 'src/app/models/ShipTo';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';



@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  //styleUrls: ['./manage-users.component.scss']
})
//todo: fix failure to shrink header bar on narrow displays: https://medium.com/the-crazy-coder/the-mystery-of-css-flex-layout-items-shrinking-8748145e96d9

export class ManageUsersComponent implements OnInit {

  @ViewChild("userModal", { static: true }) userModal: TemplateRef<any>;
  @ViewChild("soldtoModal", { static: true }) soldtoModal: TemplateRef<any>;
  @ViewChild("passwordResetModal", { static: true }) passwordResetModal: TemplateRef<any>;

  userModalRef: BsModalRef;
  soldtoModalRef: BsModalRef;
  passwordResetModalRef: BsModalRef;

  items: Observable<Array<string>>;
  shipTos: Array<ShipTo>;


  asc: boolean = false;
  sc: string = "";
  lastSort: string = "LastName";

  users: any[] = [];
  editUser: any = this.newUser();
  userErrors: string[] = [];
  strUserErrors: string = "";

  searchTerms: string = "";
  shiptoSearchTerms: string = "";
  selectedShipto: string = "";
  selfSend: boolean = false;

  hideDisabled: boolean = false;

  constructor(
    public d: DataService
  ) { }

  ngOnInit(): void {
    //if (typeof window == 'undefined') return; //do not render server-side



    console.log("manage-users ngOnInit");

    //$("#loadingMask").fadeIn();
    this.d.get("/GetUserList", {}).subscribe((result) => {
      this.users = result;
      //$("#loadingMask").fadeOut();
      this.sort(this.lastSort);
      //sort shiptos
      this.users.forEach(user => {
        if (user.CustomerIds) {
          user.CustomerIds = user.CustomerIds.split(',').sort((a, b) => { return a - b; }).join(',');
        }
        user.keyWords = [user.UserName, user.FirstName, user.LastName, user.CustomerIds].join(" ").toLowerCase();;
        user.found = true;
        user.selected = false;
      });

      //console.table(ctrl.users);

      //since the shipto data may not have arrived yet, wait until it's here:
      this.d.wait(
        () => { return this.d.model.customerSet !== undefined },
        () => { this.joinShipTos(this.users) },
        100);

    });

  }

  joinShipTos(userList) {

    userList.forEach(user => {

      let soldTolist = [];
      this.d.model.customerSet;

      if (user.CustomerIds) {
        let CustomerIds = user.CustomerIds.split(',');
        CustomerIds.forEach(id => {


          let match = this.d.model.customerSet.find((o) => {
            //console.log("Comparing " + o.Soldto + " to " + id);
            return Number(o.Soldto) == Number(id);
          });
          if (match) {
            user.keyWords += " " + [match.City, match.Country, match.Name, match.SearchTerm, match.State].join(" ").toLowerCase();;
            soldTolist.push(match);
          } else {
            if (id) {
              soldTolist.push({
                "City": "?",
                "Country": "?",
                "Name": "?",
                "SearchTerm": "",
                "Soldto": id,
                "State": "?",
              });
            }

          }
        });
      }
      user.soldTolist = soldTolist;
      //console.log(user);
    });

    //initial sort

    this.search();

  }

  search() {

    let aSearchTerms = this.searchTerms.toLowerCase().split(" ");
    let minScore = aSearchTerms.length;
    this.users.forEach((user) => {
      if (minScore > 0) {
        let score = 0;
        aSearchTerms.forEach((searchTerm) => {
          if (user.keyWords.indexOf(searchTerm) > -1) score += 1;
        });
        user.found = (score == minScore);
      } else {
        user.found = true;
      }


    })
  }

  sort(propName) {

    this.lastSort = propName;
    this.users = this.users.concat().sort(this.d.sortBy(propName));
    if (propName == this.sc) {
      this.asc = !this.asc;
    } else {
      this.asc = !(propName.startsWith('Is') || propName.startsWith('Can'));
      this.sc = propName;
    }
    if (!this.asc) this.users = this.users.reverse();
  }

  addSelectedShipTo() {
    let ctrl = this;
    let custList = ctrl.editUser.CustomerIds.length == 0 ? [] : ctrl.editUser.CustomerIds.split(',');

    //console.log(custList);
    if (custList.indexOf(ctrl.selectedShipto) == -1) {
      custList.push(ctrl.selectedShipto);
      ctrl.editUser.CustomerIds = custList.join(',');
      //console.log(ctrl.editUser.CustomerIds);
      ctrl.joinShipTos([ctrl.editUser]);
    }
    this.soldtoModalRef.hide();
    console.dir(this.editUser);
  }

  newUser() {
    return { "FirstName": "", "UserName": "" }
  }
  setUser(targetUser) {
    this.editUser = targetUser;
  }

  popUser(targetUser) {
    this.selectUser(targetUser);
    //this.editUser = targetUser;
    this.editUser = { ...targetUser };
    if (this.editUser.IsNew === undefined) this.editUser["EmailStatic"] = "E-Mail cannot be changed, but a new account can be created";
    this.userModalRef = this.d.showModal(this.userModal, 'modal-xl modal-dialog-centered');
    setTimeout(() => {
      this.d.focusInput("first-name");
    }, 1000);
    //$('#userModal').modal({ keyboard: false });
    //$('#userModal').modal('show');
  }

  selectUser(targetUser) {
    this.users.forEach(user => {
      user.selected = false;
    });
    targetUser.selected = true;
  }

  columnFilter(columnName) {

    let acct = this.d.model.accountInfo;

    //for each column, return false if that column should not be visible to the logged on user
    switch (columnName) {
      case 'IsItAdmin':
        if (!acct.IsItAdmin) return false;
      case 'IsFiAdmin':
        if (!acct.IsItAdmin) return false;
      case 'IsCsr':
        if (!acct.IsItAdmin) return false;
      case 'IsCorpAdmin':
        if (!(acct.IsItAdmin || acct.IsFiAdmin || acct.IsCsr)) return false;
      case 'IsBranchAdmin':
        if (!(acct.IsItAdmin || acct.IsFiAdmin || acct.IsCorpAdmin || acct.IsCsr)) return false;
      case 'CanCreateInquiry':
        return true;
      case 'CanCreateOrder':
        return true;
      case 'ManageSoldTos':
        if (!(acct.IsItAdmin || acct.IsFiAdmin || acct.IsCorpAdmin)) return false;
      default:
        return true;
    }

  }



  userIsInternal(userInfo): boolean {
    if (userInfo.soldTolist && userInfo.soldTolist.length > 0) return false;
    return (userInfo.IsItAdmin || userInfo.IsFiAdmin || userInfo.IsCsr) && (!userInfo.CanCreateInquiry && !userInfo.CanCreateOrder);
  }
  userIsExternal(userInfo): boolean {
    if (userInfo.soldTolist && userInfo.soldTolist.length > 0) return true;
    return (!userInfo.IsCorpAdmin && !userInfo.IsBranchAdmin && !userInfo.IsCsr && (userInfo.CanCreateInquiry || userInfo.CanCreateOrder));
  }


  whoAmI() {
    console.dir(this.d.model.accountInfo);
  }

  displayFilter(editUser, prop): boolean {
    switch (prop) {
      case 'IsExternal':
        if (editUser.IsCorpAdmin || editUser.IsBranchAdmin) return true;
      //if (targetUser.soldTolist && targetUser.soldTolist.length > 0) return true;
      case 'IsInternal':
        if (editUser.IsItAdmin || editUser.IsFiAdmin || editUser.IsCsr) return true;

      default:
        return false;
    }
  }

  filterUser(targetUser): boolean {

    if (targetUser.IsDisabled && this.hideDisabled) return false;

    //search:
    if (!targetUser.found) return false;

    //IT admin can see everyone, including self
    if (this.d.model.accountInfo.IsItAdmin) return true;

    //can't see your own account
    if (targetUser.UserName == this.d.model.accountInfo.UserName) return false;

    if (this.d.model.accountInfo.IsFiAdmin || this.d.model.accountInfo.IsCsr
    ) {
      //non admin users can't see themselves or other users
      if (targetUser.IsItAdmin) return false;
      if (targetUser.IsCsr) return false;
      if (targetUser.IsFiAdmin) return false;
    }

    if (this.d.model.accountInfo.IsBranchAdmin || this.d.model.accountInfo.IsCorpAdmin) {
      //if (targetUser.IsItAdmin) return false;
      //if (targetUser.IsCsr) return false;
      //if (targetUser.IsFiAdmin) return false;
      //can see any that has one of the coporates that they have
      if (!targetUser.CustomerIds) return false;
      let adminShiptos = this.d.model.accountInfo.CustomerIds.split(',');
      let targetShiptos = targetUser.CustomerIds.split(',');
      if (!this.isSubset(adminShiptos, targetShiptos)) return false;
    }
    return true;
  }

  isSubset(source, target) {
    return this.d.intersection(source, target).length > 0;
  }



  removeShipTo(editUser, cust) {
    let ctrl = this;
    let custList = editUser.CustomerIds.split(',');
    custList = this.d.pull(custList, cust.Soldto);
    editUser.CustomerIds = custList.join(',');
    //console.log(editUser.CustomerIds);
    ctrl.joinShipTos([ctrl.editUser]);
  }


  autoConfig() {
    setTimeout(() => {
      if (this.editUser.FirstName.indexOf('\t') > -1) {
        console.log(this.editUser.FirstName);

        try {
          let cfg = this.editUser.FirstName.split('\t');

          this.editUser.FirstName = cfg[0].trim();
          this.editUser.LastName = cfg[1].trim();
          this.editUser.UserName = cfg[2].trim();

          if (cfg.length > 4) {
            let adminText = cfg[4].trim().toLowerCase();
            if (adminText.indexOf("branch") > -1) {
              console.log("Setting Branch Admin...");
              this.editUser.IsBranchAdmin = true;
            } else if (adminText.indexOf("admin") > -1) {
              console.log("Setting Corp Admin...");
              this.editUser.IsCorpAdmin = true;
            }
          }

          if (cfg[3].trim().length > 0) {
            //editUser.soldTolist 
            this.editUser.soldTolist = [];
            this.editUser.CustomerIds = "";
            let aSoldtos = cfg[3].trim().split(",");
            aSoldtos.forEach((s) => {
              var soldTo = s.trim();
              //if (_.some(this.d.model.customerSetFiltered, { Soldto: soldTo })) {
              var cust = this.d.model.customerSetFiltered.find((c) => { return c.Soldto == soldTo })
              if (cust !== undefined) {
                this.editUser.soldTolist.push(cust);
              } else {
                this.d.toast("Customer ID " + soldTo + " is not valid");
              }
            });
            this.editUser.CustomerIds = this.editUser.soldTolist.map((s) => s.Soldto).join(",");
          }


        } catch (e) {
          console.error(e);
        }
      }

    }, 100);

  }

  userIsInvalid() {
    let ctrl = this;
    ctrl.userErrors = [];

    if (this.editUser.FirstName == "") ctrl.userErrors.push("No First Name");
    if (this.editUser.LastName == "") ctrl.userErrors.push("No Last Name");
    if (!this.d.validEmail(this.editUser.UserName)) ctrl.userErrors.push("Not a valid Email");

    if (this.editUser.IsNew) {
      let lcaseUser = this.editUser.UserName.trim().toLowerCase();
      if (this.users.some((u) => { return u.UserName == lcaseUser })) ctrl.userErrors.push("Email already in use");
    }

    if (this.editUser.CustomerIds === undefined) this.editUser.CustomerIds = "";
    //TODO: this is not preventing customer admins from hitting save without assigning a sold to!
    if (this.d.model.accountInfo.CustomerIds.trim().length > 0) {

      if (this.editUser.CustomerIds.trim() == 0) ctrl.userErrors.push("No Sold To assigned");
    }

    return (ctrl.userErrors.length > 0);
  }
  saveHover() {
    this.strUserErrors = this.userErrors.join(', ');
  }
  getUserErrors() {
    return this.userErrors.join(', ');
  }


  addUser() {
    this.popUser({
      "UserName": "",
      "FirstName": "",
      "LastName": "",
      "Note": "",
      "soldTolist": [],
      "CustomerIds": "",
      "IsDisabled": false,
      "IsItAdmin": false,
      "IsCsr": false,
      "IsFiAdmin": false,
      "IsBranchAdmin": false,
      "IsCorpAdmin": false,
      "CanCreateInquiry": true,
      "CanCreateOrder": true,
      "IsNew": true
    });
  }

  selectSoldto() {

    this.soldtoModalRef = this.d.showModal(this.soldtoModal, 'modal-lg modal-dialog-centered');
    //$('#soldtoModal').modal({ keyboard: false });
    //$('#soldtoModal').modal('show');
  }

  verifyTriggerPasswordReset() {
    this.passwordResetModalRef = this.d.showModal(this.passwordResetModal, 'modal-sm modal-dialog-centered');
  }

  async triggerPasswordReset() {
    var result = await this.d.inlineGet("/TriggerWelcomeEmail?userEmail=" + this.editUser.UserName);
    console.dir(result);
    this.passwordResetModalRef.hide();
  }
  saveUser() {
    let ctrl = this;


    //ctrl.d.post('/api/Endpoint/UpdateUser?selfSend=' + ctrl.selfSend, {}, this.editUser).subscribe(result => {
    var saveUrl = ctrl.selfSend ? '/UpdateUserSelfSend' : '/UpdateUser';
    ctrl.d.post(saveUrl, {}, this.editUser).subscribe(result => {
      //console.dir(result);
      ctrl.asc = !this.asc; //or the sort will reverse after reloading
      ctrl.ngOnInit();// refreshes the user list
      this.userModalRef.hide();
      //$('#userModal').modal('hide');
    });

  }
}


