
import { Component, Injectable, Pipe, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'
	;
import { DataService } from '../../data.service';



@Component({
	selector: 'cartbutton',
	templateUrl: './cartbutton.component.html',
	encapsulation: ViewEncapsulation.None //disables Shadow DOM, so we can style SharePoint HTML
})
export class CartButtonComponent {
	constructor(public d: DataService, private router: Router) { }

	ngOnInit() {

	}
	goToCart() {

		if (this.d.model.cart.OrderItemSet.length == 0) {
			return this.d.toast("Your cart is empty!");
		} else {
			this.router.navigate(['/cart']);
		}
	}
}
