import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { environment } from 'src/environments/environment';
export class AuthProvider {

	public msalInstance(): IPublicClientApplication {
		var authSrc = localStorage.getItem('authSrc');
		switch (authSrc) {
			case 'aad':

				return new PublicClientApplication({
					auth: {
						clientId: '0f6434fb-9825-4df2-b21d-9e2659f3accd', // This is your client ID
						authority: 'https://login.microsoftonline.com/78872ca8-56cd-44d6-af6f-4b6263a8cf3c', // This is your tenant ID
						redirectUri: environment.baseRef,// This is your redirect URI
						postLogoutRedirectUri: environment.baseRef
					},
					cache: {
						cacheLocation: 'localStorage',
						storeAuthStateInCookie: (window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1),
					}
				});

			case 'b2c':
				// https://asccustomers.onmicrosoft.com/fde9d891-6146-4860-bdb9-6d3f75adfcc3
				var authority = 'https://asccustomers.b2clogin.com/asccustomers.onmicrosoft.com/B2C_1_SignIn/';
				return new PublicClientApplication({
					auth: {
						//clientId: '83ded902-b1ec-4270-9c94-9f3f557dbafb', // XtraScape
						clientId: 'fde9d891-6146-4860-bdb9-6d3f75adfcc3', // eCommerce
						knownAuthorities: [authority],
						authority: authority,
						redirectUri: environment.baseRef,// This is your redirect URI
						postLogoutRedirectUri: environment.baseRef
					},
					cache: {
						cacheLocation: 'localStorage',
						storeAuthStateInCookie: (window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1),
					}
				});

			default:
				return new PublicClientApplication({
					auth: {
						clientId: ''
					},
					cache: {
						cacheLocation: 'localStorage',
						storeAuthStateInCookie: (window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1),
					}
				});
		}
	}


	public guardConfig(): MsalGuardConfiguration {
		var authSrc = localStorage.getItem('authSrc');
		if (authSrc == 'aad') {
			return {
				interactionType: InteractionType.Redirect,
				authRequest: {
					scopes: ['user.read']//,//'user.read'
					//extraScopesToConsent: ['user.read'] //NO! Creates a bogus Admin Consent Required prompt 
				}
			}
		} else {
			return {
				interactionType: InteractionType.Redirect,
				authRequest: {
					scopes: []//'user.read'
				}
			}
		}

	}

	public interceptorConfig(): MsalInterceptorConfiguration {
		var authSrc = localStorage.getItem('authSrc');
		switch (authSrc) {
			case 'aad':
				return {
					interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
					protectedResourceMap: new Map([
						['https://graph.microsoft.com/v1.0/me', ['user.read']],
						['https://graph.microsoft.com/User.Read', ['user.read']],
						['*', ['api://0f6434fb-9825-4df2-b21d-9e2659f3accd/access_data']],
						//['*', ['api://0f6434fb-9825-4df2-b21d-9e2659f3accd/access_data']] //this one is for Bluescope/ASCeCom
						//['*', ['api://0f6434fb-9825-4df2-b21d-9e2659f3accd/.default']] //this one is for Bluescope/ASCeCom
					])
				}
			case 'b2c':
				return {
					interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
					protectedResourceMap: new Map([
						//['*', ['api://fde9d891-6146-4860-bdb9-6d3f75adfcc3/*']] //core.mjs:6485 
						['*', ['fde9d891-6146-4860-bdb9-6d3f75adfcc3']]
						//ERROR ServerError: invalid_request: AADSTS90009: Application '0f6434fb-9825-4df2-b21d-9e2659f3accd'(0f6434fb-9825-4df2-b21d-9e2659f3accd) is requesting a token for itself. This scenario is supported only if resource is specified using the GUID based App Identifier.
						//['https://graph.microsoft.com/v1.0/me', ['user.read']],
						//['*', ['0f6434fb-9825-4df2-b21d-9e2659f3accd/.default']] //this one is for ASCCustomers/ASCeCom
						//['*', ['fde9d891-6146-4860-bdb9-6d3f75adfcc3/.default']] //no, this one is for ASCCustomers/ASCeCom

						//['*', ['fb41027f-b158-4d67-a3ad-b4435eded084/.default']] //this one is for Bluescope/XtraScape
					])
				}
			default:
				return {
					interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
					protectedResourceMap: new Map([]) // not authorized = no token passed
				}
		}
	}
}
