import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'cutlist',
  templateUrl: './cutlist.component.html',
  styleUrls: ['./cutlist.component.scss']
})
export class CutlistComponent implements OnInit {

  @Input() panel;
  @Input() semaphore;
  @Input() cartIndex;
  @Output() onUpdateCart = new EventEmitter<boolean>();

  public cutlistErrorCount: number = 0;
  public columnOrder: any[] = [];
  public maxRows = 99;
  public last4: any[] = [];
  public maxTotalInches: number = 0;
  public keyMap: any = {
    49: "1",
    50: "2",
    51: "3",
    52: "4",
    53: "5",
    54: "6",
    55: "7",
    56: "8",
    57: "9",
    48: "0",
    97: "1",
    98: "2",
    99: "3",
    100: "4",
    101: "5",
    102: "6",
    103: "7",
    104: "8",
    105: "9",
    96: "0",
    38: "ArrowUp",
    40: "ArrowDown",
    37: "ArrowLeft",
    39: "ArrowRight",
    9: "Tab",
    13: "Enter",
    8: "Backspace",
    46: "Delete",
    45: "Insert",
    32: "Space",
    17: "Ctrl",
    16: "Shift"
  };

  public validKeys: any[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Tab", "Delete"];
  public fracKeys: any[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  constructor(public d: DataService, private router: Router) { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    this.d.cutlistRef = this;
    //if (changes.hasOwnProperty("semaphore")){
    //	console.log("Either color or gauge changed!");
    //	this.clearPricing();
    //}

    //let log: string[] = [];
    for (let propName in changes) {
      let changedProp = changes[propName];
      let from = JSON.stringify(changedProp.previousValue);
      let to = JSON.stringify(changedProp.currentValue);
      console.log(`${propName} changed from ${from} to ${to}`);
      if (propName == "semaphore") {
        if (to != "-1") {
          //console.log("semaphore trigger! Either color or gauge changed!");
          this.clearPricing();
          this.cutlistChanged(null);
        }
      }
      /*
        if (propName == "srcItem"){
            //this.panel = this.d.deref(this.srcItem); //why?
          this.panel = this.srcItem;
        }
      */
    }

    //this.changeLog.push(log.join(', '));
  }

  async ngOnInit() {

    /*
        if(this.semaphore==0){
            //running in configure
            this.panel = this.srcItem;
        } else {
            //running in dialog as modify
            this.panel = this.d.deref(this.srcItem);
        }
        */
    //console.log(this.panel);
    this.columnOrder = ["Qty", "FeetInches", "TotInches", "Fractions"];
    //this.clearPricing();

    //console.log("ConfigureComponent init!");

    if (typeof window !== 'undefined') {
      if (!this.d.model.hasOwnProperty("panels")) {
        this.d.model.panels = JSON.parse(localStorage.getItem('panels'));
        this.d.chunkPanels();
        //console.dir(this.d.model.panels);

      }
      if (this.panel.metadata?.gauge.hasOwnProperty('Gauge')) {
        await this.d.delay(250);
        this.focusInput("cut-qty-0");
      }
    }




  }

  clearPricing() {


    if (this.panel.metadata.pricingSetResult.NetPriceTotal == "0.00") return;
    //console.log("cutlist clearing price!"); 
    this.panel.metadata.pricingSetResult = {
      Colorcode: "",
      Currency: "USD",
      CutlistList: null,
      DeliveryDate: "",
      DiscountAmt: "0.00",
      DiscountPct: "0.00",
      DownhillSlope: "",
      Gauge: "",
      Grade: "",
      Hash: "",
      ItemSubTotal: "0.00",
      ItemTotal: "0.00",
      LLFixExtra: "0.00",
      LLVarExtra: "0.00",
      Materialnumber: "",
      Metal: "",
      NetPrice: "0.00",
      NetPriceTotal: "0.00",
      PackagingType: "",
      PaintSystem: "",
      Perunit: "1",
      ReqQty: "0.000",
      ShipPlant: "",
      ShipTo: "",
      ShortcutExtra: "0.00",
      SoldTo: "",
      Type: "",
      Unitofmeasure: "",
      UphillSlope: "",
      WillCall: ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : "")
    }
  }

  async cutlistChanged(event) {

    await this.d.delay(200);

    //don't update packaging if the cutlist hasn't changed:
    var thisVersion = this.d.deref(this.panel.metadata.cutlist);
    let doUpdatePackaging = !(this.d.isEqual(thisVersion, this.panel.metadata.cutlistLast));

    //don't update packaging unless the longest item changes:
    let thisMaxTotalInches = 0;
    thisVersion.forEach((c) => {
      let thisTi = parseInt(c.TotalInches, 10) + (parseInt(c.Fractions, 10) / 16);
      if (thisTi > thisMaxTotalInches) thisMaxTotalInches = thisTi;
    })
    if (thisMaxTotalInches == this.maxTotalInches) {
      doUpdatePackaging = false;
    } else {
      this.maxTotalInches = thisMaxTotalInches;
    }

    this.panel.metadata.cutlistLast = thisVersion;





    let min = this.panel.metadata.product.MinCutlistLength;
    let max = this.panel.metadata.product.MaxCutlistLength;

    this.panel.metadata.cutlist.forEach((c) => {
      c.Errors = [];
      let totInches = parseInt(c.TotalInches, 10) + (parseInt(c.Fractions, 10) / 16);
      if (totInches == 0) {
        c.Errors.push(this.getDefaultError());
      } else {
        if (totInches < min) c.Errors.push("Min: " + min + "&quot;");
        if (totInches > max) c.Errors.push("Max: " + max + "&quot;");
      }
      if (c.Errors.length > 0) doUpdatePackaging = false;
    });


    //console.log("Cutlist Changed!", event);
    if (doUpdatePackaging) {
      var payloadVersion = this.d.deref(this.panel.metadata.cutlist);
      let ln = 0;
      //Two reasons to delete: 1) Less data over the wire, 2) SAP can't handle them
      payloadVersion.forEach((c) => {
        ln += 1;
        c.Linenumber = ln;
        delete c.TotalInches;
        delete c.Errors;
        delete c.Linenumber;

        c.Quantity = parseInt(c.Quantity, 10).toString(),
          c.Feet = parseInt(c.Feet, 10).toString(),
          c.Inches = parseInt(c.Inches, 10).toString(),
          c.Fractions = parseInt(c.Fractions, 10).toString();


        c.Material = this.panel.metadata.product.MATERIAL_NUMBER;
      })
      var payload = {
        "Material": this.panel.metadata.product.MATERIAL_NUMBER,
        "ShipTo": this.d.model.ShipTo.SHIPTO,
        "CutlistItems": payloadVersion
      };

      //TODO: move a copy of model.packagingTypeSet into panel metadata
      this.d.post("/GetCutlistPropertySet", {}, payload).subscribe((result) => {
        let data = result;
        this.d.model.packagingTypeSet.forEach((p, i) => {
          p.PackSuggested = (data.SuggestedPackagingSelection == p.PackType) ? " (Suggested)" : "";
          p.PackDisabled = (data.MinimumPackagingSelectionSortValue > i + 1) ? true : false;
        });
        this.checkErrors();
      });
    }


    this.checkErrors();

  }

  checkErrors() {

    let errorCount = 0;
    //console.log("cutlist checkErrors triggering clearPricing");
    this.clearPricing();
    //check cuts
    this.panel.metadata.cutlist.forEach((c) => {
      errorCount += c.Errors.length;
    });

    //check packaging selection
    if (this.panel.metadata.packaging === undefined) {
      errorCount += 1;
    } else {
      //console.log(this.selectedPackaging);
      if (this.panel.metadata.packaging.PackDisabled) errorCount += 1;
    }
    this.cutlistErrorCount = errorCount;
    return errorCount != 0;
  }


  debugPanel() {
    console.log(this.panel);
  }
  async addCut() {

    //console.log("addCut()!");

    if (this.panel.metadata.cutlist.length == this.maxRows) {
      return this.d.toast("Max Rows = " + this.maxRows);
    }

    var newRowId = this.panel.metadata.cutlist.length + 0;

    this.panel.metadata.cutlist.push({
      "Material": this.panel.MATERIAL_NUMBER,
      "Linenumber": (this.panel.metadata.cutlist.length + 1),
      "Quantity": "0",
      "Feet": "0",
      "Inches": "0",
      "TotalInches": "0", //not part of the SAP model, but Angular needs something to bind to
      "Fractions": "0",
      "Errors": [this.getDefaultError()]
    });
    this.reindexCuts();

    this.focusInput("cut-qty-" + newRowId);

    this.d.delay(100);
    this.checkErrors();
  }

  getDefaultError() {

    return "Min: " + this.panel.metadata.product.MinCutlistLength + "&quot;, Max: " + this.panel.metadata.product.MaxCutlistLength + "&quot;";
  }

  initval() {
    return (Math.floor(Math.random() * 99) + 1).toString();
  }

  moveColRight(colId) {

    var col = this.columnOrder.splice(colId, 1);
    this.columnOrder.splice((colId + 1), 0, col);
  }

  moveColLeft(colId) {

    var col = this.columnOrder.splice(colId, 1);
    this.columnOrder.splice((colId - 1), 0, col);
  }

  removeCut(rowId) {

    this.panel.metadata.cutlist.splice(rowId, 1);
    this.reindexCuts();
    this.checkErrors();
  }

  reindexCuts() {

    var newIndex = 1;
    this.panel.metadata.cutlist.forEach((cut) => { cut.Linenumber = newIndex++; });
  }

  reorderCuts() {
    this.panel.metadata.cutlist.sort((a, b) => {
      return parseInt(a.Linenumber, 10) - parseInt(b.Linenumber, 10);
    });

  }

  cutlistCantMoveUp(cut) {
    return cut.Linenumber == "1";
  }

  cutlistCantMoveDown(cut) {
    return cut.Linenumber == (this.panel.metadata.cutlist.length).toString();
  }

  moveCutDown(rowId) {

    var ln = parseInt(this.panel.metadata.cutlist[rowId].Linenumber, 10);
    this.panel.metadata.cutlist[rowId].Linenumber = (ln + 1).toString();
    this.panel.metadata.cutlist[rowId + 1].Linenumber = ln.toString();
    this.reorderCuts();
  }

  moveCutUp(rowId) {

    var ln = parseInt(this.panel.metadata.cutlist[rowId].Linenumber, 10);
    this.panel.metadata.cutlist[rowId].Linenumber = (ln - 1).toString();
    this.panel.metadata.cutlist[rowId - 1].Linenumber = ln.toString();
    this.reorderCuts();
  }

  updateTot(rowId) {

    var feet = this.fixInteger(this.panel.metadata.cutlist[rowId].Feet);
    this.panel.metadata.cutlist[rowId].Feet = feet.toString();
    var inches = this.fixInteger(this.panel.metadata.cutlist[rowId].Inches);
    this.panel.metadata.cutlist[rowId].Inches = inches.toString();
    if (isNaN(inches)) inches = 0;
    if (isNaN(feet)) feet = 0;
    var totalInches = (feet * 12) + inches;
    this.panel.metadata.cutlist[rowId].TotalInches = totalInches.toString();
  }

  updateQty(rowId) {

    this.panel.metadata.cutlist[rowId].Quantity = this.fixInteger(this.panel.metadata.cutlist[rowId].Quantity).toString();
  }

  updateFI(rowId) {

    var totalInches = this.fixInteger(this.panel.metadata.cutlist[rowId].TotalInches);
    this.panel.metadata.cutlist[rowId].TotalInches = totalInches.toString();

    var inches = (totalInches % 12);
    if (isNaN(inches)) inches = 0;
    var feet = (totalInches - inches) / 12;
    if (isNaN(feet)) feet = 0;

    this.panel.metadata.cutlist[rowId].Feet = feet.toString();
    this.panel.metadata.cutlist[rowId].Inches = inches.toString();
  }

  fixInteger(numericText) {
    numericText += " ";
    numericText = numericText.replace(/[^0-9]/g, '');
    numericText = numericText.length > 0 ? parseInt(numericText, 10) : 0;
    return numericText;
  }

  pricingCut() {
    return {
      "ReqQty": "0",
      "Quantity": "0",
      "Feet": "0",
      "Inches": "0",
      "Fractions": "0",
      "Notes": "",
      "BundlingNote": "",
      "Materialnumber": "",
      "SoldTo": "",
      "Colorcode": "",
      "Gauge": "",
      "PaintSystem": "",
      "DownhillSlope": "",
      "UphillSlope": "",
      "Metal": "",
      "ShipPlant": "",
      "Type": "",
      "Grade": "",
      "ShipTo": "",
      "WillCall": ""
    }

  }

  async pricingSet() {



    //console.dir(this.panel);
    if (!this.panel.metadata.packaging.PackType) return this.d.toast("Please first select a Packaging Type");
    if (this.d.model.ShipMode.Name === undefined) {
      this.d.lastAction = () => { this.pricingSet(); }
      this.d.appRef.changeShipto();
      return;
    }

    //wait a moment to check for errors, in case the user just entered an invalid length and then clicked Check Price
    await this.d.delay(200);


    var hasErrors = this.checkErrors();

    if (hasErrors) return;
    //There is no need to send this whole thing to the service. 
    //var url = "https://ecomm-dev3.ascprofiles.com/sap/opu/odata/sap/ZSALES_ORDER_PROCES_SRV/PricingSet";
    /*
    console.log("panel", this.panel);
    console.log("selectedColor", this.selectedColor);
    console.log("selectedGauge", this.selectedGauge);
    console.log("selectedPackaging", this.selectedPackaging);
    console.log("this.d.currentSoldTo", this.d.currentSoldTo);
    console.log("this.d.currentShipTo", this.d.currentShipTo);
    */
    let CutlistList = [];
    let totalInches = 0;
    this.panel.metadata.cutlist.forEach((cut) => {
      //console.log(cut);
      totalInches += (parseInt(cut.Quantity, 10) * (parseInt(cut.TotalInches, 10) + (parseInt(cut.Fractions, 10) / 16)));
      var pCut = this.pricingCut();
      pCut.ReqQty = "0";
      pCut.Quantity = parseInt(cut.Quantity, 10).toString(),
        pCut.Feet = parseInt(cut.Feet, 10).toString(),
        pCut.Inches = parseInt(cut.Inches, 10).toString(),
        pCut.Fractions = parseInt(cut.Fractions, 10).toString();
      CutlistList.push(pCut);
    });

    let reqQty = 0;
    if (this.panel.metadata.product.SALES_UOM == "SQ") {
      reqQty = ((totalInches * Number(this.panel.metadata.product.CONV_FACTOR)) / 144) / 100;
    } else {
      reqQty = totalInches / 12;
    }
    if (reqQty == 0) {
      return this.d.toast("Pricing requires a Quantity of at least 1 for all cuts");
    }
    //console.log("this.panel: ", this.panel);

    //"ProductID" : pricingResult.body.Materialnumber,  //metIdnew,

    if (this.panel.metadata.gauge) {
      //we are configuring this from /configure, so some values only exists in metadata!
      this.panel.Materialnumber = this.panel.metadata.gauge.MaterialNumber;
      this.panel.ColorCode = this.panel.metadata.color.ColorCode;
      this.panel.Gauge = this.panel.metadata.gauge.Gauge;
      this.panel.Metal = this.panel.metadata.gauge.Metal;
      this.panel.Grade = this.panel.metadata.gauge.Grade;
      this.panel.Ctwt = this.panel.metadata.gauge.Coating_wt;
      this.panel.PaintSystem = this.panel.metadata.product.PAINTSYSTEM;
    }

    await this.d.waitDataLoaded(this.panel, "Ctwt", 10);

    this.panel.metadata.pricingPayload = {
      "ReqQty": (reqQty.toFixed(3)),
      "SoldTo": this.d.model.SoldTo.Soldto,
      "Materialnumber": this.panel.Materialnumber, //this.panel.metadata.gauge.MaterialNumber, //this.panel.metadata.product.MATERIAL_NUMBER,
      "Colorcode": this.panel.ColorCode, //this.panel.metadata.color.ColorCode,
      "Gauge": this.panel.Gauge, //this.panel.metadata.gauge.Gauge,
      "PaintSystem": this.panel.PaintSystem, //this.panel.metadata.product.PAINTSYSTEM,
      "DownhillSlope": "",
      "UphillSlope": "",
      "Metal": this.panel.Metal, //this.panel.metadata.gauge.Metal,
      "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
      "Type": "O", // Offering: all panels are offerings
      "Grade": this.panel.Grade, //this.panel.metadata.gauge.Grade,
      "Unitofmeasure": "",
      "Perunit": "0",
      "NetPrice": "0",
      "Currency": "",
      "ShipTo": this.d.model.ShipTo.SHIPTO,
      "DeliveryDate": "",
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
      "Hash": "",
      "PackagingType": this.panel.metadata.packaging.PackType,
      "CutlistList": CutlistList,
      "Ctwt": this.panel.Ctwt //this.panel.metadata.gauge.Coating_wt
    }

    //$("#loadingMask").fadeIn();
    //console.log("GetPricingSet payload:", JSON.stringify(this.panel.metadata.pricingPayload,null,4));
    this.d.post("/GetPricingSet", {}, this.panel.metadata.pricingPayload).subscribe((result) => {
      this.panel.metadata.pricingSetResult = result;

      if (parseFloat(this.panel.metadata.pricingSetResult.NetPrice) == 0) {
        if (this.panel.metadata.pricingSetResult.MessageText.length > 0) {
          this.d.toast(this.panel.metadata.pricingSetResult.MessageText);
        } else {
          this.d.toast("Pricing error, please contact your ASC BP Customer Service Representative");
        }
      }

      //console.log("GetPricingSet result:", this.pricingSetResult);
      //$("#loadingMask").fadeOut();
    });


    //

    var payloadTemplate = {
      "ReqQty": "123.219",
      "SoldTo": "53464",
      "Materialnumber": "2.5C_B",
      "Colorcode": "BARE",
      "Gauge": "26",
      "PaintSystem": "DTXL",
      "DownhillSlope": "",
      "UphillSlope": "",
      "Metal": "",
      "ShipPlant": "MC03",
      "Type": "O",
      "Grade": "",
      "Unitofmeasure": "",
      "Perunit": "0",
      "NetPrice": "0",
      "Currency": "",
      "ShipTo": "53464",
      "DeliveryDate": "",
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
      "Hash": "",
      "PackagingType": "LL",
      "CutlistList": [
        {
          "ReqQty": "0",
          "Quantity": "2",
          "Feet": "44",
          "Inches": "11",
          "Fractions": "5",
          "Notes": "",
          "BundlingNote": "",
          "Materialnumber": "",
          "SoldTo": "",
          "Colorcode": "",
          "Gauge": "",
          "PaintSystem": "",
          "DownhillSlope": "",
          "UphillSlope": "",
          "Metal": "",
          "ShipPlant": "",
          "Type": "",
          "Grade": "",
          "ShipTo": "",
          "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : "")
        },
        {
          "ReqQty": "0",
          "Quantity": "1",
          "Feet": "33",
          "Inches": "4",
          "Fractions": "0",
          "Notes": "",
          "BundlingNote": "",
          "Materialnumber": "",
          "SoldTo": "",
          "Colorcode": "",
          "Gauge": "",
          "PaintSystem": "",
          "DownhillSlope": "",
          "UphillSlope": "",
          "Metal": "",
          "ShipPlant": "",
          "Type": "",
          "Grade": "",
          "ShipTo": "",
          "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : "")
        }
      ]
    }

  }

  getLeftFieldId(fieldId, leftColId) {
    if (fieldId == 'inches') return 'feet';
    if (leftColId === undefined) return undefined;
    if (leftColId == 'FeetInches') return 'inches';
    return (("" + leftColId).toLowerCase());
  }

  getRightFieldId(fieldId, rightColId) {
    if (fieldId == 'feet') return 'inches';
    if (rightColId === undefined) return undefined;
    if (rightColId == 'FeetInches') return 'feet';
    return (("" + rightColId).toLowerCase());

  }

  getFirstColFieldId() {

    var col = this.columnOrder[0];
    if (col == 'FeetInches') return 'feet';
    return (("" + col).toLowerCase());
  }

  cutlistEvent(e) {
    let srcElement = (e.srcElement || e.target);
    let fieldId = srcElement.getAttribute("data-fieldid");
    let key = this.keyMap[e.keyCode];
    let colId = parseInt(srcElement.getAttribute("data-colid"), 10);
    let rowId = parseInt(srcElement.getAttribute("data-rowid"), 10);

    return {
      fieldId: fieldId,
      key: key,
      colId: colId,
      rowId: rowId,
      srcEvent: e
    };
  }

  tableKey(e) {
    // IE has trouble events on dynamicly generated DOM elements, 
    // so we let them bubble up to the containing table:

    if (!this.keyMap.hasOwnProperty(e.keyCode)) {
      //console.log("Unknown keycode: " + e.keyCode);
      //console.dir(e);
      return this.noAction(e);
    }
    return this.cutlistKey(this.cutlistEvent(e));
  }

  cutlistKey(e) {
    //console.log(e);



    var leftColId = this.columnOrder[e.colId - 1];
    var rightColId = this.columnOrder[e.colId + 1];

    var leftFieldId = this.getLeftFieldId(e.fieldId, leftColId);
    var rightFieldId = this.getRightFieldId(e.fieldId, rightColId);


    //special row functions, move up and down, delete
    if (e.srcEvent.ctrlKey) {
      if (e.key == 'ArrowDown') {
        if (e.rowId < this.panel.metadata.cutlist.length - 1) {
          this.moveCutDown(e.rowId);
          setTimeout(() => { this.focusInput("cut-" + e.fieldId + "-" + (e.rowId + 1)); }, 100);
          return this.noAction(e.srcEvent);
        }
      }
      if (e.key == 'ArrowUp') {
        if (e.rowId > 0) {
          this.moveCutUp(e.rowId);
          setTimeout(() => { this.focusInput("cut-" + e.fieldId + "-" + (e.rowId - 1)); }, 100);
          return this.noAction(e.srcEvent);
        }
      }
      if (e.key == 'Delete') {
        if (this.panel.metadata.cutlist.length > 1) {
          this.removeCut(e.rowId);
          var targetRowid = e.rowId > 0 ? e.rowId - 1 : 0;
          this.focusInput("cut-" + e.fieldId + "-" + targetRowid);
          return this.noAction(e.srcEvent);
        }
      }
    }

    //add a new row if tabbing from the last row fractions 
    if (e.colId == 3 && e.key == 'Tab' && !e.srcEvent.shiftKey) {
      if (e.rowId == (this.panel.metadata.cutlist.length - 1)) this.addCut();
      var firstFieldId = this.getFirstColFieldId();
      this.focusInput("cut-" + firstFieldId + "-" + (e.rowId + 1));
      //console.log("tab caught");
      return this.noAction(e.srcEvent);
    }

    //navigate to cell below
    if (e.key == 'ArrowDown') {
      if (e.rowId != this.panel.metadata.cutlist.length - 1) {
        this.focusInput("cut-" + e.fieldId + "-" + (e.rowId + 1));
        return this.noAction(e.srcEvent);
      }
    }

    //navigate to cell above
    if (e.key == 'ArrowUp') {
      if (e.rowId != 0) {
        this.focusInput("cut-" + e.fieldId + "-" + (e.rowId - 1));
        return this.noAction(e.srcEvent);
      }
    }

    //navigate to cell to the left
    if (e.key == 'ArrowLeft') {
      if (e.shiftKey) return; //allow shift-selecting text in an input
      if (e.ctrlKey) return this.noAction(e.srcEvent);
      if (leftFieldId === undefined) return this.noAction(e.srcEvent);
      this.focusInput("cut-" + leftFieldId + "-" + e.rowId); return this.noAction(e.srcEvent);
    }

    //navigate to cell to the right
    if (e.key == 'ArrowRight') {
      if (e.shiftKey) return; //allow shift-selecting text in an input
      if (e.ctrlKey) return this.noAction(e.srcEvent);
      if (rightFieldId === undefined) return this.noAction(e.srcEvent);
      //console.log("moving to: " + "cut-" + rightFieldId + "-" + rowId);
      this.focusInput("cut-" + rightFieldId + "-" + e.rowId); return this.noAction(e.srcEvent);
    }

    //allow pressing space on the fraction dropdown
    if (e.fieldId == 'fractions' && e.key == 'Space') return;

    //on the last line only, enter makes a new line and focuses on the first column
    if (e.key == 'Enter') {
      if (e.rowId == (this.panel.metadata.cutlist.length - 1)) this.addCut();

      var firstFieldId = this.getFirstColFieldId();
      this.focusInput("cut-" + firstFieldId + "-" + (e.rowId + 1));

      return this.noAction(e.srcEvent);
    }

    //let users attempt to type the fractions
    if (e.fieldId == 'fractions' && e.key != 'Tab') {
      if (this.fracKeys.indexOf(e.key) == -1) this.noAction(e.srcEvent);
      this.last4.push(e.key);
      if (this.last4.length > 4) this.last4.shift();
      var lastKeys = this.last4.join('');
      //console.log(lastKeys);

      // Has to have longer sequences first, so 1516 isn't picked up as 516.
      // Has to be an array to search in order.
      var keyMap = [
        { seq: '1516', id: 15 },
        { seq: '1316', id: 13 },
        { seq: '1116', id: 11 },
        { seq: '916', id: 9 },
        { seq: '716', id: 7 },
        { seq: '516', id: 5 },
        { seq: '316', id: 3 },
        { seq: '116', id: 1 },
        { seq: '78', id: 14 },
        { seq: '58', id: 10 },
        { seq: '38', id: 6 },
        { seq: '34', id: 12 },
        { seq: '18', id: 2 },
        { seq: '14', id: 4 },
        { seq: '12', id: 8 },
        { seq: '0', id: 0 }
      ];
      var targetIndex = -1;
      keyMap.forEach((k) => {
        if (lastKeys.endsWith(k.seq)) {
          targetIndex = k.id;
          return false;
        }
      })
      if (targetIndex != -1) {
        this.panel.metadata.cutlist[e.rowId].Fractions = targetIndex.toString();
        this.last4 = [];
      }
      return this.noAction(e.srcEvent);
    }

    //disallow invalid input
    if (this.validKeys.indexOf(e.key) == -1) {
      //console.log("disallow invalid input");
      return this.noAction(e.srcEvent);
    }



    //enforce a max inches value of 11
    if (e.fieldId == 'inches') setTimeout(() => {
      var inches = parseInt(this.panel.metadata.cutlist[e.rowId].Inches, 10);
      if (isNaN(inches)) inches = 0;
      if (inches > 11) {
        this.panel.metadata.cutlist[e.rowId].Inches = "11";
        this.updateTot(e.rowId);
        this.d.toast("Max Inches = 11");
      }
    }, 100);


  }

  blurInput(e) {
    let srcElement = (e.srcElement || e.target);
    if (srcElement.value == "") {
      srcElement.value = "0";
    } else {
      srcElement.value = parseInt(srcElement.value, 10).toString();
    }
    this.cutlistChanged(e);
  }

  clearZero(e) {
    let srcElement = (e.srcElement || e.target);
    if (srcElement.value == "0") {
      srcElement.value = "";
    }
    //console.log("clearZero triggering clearPricing");
    this.clearPricing();
  }

  focusInput(elementId) {
    var el = document.getElementById(elementId);
    if (el) {
      el.focus();
    } else {
      setTimeout(() => {
        var el2 = document.getElementById(elementId);
        if (el2) el2.focus();
      }, 100);
    }


  }

  selectInputContents(e) {
    let srcElement = (e.srcElement || e.target);
    srcElement.value.length;
    srcElement.setSelectionRange(0, srcElement.value.length);


    //this.focusInput(event.srcElement.id);
  }

  noAction(e: any) {
    //cancels any click event
    e.returnValue = false;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    return false;
  }

  notifyCheckPrice() {
    if (this.panel.metadata.pricingSetResult.ItemTotal == '0.00') {
      this.d.toast('You must click Check Price first');
    }
  }


  addToCart(event) {

    event.stopPropagation();


    //other than the cutlist, most of this information will change in the cart, so we can set it now:
    this.panel.ColorCode = this.panel.metadata.color.ColorCode;
    this.panel.CutlistProvide = "Y";
    this.panel.DownhillSlope = "";
    this.panel.Gauge = this.panel.metadata.pricingPayload.Gauge;
    this.panel.Ctwt = this.panel.metadata.pricingPayload.Ctwt;
    this.panel.Grade = "";
    this.panel.Itemnumber = ""; //will be set on SalesOrder CREATE
    this.panel.Materialnumber = this.panel.metadata.pricingPayload.Materialnumber;
    this.panel.Metal = this.panel.metadata.pricingPayload.Metal;
    if (this.d.logFlags.includes("OFFERING_ID")) this.d.log("cutlist.component.ts 961");
    this.panel.OfferingID = this.panel.metadata.product.OFFERING_ID;
    this.panel.PackagingType = this.panel.metadata.pricingPayload.PackagingType;
    this.panel.PaintSystem = this.panel.metadata.pricingPayload.PaintSystem;
    this.panel.Plant = this.panel.metadata.pricingPayload.ShipPlant;
    this.panel.Product = this.panel.metadata.pricingPayload.Materialnumber;
    this.panel.Productname = this.panel.metadata.product.NAME;
    this.panel.Quantity001 = this.panel.metadata.pricingPayload.ReqQty;
    this.panel.Requesteddelivery = this.panel.metadata.pricingSetResult.DeliveryDate;
    this.panel.Unitofmeasure = this.panel.metadata.pricingSetResult.Unitofmeasure;
    this.panel.UphillSlope = "";

    if (this.panel.metadata.color.ColorDescription.indexOf('(Striated Only)') > -1) {
      this.panel.metadata.warning = '(Striated Only)';
    } else {
      this.panel.metadata.warning = null;
    }

    this.d.addToCartAnim();
    this.d.model.cart.OrderItemSet.push(this.d.deref(this.panel));
    this.clearPricing();
    return this.d.toast("Item Added To Cart");
  }


  updateCart(event) {


    event.stopPropagation();



    this.d.model.cart.OrderItemSet[this.panel.Itemnumber] = this.d.deref(this.panel);

    console.dir(this.d.model.cart.OrderItemSet[this.panel.Itemnumber]);

    this.d.model.cart.OrderItemSet[this.panel.Itemnumber].metadata.Quantity = this.panel.metadata.pricingPayload.ReqQty;
    this.d.model.cart.OrderItemSet[this.panel.Itemnumber].Quantity001 = this.panel.metadata.pricingPayload.ReqQty;
    this.d.model.cart.OrderItemSet[this.panel.Itemnumber].Materialnumber = this.panel.metadata.pricingPayload.Materialnumber;
    this.d.model.cart.OrderItemSet[this.panel.Itemnumber].Product = this.panel.metadata.pricingPayload.Materialnumber;


    if (this.panel.metadata.color.ColorDescription.indexOf('(Striated Only)') > -1) {
      this.panel.metadata.warning = '(Striated Only)';
      this.d.model.cart.OrderItemSet[this.panel.Itemnumber].metadata.warning = '(Striated Only)';
    } else {
      this.panel.metadata.warning = null;
      this.d.model.cart.OrderItemSet[this.panel.Itemnumber].metadata.warning = null;
    }

    //console.dir(this.d.model.cart.OrderItemSet[this.panel.Itemnumber]);
    //this.d.model.cart.OrderItemSet.push(this.d.deref(this.panel));
    //this.srcItem = this.d.deref(this.panel);
    this.onUpdateCart.emit(true);
  }


}
