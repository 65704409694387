<div class="trimprice">


  <div *ngIf="trim.FreeUpHillSlopes.results?.length>0">
    Uphill:
    <select class="form-control auto-width" [(ngModel)]="trim.metadata.UphillSlope" (ngModelChange)="updatePrice()">
      <option class="text-right" *ngFor="let c of trim.FreeUpHillSlopes.results" [ngValue]="c">{{c.Description}}</option>
    </select>
  </div>

  <div *ngIf="trim.FreeDownHillSlopes.results?.length>0">
    Downhill:
    <select class="form-control auto-width" [(ngModel)]="trim.metadata.DownhillSlope" (ngModelChange)="updatePrice()">
      <option class="text-right" *ngFor="let c of trim.FreeDownHillSlopes.results" [ngValue]="c">{{c.Description}}</option>
    </select>
  </div>



  <div *ngIf="trim.CombinedSlopes.results?.length>0">
    Combined:
    <select class="form-control auto-width" [(ngModel)]="trim.metadata.CombinedSlope" (ngModelChange)="updatePrice()">
      <option class="text-right" *ngFor="let c of trim.CombinedSlopes.results" [ngValue]="c">{{c.Description}}</option>
    </select>
  </div>

  <span class="pull-right">Quantity:</span>
  <input class="form-control pull-right" style="width:50px;text-align:center" id="{{trim.TrimId+'_'+trim.ColorId}}"
         [(ngModel)]="trim.metadata.Quantity" (ngModelChange)="reprice()" (focus)="selectInputContents($event)" />


  <fa-icon *ngIf="trim.metadata.Loading" [icon]="d.faSpinner" [spin]="true" style="margin:8px 17px;"></fa-icon>
  
  <span *ngIf="!trim.metadata.Loading && trim.metadata.pricingSetResult.ItemSubTotal != trim.metadata.pricingSetResult.NetPriceTotal" style="margin:6px;text-decoration: line-through;">{{trim.metadata.pricingSetResult?.NetPriceTotal|currency:'USD':'symbol':'1.2-2'}}</span>
  <span *ngIf="!trim.metadata.Loading" style="font-weight:bold;margin:6px;" [innerHTML]="trim.metadata.pricingSetResult?.ItemSubTotal|currency:'USD':'symbol':'1.2-2'|meta:'pricingError'|safe"></span>



  <button class="btn btn-primary pull-right" [ngClass]="{'disabled':(trim.metadata.Loading||trim.metadata.pricingSetResult?.ItemSubTotal=='0.00')}" (click)="addToCart($event)">
    <fa-icon [icon]="d.faCartPlus"></fa-icon>
  
    Add To Cart
  </button>



</div>