import { Component, OnInit, OnDestroy, Inject, AfterViewInit, AfterContentInit, TemplateRef, ViewChild, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, PublicClientApplication, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthManager } from './authmanager';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from './data.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  //@ViewChild(CdkVirtualScrollViewport) soldToVirtualScroll: CdkVirtualScrollViewport;
  @ViewChildren(CdkVirtualScrollViewport) viewPorts: QueryList<CdkVirtualScrollViewport>;
  @ViewChild("carrierName") carrierName: ElementRef;
  @ViewChild("soldtoSearchTermsInput") soldtoSearchTermsInput: ElementRef;

  @ViewChild("logoutModal", { static: true }) logoutModal: TemplateRef<any>;
  logoutModalRef: BsModalRef;

  @ViewChild("changeSoldtoModal", { static: true }) changeSoldtoModal: TemplateRef<any>;
  changeSoldtoModalRef: BsModalRef;

  @ViewChild("changeShiptoModal", { static: true }) changeShiptoModal: TemplateRef<any>;
  changeShiptoModalRef: BsModalRef;

  @ViewChild("carrModal", { static: true }) carrModal: TemplateRef<any>;
  carrModalRef: BsModalRef;

  @ViewChild("willCallWarningModal", { static: true }) willCallWarningModal: TemplateRef<any>;
  willCallWarningModalRef: BsModalRef;

  @ViewChild("spokaneLeadTimeModal", { static: true }) spokaneLeadTimeModal: TemplateRef<any>;
  spokaneLeadTimeModalRef: BsModalRef;

  @ViewChild("agreementModal", { static: true }) agreementModal: TemplateRef<any>;
  agreementModalRef: BsModalRef;

  @ViewChild("errorModal", { static: true }) errorModal: TemplateRef<any>;
  errorModalRef: BsModalRef;

  title = 'ASC Portal';
  isIframe = false;
  loginDisplay = false;
  loginInfo = '';
  showProfile = false;
  closeResult: string = '';

  soldtoSearchTerms: string = "";
  selectedSoldto: number = -1;
  selectedShipto: any;
  shiptoEmails: any[] = [];





  private readonly _destroying$ = new Subject<void>();

  //private am = new AuthManager();
  //modalRef?: BsModalRef;

  constructor(
    public d: DataService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    console.log("App Init!");
    this.d.appRef = this;
    this.isIframe = window !== window.parent && !window.opener;
  }
  routeTo(route: string[]) {
    this.router.navigate(route);
  }
  soldToscrollToTop() {
    //$('.vscroll').animate({ scrollTop: 0 }, 0);
    //this.soldToVirtualScroll.scrollToIndex(0);
    this.viewPorts.toArray().forEach(el => el.scrollToIndex(100, "smooth"));
  }
  repeatSearch() {
    let lastSearch = localStorage.getItem('soldToSearch');
    //console.log("lastSearch: " + lastSearch);
    this.soldtoSearchTerms = lastSearch;
    this.d.searchSoldTo(lastSearch);
    //$('#soldtoSearchTerms').val(lastSearch);
    //this.soldToVirtualScroll.scrollToIndex(0);
    this.viewPorts.toArray().forEach(el => el.scrollToIndex(100, "smooth"));
  }

  selectSoldto(soldToId) {

    this.selectedSoldto = soldToId;

    if (!this.d.model.accountInfo.IsInternal) return;

    this.d.model.cart.ECommEmail = "";
    this.d.get("/GetEmailsFor?soldTo=" + soldToId, {}).subscribe((result) => {
      this.shiptoEmails = result;
      if (this.shiptoEmails.length > 0) {
        this.d.model.cart.ECommEmail = this.shiptoEmails[0];
      } else {
        this.d.model.cart.ECommEmail = this.d.model.accountInfo.UserName;
      }
    });


    this.d.get("/GetJobSiteDelivery?soldTo=" + soldToId, {}).subscribe((result) => {
      this.d.model.jobSiteDelivery = result;
    });

  }

  changeSoldto() {
    //console.log("ctrl.nextAction: " + ctrl.nextAction);

    if (!this.d.model.hasOwnProperty('cart')) this.d.model.cart = { OrderItemSet: [] };
    if (!this.d.model.cart.hasOwnProperty('OrderItemSet')) this.d.model.cart = { OrderItemSet: [] };

    if (this.d.model.cart.OrderItemSet.length > 0) {
      this.router.navigate(['/cart']);
      return this.d.toast("Cart must be empty before changing Sold To");

    }


    this.selectedSoldto = -1;
    this.d.searchSoldTo("");
    //_.forEach(this.d.model.customerSet, (c) => { c.found = true });

    console.log("Triggering soldToModal...");

    this.changeSoldtoModalRef = this.d.showModal(this.changeSoldtoModal, 'modal-dialog-centered modal-dialog-centered');
    //this.d.showModal(this.soldtoModal, 'modal-dialog-centered');
    //$('#changeSoldtoModal').modal({ keyboard: false });

    this.d.focusInput("soldtoSearchTermsInput");
    this.d.clearInput("soldtoSearchTermsInput");
    //$('#soldtoSearchTerms').val("");
  }


  async setSelectedSoldTo() {

    if (this.setSoldToIsDisabled()) return;

    //this.d.model.SoldTo = _.find(this.d.model.customerSet, { Soldto: this.selectedSoldto });
    console.log("Clicked:", this.selectedSoldto);
    console.log("looking for match in:", this.d.model.customerSet);
    this.d.model.SoldTo = this.d.model.customerSet.find(o => { return o.Soldto == this.selectedSoldto });
    console.log("Selected soloto: ", this.d.model.SoldTo);
    this.changeSoldtoModalRef.hide();

    console.log("setSelectedSoldTo running d.lastAction: ", this.d.lastAction);
    if (this.d.lastAction !== undefined) {
      setTimeout(() => { this.d.lastAction(); }, 500);
    }

    //populate emails
    this.d.model.ShipTo = {};
    this.d.model.ShipMode = {};
    this.d.model.shipToList = await this.d.inlineGet("/ShipToList?soldTo=" + this.selectedSoldto);

  }
  setSoldToIsDisabled() {
    //console.log((this.d.model.cart.ECommEmail == 0) + " " + (this.selectedSoldto  == -1));
    return (this.d.model.cart.ECommEmail.length == 0 || this.selectedSoldto == -1)
  }
  setShipToIsDisabled() {
    if (this.d.model.ShipMode.ShippingModeId == 'CARR') {
      if (!this.d.model.hasOwnProperty('currentCarrierName')) return true;
      if (this.d.model.currentCarrierName.length == 0) return true;
    }
    return !this.d.model.ShipTo.hasOwnProperty("NAME1");
  }
  autoSelectShipMode() {
    this.d.model.ShipMode = this.d.model.deliveryOptionSet.find((o) => {
      return o.DeliveryOptionId == this.d.model.ShipTo.DeliveryOptions;
    });
  }
  deliveryOptionDisabled(opt) {

    let disabled = false;

    if (['CF', 'EF'].includes(this.d.targetAction)) {
      if (!opt.ShippingModeId.includes(['STD', 'CUST', 'CARR'])) disabled = true;
    }

    if (this.d.targetAction == 'TRIM') {
      if (opt.ShippingModeId == "PARC") {
        disabled = true;
      }
    }

    return disabled;
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  changeShipto() {

    if (this.d.model.cart.OrderItemSet.length > 0) {
      this.router.navigate(['/cart']);
      return this.d.toast("Cart must be empty before changing Ship To");
    }

    if (this.d.model.SoldTo.hasOwnProperty('Name')) {
      this.d.nextAction = undefined;
      this.changeShiptoModalRef = this.d.showModal(this.changeShiptoModal, 'modal-dialog-centered');

    } else {
      this.d.nextAction = this.changeShipto;
      this.changeSoldto();
    }
  }

  focusCarrier(option) {
    console.log(option.ShippingModeId);
    if (option.ShippingModeId == 'CARR') {
      this.carrModalRef = this.d.showModal(this.carrModal, 'modal-dialog-centered', true);

    } else {
      this.d.focusInput("carrierName");
    }
  }

  agreeToCarr(didAgree) {
    //this.d.hideModal('carrModal');
    this.carrModalRef.hide();
    if (!didAgree) {
      //$("#loadingMask").fadeIn(100);
      setTimeout(() => {
        this.d.model.ShipMode = this.d.model.deliveryOptionSet.find((o) => { return o.ShippingModeId == 'CUST' });
        //$("#loadingMask").fadeOut(100);
      }, 750);
    } else {
      this.d.focusInput("carrierName");
    }
  }

  setSelectedShipTo() {
    this.changeShiptoModalRef.hide();
    //$('#changeShiptoModal').modal('hide');

    if (this.d.lastAction !== undefined) {
      setTimeout(() => {
        this.d.lastAction();
        this.d.lastAction = undefined;
      }, 500);
    }

    this.d.get('/GetPanels?shippingPlant=' + this.d.model.ShipTo.SHIPPINGPLANT, {})
      .subscribe((result) => {

        var data = result;
        data.forEach((p) => {
          p.MinCutlistLength = parseInt(p.MinCutlistLength, 10);
          p.MaxCutlistLength = parseInt(p.MaxCutlistLength, 10);
        });

        this.d.model.panels = data;
        this.d.chunkPanels();

        localStorage.setItem('panels', JSON.stringify(this.d.model.panels));
        this.d.model.jobSite = Number(this.d.model.ShipTo.DeliveryOptions) > 20;
      });

    if (this.d.model.ShipTo.DeliveryOptions == "20") {
      this.willCallWarningModalRef = this.d.showModal(this.willCallWarningModal, 'modal-dialog-centered', true);
    }

    if (this.d.model.ShipTo.SHIPPINGPLANT == 'SP03') {
      this.spokaneLeadTimeModalRef = this.d.showModal(this.spokaneLeadTimeModal, 'modal-dialog-centered', true);
    }

  }

  dismissWillCallWarningModal() {
    this.willCallWarningModalRef.hide();
  }

  dismissSpokaneLeadTimeModal() {
    this.spokaneLeadTimeModalRef.hide();
  }

  appKeyDown(event) {
    //console.log(event);
    if (event.keyCode == 17) this.d.debugVisible = true;
  }

  appKeyUp(event) {
    //console.log(event);
    if (event.keyCode == 17) this.d.debugVisible = false;
    if (this.d.model.flags.dev_mode) this.d.debugVisible = true;
  }


  enableAjaxDebug() {
    this.d.debugAjax = true;
  }

  debugModel() {
    console.dir(this.d.model);
  }

  decrementDates() {

    if (this.d.model.cart.OrderItemSet.length == 0) {
      console.log("No items in cart");
      return;
    }
    console.log("Changing Dates:");
    var dateOffset = (24 * 60 * 60 * 1000) * 7; //7 days
    this.d.model.cart.OrderItemSet.forEach((item) => {
      if (item.hasOwnProperty("Requesteddelivery")) {

        let itemDate = this.d.sapToJsDate(item.Requesteddelivery);
        itemDate.setTime(itemDate.getTime() - dateOffset);
        let newDate = this.d.jsToSapDate(itemDate);
        console.log(item.Requesteddelivery + " -> " + newDate);
        item.Requesteddelivery = newDate;
      }
    });

    //make the cart date update if it doesn't automatically
    this.cdr.detectChanges();
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 500);

  }

  showLocalStorage() {
    let len = localStorage.length;
    let data = {};
    for (var i = len - 1; i >= 0; i--) {
      var key = localStorage.key(i);
      data[key] = JSON.parse(localStorage.getItem(key));
    }
    console.dir(data);
  }

  clearLocalStorage() {
    //localStorage.clear(); // Works, but I want feedback about which values are being cleared
    var len = localStorage.length;
    for (var i = len - 1; i >= 0; i--) {
      var key = localStorage.key(i);
      console.log("Clearing " + key);
      localStorage.removeItem(key);
    }
  }

  cacheModel() {
    let ctrl = this;
    localStorage.setItem("model", JSON.stringify(ctrl.d.model));
    console.log("Model cached to localStorage!");
  }

  loadModel() {
    let ctrl = this;
    localStorage.setItem("model", JSON.stringify(window['model']));
    console.log("Model loaded from localStorage!");
    location.reload();
  }

  clearModel() {
    var len = localStorage.length;
    var found = false;
    for (var i = len - 1; i >= 0; i--) {
      var key = localStorage.key(i);
      if (key == "model") {
        this.d.toast("Found and cleared cached model");
        found = true;
        localStorage.removeItem(key);
        location.reload();
      }
    }
    if (!found) this.d.toast("No cached model found!");
  }

}