import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard } from '@azure/msal-angular';

import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AccessoriesComponent } from './components/accessories/accessories.component';
import { TrimsComponent } from './components/trims/trims.component';
import { TrimrenderComponent } from './components/trimrender/trimrender.component';
import { PanelsComponent } from './components/panels/panels.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { CartComponent } from './components/cart/cart.component';
import { LoginComponent } from './components/login/login.component';

// For some reason, adding this PREVENTS the msal system from initializing!
//, canActivate: [MsalGuard]
const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'manage-users', component: ManageUsersComponent },
  { path: 'panels/:id', component: PanelsComponent },
  { path: 'configure/:id', component: ConfigureComponent },
  { path: 'cart', component: CartComponent },
  { path: 'orders/:type', component: OrdersComponent },
  { path: 'orders/:type/:id', component: OrdersComponent },
  { path: 'accessories', component: AccessoriesComponent },
  { path: 'trims', component: TrimsComponent },
  { path: 'trimrender', component: TrimrenderComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'home' }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }