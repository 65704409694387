import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'cms'
})
export class CmsPipe implements PipeTransform {
  constructor(public d: DataService, private sanitizer: DomSanitizer) { }
  transform(url): any {
    if (url === undefined) return "";
    if (url.startsWith(this.d.API_ROOT)) return url;
    if (url.startsWith('data:image')) return this.sanitizer.bypassSecurityTrustResourceUrl(url);;

    if (url.startsWith('/cms')) {
      return this.d.API_ROOT + url;
    } else {
      return this.d.API_ROOT + '/cms' + url;
    }
  }

}
