import { Component, OnInit, Input, EventEmitter, Output, ViewChild, TemplateRef, OnDestroy, AfterContentInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from '../../data.service';


@Component({
  selector: 'trimeditfct',
  templateUrl: './trimeditfct.component.html',
})
export class TrimEditFctComponent implements OnInit, OnDestroy, AfterContentInit {

  constructor(public d: DataService) { }


  @Input() customtrim;
  @Input() semaphore;
  @Input() cartIndex;
  @Output() onUpdateCart = new EventEmitter<boolean>();

  @ViewChild("pbCollisonWarningModal", { static: true }) pbCollisonWarningModal: TemplateRef<any>;
  pbCollisonWarningModalRef: BsModalRef;

  @ViewChild('tleft') public tooltip: NgbTooltip;

  currentLabel: string = "";
  spinner = null;
  spinnerFn = null;
  lastFocusId = null;
  isFCT = false;
  altAngles = [];
  initialized = false;

  public hemTypes: any = ['posHem', 'posHook', 'negHem', 'negHook'];
  ngOnInit() {
    this.initialized = false;
    console.log("TrimEditFctComponent Init", this.customtrim);
    if (this.customtrim.metadata.TDLData.isFullyCustom) this.isFCT = true;

    this.d.trimfcteditRef = this;

    this.spinnerReStart();



  }

  ngAfterContentInit(): void {
    setTimeout(() => { this.initialized = true }, 100);
  }

  ngOnDestroy(): void {
    console.log("TrimEditFctComponent exit, unlinking trimfcteditRef...");
    this.d.trimfcteditRef = {};
  }

  fixHemLengths() {

    let hemCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return this.d.isHem(f); }).length;
    let hemConfig = this.customtrim.metadata.mins.HemConfiguration.results.find(h => h.Hems == hemCount);
    this.customtrim.metadata.hemLength = Number(hemConfig.HemLength);
    this.customtrim.metadata.TDLData.flanges.forEach((f) => {
      if (this.d.isHem(f)) f.value = this.customtrim.metadata.hemLength;
    });
    //console.log("HEMCOUNT: " + hemCount);
  }

  fixHemLengthsPerHem() {

    //let hemCount = _.filter(this.customtrim.metadata.TDLData.flanges, (f) => { return this.d.isHem(f); }).length;
    //let hemConfig = _.find(this.customtrim.metadata.mins.HemConfiguration.results, { Hems: hemCount });
    //this.customtrim.metadata.hemLength = Number(hemConfig.HemLength);
    var hemCount = 0;
    this.customtrim.metadata.TDLData.flanges.forEach((f) => {
      if (this.d.isHem(f)) {
        hemCount += 1;
        let hemConfig = this.customtrim.metadata.mins.HemConfiguration.results.find(h => h.Hems == hemCount);
        f.value = Number(hemConfig.HemLength);
        //console.log("Hem " + hemCount +": " + f.value);
      }
    });
    //console.log("HEMCOUNT: " + hemCount);
  }


  spinnerReStart() {
    clearInterval(this.spinner);
    this.spinner = setInterval(() => {
      if (typeof this.spinnerFn === "function") this.spinnerFn();
    }, 100);
  }


  fixRotation = this.d.debounce(this.doFixRotation, 300);
  async doFixRotation() {
    this.customtrim.metadata.TDLData.startAngle = this.max180(Number(this.customtrim.metadata.TDLData.startAngle) % 360);
    this.rerender();
    await this.d.delay(100);
  }

  max180(angle) {
    if (angle > 180) angle = -(360 - angle);
    return this.d.isNaN(angle) ? 0 : angle;
  }

  rotateDrawing(deg) {

    this.customtrim.metadata.TDLData.startAngle = Number(this.customtrim.metadata.TDLData.startAngle);
    this.customtrim.metadata.TDLData.startAngle += deg;
    this.customtrim.metadata.TDLData.startAngle %= 360;
    this.rerender();
    this.selectLastInput();
  }

  copyTdl() {

    let tdl = this.d.deref(this.customtrim.metadata.TDLData);
    tdl.flanges.forEach((f) => { delete f.metadata; });
    this.d.copyToClipboard(JSON.stringify(tdl, null, 2));
  }

  walk(node, func) {
    func(node);
    node = node.firstChild;
    while (node) {
      this.walk(node, func);
      node = node.nextSibling;
    }
  }

  removeAngularMetadata(html, thumbMode = false) {
    html = this.d.stripHtmlComments(html);

    let temp = document.createElement("DIV");
    temp.innerHTML = html;

    if (thumbMode) {
      let elements = temp.getElementsByClassName('arc'), index;
      for (index = elements.length - 1; index >= 0; index--) {
        elements[index].parentNode.removeChild(elements[index]);
      }
    }

    this.walk(temp.firstChild, (el) => {

      if (el.removeAttribute) {

        var attrs = el.attributes;
        for (var i = attrs.length - 1; i >= 0; i--) {
          let doRemove = false;
          let name = attrs[i].name

          if (name == 'class') doRemove = true;
          if (name == 'id') doRemove = true;
          if (name.indexOf('ngcontent') > -1) doRemove = true;
          if (doRemove) el.removeAttribute(name);
        }
      }


    });

    if (thumbMode) {
      //remove extra content
      let bannedTags = ['line', 'rect', 'text'];
      bannedTags.forEach((tag) => {
        let elements = temp.getElementsByTagName(tag), index;
        for (index = elements.length - 1; index >= 0; index--) {
          elements[index].parentNode.removeChild(elements[index]);
        }
      })
    }

    html = temp.innerHTML;

    html = html.replace(/\t/g, ''); //tabs
    html = html.replace(/\r/g, ''); //carriage returns
    html = html.replace(/\n/g, ''); //newlines
    html = html.replace(/\s{2,}/g, ' '); //doublespaces

    //delete temp;
    //document.removeChild(temp);

    return html;
  }

  getRawSvg() {

    //let svg = this.d.controllers['trimrender'].renderedSvgElementRef.nativeElement.outerHTML; //doesn't work in IE!

    let svgDoc = document.getElementById("trimsvg_fct");
    let temp = document.createElement('div');
    let node = svgDoc.cloneNode(true);
    temp.appendChild(node);
    let svg = temp.innerHTML;


    svg = this.removeAngularMetadata(svg, false);

    let header = [
      '<?xml version="1.0" encoding="utf-8"?>',
      '<!-- Generator: ASC eCommerce Portal -->',
      '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd" >'
    ].join('\r\n');


    svg = header + svg;

    //IE11 helpfully adds an additional xmlns, invalidating the XML
    var nth = 0;
    svg = svg.replace(/xmlns="http:\/\/www.w3.org\/2000\/svg"/g, (match, i, original) => {
      nth++;
      return (nth === 2) ? "" : match;
    });

    return svg
  }

  downloadSvg() {
    let svg = this.getRawSvg();
    let blob = new Blob([svg], { type: "image/svg+xml" });
    //this.d.saveBlobAs(blob,this.customtrim.metadata.TrimXYGroupId + "_P.svg");
    //this.d.saveBlobAs(blob,this.customtrim.metadata.TrimXYGroupId + "_B.svg");
    this.d.saveBlobAs(blob, this.customtrim.metadata.TrimXYGroupId + ".svg");
  }

  async spinnerStart(fnName, flange, focusId) {
    this.spinnerStop();

    if (focusId !== undefined) this.lastFocusId = focusId;


    this.spinnerFn = "starting";
    let fn = null;
    switch (fnName) {
      case 'lenNeg': fn = () => { this.changeLen(flange, -0.0625, focusId) }; break;
      case 'lenPos': fn = () => { this.changeLen(flange, 0.0625, focusId) }; break;
      case 'angNeg': fn = () => { this.changeAngle(flange, -1, focusId) }; break;
      case 'angPos': fn = () => { this.changeAngle(flange, 1, focusId) }; break;
      case 'rotNeg': fn = () => { this.rotateDrawing(-15) }; break;
      case 'rotPos': fn = () => { this.rotateDrawing(15) }; break;
    }
    if (!(typeof fn === "function")) return;

    fn();

    await this.d.delay(500);
    if (typeof this.spinnerFn === "string") {
      this.spinnerReStart();
      this.spinnerFn = fn;
    }


  }
  spinnerStop() {
    this.spinnerFn = null;
    this.setAngleError();
  }

  changeLen(flange, amt, focusId) {
    flange.value = Number(flange.value) + amt;
    this.checkLength(flange, focusId);
  }

  changeAngle(flange, amt, focusId) {
    flange.value = Number(flange.value);
    let minAng = Number(this.customtrim.metadata.mins.AngleMin);
    if (focusId !== undefined) this.lastFocusId = focusId;
    if (flange.value < 0) amt *= -1;
    let newVal = flange.value + amt;
    if (newVal >= 180) return;
    if (newVal <= -180) return;
    if (newVal > 0 && newVal < minAng) return;
    if (newVal < 0 && newVal > -minAng) return;
    flange.value = newVal;

    this.rerender();
    this.clearHls();
    this.clearFocused();
    flange.metadata.focused = true;
    this.selectLastInput();
  }

  checkLengthD = this.d.debounce(this.checkLength, 1000);
  async checkLength(flange, focusId) {
    this.clearPricing();
    if (focusId !== undefined) this.lastFocusId = focusId;
    await this.d.delay(100); //let angular model catch up

    let fixedValue = Number(flange.value);
    if (this.d.isNaN(fixedValue)) fixedValue = 0; //they didn't type a nmber, trigger min length validation 
    fixedValue = Math.round(fixedValue * 16) / 16;
    flange.value = fixedValue;


    //let minLeg = Number(this.customtrim.metadata.TrimFullyCustomSet[0].HasPOPCapability.LegLengthMin);
    //let minLeg = Number(this.d.model.TrimFullyCustomSet[0].HasPOPCapability.LegLengthMin);
    let minLeg = Number(this.customtrim.metadata.mins.LegLengthMin);
    if (flange.value < minLeg) {


      flange.value = minLeg;
      this.d.toast("Invalid length. Reset length to minimum: " + minLeg + " inches");
      this.spinnerStop();
    }


    //console.log(flange.lengthName);

    this.rerender();
    this.clearHls();
    this.clearFocused();
    flange.metadata.focused = true;
    //await this.d.delay(500);
    //this.enterLabel(flange,'len');

    this.selectLastInput();

  }

  async selectLastInput() {
    await this.d.delay(100);
    if (this.lastFocusId != null) {
      //console.log("selectLastInput: " + this.lastFocusId);
      let srcElement = <HTMLInputElement>document.getElementById(this.lastFocusId);
      if (srcElement == null) {
        //console.log("There is no element with id " + this.lastFocusId);
      } else {
        srcElement.select();
      }
    } else {
      //console.log("selectLastInput NULL!: " + this.lastFocusId);
    }
  }


  async rerender() {
    return this.d.trimrenderRef.render();
  }

  clearHls() {
    this.customtrim.metadata.TDLData.flanges.forEach((f) => {
      if (f.metadata !== undefined) f.metadata.hovered = false;
    });
  }
  clearFocused() {
    this.customtrim.metadata.TDLData.flanges.forEach((f) => {
      if (f.metadata !== undefined) f.metadata.focused = false;
    });
  }

  hover(flange) {
    this.clearHls();
    if (flange.metadata !== undefined) flange.metadata.hovered = true;
  }
  unhover(flange) {

    if (flange.metadata !== undefined) flange.metadata.hovered = false;
  }

  blur(flange) {
    if (flange.metadata !== undefined) flange.metadata.focused = false;
  }

  selectInputContents(e, flange = null) {
    let srcElement = (e.srcElement || e.target);
    if (srcElement.value.indexOf("-") > -1) {
      srcElement.setSelectionRange(1, srcElement.value.length);
    } else {
      srcElement.setSelectionRange(0, srcElement.value.length);
    }
    this.clearHls();
    this.clearFocused();
    if (flange != null && flange.hasOwnProperty("metadata")) flange.metadata.focused = true;



  }

  checkAngle = this.d.debounce(this.doCheckAngle, 300);

  async doCheckAngle(flange, focusId) {
    //console.log("flangeChange", flange, angle);
    this.clearPricing();
    await this.d.delay(100); //let angular model catch up
    if (focusId !== undefined) this.lastFocusId = focusId;

    let fixedValue = Number(flange.value);
    if (this.d.isNaN(fixedValue)) {
      fixedValue = 45; //they didn't type a number, trigger validation
      this.d.toast("Invalid Angle. Reset 45 degrees");
    }
    flange.value = fixedValue;

    flange.value = this.max180(flange.value);

    let minAngle = 45;
    let minLeg = 0.5;
    if (this.d.model.hasOwnProperty("TrimFullyCustomSet")) {
      let mins = this.d.model.TrimFullyCustomSet[0].HasPOPCapability;
      minAngle = Number(mins.AngleMin);
      minLeg = Number(mins.LegLengthMin);
    }
    if (flange.value > 0) {
      if (flange.value < minAngle) flange.value = minAngle;
    } else {
      if (flange.value > -minAngle) flange.value = -minAngle;
    }
    this.clearHls();
    this.clearFocused();
    flange.metadata.focused = true;
    this.rerender();
    this.selectLastInput();
    this.setAngleError();

  }
  async selectMaterial(matId) {
    this.clearPricing();
    this.customtrim.metadata.materialType = matId;
    this.customtrim.Gauge = "";

  }

  async selectGauge(gaugeId) {

    this.clearPricing();
    delete this.customtrim.metadata.color;
    this.customtrim.Gauge = gaugeId;

    var colorUrl = ""
    if (this.isFCT) {
      colorUrl = '/GetTrimFullyCustomGetFilterBaseColor?GaugeId=' + gaugeId;
    } else {
      // var selectedGauge = this.customtrim.metadata.TrimXYGroupFilterGauge.find((g) => g.GaugeId == gaugeId);
      this.customtrim.metadata.TrimXYGroupFilterGauge.forEach(async (item) => {
        item.selected = item.GaugeId == gaugeId;
      });
      this.customtrim.Gauge = gaugeId;
      var TRIMXYGROUPID = this.customtrim.metadata.TrimXYGroupId;
      colorUrl = '/GetTrimXYGroupFilterBaseColor?TRIMXYGROUPID=' + TRIMXYGROUPID + '&GAUGEID=' + gaugeId;
    }

    var colors = await this.d.inlineGet(colorUrl);
    colors.forEach((color) => {
      var baseColor = this.d.model.colorSet.find((c) => c.BasecolorId == color.BasecolorId);
      if (baseColor !== undefined) {
        color.ImageUrl = "/images/PaintChips/" + baseColor.ColorId + ".gif",
          color.ColorId = baseColor.ColorId;
        color.RGB = baseColor.RGB;
        color.BasecolorId = baseColor.BasecolorId;
      } else {
        color.ImageUrl = "";
      }
    });
    this.customtrim.metadata.TrimXYGroupFilterBaseColor = colors;


    this.d.clickElement("xy-color-select");

  }
  /*
this.customtrim.metadata.TrimXYGroupFilterGauge.forEach(async (item) => {
      item.selected = item.GaugeId == gaugeId;
      if (item.selected) {

        var TRIMXYGROUPID = this.customtrim.metadata.TrimXYGroupId;

        var colors = await this.d.inlineGet('/GetTrimXYGroupFilterBaseColor?TRIMXYGROUPID=' + TRIMXYGROUPID + '&GAUGEID=' + item.GaugeId, {});

        //console.dir(this.d.model.colorSet);
        colors.forEach((color) => {
          var baseColor = this.d.model.colorSet.find(c => c.BasecolorId == color.BasecolorId);

          if (baseColor !== undefined) {
            color.ImageUrl = "/images/PaintChips/" + baseColor.ColorId + ".gif"
            color.ColorId = baseColor.ColorId;
            color.RGB = baseColor.RGB;
            //color.baseColor = _.find(this.customtrim.TrimXYGroupData.results, { Gauge: gaugeId,  });
          } else {
            color.ImageUrl = "";
          }
        });

        this.customtrim.metadata.TrimXYGroupFilterBaseColor = colors;
        this.d.clickElement("xy-color-select");
      }
    });


  */

  async flangeChange(flange, event) {
    //console.log("flangeChange flange", flange)
    //console.log("flangeChange event", typeof event, event)
    var angle = parseInt(event.target.value, 10);
    this.clearPricing();
    flange.value = angle;
    this.rerender();

  }
  setHem(isFirst, hemType) {
    this.clearPricing();

    if (!isFirst) {
      //Easy case: End Hem

      let lastIndex = this.customtrim.metadata.TDLData.flanges.length - 1;
      if (this.d.isHem(this.customtrim.metadata.TDLData.flanges[lastIndex])) {
        if (hemType.length == 0) {
          this.customtrim.metadata.TDLData.flanges.pop();
        } else {
          this.customtrim.metadata.TDLData.flanges[lastIndex].type = hemType;
        }
      } else {
        this.customtrim.metadata.TDLData.flanges.push({ "type": hemType, "value": 0.5 });
      }

    } else {
      //Hard case: Start Hem

      let firstFlange = this.customtrim.metadata.TDLData.flanges[0];
      let angleDelta = 0;

      //remove the existing startAngle modification, if any
      switch (this.customtrim.metadata.TDLData.flanges[0].type) {
        case 'posHem': this.customtrim.metadata.TDLData.startAngle += 190; break
        case 'posHook': this.customtrim.metadata.TDLData.startAngle += 180; break
        case 'negHem': this.customtrim.metadata.TDLData.startAngle -= 190; break
        case 'negHook': this.customtrim.metadata.TDLData.startAngle -= 180; break
      }


      if (this.d.isHem(this.customtrim.metadata.TDLData.flanges[0])) {
        //swap it
        this.customtrim.metadata.TDLData.flanges[0].type = hemType;
      } else {
        //insert it
        this.customtrim.metadata.TDLData.flanges.unshift({ "type": hemType, "value": 0.5 });

      }

      //re-add the startangle offset
      switch (this.customtrim.metadata.TDLData.flanges[0].type) {
        case 'posHem': this.customtrim.metadata.TDLData.startAngle -= 190; break
        case 'posHook': this.customtrim.metadata.TDLData.startAngle -= 180; break
        case 'negHem': this.customtrim.metadata.TDLData.startAngle += 190; break
        case 'negHook': this.customtrim.metadata.TDLData.startAngle += 180; break
      }

    }
    //clean up
    this.customtrim.metadata.TDLData.flanges = this.customtrim.metadata.TDLData.flanges.filter((f) => {
      return f.type != ""
    });

    //determine and apply hem length
    let hemCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return this.hemTypes.includes(f.type) }).length;
    let selectedMat = this.d.model.TrimFullyCustomSet.find((mat) => { return this.customtrim.metadata.materialType == mat.TrimCoating });
    let hemCfg = selectedMat.HasPOPCapability.HemConfiguration.results.find((f) => { return f.Hems == hemCount });
    let hemLen = parseFloat(hemCfg.HemLength);
    this.customtrim.metadata.TDLData.flanges.forEach((f) => {
      if (this.hemTypes.includes(f.type)) f.value == hemLen;
    });

    //this.fixHemLengths();
    this.fixHemLengthsPerHem(); //new fix for Salem press break issue

    //console.log(hemCount);

    this.rerender();
  }




  checkQty = this.d.debounce(this.doCheckQty, 300);
  doCheckQty() {
    this.clearPricing();
    let fixedQty = Number(this.customtrim.Quantity001);
    let isOk = true;
    if (this.d.isNaN(fixedQty)) isOk = false;
    if (fixedQty < 1) isOk = false;
    if (!isOk) {
      this.customtrim.Quantity001 = 1;
      this.d.toast("Invalid Quantity, reset to 1");
    }

  }

  setPaintedSide(side) {

    this.clearPricing();
    this.customtrim.metadata.TDLData.paintedSide = side;

    switch (this.customtrim.metadata.TDLData.paintedSide) {
      case "L":
        this.customtrim.metadata.lColor = this.customtrim.metadata.color.RGB;
        this.customtrim.metadata.rColor = "#A9B0B7";
        break;
      case "R":
        this.customtrim.metadata.lColor = "#A9B0B7";
        this.customtrim.metadata.rColor = this.customtrim.metadata.color.RGB;
        break;
      default:
        this.customtrim.metadata.lColor = "#A9B0B7";
        this.customtrim.metadata.rColor = "#A9B0B7";
    }

    this.rerender();
  }


  async selectColor(color) {
    this.clearPricing();

    this.customtrim.metadata.color = color;
    this.customtrim.metadata.materialType = (color.BasecolorId == 1) ? "B" : "P";
    if (!this.isFCT) {
      this.customtrim.metadata.TrimXY = this.customtrim.metadata.TrimXYSet.find(t => t.CoatingId == this.customtrim.metadata.materialType);
      this.customtrim.metadata.TrimXY.TrimXYSlopeCombinedSet = await this.d.inlineGet('/GetTrimXYSlopeCombinedSet?TrimXYId=' + this.customtrim.metadata.TrimXY.TrimXYId, {});
      this.customtrim.metadata.TrimXY.TrimXYSlopeCombinedSet.sort((a, b) => { return a.Degree - b.Degree; });
      this.customtrim.metadata.mins = this.customtrim.metadata.TrimXY.HasPOPCapability;
      console.log("this.customtrim.metadata.mins: ", this.customtrim.metadata.mins)
    }

    this.customtrim.metadata.hems = {
      startHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }],
      endHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }]
    }


    switch (this.customtrim.metadata.TDLData.paintedSide) {
      case "L":
        this.customtrim.metadata.lColor = color.RGB;
        this.customtrim.metadata.rColor = "#A9B0B7";
        break;
      case "R":
        this.customtrim.metadata.lColor = "#A9B0B7";
        this.customtrim.metadata.rColor = color.RGB;
        break;
      default:
        this.customtrim.metadata.lColor = "#A9B0B7";
        this.customtrim.metadata.rColor = "#A9B0B7";
    }

    this.rerender();

  }

  selectHem(position, name) {
    this.clearPricing();
    this.customtrim.metadata.hems[position].forEach((hem) => {
      hem.selected = hem.name == name;
    });
  }

  canRemove() {
    if (!this.isFCT) return false; //should never happen!
    let lineCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return f.type == "line"; }).length;
    return lineCount > 1;
  }

  canRemoveAt(i: number) {
    //you can only remove it this way if the following element is an angle!
    if (!this.isFCT) return false; //should never happen!
    let lastIndex = this.customtrim.metadata.TDLData.flanges.length - 1;
    if (i >= lastIndex) {
      return false;
    } else {
      return (this.customtrim.metadata.TDLData.flanges[i + 1].type == "angle");
    }

  }

  addLength() {
    var isFirst = false;
    if (!this.isFCT) return this.d.toast("Can't add segment to XY trims!"); //should never happen!
    this.clearPricing();
    let lastIndex = this.customtrim.metadata.TDLData.flanges.length - 1;
    if (this.d.isHem(this.customtrim.metadata.TDLData.flanges[lastIndex])) {
      this.customtrim.metadata.TDLData.flanges.splice(lastIndex, 0, { "type": "line", "value": 2 });
      this.customtrim.metadata.TDLData.flanges.splice(lastIndex, 0, { "type": "angle", "value": 135 });
    } else {
      this.customtrim.metadata.TDLData.flanges.push({ "type": "angle", "value": 135 });
      this.customtrim.metadata.TDLData.flanges.push({ "type": "line", "value": 2 });
    }
    this.rerender();
  }

  swapAngle(i: number) {
    var a = this.customtrim.metadata.TDLData.flanges[i].value;
    this.customtrim.metadata.TDLData.flanges[i].value = (a - (a * 2));
    this.rerender();
  }

  removeLength() {
    this.clearPricing();
    let lastIndex = this.customtrim.metadata.TDLData.flanges.length - 1;
    if (this.d.isHem(this.customtrim.metadata.TDLData.flanges[lastIndex])) {
      let hem = this.customtrim.metadata.TDLData.flanges.pop();
      this.customtrim.metadata.TDLData.flanges = this.customtrim.metadata.TDLData.flanges.slice(0, lastIndex - 2);
      this.customtrim.metadata.TDLData.flanges.push(hem);
    } else {
      this.customtrim.metadata.TDLData.flanges = this.customtrim.metadata.TDLData.flanges.slice(0, lastIndex - 1);
    }
    this.rerender();
  }

  removeAt(index) {
    this.clearPricing();
    this.customtrim.metadata.TDLData.flanges.splice(index, 1); //remove length
    if (!this.d.isHem(this.customtrim.metadata.TDLData.flanges[index])) {
      this.customtrim.metadata.TDLData.flanges.splice(index, 1); //remove following angle
    }
    this.rerender();
  }

  isValid() {
    if (this.altAngles.length > 0) return false;
    if (this.isFCT) {
      if (this.customtrim.Gauge == '') return false;
    } else {
      let gaugeObj = this.customtrim.metadata.TrimXYGroupFilterGauge.find(g => g.selected);
      if (gaugeObj === undefined) return false;
    }
    if (!this.customtrim.metadata.color) return false;
    if (this.customtrim.metadata.mins !== undefined && this.customtrim.metadata.totalGirth > this.customtrim.metadata.mins.GirthMax) return false;
    if (this.customtrim.metadata.totalGirth < this.minGirth()) return false;
    if (this.d.trimrenderRef.model.selfIntersections.length > 0) return false;
    return true;
  }

  minGirth() {
    if (this.isFCT) {
      let hemCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return this.d.isHem(f); }).length;
      let minGirthSrc = this.d.model.TrimFullyCustomSet[0].HasPOPCapability.HemConfiguration.results.find((f) => f.Hems == hemCount);
      return parseFloat(minGirthSrc.GirthMin); // "3.5000"
    } else {
      let hemCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return this.d.isHem(f); }).length;
      let minGirthSrc = this.customtrim.metadata.TrimXYSet[0].HasPOPCapability.HemConfiguration.results.find((f) => f.Hems == hemCount);
      return minGirthSrc.GirthMin; // 3.5000
    }

  }

  clearPricing() {
    this.customtrim.metadata.pricingSetResult = { NetPrice: '0.00', DiscountAmt: '0.00' };
  }

  toggleLabelFlip() {
    if (this.customtrim.metadata.TDLData.hasOwnProperty("labelFlip")) {
      this.customtrim.metadata.TDLData.labelFlip = !this.customtrim.metadata.TDLData.labelFlip;
    } else {
      this.customtrim.metadata.TDLData.labelFlip = true;
    }
    this.rerender();
  }

  setLabelDistance(dist) {
    if (this.customtrim.metadata.TDLData.hasOwnProperty("labelDistance")) {
      this.customtrim.metadata.TDLData.labelDistance += dist;
    } else {
      this.customtrim.metadata.TDLData.labelDistance = dist;
    }
    if (this.customtrim.metadata.TDLData.labelDistance < 0) {
      this.customtrim.metadata.TDLData.labelDistance = 0;
    }
    console.log(this.customtrim.metadata.TDLData.labelDistance);

    this.rerender();
  }

  async validateName() {
    if (this.customtrim.metadata.TDLData.name == "") {
      this.d.toast("Your custom trim must have a name");
      //this.customtrim.metadata.TDLData.name = "My Fully Custom Trim";
      this.d.selectInput("fctname");
      return false;
    } else {
      return true;
    }
  }

  async pricingSet() {

    this.clearHls();
    this.clearFocused();
    await this.rerender(); //in case they zoomed in


    this.customtrim.metadata.TDLData.name = this.customtrim.metadata.TDLData.name.trim();

    let isValid = await this.validateName();
    if (!isValid) return;


    //console.log("PricingSet");
    //console.dir(this.customtrim);

    /*
     ReqQty=2,
    SoldTo='50080',
    Materialnumber='JAMXYZ_P',
    Colorcode='CANC',
    Gauge='29',
    PaintSystem='DTXL',
    ShipPlant='SA03',
    DownhillSlope='',
    UphillSlope='',
    Type='T',
    Metal='TRUZ',
    Grade='80',
    ShipTo='50080',
    WillCall='',
    Ctwt='G60',
    Girth=5,
    Hems=1,
    Strokes=1,
    Pitch=1
     */
    //let gaugeObj = _.find(this.customtrim.metadata.filterGauge, { selected: true });
    //let fctObj = _.find(this.customtrim.metadata.TrimFullyCustomSet, { selected: true });

    //let colorCode = this.customtrim.metadata.color.ColorId; //don't forget to validate that a color was set
    //let gauge = gaugeObj.GaugeId


    //get group data by ColorCode, Gauge and Metal
    //let fltr = { BasecolorId: this.customtrim.metadata.color.BasecolorId, Gauge: gauge };
    //console.log("Searching this.customtrim.metadata.TrimXYGroupData.results for: " + JSON.stringify(fltr));
    //this.customtrim.metadata.groupData = _.find(this.customtrim.metadata.TrimFullyCustomSet.results, fltr);
    let hemCount = this.customtrim.metadata.TDLData.flanges.filter((f) => { return this.hemTypes.includes(f.type) }).length;
    this.setGroupData();
    /*
    console.dir(this.customtrim.metadata.color);
    let fltr = {
      Gauge: this.customtrim.Gauge, 
      ColorCode:this.customtrim.metadata.color.ColorId
    };
    console.log(JSON.stringify(fltr));
    console.dir(this.d.model.TrimFullyCustomGetData);
    this.customtrim.metadata.groupData = _.find(this.d.model.TrimFullyCustomGetData, fltr);
    */
    //console.log(this.customtrim.metadata.groupData);
    //console.log(JSON.stringify(this.customtrim.metadata.groupData,null,2));

    this.customtrim.metadata.pricingSetResult = { NetPrice: '0.00' };


    console.log('TrimfcId ref #1');
    this.customtrim.metadata.pricingPayload = {
      "ReqQty": Number(this.customtrim.Quantity001), //2,
      "SoldTo": this.d.model.SoldTo.Soldto, //'50080',
      "Materialnumber": this.isFCT ? this.customtrim.metadata.groupData.TrimfcId : this.customtrim.metadata.TrimXY.TrimXYId,//'JAMXYZ_P',
      "Colorcode": this.customtrim.metadata.groupData.ColorCode, //'CANC',
      "Gauge": this.customtrim.metadata.groupData.Gauge, //'29',
      "PaintSystem": this.customtrim.metadata.groupData.PaintSystem, //'DTXL',

      "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,//'SA03',
      "DownhillSlope": '',
      "UphillSlope": '',
      "Type": 'T',
      "Metal": this.customtrim.metadata.groupData.Metal, //'TRUZ',
      "Grade": this.customtrim.metadata.groupData.Grade, //'80',
      "ShipTo": this.d.model.ShipTo.SHIPTO, //'50080',
      "ShippingModeId": this.d.model.ShipMode.ShippingModeId,
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),//'',
      "Ctwt": this.customtrim.metadata.groupData.CoatingWt, //'G60',
      "Girth": this.customtrim.metadata.totalGirth,
      "Hems": this.isFCT ? hemCount : 0,
      "Strokes": this.isFCT ? this.customtrim.metadata.totalStrokes : 0,
      "Pitch": 0
    }
    //console.dir(this.customtrim.metadata.pricingPayload);

    this.d.post("/GetPricingSet", {}, this.customtrim.metadata.pricingPayload).subscribe((result) => {
      //console.dir(result);
      this.customtrim.metadata.pricingSetResult = result;
      //this.trim.metadata.pricingSetResult.ItemTotal = parseFloat(this.trim.metadata.pricingSetResult.NetPrice) * this.trim.metadata.Quantity;

      //console.log("Pricing Result");
      //console.log(this.customtrim.metadata.pricingSetResult);


      //NetPrice is the unit price for some reason
      //ItemTotal is the sum of all units
      if (parseFloat(this.customtrim.metadata.pricingSetResult.NetPrice) == 0) {
        if (this.customtrim.metadata.pricingSetResult.MessageText.length > 0) {
          this.d.toast(this.customtrim.metadata.pricingSetResult.MessageText);
        } else {
          this.d.toast("Pricing error, please contact your ASC BP Customer Service Representative");
        }
      }


      this.customtrim.metadata.Requesteddelivery = this.customtrim.metadata.pricingSetResult.DeliveryDate;

      if (this.d.trimrenderRef.model.punchIntersections.length > 0) {
        setTimeout(() => {
          this.pbCollisonWarningModalRef = this.d.showModal(this.pbCollisonWarningModal, 'modal-dialog-centered', true);
        }, 1000);
      }
      if (this.customtrim.metadata.TDLData.name == "My Fully Custom Trim") {
        this.d.toast("Give your trim a useful name", 1000);
        //$("#fctname").select();
        this.d.selectInput("fctname");
      }

    });


  }
  debug() {
    console.log(this.customtrim);
  }


  setGroupData() {
    let compatColors = this.d.model.colorSet.filter(c => c.BasecolorId == this.customtrim.metadata.color.BasecolorId);
    let compatColorIds = compatColors.map(f => f.ColorId);

    //console.log("Finding TrimFullyCustomGetData with Gauge == " + this.customtrim.Gauge + ", and ColorCode in " + JSON.stringify(compatColorIds));
    if (this.isFCT) {
      this.customtrim.metadata.groupData = this.d.model.TrimFullyCustomGetData.find((f) => {
        return f.Gauge == this.customtrim.Gauge && compatColorIds.includes(f.ColorCode);
      });
    } else {

      var gaugeObj = this.customtrim.metadata.TrimXYGroupFilterGauge.find(t => t.selected);
      this.customtrim.metadata.groupData = this.customtrim.metadata.TrimXYGroupData.results.find((t) => {
        return t.BasecolorId == this.customtrim.metadata.color.BasecolorId && t.Gauge == gaugeObj.GaugeId;
      });
      this.customtrim.metadata.groupData.Gauge = gaugeObj.GaugeId;

    }
  }

  async finalize() {

    this.customtrim.Quantity001 = this.customtrim.Quantity001.toString();

    // Shared FCT and XY Properties:

    this.setGroupData();


    //this.customtrim.metadata.groupData = this.customtrim.metadata.TrimXYGroupData.results.find((t) => {
    //  return t.BasecolorId == this.customtrim.metadata.color.BasecolorId && t.Gauge == gaugeObj.GaugeId;
    //});
    this.customtrim.ColorCode = this.customtrim.metadata.groupData.ColorCode; //"RUSC",
    this.customtrim.Ctwt = this.customtrim.metadata.groupData.CoatingWt;
    this.customtrim.Grade = this.customtrim.metadata.groupData.Grade; //"33",
    this.customtrim.Metal = this.customtrim.metadata.groupData.Metal; //"ZNAL",
    this.customtrim.PaintSystem = this.customtrim.metadata.groupData.PaintSystem; //"DTXL",

    this.customtrim.CutlistProvide = "T";//never Y unless a panel
    this.customtrim.Girth = this.customtrim.metadata.totalGirth.toString();
    this.customtrim.Itemnumber = "001000"; //reindexed in cart
    this.customtrim.metadata.Quantity = this.customtrim.Quantity001;
    this.customtrim.Plant = this.customtrim.metadata.pricingPayload.ShipPlant; //"SA03",
    this.customtrim.Productname = this.customtrim.metadata.Name; //"UPHILL CHIMNEY PTD (CH16_P)",
    this.customtrim.Requesteddelivery = this.customtrim.metadata.pricingSetResult.DeliveryDate; //"20170130",
    this.customtrim.Unitofmeasure = this.customtrim.metadata.pricingSetResult.Unitofmeasure; //"PC",

    if (this.isFCT) {
      // Fully configured trim only properties:
      this.customtrim.DownhillSlope = '';
      this.customtrim.Gauge = this.customtrim.metadata.groupData.Gauge; //"26",
      this.customtrim.Materialnumber = this.customtrim.metadata.groupData.TrimfcId; //"CH16_P",

      this.customtrim.Product = this.customtrim.metadata.groupData.TrimfcId; //"CH16_P",
      this.customtrim.UphillSlope = '';
      this.customtrim.Pitch = 0;
      this.customtrim.Strokes = this.customtrim.metadata.pricingPayload.Strokes
      this.customtrim.Hems = this.customtrim.metadata.pricingPayload.Hems;

    } else {
      // XY Trim only properties:
      var gaugeObj = this.customtrim.metadata.TrimXYGroupFilterGauge.find(t => t.selected);
      let angleObj = { Downhill: " ", Uphill: " " };
      let angledFlange = this.customtrim.metadata.TDLData.flanges.find((f) => { return f.type == 'angle' && f.hasOwnProperty("name"); });
      if (angledFlange !== undefined) angleObj =
        this.customtrim.metadata.TrimXY.TrimXYSlopeCombinedSet.find(c => c.Degree == angledFlange.value.toString());
      this.customtrim.DownhillSlope = angleObj.Downhill;
      this.customtrim.UphillSlope = angleObj.Uphill;
      this.customtrim.Gauge = gaugeObj.GaugeId; //"26",

      this.customtrim.Materialnumber = this.customtrim.metadata.TrimXY.TrimXYId; //"CH16_P",
      this.customtrim.OfferingID = ""; //always blank unless a panel
      this.customtrim.Product = this.customtrim.metadata.TrimXY.TrimXYId; //"CH16_P",

      this.customtrim.metadata.groupData.Gauge = gaugeObj.GaugeId;
    }

    this.customtrim.metadata.svg = this.getRawSvg();
    this.customtrim.metadata.blob = new Blob([this.customtrim.metadata.svg], { type: "image/svg+xml" });
    //let imgUrl = URL.createObjectURL(this.customtrim.metadata.blob); //IE can't show an SVG in a blob: url
    let imgUrl = "data:image/svg+xml;base64," + this.d.b64EncodeUnicode(this.customtrim.metadata.svg);

    this.customtrim.metadata.product = {
      ImageUrl: imgUrl
    };




    if (this.d.trimrenderRef.model.punchIntersections.length > 0) {
      this.customtrim.metadata.cartError = {
        Type: 'PB_COLL_WARN',
        Message: 'Press Brake Collision Warning'
      };
    } else {
      if (this.customtrim.metadata.hasOwnProperty("cartError")) {
        delete this.customtrim.metadata.cartError;
      }
    }
  }

  fixAngle(newAngle) {
    this.closeTooltip();
    this.altAngles = [];
    this.customtrim.metadata.TDLData.flanges.forEach(f => {
      if (f.type == 'angle' && f.hasOwnProperty("name")) {
        f.value = newAngle;
      }
    });
    this.rerender();
  }

  closeTooltip() {
    if (this.tooltip !== undefined) {
      this.tooltip.close();
    }
  }

  setAngleError() {

    if (this.isFCT) {
      console.log("setAngleError: isFCT, so no error");
      this.closeTooltip();
      this.altAngles = [];
    } else {
      if (this.customtrim?.metadata?.TrimXY?.TrimXYSlopeCombinedSet === undefined) {
        console.log("setAngleError: TrimXYSlopeCombinedSet, so no error");
        this.closeTooltip();
        this.altAngles = [];
      } else {
        let angledFlange = this.customtrim.metadata.TDLData.flanges.find((f) => { return f.type == 'angle' && f.hasOwnProperty("name"); });
        if (angledFlange === undefined) {
          console.log("setAngleError: No angle found, so we are good");
          this.closeTooltip();
          this.altAngles = [];
        } else {
          let angleObj = this.customtrim.metadata.TrimXY.TrimXYSlopeCombinedSet.find(c => c.Degree == angledFlange.value.toString());
          if (angleObj === undefined) {

            this.altAngles = [];
            var validValues = this.customtrim.metadata.TrimXY.TrimXYSlopeCombinedSet.map(s => parseInt(s.Degree));
            var lowestArr = validValues.filter(v => v < angledFlange.value);
            var highestArr = validValues.filter(v => v > angledFlange.value);


            if (lowestArr.length > 0) this.altAngles.push(lowestArr[lowestArr.length - 1]);
            if (highestArr.length > 0) this.altAngles.push(highestArr[0]);
            //console.log("validValues: ", validValues);
            //console.log("lowest: ", lowestArr);
            //console.log("highest: ", highestArr);
            //console.log("alternatives: ", alternatives);

            console.log("setAngleError: BAD angleObj is undefined, ERROR. Options:", this.altAngles);
            this.tooltip.open();
          } else {
            console.log("setAngleError: GOOD angleObj is not undefined", angleObj);
            this.closeTooltip();
            this.altAngles = [];
          }
        }
      }
    }
  }

  async updateCart() {


    let isValid = await this.validateName();
    if (!isValid) return;
    this.finalize();

    this.d.model.cart.OrderItemSet[this.customtrim.metadata.cartIndex] = this.d.deref(this.customtrim);
    this.onUpdateCart.emit(true);
  }

  async addToCart() {

    let isValid = await this.validateName();
    if (!isValid) return;
    this.finalize();

    /*
    let debugCopy = this.d.deref(orderItem);
    delete debugCopy.metadata;
    console.log(JSON.stringify(debugCopy,null, 4));
    */

    //console.log("orderItem");
    //console.dir(orderItem);

    this.d.addToCartAnim();
    this.d.model.cart.OrderItemSet.push(this.d.deref(this.customtrim));
    return this.d.toast("Item Added To Cart");
  }

}