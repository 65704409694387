import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sapdate' })
export class SapDatePipe implements PipeTransform {
	transform(value: string): any {
		if (value === undefined) return "undefined";
		let m = value.substring(4, 6);
		let d = value.substring(6, 8);
		let y = value.substring(0, 4);
		return [m, d, y].join('-');
	}
}