import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { CmsPipe } from './cms.pipe';
import { MetaPipe } from './meta.pipe';
import { DiscountPercentPipe } from './discountpercent.pipe';
import { ColorDescPipe } from './colordesc.pipe'
import { SafeUrlPipe } from './safeurl.pipe'
import { SapDatePipe } from './sapdate.pipe'
import { CapitalizePipe } from './capitalize.pipe'
import { DemoPricePipe } from './demoprice.pipe';
import { ReverseIterablePipe } from './reverse.pipe';
import { RemoveLeadingZerosPipe } from './removeleadingzeros.pipe';
import { TruncatePipe } from './truncate.pipe';



@NgModule({
	declarations: [
		SafePipe,
		CmsPipe,
		MetaPipe,
		DiscountPercentPipe,
		ColorDescPipe,
		SafeUrlPipe,
		SapDatePipe,
		CapitalizePipe,
		DemoPricePipe,
		ReverseIterablePipe,
		RemoveLeadingZerosPipe,
		TruncatePipe
	],
	imports: [],
	exports: [
		SafePipe,
		CmsPipe,
		MetaPipe,
		DiscountPercentPipe,
		ColorDescPipe,
		SafeUrlPipe,
		SapDatePipe,
		CapitalizePipe,
		DemoPricePipe,
		ReverseIterablePipe,
		RemoveLeadingZerosPipe,
		TruncatePipe
	]
})
export class PipesModule { }