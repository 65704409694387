<div *ngIf="d.cartRef?.timeStamp?.length > 0">
  <!-- Logo, Header, Order Number, etc. -->
  <table width="100%">
    <tr>
      <td>
        <table>
          <tr>
            <td>
              <img src="/assets/images/ascbplogo.svg" width="200" height="85" style="margin-bottom:5px" />
            </td>
          </tr>
          <tr>
            <td>2110 Enterprise Blvd.</td>
          </tr>
          <tr>
            <td>West Sacramento, CA 95691</td>
          </tr>
        </table>
      </td>
      <td style="text-align:right;">
        <table style="margin-left:auto">
          <tr>
            <td style="text-align:right;">
              <H1>Pre-Order Summary</H1>
            </td>
          </tr>
          <tr>
            <td>
              <table class="darkborder" width="100%">
                <tr>
                  <td class="pad"><b>Customer Number</b></td>
                  <td class="pad"><b>Ship To Number</b></td>
                </tr>
                <tr>
                  <td class="pad">{{d.model.SoldTo.Soldto}}</td>
                  <td class="pad">{{d.model.ShipTo.SHIPTO}}</td>
                </tr>
                <tr>
                  <td colspan="2" class="pad">
                    <b>Timestamp:</b> {{d.cartRef.timeStamp}}<br />
                    {{printHash()}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <br />

  <!-- SoldTo, ShipTo and JobName -->
  <table class="darkborderl" style="width:100%;">
    <tr>
      <td rowspan="2" class="pad" style="white-space:no-wrap" width="30%">
        <div><b>Sold To: </b>{{d.model.SoldTo.Soldto}}</div>
        <div class="pad">{{d.model.SoldTo.Name}}</div>
      </td>
      <td rowspan="2" class="pad" style="white-space:no-wrap" width="30%">
        <div><b>Ship To:</b> {{d.model.ShipTo.SHIPTO}} / {{d.model.ShipTo.SHIPPINGPLANT}}</div>
        <div>{{d.model.ShipTo.NAME1}}</div>
        <div>{{d.model.ShipTo.STRAS}}</div>
        <div>{{d.model.ShipTo.ORT01}}<span *ngIf="d.model.ShipTo.ORT02">, {{d.model.ShipTo.ORT02}}</span>, {{d.model.ShipTo.REGIO}} {{d.model.ShipTo.PSTLZ}}</div>
      </td>
      <td width="10%" class="pad">
        <B>Job&nbsp;Name</B>
      </td>
      <td class="pad">
        {{d.model.cart.JobName}}
      </td>
    </tr>
    <tr>
      <td colspan="2" width="40%" class="pad">The price and availability of items on this Pre-Order Summary are subject to change. This Pre-Order Summary is a print-out of your cart on My ASC Profiles Portal and reflects each item’s most recent price.</td>
    </tr>
  </table>
  <br />

  <!-- Order User Name, Delivery Options, Shipping Details -->
  <table class="darkborder nobottom" style="width:100%;">
    <tr>
      <td class="pad">
        <b>e-Commerce User Name</b>
      </td>
      <td class="pad">
        <b>Delivery Options</b>
      </td>
      <td class="pad">
        <b>Shipping Details</b>
      </td>
    </tr>
    <tr>
      <td class="pad">{{d.model.accountInfo.UserName}}</td>
      <td class="pad">{{d.model.ShipMode.Description}}</td>
      <td class="pad">{{d.model.ShipMode.Name}}</td>
    </tr>
  </table>

  <!-- Order LineItems Header -->
  <table class="darkborder nobottom" style="width:100%;">
    <tr class="headerrow" style="border-bottom:2px solid black">
      <td class="pad">Line</td>
      <td class="pad">Product Number / Product Description</td>
      <!--<td class="pad">Confirmed<br/>Ship Date</td>-->
      <td class="pad">Qty</td>
      <td class="pad">UOM</td>
      <td class="pad" *ngIf="d.cartRef.printPrices">Net<br />Price</td>
      <td class="pad" *ngIf="d.cartRef.printPrices">Item Sub<br />Total</td>
    </tr>

    <!-- Order LineItems -->
    <ng-container *ngFor="let item of d.model.cart?.OrderItemSet; let i = index;">
      <tr class="notop " style="border-top:1px solid #ccc">
        <td class="pad" style="text-align:right">{{i+1}}000</td>
        <td class="pad" style="text-align:left">
          <div>
            {{item.Materialnumber}} {{item.Productname}}
            <ng-container *ngIf="item.metadata?.TDLData?.isFullyCustom">- {{item.metadata.TDLData.name}}</ng-container>
            <ng-container *ngIf="item.Gauge">- {{item.Gauge}}G</ng-container>
            <ng-container *ngIf="item.Metal">- {{item.Metal|meta:'metalName'}}</ng-container>
            <ng-container *ngIf="item.PaintSystem&&item.ColorCode!='BARE'">- {{item.PaintSystem}}</ng-container>
            <ng-container *ngIf="item.ColorCode">- {{item.ColorCode|colordesc}}</ng-container>
            <ng-container *ngIf="item.UphillSlope.length > 0">
              <ng-container *ngIf="item.DownhillSlope == 'NA'">- Combined Pitch {{item.UphillSlope}}:12</ng-container>
              <ng-container *ngIf="item.DownhillSlope != 'NA'">- Uphill Pitch {{item.UphillSlope}}:12 / Downhill Pitch {{item.DownhillSlope}}:12, </ng-container>
            </ng-container>

          </div>
          <table class="fixedwidth">
            <tr *ngFor="let cut of item?.metadata?.cutlist">
              <td>{{cut.Quantity}}</td>
              <td></td>
              <td>{{cut.Feet}}&apos;</td>
              <td><ng-container *ngIf="cut.Inches > 0">{{cut.Inches}}</ng-container></td>
              <td *ngIf="cut.Fractions > 0">{{(cut.Fractions/16).toString()|meta:'formatFraction'}}</td>
              <td style="text-align:left;padding-left:0px" ng-show="cut.Fractions > 0 || cut.Inches > 0">&quot;</td>
              
            </tr>
          </table>
        </td>
        <!--<td class="pad">lineitem.ConfShpDate| date:"MM/dd/yyyy"</td>-->
        <td class="pad" style="text-align:right">{{item.Quantity001|meta:'stripDecimal'}}</td>
        <td class="pad">{{item.Unitofmeasure}}</td>
        <td class="pad" style="text-align:right;" *ngIf="d.cartRef.printPrices">
          <table class="noborder" style="width:100%">
            <tr>
              <td style="text-align:left;"></td>
              <td style="text-align:right;">{{item.metadata.pricingSetResult.ItemSubTotal| currency:'USD':'symbol':'1.2-2'}}</td>
            </tr>
          </table>
        </td>
        <td class="pad" style="text-align:right;" *ngIf="d.cartRef.printPrices">
          <table class="noborder" style="width:100%">
            <tr>
              <td style="text-align:left;"></td>
              <td style="text-align:right;">{{item.metadata.pricingSetResult.ItemTotal| currency:'USD':'symbol':'1.2-2'}}</td>
            </tr>
          </table>
        </td>
      </tr>

      <!-- Order LineItems Extras -->
      <tr class="notop " *ngIf="d.cartRef.printPrices">
        <td></td>
        <td class="pad" style="text-align:left;font-style: italic;">
          <div class="lineitemdetail" *ngIf="item?.metadata.pricingSetResult.LLFixExtra != '0.00'">Fixed Long Length Extra</div>
          <div class="lineitemdetail" *ngIf="item?.metadata.pricingSetResult.LLVarExtra != '0.00'">Var. Long Length Extra</div>
          <div class="lineitemdetail" *ngIf="item?.metadata.pricingSetResult.ShortcutExtra != '0.00'">Shortcut Extra</div>
          <div class="lineitemdetail" *ngIf="item?.metadata.pricingSetResult.DiscountAmt != '0.00'">MyASCProfilesPortal Discount</div>
        </td>
        <td></td>

        <td *ngIf="d.cartRef.printPrices"></td>
        <td class="pad" *ngIf="d.cartRef.printPrices">
          <div class="pad lineitemdetail" *ngIf="item?.metadata.pricingSetResult.LLFixExtra != '0.00'">
            <table class="noborder" style="width:100%">
              <tr>
                <td style="text-align:left;"></td>
                <td style="text-align:right;font-style: italic;">{{item?.metadata.pricingSetResult.LLFixExtra| currency:'USD':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
          <div class="pad lineitemdetail" *ngIf="item?.metadata.pricingSetResult.LLVarExtra != '0.00'">
            <table class="noborder" style="width:100%">
              <tr>
                <td style="text-align:left;"></td>
                <td style="text-align:right;font-style: italic;">{{item?.metadata.pricingSetResult.LLVarExtra| currency:'USD':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
          <div class="pad lineitemdetail" *ngIf="item?.metadata.pricingSetResult.ShortcutExtra != '0.00'">
            <table class="noborder" style="width:100%">
              <tr>
                <td style="text-align:left;"></td>
                <td style="text-align:right;font-style: italic;">{{item?.metadata.pricingSetResult.ShortcutExtra| currency:'USD':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
          <div class="pad lineitemdetail" *ngIf="item?.metadata.pricingSetResult.DiscountAmt != '0.00'">
            <table class="noborder" style="width:100%">
              <tr>
                <td style="text-align:left;"></td>
                <td style="text-align:right;font-style: italic;">{{item?.metadata.pricingSetResult.DiscountAmt| currency:'USD':'symbol':'1.2-2'}}</td>
              </tr>
            </table>
          </div>
        </td>
        <td></td>
      </tr>


    </ng-container>
    <tr style="border-top:1px solid black;">
      <td [attr.colspan]="(d.cartRef.printPrices) ? 6 : 4" style="border-left:0;border-right:0; text-align:right">
        
        <table class="noborder" style="float:right;" *ngIf="d.cartRef.printPrices">
          <tr *ngIf="d.model.jobSite">
            <td style="text-align:right;padding:4px"><b>SUBTOTAL:</b></td>
            <td style="text-align:right;padding:4px">{{d.cartRef.printSubTotal| currency:'USD':'symbol':'1.2-2'}}</td>
          </tr>
          <tr *ngIf="d.model.jobSite">
            <td style="text-align:right;padding:4px">Job Site Freight:</td>
            <td style="text-align:right;padding:4px">$125.00</td>
          </tr>
          <tr>
            <td style="text-align:right;padding:4px"><b>TOTAL:</b></td>
            <td style="text-align:right;padding:4px">{{d.cartRef.printTotal| currency:'USD':'symbol':'1.2-2'}}</td>
          </tr>
        </table>


      </td>
    </tr>
    
    </table>
       <!--
    <tr style="border-bottom:1px solid black;">
   
      <td colspan="4" rowspan="2" class="pad" style="text-align:left;">
        <b>Shipping Instructions:</b>
        <div class="pad" style="margin-top:5px">{{d.model.cart.ShipmentInstruction}}</div>
      </td>
      
      <td class="pad" style="text-align:right;border-right:0px!important;" *ngIf="d.cartRef.printPrices">
        <B>TOTAL:</B>
      </td>
      there are 4 columns when the price is hidden
      there are 6 columns when the price is shown
       
      <td [attr.colspan]="(d.cartRef.printPrices) ? 6 : 4" style="border-left:1px solid black;border-bottom:0px" class="pad">
        <table class="noborder" style="width:100%" *ngIf="d.cartRef.printPrices">
          <tr *ngIf="d.model.jobSite">
            <td style="text-align:right;padding-left:1px"><b>SUBTOTAL:</b></td>
            <td style="text-align:right;">{{d.cartRef.printSubTotal| currency:'USD':'symbol':'1.2-2'}}</td>
          </tr>
          <tr *ngIf="d.model.jobSite">
            <td style="text-align:right;padding-left:1px">Job Site Freight:</td>
            <td style="text-align:right;">$125.00</td>
          </tr>
          <tr>
            <td style="text-align:right;padding-left:1px"><b>TOTAL:</b></td>
            <td style="text-align:right;">{{d.cartRef.printTotal| currency:'USD':'symbol':'1.2-2'}}</td>
          </tr>
        </table>
      </td>
    </tr>
    <tr style="border-bottom:1px solid black" *ngIf="d.cartRef.printPrices">
      <td colspan="2" style="border-top:1px solid transparent;text-align:left;padding:0px 5px;">
        <span style="position:relative;top:-3px;" *ngIf="d.model.ShipMode.ShippingModeId=='PARC'">Parcel carrier charges not included</span>
      </td>
    </tr>
 -->



</div>