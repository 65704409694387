import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  //styleUrls: ['./tabset.component.scss']
})
export class TabsetComponent implements OnInit {

  constructor() { }
  @Input() tabs: any[];
  active = 0;
  ngOnInit() {
    this.tabs.forEach((tab, i) => {
      tab.active = i == 0;
    });
  }
  switchTab(e) {

    this.tabs.forEach((tab, i) => {
      tab.active = e.nextId == i;
    });
  }


}
