import { Component, OnInit, Input, Output, QueryList, ViewChild, ElementRef, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DataService } from '../../data.service';
import { TdlParser } from '../../TdlParser';


@Component({
  selector: 'trimrender',
  templateUrl: './trimrender.component.html',
})
export class TrimRenderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('renderedSvg', { read: ElementRef }) renderedSvgElementRef: ElementRef;
  //@Input() customtrim: any;
  @Input() customtrim: any;
  @Input() renderid;
  @Output('customtrimChange') update = new EventEmitter();

  constructor(public d: DataService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("trimrender detected a model change!");
  }
  ngOnDestroy(): void {
    console.log("trimrender exit, unlinking trimrenderRef...");
    this.d.trimrenderRef = {};
  }

  public tdlParser;


  ngOnInit() {
    this.tdlParser = new TdlParser(this.d, this.customtrim, this.renderid);
    this.d.trimrenderRef = this.tdlParser;
  }



}

