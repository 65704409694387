<div style="text-align:left;">

  <div class="form-group" style="display:inline-block;margin-right:15px;">
    <b>&nbsp;&nbsp;Color:</b>

    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn btn-default" id="panel-color-select" ngbDropdownToggle>
        <span *ngIf="!panel.metadata.color.ColorDescription">Select Color...</span>
        <span *ngIf="panel.metadata.color.ColorDescription">
          &nbsp;
          <img src="{{panel.metadata.color.ImageUrl|cms}}" class="color-menu-image" />
          {{panel.metadata.color.ColorDescription}}
        </span>
      </button>
      <div ngbDropdownMenu aria-labelledby="panel-color-select" id="panel-color-dropdown">
        <ng-container *ngFor="let color of colors">
          <button ngbDropdownItem (click)="selectColor(color)" [ngClass]="{'selected':panel.metadata.color==color}">
            <img src="{{color.ImageUrl|cms}}" class="color-menu-image" />{{color.ColorDescription}}</button>
        </ng-container>

      </div>
    </div>

  </div>

  <br />

  <div class="form-group" style="display:inline-block">
    <b>Gauge:</b>
    <div ngbDropdown class="d-inline-block">
      <button type="button" class="btn btn-default" id="panel-gauge-select" ngbDropdownToggle>
        <span *ngIf="!panel.metadata.gauge">Select Gauge...</span>
        <span *ngIf="panel.metadata.gauge">{{panel.metadata.gauge.GaugeDescription}} -
          {{panel.metadata.gauge.Metal|meta:'metalName'}}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="panel-gauge-select">
        <ng-container *ngFor="let gauge of gauges">
          <button ngbDropdownItem (click)="selectGauge(gauge)" [ngClass]="{'selected': panel.metadata.gauge==gauge}">
            {{gauge.GaugeDescription}} - {{gauge.Metal|meta:'metalName'}}
          </button>
        </ng-container>

      </div>
    </div>


  </div>


</div>



<ng-template #striatedWarningModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Striated Only</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close"
      (click)="striatedWarningModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    <p>Currently this color is only available Striated – Do you still want them?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" (click)="reselectColor()"><fa-icon
        [icon]="d.faTimes"></fa-icon>No</button>
    <button class="btn btn-secondary" (click)="confirmColor()"><fa-icon [icon]="d.faCheck"></fa-icon>Yes</button>
  </div>
</ng-template>