<div class="flex-container">

  <div class="account d-flex" *ngIf="d.model.user.authState == 1">
    <div class="d-flex align-items-center w-100">
      <div class="logonBox d-flex flex-column">
  
        <div class="p-3 bd-highlight">
          <img src="/assets/images/MyASCProfilesPortal.svg" class="headerImg"></div>
        <div class="p-2 bd-highlight">
          <button class="btn btn-default w-100" (click)="d.am.login('b2c')"  style="line-height:2.5em" 
          [ngbTooltip]="tipContent" placement="bottom" tooltipClass="helpTip" >
            <img src="/assets/images/auth_asc.svg" class="authIcon float-start">ASC Customer Logon
          </button>
          <ng-template #tipContent>
            <div class="widetip">
            If this is your first time seeing this, note that you will need to first click 
            <b>Forgot your password?</b> on the next screen, and follow the instructions from there. 
            <br><img src="/assets/images/login_help.png">
            </div>
          </ng-template>
        </div>
        <div class="gap-2 col-6 mx-auto">
          
          <a href="https://forms.ascprofiles.com/eCommerceSignup/" class="btn btn-tertiary btn-sm">Sign Up Your Company</a>
        </div>
  
        <div class="p-2 bd-highlight">
          <hr>
        </div>
        <div class="p-2 bd-highlight">
  
          <button class="btn btn-default w-100" (click)="d.am.login('aad')" style="line-height:2.5em">
            <img src="/assets/images/auth_bs.svg" class="authIcon float-start">BlueScope Employee SSO</button>
        </div>
      
      </div>
    </div>
  </div>
  <!--<pre>{{d.model.user.authState|json}}</pre>-->
  
</div>

