import { DataService } from './data.service';
//var _: any = require("lodash");
//var $: any = require("jquery");
export class TdlParser {

	public model: any = {};

	public panOrigin = { x: 0, y: 0 }
	public isPanning = false;
	public rectInfo = { x: 50, y: 20, w: 150, h: 150 };
	public showDepth: boolean = false;

	constructor(public d: DataService, public customtrim: any, private renderid: string) {
		console.log('TdlParser instance created: ', this.customtrim);

		this.model.showLabels = true;
		this.model.scale = 0.75;
		this.model.view = "diagram";
		this.model.translate = { x: 0, y: 0 };
		this.model.pTranslate = 'translate(-500,-500)';
		this.model.selfIntersections = [];
		this.model.punchIntersections = [];
		this.model.pAngle = 0;
		// this.setPTransform();
		//if (this.customtrim !== undefined){
		this.render();
		//}
	}

	public Hello() {
		console.log('Hello from TdlParser');
	}



	switchView(viewName) {
		this.model.view = viewName;
		if (this.d.model.hd3d === undefined) this.d.model.hd3d = false;
		switch (viewName) {

			case "3d":
				this.d.model.hd3d = false;
				this.model.depthUrl = '/assets/rendertrim/trim3d2.html?hd=' + this.d.model.hd3d + '&t=' + new Date().getTime();
				break;

			case "hd3d":
				this.d.model.hd3d = true;
				this.model.depthUrl = '/assets/rendertrim/trim3d2.html?hd=' + this.d.model.hd3d + '&t=' + new Date().getTime();
				break;

		}
	}

	getAvgVector() {
		let x = 0;
		let y = 0;
		this.model.perpVecs.forEach((a) => {
			x += Math.cos(this.degToRad(a % 360));
			y += Math.sin(this.degToRad(a % 360));
		})
		return this.radToDeg(Math.atan2(y, x));
	}

	toggleLabels() {
		this.model.showLabels = !this.model.showLabels;
		setTimeout(() => {
			this.render();
		}, 100);

	}


	setPTransform() {

		let bounds = 955;

		let pAdder = (this.customtrim.metadata.TDLData.paintedSide == "R") ? 180 : 0;


		//this.model.pAngle = _.mean(this.model.perpVecs);
		this.model.pAngle = this.getAvgVector();
		//console.log(this.model.pAngle + ": " + JSON.stringify(this.model.perpVecs));
		this.model.pAngle = (this.model.pAngle + pAdder) % 360;

		let a = this.degToRad(this.model.pAngle);
		let abs_cos_angle = Math.abs(Math.cos(a));
		let abs_sin_angle = Math.abs(Math.sin(a));
		let magnitude = bounds / 2;
		if (bounds / 2 * abs_sin_angle <= bounds / 2 * abs_cos_angle) {
			magnitude = bounds / 2 / abs_cos_angle;
		} else {
			magnitude = bounds / 2 / abs_sin_angle;
		}
		//this.model.pAngle -= this.customtrim.metadata.TDLData.startAngle;
		//var pPoint = this.pointFrom({ x: 500, y: 500 }, magnitude, this.model.pAngle, false);
		var pPoint = this.model.pPoint;
		if (!this.model.showLabels) pPoint = { x: -10000, y: -10000 };
		let pScale = this.model.baseScale / 5;
		this.model.pTranslate = `translate(${pPoint.x},${pPoint.y}) scale(${pScale}) rotate(${this.model.pAngle + 90})`;
	}

	render(rezoom: boolean = true, fromMouse: boolean = false) {

		this.d.debugWrite(["Rendering", this.model]);

		let ctrl = this;


		this.model.fontProps = {
			"fontFamily": "Consolas,Andale Mono,monospace",
			"fontStretch": "condensed",
			"fontSize": 10,
			"alignmentBaseline": "baseline"
		}

		this.model.depthUrl = '/assets/rendertrim/trim3d2.html?hd=' + this.d.model.hd3d + '&t=' + new Date().getTime();
		this.model.minpoint = { x: -280, y: -960 }
		this.model.maxpoint = { x: -280, y: -960 }
		this.model.mPath = "";
		this.model.lPath = "";
		this.model.rPath = "";
		this.model.baseScale = 1;
		//this.model.texts = [];
		this.model.arcs = [];
		this.model.lines = [];
		this.model.punchLines = [];
		this.model.rects = [];
		this.model.mPoints = [];
		this.model.lPoints = [];
		this.model.rPoints = [];

		this.model.arrowPath = "";
		this.model.arrowPoints = [];

		this.model.closedHems = [];
		this.model.openHooks = [];

		//let thickness3d = 6;
		//let lPoints3d = [];
		//let rPoints3d = [];
		//let insidePoints3d = [];
		//let hemRadius3d = thickness3d * 1.5;

		this.model.pPoint = { x: 0, y: 0 };
		this.model.debugPoints = [];
		this.model.thickness = 6;
		this.model.arcRadius = 15;

		this.model.hemRadius = this.model.thickness * 1.5;
		this.model.lenMult = 100; //75; //140;
		this.model.lColor = "#A9B0B7";
		this.model.rColor = "#A9B0B7";
		this.model.perpVecs = [];

		this.model.ultPoint = { x: 0, y: 0 };
		this.model.penultPoint = { x: 0, y: 0 };

		this.model.fontSize = "72";
		this.model.svgBB = null;
		this.model.debugOutput = "";
		this.model.strokes = 0;

		let hemAngle = 190;
		let hookAngle = 180;

		this.model.minMax = {
			min: { x: 1000, y: 1000 },
			max: { x: -1000, y: -1000 },
		}
		this.resetMinMax();
		this.model.initMinMax = {
			min: { x: 1000, y: 1000 },
			max: { x: -1000, y: -1000 },
		}
		let curPoint = { x: 0, y: 0 };




		//let combos = this.allCombos(['A','B','C']);
		//console.log(combos);

		if (!this.customtrim.metadata.TDLData.hasOwnProperty("isFullyCustom")) {
			//handle mastered child angles
			if (this.customtrim.metadata.TrimXYSet[0].LegMirrorBehavior == "X") {
				let specifiedAngle = 0;
				this.customtrim.metadata.TDLData.flanges.forEach((flange) => {
					if (flange.type == "angle" && flange.hasOwnProperty("name")) {
						specifiedAngle = parseFloat(flange.value.toString());
						return false;
					}
				});

				if (specifiedAngle != 0) {
					let childAngle = 180 - (specifiedAngle / 2);
					this.customtrim.metadata.TDLData.flanges.forEach((flange) => {
						if (flange.type == "angle" && flange.hasOwnProperty("flags")) {
							if (flange.flags == "matchPos") flange.value = childAngle;
							if (flange.flags == "matchNeg") flange.value = childAngle * -1;
						}
					});
				}
			}
		}

		//handle lockVert
		var lockVertDelta = 0;
		this.customtrim.metadata.foundLockVert = false;

		this.customtrim.metadata.TDLData.flanges.forEach((flange) => {
			if (this.customtrim.metadata.foundLockVert) return;
			if (flange.hasOwnProperty("flags")) {
				if (flange.flags == "lockVert") {
					this.customtrim.metadata.foundLockVert = true;
					return false;
				}
			}
			if (flange.type == "angle") lockVertDelta += parseFloat(flange.value.toString()); //the combo value from SAP is a string
		});
		if (this.customtrim.metadata.foundLockVert) {
			//console.log("LockVert active, setting startAngle to " + lockVertDelta);
			this.customtrim.metadata.TDLData.startAngle = lockVertDelta - 90;
			//console.log(this.customtrim.metadata.PictureId);
			if (this.customtrim.metadata.PictureId.indexOf("FLASHXY") > -1) this.customtrim.metadata.TDLData.startAngle += 180;

			//console.log(this.customtrim.metadata.TDLData.flanges[0]);
			//if (this.customtrim.metadata.TDLData.flanges[0].angle == 180) this.customtrim.metadata.TDLData.startAngle += 180;

			if (["posHem", "posHook", "negHem", "negHook"].includes(this.customtrim.metadata.TDLData.flanges[0].type)) {
				switch (this.customtrim.metadata.TDLData.flanges[0].type) {
					case "posHem": this.customtrim.metadata.TDLData.startAngle -= hemAngle - 180; break;
					case "posHook": this.customtrim.metadata.TDLData.startAngle -= hookAngle - 180; break;
					case "negHem": this.customtrim.metadata.TDLData.startAngle += hemAngle - 180; break;
					case "negHook": this.customtrim.metadata.TDLData.startAngle += hookAngle - 180; break;
				}
			}

		}




		let heading = 180;
		if (this.customtrim != undefined) {
			heading += this.customtrim.metadata.TDLData.startAngle;
			this.customtrim.metadata.totalGirth = 0;
		}

		let cornerDistance = this.model.thickness;

		//let line = ctrl.linearize(this.customtrim.metadata.TDLData.flanges);

		this.updateBaseScale(this.customtrim.metadata.TDLData.flanges);



		if (this.customtrim.metadata.lColor !== undefined) {
			this.model.lColor = this.customtrim.metadata.lColor;
			this.model.rColor = this.customtrim.metadata.rColor;
		}

		ctrl.model.mPoints.push({ x: 0, y: 0 });
		ctrl.model.lPoints.push(this.pointFrom(curPoint, this.model.thickness, heading - 90));
		ctrl.model.rPoints.push(this.pointFrom(curPoint, this.model.thickness, heading + 90));

		//these are the outside edges, mPoints will be shifted in later
		//lPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading - 90)});
		//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading + 90)});

		let mPathCmds = ["M " + ctrl.model.mPoints[0].x + " " + ctrl.model.mPoints[0].y];
		let lPathCmds = ["M " + ctrl.model.mPoints[0].x + " " + ctrl.model.mPoints[0].y];
		let rPathCmds = ["M " + ctrl.model.mPoints[0].x + " " + ctrl.model.mPoints[0].y];

		let mPathCmdsInside = [this.lineTo({ x: 0, y: 0 })];
		//insidePoints3d.push({type:"l",p:{ x: 0, y: 0 }});

		lPathCmds.push(this.lineTo(ctrl.model.lPoints[0]));
		rPathCmds.push(this.lineTo(ctrl.model.rPoints[0]));


		// console.log(this.customtrim.metadata.TDLData);

		let flangeCount = this.customtrim.metadata.TDLData.flanges.length;
		let midFlange = Math.round(flangeCount / 2) - 1;
		//console.log("midFlange: " + midFlange);



		let maxLen = 0;

		//console.log("START, heading: " + heading);

		let lPoint1, rPoint1, lPoint2, rPoint2;
		let isFirst = false;
		let isLast = false;
		this.customtrim.metadata.TDLData.flanges.forEach((node, i) => {

			//isFirst = (i == 0);
			//isLast = (i == this.customtrim.metadata.TDLData.flanges.length-1);
			//delete node.metadata.focused;
			//delete node.metadata.hovered;
			if (node.metadata === undefined) {
				node.metadata = {
					focused: false,
					hovered: false,
					text: null
				}
			}

			switch (node.type) {

				case "line": {
					this.model.penultPoint = curPoint;
					curPoint = this.pointFrom(curPoint, node.value * this.model.lenMult, heading);
					this.model.ultPoint = curPoint;
					ctrl.model.mPoints.push(curPoint);
					mPathCmds.push(this.lineTo(curPoint));
					mPathCmdsInside.unshift(this.lineTo(curPoint));
					//insidePoints3d.unshift({type:"l",p:curPoint});

					let labelText = this.d.formatFraction(node.value) + '"';
					if (node.hasOwnProperty("name")) labelText = node.name + ": " + labelText;


					let labelAngle = heading - 90;
					let origLabelAngle = labelAngle % 360;

					//if (this.model.perpVecs.length == 0)  this.model.perpVecs.push(labelAngle % 360);

					/*
					if (i != 0 && line.length > i+1){
						let thresh = 25;
						let lastAngle = parseInt(line[i-1].value.toString());
						let nextAngle = parseInt(line[i+1].value.toString());
						let diff = (nextAngle-lastAngle) /2;
						labelAngle = heading + (diff);
						if (labelAngle > heading) labelAngle -=180;
						if (heading-labelAngle < thresh) labelAngle -= thresh;
						if (heading-labelAngle > (180-thresh)) labelAngle += thresh;

						if (lastAngle + nextAngle == 0){
							labelAngle = heading + 90;
						}

					}
					*/


					let labelFlip = 0;
					if (this.customtrim.metadata.TDLData.hasOwnProperty("labelFlip")) {
						if (this.customtrim.metadata.TDLData.labelFlip) labelFlip += 180;
					}
					let labelDistance = 0;
					if (this.customtrim.metadata.TDLData.hasOwnProperty("labelDistance")) {
						labelDistance = this.customtrim.metadata.TDLData.labelDistance;
					}


					isLast = false;
					//Alan's idea: obtuse angles attract, acute angles repell
					let gravityDivisor = 5;
					if (i != 0) {
						let prevFlange = this.customtrim.metadata.TDLData.flanges[i - 1];
						let lastAngle = 0;
						if (this.d.isHem(prevFlange)) {
							lastAngle = (prevFlange.type.indexOf("pos") > -1) ? 90 : -90;
						} else {
							lastAngle = parseInt(this.customtrim.metadata.TDLData.flanges[i - 1].value.toString());
						}

						//lastAngle = lastAngle > 0 ? (180-lastAngle) : (-180+lastAngle);
						//if (labelFlip == 0) {
						labelAngle -= lastAngle / gravityDivisor;
						//} else {
						//  labelAngle += lastAngle / gravityDivisor;
						//}
					}
					if (this.customtrim.metadata.TDLData.flanges.length > i + 1) {
						let nextFlange = this.customtrim.metadata.TDLData.flanges[i + 1];
						let nextAngle = 0;
						if (this.d.isHem(nextFlange)) {
							isLast = true;
							nextAngle = (nextFlange.type.indexOf("pos") > -1) ? 90 : -90;
						} else {
							nextAngle = parseInt(this.customtrim.metadata.TDLData.flanges[i + 1].value.toString());
						}
						//nextAngle = nextAngle > 0 ? (180-nextAngle) : (-180+nextAngle);
						//if (labelFlip == 0) {
						labelAngle += nextAngle / gravityDivisor;
						//} else {
						//  labelAngle -= nextAngle / gravityDivisor;
						//}
					} else {
						isLast = true;
					}



					labelAngle += labelFlip;

					//normalize the labelAngle, or it ends up in the label midpoint
					while (labelAngle < 0) labelAngle += 360;
					labelAngle %= 360;


					let midPoint = this.pointFrom(curPoint, (node.value * this.model.lenMult) / 2, heading + 180);

					if (node.value >= maxLen) {
						maxLen = node.value;

						//the paint side indicator should never be near a hem
						let pDist = ((node.value * this.model.lenMult) / 3);
						if (isLast) pDist *= 2;
						let paintPoint = this.pointFrom(curPoint, pDist, heading + 180);

						if (this.customtrim.metadata.TDLData.paintedSide == "L") {
							this.model.pPoint = this.pointFrom(paintPoint, this.model.thickness, heading - 90);
						} else {
							this.model.pPoint = this.pointFrom(paintPoint, this.model.thickness, heading + 90);
						}
						this.model.perpVecs = [origLabelAngle];
					}

					let lineStartPoint = this.pointFrom(midPoint, this.model.thickness, heading - 90 + labelFlip);
					let calloutLength = (this.model.arcRadius + this.model.thickness * (2 + labelDistance));
					//if (labelDistance > 0) calloutLength += labelDistance;

					let linePoint = this.pointFrom(lineStartPoint, calloutLength, labelAngle);
					if (this.model.showLabels) this.model.lines.push({ pt1: lineStartPoint, pt2: linePoint });

					let labelBB = this.getTextBbox({ text: labelText, attr: this.model.fontProps });
					let lblX = (linePoint.x - labelBB.width / 2)
					let lblY = (linePoint.y - labelBB.height / 2);


					if (labelAngle > 180 && labelAngle < 360) lblY += labelBB.height / 2;
					if (labelAngle > 0 && labelAngle < 180) lblY -= labelBB.height / 2;
					if (labelAngle > 90 && labelAngle < 270) lblX += labelBB.width / 2;
					if ((labelAngle >= 0 && labelAngle < 90) || (labelAngle > 270 && labelAngle <= 360)) lblX -= labelBB.width / 2;


					/*
					let labelDist = this.model.arcRadius + this.model.thickness + (this.model.thickness/2); // + this.getDistanceFromBoxCenter(labelBB, labelAngle);

				  
					var linePoint = this.pointFrom(midPoint, this.model.arcRadius + this.model.thickness, labelAngle);
					this.model.lines.push({pt1:midPoint, pt2:linePoint});

					let labelPt = this.pointFrom(midPoint, labelDist, labelAngle);
					*/
					if (this.model.showLabels) {
						let label = { text: labelText, x: lblX, y: lblY + labelBB.height / 2, w: labelBB.width, h: labelBB.height, lblid: "label_" + node.uid, rectid: "rect_" + node.uid };
						this.updateMinMax({ x: lblX, y: lblY });
						//this.model.texts.push(label);
						this.updateMinMax({ x: lblX + labelBB.width, y: lblY + labelBB.height });
						node.metadata.text = label;

					}

					//this.model.rects.push({x:lblX, y:lblY, h:labelBB.height, w: labelBB.width});


					if (i == midFlange) {
						this.model.pAngle = heading - 90;
					}

					break;
				}
				case "angle": {

					//let cd3d = thickness3d;
					cornerDistance = this.model.thickness;
					let A = Math.abs(node.value) / 2;
					if (A != 90) {
						try {
							var angles = this.solveASA(this.model.thickness, null, null, A, null, 90);
							cornerDistance = angles.c;

							//var angles3d = this.solveASA(thickness3d, null, null, A, null, 90);
							//cd3d = angles3d.c;
						} catch (e) {
							console.log(e);
						}
					}


					let insideCornerAngle = heading - (node.value / 2) + 180;


					let arcOrigin = null;
					//add points at the beginning of the curve
					if (node.value > 0) {
						//add first point to left side
						lPoint1 = this.pointFrom(curPoint, this.model.thickness, heading - 90);
						//lPoints3d.push({type:"l",p:this.pointFrom(curPoint,thickness3d, heading - 90)});

						//add inside point to the right side:
						rPoint1 = this.pointFrom(curPoint, cornerDistance, insideCornerAngle);
						//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, cd3d, insideCornerAngle)});
						arcOrigin = this.pointFrom(curPoint, cornerDistance - 1.75, insideCornerAngle);;
					} else {
						//add first point to the right side
						rPoint1 = this.pointFrom(curPoint, this.model.thickness, heading + 90);
						//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading + 90)});
						//add inside point to the left side:
						lPoint1 = this.pointFrom(curPoint, cornerDistance, insideCornerAngle);
						//lPoints3d.push({type:"l",p:this.pointFrom(curPoint,cd3d, insideCornerAngle)});
						arcOrigin = this.pointFrom(curPoint, cornerDistance - 1.75, insideCornerAngle);
					}
					ctrl.model.lPoints.push(lPoint1);
					ctrl.model.rPoints.push(rPoint1);
					rPathCmds.push(this.lineTo(rPoint1));
					lPathCmds.push(this.lineTo(lPoint1));

					let rtLinestart = null;
					//add angle indicators
					if (node.value == 90 || node.value == -90) {
						let newArc = [];
						let arcPt1 = this.pointFrom(arcOrigin, this.model.arcRadius, heading + 180);
						let arcPt3 = this.pointFrom(arcOrigin, this.model.arcRadius, heading + 180 - node.value);
						let arcPt2 = this.pointFrom(arcPt3, this.model.arcRadius, heading - 180);
						rtLinestart = arcPt2;
						newArc.push(this.moveTo(arcOrigin));
						newArc.push(this.lineTo(arcPt1));
						newArc.push(this.lineTo(arcPt2));
						newArc.push(this.lineTo(arcPt3));
						newArc.push(this.lineTo(arcOrigin));
						if (this.model.showLabels) this.model.arcs.push(newArc.join(" "));
					} else if (node.value == 180 || node.value == -180) {
						//don't need them on hems
					} else {
						let newArc = [];
						let arcPt1 = this.pointFrom(arcOrigin, this.model.arcRadius, heading + 180);
						let arcPt2 = this.pointFrom(arcOrigin, this.model.arcRadius, heading + 180 - node.value);
						newArc.push(this.moveTo(arcOrigin));
						newArc.push(this.lineTo(arcPt1));
						newArc.push(this.arcTo(arcPt2, this.model.arcRadius, -node.value));
						newArc.push(this.lineTo(arcOrigin));
						if (this.model.showLabels) this.model.arcs.push(newArc.join(" "));
					}

					// somewhere around here we need to identify line segments representing the press brake punch geometry.
					// for now, let's just add an arbitrary line coming out of the inner angle

					if (!ctrl.d.isHem(node)) {
						//add angle label
						let labelText = node.value + '\xB0'; //degree symbol
						if (node.hasOwnProperty("name")) labelText = node.name + ": " + labelText;
						//if (node.hasOwnProperty("name")) labelText = "∠: " + labelText;

						let labelBB = this.getTextBbox({ text: labelText, attr: this.model.fontProps });
						let labelAngle = (heading - 180 - (node.value / 2));
						while (labelAngle < 0) labelAngle += 360;
						labelAngle %= 360;


						let lblX = 0;
						let lblY = 0;

						let lineStartPoint = (node.value == 90 || node.value == -90) ? rtLinestart : this.pointFrom(arcOrigin, this.model.arcRadius, labelAngle);

						let punchLineStartPoint = this.pointFrom(curPoint, 0.01, labelAngle)
						const punchDepth = 100 * 1.375; //1 inch = 100 drawing units
						const punchAngle = 28;
						const punchEdgeOffset = punchAngle / 2;
						//let punchCenterExt = this.pointFrom(arcOrigin, punchDepth, labelAngle);
						let punchLeft = this.pointFrom(punchLineStartPoint, punchDepth, (labelAngle - punchEdgeOffset));
						let punchRight = this.pointFrom(punchLineStartPoint, punchDepth, (labelAngle + punchEdgeOffset));
						this.model.punchLines.push({ pt1: punchLineStartPoint, pt2: punchLeft });
						this.model.punchLines.push({ pt1: punchLineStartPoint, pt2: punchRight });

						//label text center as close to line as possible
						let boxDist = this.getDistanceFromBoxCenter(labelBB, labelAngle);
						let baseDist = this.model.arcRadius + this.model.thickness;

						//if (node.value == 90 || node.value == -90) { labelDist *=1.5 }
						let labelPt = this.pointFrom(lineStartPoint, baseDist + boxDist, labelAngle);
						let linePt = this.pointFrom(lineStartPoint, baseDist, labelAngle);
						lblX = labelPt.x;//-= labelBB.width / 2;
						lblY = labelPt.y;//-= labelBB.height / 2;

						/*
												//label line snap to label corner
												let labelDist = (this.model.arcRadius + this.model.thickness / 2) * 2; // + this.getDistanceFromBoxCenter(labelBB, labelAngle);
												//if (node.value == 90 || node.value == -90) { labelDist *= 1.414 }
												let labelPt = this.pointFrom(arcOrigin, labelDist, labelAngle);
												lblX = (labelPt.x - labelBB.width / 2);
												lblY = (labelPt.y - labelBB.height / 2);
												if (labelAngle > 180 && labelAngle < 360) {
													// ←↙↓↘→
													lblY += labelBB.height / 2; 
												} //ok
												if (labelAngle > 0 && labelAngle < 180) {
													//←↖↑↗→
													lblY -= labelBB.height / 2; 
												}
												if (labelAngle > 90 && labelAngle < 270) {
													lblX += labelBB.width / 2; 
												}
												if ((labelAngle >= 0 && labelAngle < 90) || (labelAngle > 270 && labelAngle <= 360)) {
													lblX -= labelBB.width / 2; 
												}
						*/


						if (this.model.showLabels) {
							this.updateMinMax({ x: lblX, y: lblY });
							//this.model.texts.push({ text: labelText, x: lblX, y: lblY + labelBB.height / 2, w:labelBB.width, h:labelBB.height, id: "label_"+node.uid });
							//this.model.texts.push({ text: labelText, x: lblX, y: lblY + labelBB.height / 2, w: labelBB.width, h: labelBB.height, lblid: "label_" + node.uid, rectid: "rect_" + node.uid });
							let label = { text: labelText, x: lblX - labelBB.width / 2, y: lblY, w: labelBB.width, h: labelBB.height };
							//this.model.texts.push(label);
							this.updateMinMax({ x: lblX + labelBB.width, y: lblY + labelBB.height });

							if (Math.abs(node.value) != 90) {
								node.metadata.text = label;
								this.model.lines.push({ pt1: lineStartPoint, pt2: linePt });
							} else {
								node.metadata.text = null;
							}

						}
						//this.model.lines.push({pt1:curPoint, pt2:labelPt});
						//this.model.rects.push({x:lblX, y:lblY, h:labelBB.height, w: labelBB.width});

						//if (this.model.showLabels) this.model.lines.push({ pt1: lineStartPoint, pt2: linePt });


						if (i == midFlange) {
							this.model.pAngle = labelAngle;
							if (node.value > 0) this.model.pAngle += 180;
						}

					}

					let prevHeading = heading % 360;
					//advance the heading
					heading += 180 - node.value;
					heading %= 360;

					//console.log("ANGLE, heading: " + heading);

					//var radius = 20;
					//add points at the end of the curve, if any
					if (node.value > 0) {
						//add second point to left side
						lPoint2 = this.pointFrom(curPoint, this.model.thickness, heading - 90);
						ctrl.model.lPoints.push(lPoint2);
						/*
						lPoints3d.push({
							type:"a",
							x: curPoint.x,
							y: curPoint.y,
							radius:thickness3d,
							startAngle: this.degToRad(this.svgHeadingToWebGL(prevHeading)),
							endAngle: this.degToRad(this.svgHeadingToWebGL(heading)),
							clockwise: (node.value < 0) ? false : true
							//p:this.pointFrom(curPoint, thickness3d, heading - 90)
						});
						*/
						lPathCmds.push(this.arcTo(lPoint2, this.model.thickness, node.value));
					} else {
						//add second point to right side
						rPoint2 = this.pointFrom(curPoint, this.model.thickness, heading + 90);

						ctrl.model.rPoints.push(rPoint2);
						/*
						rPoints3d.push({
							type:"a",
							x: curPoint.x,
							y: curPoint.y,
							radius:thickness3d,
							startAngle: this.degToRad(this.svgHeadingToWebGL(prevHeading+180)),
							endAngle: this.degToRad(this.svgHeadingToWebGL(heading+180)),
							clockwise: (node.value < 0) ? false : true
							//p:this.pointFrom(curPoint, thickness3d, heading + 90)
						});
						*/
						rPathCmds.push(this.arcTo(rPoint2, this.model.thickness, node.value));
					}





					break;
				}
				case "posHem": case "posHook": case "negHem": case "negHook": {

					//add start hem length
					if (i == 0) {
						curPoint = this.pointFrom(curPoint, node.value * this.model.lenMult, heading);
						ctrl.model.mPoints.push(curPoint);
						mPathCmds.push(this.lineTo(curPoint));
						mPathCmdsInside.unshift(this.lineTo(curPoint));
						//insidePoints3d.unshift({type:"l",p:curPoint});
					}


					//var val = node.type.indexOf("pos") > -1 ? 180 : -180;
					var val = node.type.indexOf("Hem") > -1 ? hemAngle : hookAngle;
					if (node.type.indexOf("neg") > -1) val *= -1;




					lPoint1 = this.pointFrom(curPoint, this.model.thickness, heading - 90);
					ctrl.model.lPoints.push(lPoint1);
					//lPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading - 90)});
					lPathCmds.push(this.lineTo(lPoint1));

					rPoint1 = this.pointFrom(curPoint, this.model.thickness, heading + 90);
					//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading + 90)});
					ctrl.model.rPoints.push(rPoint1);
					rPathCmds.push(this.lineTo(rPoint1));


					mPathCmdsInside.unshift(this.lineTo(curPoint));
					//insidePoints3d.unshift({type:"l",p:curPoint});

					mPathCmdsInside.unshift(this.arcTo(curPoint, this.model.thickness, -val));

					var hemOffset = val > 0 ? 90 : -90;

					//var hemCenter3d = this.pointFrom(curPoint, hemRadius3d, heading + hemOffset);
					let reOrienter = (val < 0) ? 180 : 0;

					/*
					insidePoints3d.unshift({
						type:"a",
						x: hemCenter3d.x,
						y: hemCenter3d.y,
						radius:hemRadius3d,
						startAngle: this.degToRad(this.svgHeadingToWebGL(heading-val+reOrienter)),
						endAngle: this.degToRad(this.svgHeadingToWebGL(heading+reOrienter)),
						clockwise: (val < 0) ? true : false
						//p:this.pointFrom(curPoint, thickness3d, heading - 90)
					});
										*/
					//let hemLabelPoint = this.pointFrom(curPoint, this.model.hemRadius * 2, heading + (hemOffset/2));
					let hemLabelCenterPoint = this.pointFrom(curPoint, this.model.hemRadius, heading + hemOffset);
					curPoint = this.pointFrom(curPoint, this.model.hemRadius * 2, heading + hemOffset);


					ctrl.model.mPoints.push(curPoint);

					mPathCmds.push(this.arcTo(curPoint, this.model.thickness, val));

					mPathCmdsInside.unshift(this.lineTo(curPoint));
					//insidePoints3d.unshift({type:"l",p:curPoint});

					let prevHeading = heading;
					let hemLabelAngle = heading + (val / 2) + 90;
					hemLabelAngle += (val < 0) ? 0 : 180;
					heading += val;

					//console.log(node.type + ", heading: " + heading);

					lPoint2 = this.pointFrom(curPoint, this.model.thickness, heading - 90);
					ctrl.model.lPoints.push(lPoint2);
					//lPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading - 90)});
					lPathCmds.push(this.arcTo(lPoint2, this.model.hemRadius - this.model.thickness, val));


					//this.model.closedHems = [];
					//this.model.openHooks = [];

					//XY trim diagrams are not sent to the floor, so the hem labels are not needed
					if (this.customtrim.metadata.TDLData.hasOwnProperty("isFullyCustom")) {
						let hemLabelScale = this.model.baseScale / 30;
						let hemLabelPoint = this.pointFrom(hemLabelCenterPoint, this.model.hemRadius + this.model.thickness, hemLabelAngle);
						if (node.type.indexOf("Hem") > -1) {
							this.model.closedHems.push({ x: hemLabelPoint.x, y: hemLabelPoint.y, angle: hemLabelAngle, scale: hemLabelScale });
							this.updateMinMax(this.pointFrom(hemLabelPoint, 560.24 * hemLabelScale, hemLabelAngle));
						} else {
							this.model.openHooks.push({ x: hemLabelPoint.x, y: hemLabelPoint.y, angle: hemLabelAngle, scale: hemLabelScale });
							this.updateMinMax(this.pointFrom(hemLabelPoint, 448.69 * hemLabelScale, hemLabelAngle));
						}
					}
					/*
					lPoints3d.push({
							type:"a",
							x: hemCenter3d.x,
							y: hemCenter3d.y,
							radius: hemRadius3d - thickness3d,
							startAngle: this.degToRad(this.svgHeadingToWebGL(prevHeading)),
							endAngle: this.degToRad(this.svgHeadingToWebGL(heading)),
							clockwise: (val < 0) ? false : true
							//p:this.pointFrom(curPoint, thickness3d, heading - 90)
						});
					*/

					rPoint2 = this.pointFrom(curPoint, this.model.thickness, heading + 90);
					ctrl.model.rPoints.push(rPoint2);
					//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading + 90)});
					rPathCmds.push(this.arcTo(rPoint2, this.model.hemRadius - this.model.thickness, val));

					/*
					rPoints3d.push({
							type:"a",
							x: hemCenter3d.x,
							y: hemCenter3d.y,
							radius: hemRadius3d - thickness3d,
							startAngle: this.degToRad(this.svgHeadingToWebGL(prevHeading+reOrienter)),
							endAngle: this.degToRad(this.svgHeadingToWebGL(heading+reOrienter)),
							clockwise: (val < 0) ? false : true
							//p:this.pointFrom(curPoint, thickness3d, heading - 90)
						});
				 */
					if (i == this.customtrim.metadata.TDLData.flanges.length - 1) {
						curPoint = this.pointFrom(curPoint, node.value * this.model.lenMult, heading);
						ctrl.model.mPoints.push(curPoint);
						mPathCmds.push(this.lineTo(curPoint));
						mPathCmdsInside.unshift(this.lineTo(curPoint));
						//insidePoints3d.unshift({type:"l",p:curPoint});
					}

					break;
				}
			}



		});
		//console.log("STOP, heading: " + heading);

		lPoint1 = this.pointFrom(curPoint, this.model.thickness, heading - 90);
		ctrl.model.lPoints.push(lPoint1);
		//lPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading - 90)});
		//lPoints3d.push({type:"l",p:curPoint});
		lPathCmds.push(this.lineTo(lPoint1));
		//lPathCmds.push(this.lineTo({x:0,y:0}));


		rPoint1 = this.pointFrom(curPoint, this.model.thickness, heading + 90);
		ctrl.model.rPoints.push(rPoint1);
		//rPoints3d.push({type:"l",p:this.pointFrom(curPoint, thickness3d, heading + 90)});
		//rPoints3d.push({type:"l",p:curPoint});
		rPathCmds.push(this.lineTo(rPoint1));
		//rPathCmds.push(this.lineTo(curPoint));




		this.model.mPoints.forEach((pt) => { this.updateMinMax(pt) });
		this.model.lPoints.forEach((pt) => { this.updateMinMax(pt) });
		this.model.rPoints.forEach((pt) => { this.updateMinMax(pt) });

		ctrl.model.mPath = mPathCmds.join(" ");
		ctrl.model.lPath = lPathCmds.join(" ") + " " + mPathCmdsInside.join(" ");
		ctrl.model.rPath = rPathCmds.join(" ") + " " + mPathCmdsInside.join(" ");;

		ctrl.model.lPathCmds = lPathCmds.join(" ") + " " + curPoint.x + "," + curPoint.y;
		ctrl.model.rPathCmds = rPathCmds.join(" ") + " " + curPoint.x + "," + curPoint.y;
		/*
		console.log(JSON.stringify(this.minMax));
		let midX = 500-(Math.abs(this.minMax.max.x/2));
		let midY = 500-((this.minMax.max.y + this.minMax.min.y) /2);

	 // this.translate = "translate("+(Math.abs(this.minMax.min.x))+","+(Math.abs(this.minMax.min.y))+")";

		console.log(this.translate);
		this.viewBox = "0 0 "+(Math.abs(this.minMax.min.x)+Math.abs(this.minMax.max.x))+" "+(Math.abs(this.minMax.min.y)+Math.abs(this.minMax.max.y));
		console.log(this.viewBox);
		*/

		/*
				this.model.minMax.min.y = this.less(pt.y, this.model.minMax.min.y);
		this.model.minMax.max.y = this.more(pt.y, this.model.minMax.max.y);
		this.model.minMax.min.x = this.less(pt.x, this.model.minMax.min.x);
		this.model.minMax.max.x = this.more(pt.x, this.model.minMax.max.x);*/

		if (this.model.minMax.min.y < this.model.initMinMax.min.y ||
			this.model.minMax.min.x < this.model.initMinMax.min.x ||
			this.model.minMax.max.y > this.model.initMinMax.max.y ||
			this.model.minMax.max.x > this.model.initMinMax.max.x) rezoom = true;

		if (fromMouse) rezoom = false;

		this.resetScaleZoom(rezoom);


		var arrowBasePt = this.pointFrom(curPoint, this.model.thickness * 2, heading);
		var aPointRt = this.pointFrom(arrowBasePt, this.model.thickness, heading + 90);
		var aPointTip = this.pointFrom(arrowBasePt, this.model.thickness * 2, heading);
		var aPointLt = this.pointFrom(arrowBasePt, this.model.thickness, heading - 90);

		ctrl.model.arrowPoints = ["M " + aPointRt.x + " " + aPointRt.y];
		ctrl.model.arrowPoints.push(this.lineTo(aPointTip));
		ctrl.model.arrowPoints.push(this.lineTo(aPointLt));
		ctrl.model.arrowPoints.push(this.lineTo(aPointRt));
		ctrl.model.arrowPath = ctrl.model.arrowPoints.join(" ");
		ctrl.model.selfIntersections = [];
		ctrl.model.punchIntersections = [];


		// the threejs shape tool closes the shape itself, so it is not required
		// to pass in the home coordinate again:
		/*
		lPoints3d = [
			{x:0,y:0},
			{x:50,y:0},
			{x:50,y:100},
			{x:0,y:100}
		]
		*/



		/*
		The value of the viewBox attribute is a list of four numbers:
			min-x, min-y, width and height, 
		separated by whitespace and/or a comma, which specify a rectangle in 
		user space which should be mapped to the bounds of the viewport established 
		by the given element, taking into account attribute preserveAspectRatio. Negative 
		values for width or height are not permitted and a value of zero disables rendering
		of the element.
		*/


		/*
		this.model.vb = {
			min: { x: 0, y: 0 },
			w: 1000,
			h: 1000
		}
		this.model.vb.min.x = 500 - this.model.rectInfo.w / 2 - margin;
		this.model.vb.min.y = 500 - this.model.rectInfo.h / 2 - margin;

		this.model.vb.w = this.model.rectInfo.w + margin * 2;
		this.model.vb.h = this.model.rectInfo.h + margin * 2;
	 

		//this.updateViewbox();

		//calculate relative coordinate space based on viewport 
		let xOrigin = this.model.rectInfo.w / 2 + this.model.rectInfo.x;
		let yOrigin = this.model.rectInfo.h / 2 + this.model.rectInfo.y;
		//let maxDim = this.largestOf(this.model.vb.h, this.model.vb.w);
		let xMin = xOrigin - 500;
		let yMin = yOrigin - 500;
		this.model.minpoint = { x: xMin, y: yMin };
		this.model.maxpoint = { x: xMin + 500, y: yMin + 500 };

		//this.model.translate.x = -xOrigin;
	 // this.model.translate.y = -yOrigin;
 */
		//this.resetScaleZoom();

		let trim3d = this.d.deref(this.customtrim.metadata.TDLData);
		//todo, include painted side, and hem length
		//trim3d.lPoints3d = lPoints3d;
		//trim3d.rPoints3d = rPoints3d;

		trim3d.lColor = this.model.lColor;
		trim3d.rColor = this.model.rColor;

		if (this.customtrim.metadata.hasOwnProperty("color")) {
			if (this.customtrim.metadata.color.hasOwnProperty("ColorId")) {
				trim3d.ColorId = this.customtrim.metadata.color.ColorId;
			}
		}
		//trim3d.ColorId = "NRSP";

		localStorage.setItem('trim3d', JSON.stringify(trim3d));


		ctrl.setPTransform();
		this.checkForIntersections()
		return true;
	}

	checkForIntersections() {
		//reset lists of intersections
		this.model.punchIntersections = [];
		this.model.selfIntersections = [];

		// console.log('simulateManufacture, point count: ',this.model.mPoints.length);
		if (this.model.mPoints.length < 3) {
			// console.log('Single segment cannot intersect');
			return false;
		}
		if (this.model.mPoints.length < 4) {
			// console.log('Two segments cannot intersect');
			return false;
		}

		// make list of segments:
		const segments = [];

		for (var i = 0; i < this.model.mPoints.length - 1; i++) {
			segments.push({
				pt1: this.model.mPoints[i],
				pt2: this.model.mPoints[i + 1],
				name: String.fromCharCode(65 + i)
			});
		}
		// console.log(JSON.stringify(segments, null, 4));

		for (var o = 0; o < segments.length; o++) {
			const l1 = segments[o];

			//check all of the punch lines
			for (var p = 0; p < this.model.punchLines.length; p++) {
				const punchLine = this.model.punchLines[p];

				const intersection = this.segmentsIntersect(l1, punchLine);

				if (intersection.collides) {
					// console.log("Punch Collision found @ " + intersection.point.x +", " + intersection.point.y);
					this.model.punchIntersections.push(intersection);
				}

			}

			// check for self intersections
			for (var i = o + 2; i < segments.length; i++) {
				const l2 = segments[i];
				// console.log("checking " + l2.name + " vs " + l1.name);
				const intersection = this.segmentsIntersect(l1, l2);
				if (intersection.collides) {
					// console.log("Collision found at " + l1.name + " and " + l2.name + " @ " + intersection.point.x +", " + intersection.point.y);
					this.model.selfIntersections.push(intersection);
				}
			}
		}


	}

	segmentsIntersect(l1: any, l2: any) {
		/*
		return this.checkIntersection(
			l1.start.x, l1.start.y, l1.end.x, l1.end.y, 
			l2.start.x, l2.start.y, l2.end.x, l2.end.y
		);
		*/
		return this.checkIntersection(
			l1.pt1, l1.pt2,
			l2.pt1, l2.pt2
		);

	}
	checkIntersection(from1: any, to1: any, from2: any, to2: any) {
		const dX: number = to1.x - from1.x;
		const dY: number = to1.y - from1.y;

		const determinant: number = dX * (to2.y - from2.y) - (to2.x - from2.x) * dY;
		if (determinant === 0) return { collides: false, point: { x: -1, y: -1 } }; // parallel lines

		const lambda: number = ((to2.y - from2.y) * (to2.x - from1.x) + (from2.x - to2.x) * (to2.y - from1.y)) / determinant;
		const gamma: number = ((from1.y - to1.y) * (to2.x - from1.x) + dX * (to2.y - from1.y)) / determinant;

		// check if there is an intersection
		if (!(0 <= lambda && lambda <= 1) || !(0 <= gamma && gamma <= 1)) return { collides: false, point: { x: -1, y: -1 } };

		return {
			collides: true,
			point: {
				x: from1.x + lambda * dX,
				y: from1.y + lambda * dY
			}
		};
	}

	segmentsIntersect1(l1: any, l2: any) {
		return this.checkIntersection1(
			l1.start.x, l1.start.y, l1.end.x, l1.end.y,
			l2.start.x, l2.start.y, l2.end.x, l2.end.y
		);

	}

	//adapted for JS from https://cboard.cprogramming.com/c-programming/154196-check-if-two-line-segments-intersect.html
	checkIntersection1(p0_x, p0_y, p1_x, p1_y, p2_x, p2_y, p3_x, p3_y): any {

		var s02_x, s02_y, s10_x, s10_y, s32_x, s32_y, s_numer, t_numer, denom;
		s10_x = p1_x - p0_x;
		s10_y = p1_y - p0_y;
		s02_x = p0_x - p2_x;
		s02_y = p0_y - p2_y;

		s_numer = s10_x * s02_y - s10_y * s02_x;
		if (s_numer < 0) return { collides: false, x: -1, y: -1 };

		s32_x = p3_x - p2_x;
		s32_y = p3_y - p2_y;
		t_numer = s32_x * s02_y - s32_y * s02_x;
		if (t_numer < 0) return { collides: false, x: -1, y: -1 };

		denom = s10_x * s32_y - s32_x * s10_y;
		if (s_numer > denom || t_numer > denom) return { collides: false, x: -1, y: -1 };

		// Collision detected!
		var t = t_numer / denom;
		return {
			collides: true,
			point: {
				x: (p0_x + (t * s10_x)),
				y: (p0_y + (t * s10_y))
			}
		}

	}
	async addLength(e, fromMouse: boolean = false) {
		if (!this.customtrim.metadata.TDLData.hasOwnProperty("isFullyCustom")) {
			return;
		} else {
			if (!this.customtrim.metadata.TDLData.isFullyCustom) return;
		}
		if (this.d.trimfcteditRef.hasOwnProperty('clearPricing')) {
			this.d.trimfcteditRef.clearPricing();
		}


		let points = this.getEventPoints(e);
		//this.model.debugPoints.push(points.g);


		//let's also put a dot on the end of the last segment
		//let lastLine = 
		//if (this.d.isHem(this.customtrim.flanges.length-1

		//this.model.debugPoints.push(this.model.ultPoint);
		//this.model.debugPoints.push(this.model.penultPoint);


		//what is the angle between the two points?

		let minAngle = 45;
		let minLeg = 0.5;
		if (this.d.model.hasOwnProperty("TrimFullyCustomSet")) {
			//let mins = this.d.model.TrimFullyCustomSet[0].HasPOPCapability;
			minAngle = Number(this.customtrim.metadata.mins.AngleMin);
			minLeg = Number(this.customtrim.metadata.mins.LegLengthMin);
		}


		let dist = this.getDistance(this.model.ultPoint, points.g) / this.model.lenMult;
		if (dist < minLeg) dist = minLeg;
		dist = Math.round(dist * 8) / 8; //snap to nearest 8th

		let angle = Number((this.find_angle2(this.model.penultPoint, this.model.ultPoint, points.g)) | 0);
		//console.log(angle);
		//determine angle sign, feels like there ought to be a better way
		/*
		let posPoint = this.pointFrom(this.model.penultPoint,dist,angle,false);
		let negPoint = this.pointFrom(this.model.penultPoint,dist,-angle,false);
		let posDist = this.getDistance(posPoint, points.g) / this.model.lenMult;
		let negDist = this.getDistance(negPoint, points.g) / this.model.lenMult;
	  

		console.log("posDist: " + posDist + ", negDist: " + negDist);
		if (posDist < negDist) angle *= -1;

	 */
		if (angle > 0) {
			if (angle < minAngle) angle = minAngle;
		} else {
			if (angle > -minAngle) angle = -minAngle;
		}
		angle = Math.round(angle / 15) * 15; //snap to common angles

		var angleCount = this.customtrim.metadata.TDLData.flanges.reduce((n, f) => {
			return n + (this.d.isHem(f) ? 1 : 0);
		}, 0);

		let lastIndex = this.customtrim.metadata.TDLData.flanges.length - 1;
		if (this.d.isHem(this.customtrim.metadata.TDLData.flanges[lastIndex])) {
			this.customtrim.metadata.TDLData.flanges.splice(lastIndex, 0, { "type": "line", "value": dist });
			this.customtrim.metadata.TDLData.flanges.splice(lastIndex, 0, { "type": "angle", "value": angle });
		} else {
			this.customtrim.metadata.TDLData.flanges.push({ "type": "angle", "value": angle });
			this.customtrim.metadata.TDLData.flanges.push({ "type": "line", "value": dist });
		}

		if (angleCount == 0) {
			if (angle < 0) {
				this.customtrim.metadata.TDLData.labelFlip = true;
			} else {
				this.customtrim.metadata.TDLData.labelFlip = false;
			}
		}


		await this.d.delay(100);

		if (fromMouse) {
			this.render(false, true);
		} else {
			this.render(true, false);
		}


		//console.log(angle);
	}

	contextMenu(e) {
		//console.log("Context Menu!");
		return this.d.noAction(e);
	}
	/*
		public panOrigin = {x:0,y:0}
	public isPanning = false;
	 */

	startMove(e: MouseEvent) {
		if (e.button == 2) {       //right click
			let p = this.getEventPoints(e);

			this.panOrigin = { x: p.svg.x, y: p.svg.y };

			this.isPanning = true;
			return this.d.noAction(e);
		} else {
			this.endMove(e);
		}
	}

	endMove(e, leftCanvas: boolean = false) {
		this.isPanning = false;
		if (leftCanvas) this.render(true, false);
		return this.d.noAction(e);
	}

	mouseMove(e) {
		if (!this.isPanning) return;

		let p = this.getEventPoints(e);
		let panDest = { x: p.svg.x, y: p.svg.y };
		this.svgTranslate(panDest.x - this.panOrigin.x, panDest.y - this.panOrigin.y);
		this.panOrigin = panDest;
	}
	svgTranslate(deltaX, deltaY) {
		this.model.translate.x += deltaX;
		this.model.translate.y += deltaY;
	}


	getSign(a, b, c) {
		let v1x = b.x - c.x;
		let v1y = b.y - c.y;
		let v2x = a.x - c.x;
		let v2y = a.y - c.y;
		let rad = Math.atan2(v1x, v1y) - Math.atan2(v2x, v2y);
		rad += Math.PI;
		rad %= Math.PI * 2;
		return (rad > Math.PI) ? -1 : 1;
	}


	find_angle(A, B, C) {
		let AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
		let BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
		let AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
		let deg = this.radToDeg(Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB)))
		return deg; //Math.abs(deg); //* this.getSign(A, B, C);
	}


	find_angle2(A, B, C) {

		let angle360 = this.CalculateAngle0To360(B.x, B.y, C.x, C.y, A.x, A.y);

		if (angle360 > 180) angle360 = -(360 - angle360);

		//console.log(angle360);
		return angle360;

	}

	CalculateAngleFromHorizontal(startX, startY, endX, endY) {
		var atan = Math.atan2(endY - startY, endX - startX); // Angle in radians
		var angleDegrees = atan * (180 / Math.PI);  // Angle in degrees (can be +/-)
		if (angleDegrees < 0.0) {
			angleDegrees = 360.0 + angleDegrees;
		}
		return angleDegrees;
	}

	// Angle from point2 to point 3 counter clockwise
	CalculateAngle0To360(centerX, centerY, x2, y2, x3, y3) {
		var angle2 = this.CalculateAngleFromHorizontal(centerX, centerY, x2, y2);
		var angle3 = this.CalculateAngleFromHorizontal(centerX, centerY, x3, y3);
		return (360.0 + angle3 - angle2) % 360;
	}

	// Smaller angle from point2 to point 3
	CalculateAngle0To180(centerX, centerY, x2, y2, x3, y3) {
		var angle = this.CalculateAngle0To360(centerX, centerY, x2, y2, x3, y3);
		if (angle > 180.0) {
			angle = 360 - angle;
		}
		return angle;
	}


	getDistance(a, b) {
		let A = a.x - b.x;
		let B = a.y - b.y;
		let dist = Math.sqrt(A * A + B * B);
		//console.log("Distance from: " + JSON.stringify(a) + " to " + JSON.stringify(b) + " is " + dist);
		return dist;
	}

	getDistance2(p1, p2) {
		// Approximation by using octagons approach
		var x = p2.x - p1.x;
		var y = p2.y - p1.y;
		return 1.426776695 * Math.min(0.7071067812 * (Math.abs(x) + Math.abs(y)), Math.max(Math.abs(x), Math.abs(y)));
	}

	getEventPoints(e) {

		if (this.model.svgBB == null) {
			let target = e.target || e.srcElement || e.currentTarget;
			let svgEl = target;
			while (svgEl.tagName != "svg") svgEl = svgEl.parentNode;
			this.model.svgBB = svgEl.getBoundingClientRect();
		}

		let svgCoords = {
			top: this.model.svgBB.top + pageYOffset,
			left: this.model.svgBB.left + pageXOffset
		}
		let dx = (e.clientX - svgCoords.left);
		let dy = (e.clientY - svgCoords.top);
		//dx,dy now contain the actual screen point on the svg that was clicked, from 0,0 to 400,400


		let sx = dx / this.model.svgBB.width * 1000;
		let sy = dy / this.model.svgBB.width * 1000;
		//sx,sy now contain the point within the SVG viewbox that was clicked

		let gx = sx / this.model.scale - this.model.translate.x / this.model.scale;
		let gy = sy / this.model.scale - this.model.translate.y / this.model.scale;
		//gx,gy now contain the point on the translate/scale group that was clicked, where a new SVG element could be placed


		let resultPoint = {
			dom: { x: dx, y: dy },
			svg: { x: sx, y: sy },
			g: { x: gx, y: gy }
		}
		//console.log(JSON.stringify(resultPoint,null,2));
		return resultPoint;
	}



	svgZoomIn() {
		let vx = 500 / this.model.scale - this.model.translate.x / this.model.scale;
		let vy = 500 / this.model.scale - this.model.translate.y / this.model.scale;
		this.svgZoom(1.1, { svg: { x: 500, y: 500 }, g: { x: vx, y: vy } });
	}
	svgZoomOut() {
		let vx = 500 / this.model.scale - this.model.translate.x / this.model.scale;
		let vy = 500 / this.model.scale - this.model.translate.y / this.model.scale;
		this.svgZoom((5 / 6), { svg: { x: 500, y: 500 }, g: { x: vx, y: vy } });
	}
	wheelZoom(e) {
		let points = this.getEventPoints(e);

		let zoomAmt = e.deltaY < 0 ? 1.2 : (5 / 6);
		this.svgZoom(zoomAmt, points);

	}
	svgZoom(amt, origin) {
		let prevScale = this.model.scale;
		this.model.scale *= amt;
		this.model.translate.x = (origin.svg.x - (origin.g.x * this.model.scale));
		this.model.translate.y = (origin.svg.y - (origin.g.y * this.model.scale));
	}

	getTransform() {
		//Remember, the order here is really important. It needs to be translate and then scale:
		return `translate(${this.model.translate.x},${this.model.translate.y}) scale(${this.model.scale})`;
	}


	resetScaleZoom(rezoom: boolean = true) {
		//console.log("resetScaleZoom, rezoom: " + rezoom);
		let margin = (this.model.thickness) + (this.model.hemRadius * 2);
		this.model.rectInfo = {};
		this.model.rectInfo.x = (this.model.minMax.min.x) | 0;
		this.model.rectInfo.y = (this.model.minMax.min.y) | 0;
		this.model.rectInfo.w = (Math.abs(this.model.minMax.min.x) + Math.abs(this.model.minMax.max.x)) | 0;
		this.model.rectInfo.h = (Math.abs(this.model.minMax.min.y) + Math.abs(this.model.minMax.max.y)) | 0;
		let largest = this.largestOf(this.model.rectInfo.w, this.model.rectInfo.h);
		if (rezoom) this.model.scale = 1000 / (largest + margin * 2);

		//console.log("scale width: " + this.model.rectInfo.w + ", scale height: " + this.model.rectInfo.h);

		let tx = 500 - this.model.rectInfo.x * this.model.scale - this.model.rectInfo.w * this.model.scale / 2;
		let ty = 500 - this.model.rectInfo.y * this.model.scale - this.model.rectInfo.h * this.model.scale / 2;
		if (rezoom) this.model.translate = { x: tx, y: ty }

	}




	translateFromPoint(p) {
		return "translate(" + p.x + " " + p.y + ")";
	}

	getCoords(elem) {
		let box = elem.getBoundingClientRect();

		return {
			top: box.top + pageYOffset,
			left: box.left + pageXOffset
		};
	}

	largestOf(a, b) {
		return a > b ? a : b;
	}
	smallestOf(a, b) {
		return a < b ? a : b;
	}

	getDrawingBlob() {
		var el: HTMLElement = document.getElementById("svgContainer");
		if (el == null) return null;
		var html = el.innerHTML;
		return new Blob([html], { type: "image/svg+xml" });
	}

	handleDblClick() {
		//console.dir(this.model);
		//this.d.copyToClipboard(this.model.debugOutput);
		//let blob = this.getDrawingBlob();
		//this.d.saveBlobAs(blob, "test.svg");
	}





	calculateTextBbox(p) {
		//TODO: Since we are using a monospace font, it might be possible to estimate instead of measure

		//find the scratch pad to render and measure the text box
		let svgElem = document.getElementById("sizergroup");
		if (svgElem == null) {
			// probably running headless, so this doesn't matter, and we can make something up
			let result = {
				height: 100,
				width: 100,
				x: 0,
				y: 0,
				radius: (Math.sqrt((Math.pow(100, 2) + Math.pow(100, 2))))
			}
			return result;

		} else {

			let textElem = this.createTextBox(svgElem, p.text, p.attr);
			let tbBB = textElem.getBBox();

			//remove the element, since it's temporary, and will be recreated on the final svg
			svgElem.removeChild(textElem);

			//assemble and return the results
			let result = {
				height: tbBB.height,
				width: tbBB.width,
				x: tbBB.x,
				y: tbBB.y,
				radius: (Math.sqrt((Math.pow(tbBB.width, 2) + Math.pow(tbBB.height, 2))))
			}
			return result;
		}

	}

	//calculateTextBbox is very expensive, this makes sure we never perform the same calc twice:
	getTextBbox = this.d.memoize(this.calculateTextBbox);

	getDistanceFromBoxCenter(bb, angle) {
		var abs_cos_angle = Math.abs(Math.cos(this.degToRad(angle)));
		var abs_sin_angle = Math.abs(Math.sin(this.degToRad(angle)));
		let dist = 0;
		if (bb.width / 2 * abs_sin_angle <= bb.height / 2 * abs_cos_angle) {
			dist = bb.width / 2 / abs_cos_angle;
		}
		else {
			dist = bb.height / 2 / abs_sin_angle;
		}
		return dist;
	}

	createTextBox(parentElem, text, attributes) {
		//important that normal text creation and getTextBbox use this so that measurements are identical

		//create a svg text element
		var textElem = document.createElementNS("http://www.w3.org/2000/svg", "text");

		//set the attributes, like font-size, weight, x, y, etc

		Object.keys(attributes).forEach(key => {
			textElem.setAttributeNS(null, this.d.kebabCase(key), attributes[key]);
		});
		//textElem.setAttributeNS(null,"alignment-baseline","baseline"); //makes it pixel perfect, default is typesetting

		//insert the text itself
		var textNode = document.createTextNode(text);
		textNode.textContent = text;
		textElem.appendChild(textNode);
		parentElem.appendChild(textElem);

		return textElem;
	}



	updateBaseScale(f) {

		if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalGirth = 0;
		if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalStrokes = 1;

		let testHeading = 180;
		if (this.customtrim != undefined) {
			testHeading += this.customtrim.metadata.TDLData.startAngle;
		}

		this.resetMinMax();
		let testPt = { x: 0, y: 0 };
		//console.log(JSON.stringify(testPt));
		this.updateMinMax(testPt);
		let hemAngle = 190;
		let hookAngle = 180;
		let hemLength = 0;
		f.forEach((flange) => {

			//f.value = Number(f.value); //need to find out what's setting angles as strings!
			switch (flange.type) {
				case "line":
					if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalGirth += parseFloat(flange.value);
					//console.log("x1: " + testPt.x.toFixed(2) + ", y1: " + testPt.y.toFixed(2) + ", angle: " + testHeading + ", len: " + flange.value);
					testPt = this.pointFrom(testPt, flange.value, testHeading);
					//console.log("x2: " + testPt.x.toFixed(2) + ", y2: " + testPt.y.toFixed(2));
					this.updateMinMax(testPt);
					break;

				case "angle":
					testHeading += 180 - flange.value;
					if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalStrokes += 1;
					break;

				case "posHem": case "posHook": case "negHem": case "negHook":

					//testHeading += 180;
					var val = flange.type.indexOf("Hem") > -1 ? hemAngle : hookAngle;
					if (flange.type.indexOf("neg") > -1) val *= -1;
					testHeading += val;

					if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalGirth += parseFloat(flange.value);
					if (this.customtrim.metadata != undefined) this.customtrim.metadata.totalStrokes += 2;
					hemLength = flange.value;
			}

		});
		/*   
			 let totalX = Math.abs(this.model.minMax.min.x) + Math.abs(this.model.minMax.max.x);
			 let totalY = Math.abs(this.model.minMax.min.y) + Math.abs(this.model.minMax.max.y);
			 this.model.baseScale = totalX > totalY ? totalX : totalY;
			 */
		//console.log(JSON.stringify(this.model.minMax, null, 2));

		//let margin = (this.model.thickness) + (this.model.hemRadius * 2);
		let w = Math.abs(this.model.minMax.min.x) + Math.abs(this.model.minMax.max.x);
		let h = Math.abs(this.model.minMax.min.y) + Math.abs(this.model.minMax.max.y);
		let largest = this.largestOf(w, h);
		//console.log("largest of w:" + w.toFixed(2) + " and h: " + h.toFixed(2) + " is " + largest.toFixed(2));
		////let scale = 1000 / (largest + margin * 2);


		//console.log("test width: " + w + ", test height: " + h);

		this.model.baseScale = largest;
		//console.log("this.model.baseScale: " + this.model.baseScale + ", scale: " + scale);

		//console.log("this.model.baseScale: " + this.model.baseScale);
		//update model scaling factors:
		this.model.fontProps.fontSize = this.model.baseScale * 4;
		this.model.thickness = this.model.baseScale * 2;
		this.model.arcRadius = this.model.baseScale * 3;

		this.model.hemRadius = this.model.thickness * 1.5; //poor approximation

		if (hemLength > 0) {
			//console.log("hemLength: " + hemLength);

			//o.sides.a,o.sides.b,o.sides.c,o.angles.A,o.angles.B,o.angles.C
			var angles = this.solveASAo({
				sides: { a: null, b: hemLength * this.model.lenMult, c: null },
				angles: { A: null, B: 90, C: 10 }
			});

			//var angles = this.solveASA(null, hemLength * this.model.baseScale,  null, 80, null, 90);
			//console.log(JSON.stringify(angles,null,2));
			this.model.hemRadius = ((angles.c + this.model.thickness * 2) / 2) + 0.25; ///2 * 3 * this.;
			//this.model.thickness*Math.PI/2;//this.model.arcRadius * 2; //this.model.baseScale * 1.5;
		}

		//console.log("w: " + w.toFixed(2) + ", h: " + h.toFixed(2) + ", fontSize: " + this.model.fontProps.fontSize.toFixed(2)  + ", baseScale: " + this.model.baseScale.toFixed(2));
		//console.log("thickness: " + this.model.thickness + ", hemRadius: " + this.model.hemRadius);

		this.resetMinMax();

	}


	arcTo(p, r, a) {
		let t = (a < 0) ? 0 : 1;
		return `A${r},${r},0,0,${t},${this.trimFloat(p.x)},${this.trimFloat(p.y)}`;
	}

	moveTo(p) {
		return `M${this.trimFloat(p.x)},${this.trimFloat(p.y)}`;
	}

	lineTo(p) {
		return `L${this.trimFloat(p.x)},${this.trimFloat(p.y)}`;
	}

	//very small javascript floats use scientific notation, which is interpreted as an error in SVG
	trimFloat(num) {
		return num.toFixed(4).toString();
	}

	pointFrom(srcPt, dist, angle, doLog = true) {
		let newX = srcPt.x - (dist * this.CosDeg(angle));
		let newY = srcPt.y - (dist * this.SinDeg(angle));
		let newPt = { x: newX, y: newY };
		if (this.model.showLabels && doLog) this.updateMinMax(newPt);

		return newPt;
	}

	resetMinMax() {
		this.model.minMax = {
			min: { x: 1000, y: 1000 },
			max: { x: -1000, y: -1000 },
		}
	}

	updateMinMax(pt) {
		//console.log("x: " + pt.x.toFixed(2) + ", y: " + pt.y.toFixed(2));
		this.model.minMax.min.y = this.less(pt.y, this.model.minMax.min.y);
		this.model.minMax.max.y = this.more(pt.y, this.model.minMax.max.y);
		this.model.minMax.min.x = this.less(pt.x, this.model.minMax.min.x);
		this.model.minMax.max.x = this.more(pt.x, this.model.minMax.max.x);
	}

	more(a, b) {
		return a > b ? a : b;
	}
	less(a, b) {
		return a < b ? a : b;
	}


	CosDeg(d) {
		return Math.cos(this.degToRad(d));
	}

	SinDeg(d) {
		return Math.sin(this.degToRad(d));
	}

	degToRad(deg) {
		return deg * Math.PI / 180;
	};

	svgHeadingToWebGL(h) {
		return -(h - 90) + 180
	}

	solveASAo(o) {
		return this.solveASA(o.sides.a, o.sides.b, o.sides.c, o.angles.A, o.angles.B, o.angles.C);
	}


	solveASA(a, b, c, A, B, C) {
		var area, status;
		// Find missing angle
		if (A == null) A = 180 - B - C;
		if (B == null) B = 180 - C - A;
		if (C == null) C = 180 - A - B;
		if (A <= 0 || B <= 0 || C <= 0) throw status + " - No solution";
		var sinA = Math.sin(this.degToRad(A));
		var sinB = Math.sin(this.degToRad(B));
		var sinC = Math.sin(this.degToRad(C));
		// Use law of sines to find sides
		var ratio;  // side / sin(angle)
		if (a != null) { ratio = a / sinA; area = a * ratio * sinB * sinC / 2; }
		if (b != null) { ratio = b / sinB; area = b * ratio * sinC * sinA / 2; }
		if (c != null) { ratio = c / sinC; area = c * ratio * sinA * sinB / 2; }
		if (a == null) a = ratio * sinA;
		if (b == null) b = ratio * sinB;
		if (c == null) c = ratio * sinC;

		return {
			a: a,
			b: b,
			c: c,
			A: A,
			B: B,
			C: C,
			area: area,
			status: status
		}

	}


	solveAngle(a, b, c) {
		var temp = (a * a + b * b - c * c) / (2 * a * b);
		if (-1 <= temp && temp <= 0.9999999)
			return this.radToDeg(Math.acos(temp));
		else if (temp <= 1)  // Explained in https://www.nayuki.io/page/numerically-stable-law-of-cosines
			return this.radToDeg(Math.sqrt((c * c - (a - b) * (a - b)) / (a * b)));
		else
			throw "No solution";
	}

	solveSide(a, b, C) {
		C = this.degToRad(C);
		if (C > 0.001)
			return Math.sqrt(a * a + b * b - 2 * a * b * Math.cos(C));
		else  // Explained in https://www.nayuki.io/page/numerically-stable-law-of-cosines
			return Math.sqrt((a - b) * (a - b) + a * b * C * C * (1 - C * C / 12));
	}


	radToDeg(x) {
		return x / Math.PI * 180;
	}


	allCombos(arr) {
		var n = arr.length;
		var combos = [];
		var i, j;

		for (i = 0; i < n; i++) {
			for (j = i + 1; j < n; j++) {
				combos.push([arr[i], arr[j]]);
			}
		}
		return combos;
	}


}