<header class="routeheader">

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <button class="btn btn-secondary" (click)="router.navigate(['/home'])">
        <fa-icon [icon]="d.faHome" flip="horizontal"></fa-icon>Continue Shopping
      </button>
    </div>

    <div class="dist-cols-child pageTitle">
      <fa-icon [icon]="d.faShoppingCart"></fa-icon>Review Cart
    </div>

    <div class="dist-cols-child">
      <button type="button" class="btn btn-secondary" (click)="printCart()">
        <fa-icon [icon]="d.faPrint"></fa-icon>Print Pre-Order Summary</button>
    </div>
  </div>

</header>

<div class="routecontent cart-content">







  <div class="cart-header">
    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label top-pad" style="display:inline-block">Purchase Order:</label>
      </div>
      <div class="ungrid-col" style="width:80%">
        <input [(ngModel)]="d.model.cart.PO" required maxlength="35" type="text" (dblclick)="poTimeStamp()"
          class="form-control" id="cart-po" style="display:inline-block">
      </div>
    </div>

    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label top-pad" style="display:inline-block">Job Name:</label>
      </div>
      <div class="ungrid-col" style="width:80%">
        <input [(ngModel)]="d.model.cart.JobName" maxlength="40" required type="text" class="form-control"
          id="cart-jobname" style="display:inline-block">
      </div>
    </div>

    <div class="form-group ungrid-row" *ngIf="d.model.cart?.OrderItemSet.length > 0">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;line-height:35px">
        <label for="first-name" class="control-label" style="display:inline-block">Order Ship Date:</label>
      </div>
      <div class="ungrid-col" style="width:80%;line-height:35px" *ngIf="!d.model.jobSite">
        <b>TO BE CONFIRMED:</b>
        <b style="color:red">{{shipDate()|date:'fullDate'}}</b>
      </div>
      <div class="ungrid-col" style="width:80%;line-height:35px" *ngIf="d.model.jobSite">
        <b>TO BE CONFIRMED</b>
        <ng-container *ngIf="d.model.ShipTo.DeliveryOptions == '30'">
          (Requested: {{d.model.jobSiteForm.RequestedShipDate|date:'fullDate'}})
          <button class="btn btn-sm btn-primary" (click)="d.closeJobsitePrompt('jobsite')"><fa-icon
              [icon]="d.faLocationArrow"></fa-icon>Modify Request Form</button>
        </ng-container>
      </div>
    </div>

    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label" style="display:inline-block"><fa-icon
            [icon]="d.faMapMarker"></fa-icon>&nbsp;Ship To:</label>
      </div>
      <div class="ungrid-col" style="width:80%">
        <span *ngIf="d.model.ShipTo.SHIPTO">
          <b>{{d.model.ShipTo.SHIPTO}} - {{d.model.ShipTo.NAME1}}</b><br />
          {{d.model.ShipTo.STRAS}}<br />
          {{d.model.ShipTo.ORT01}}<span *ngIf="d.model.ShipTo.ORT02">, {{d.model.ShipTo.ORT02}}</span>,
          {{d.model.ShipTo.REGIO}} {{d.model.ShipTo.PSTLZ}}
        </span>
      </div>
    </div>

    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label" style="display:inline-block">Shipping Plant:</label>
      </div>
      <div class="ungrid-col" style="width:80%">
        <span *ngIf="d.model.ShipTo.SHIPPINGPLANT">
          <fa-icon [icon]="d.faTruck" *ngIf="d.model.ShipTo.DeliveryOptions == 10"></fa-icon>
          <fa-icon [icon]="d.faIndustry" *ngIf="d.model.ShipTo.DeliveryOptions == 20"></fa-icon>
          {{d.model.ShipTo.SHIPPINGPLANT}}
        </span>
      </div>
    </div>

    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label" style="display:inline-block">
          <span *ngIf="d.model.ShipTo.DeliveryOptions == '10'">Shipping Method:</span>
          <span *ngIf="d.model.ShipTo.DeliveryOptions == '20'">Pick Up Via:</span>
        </label>
      </div>
      <div class="ungrid-col" style="width:80%">
        {{d.model.ShipMode.Name}} ({{d.model.ShipMode.ShippingModeId}})&nbsp;{{d.model.currentCarrierName}}
      </div>
    </div>
    <!--
    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label top-pad" style="display:inline-block">Shipping Instructions:</label>
      </div>
      <div class="ungrid-col" style="width:80%">
        <textarea [(ngModel)]="d.model.cart.ShipmentInstruction" maxlength="132" required type="text" class="form-control" id="shipment-instruction" style="display:inline-block"></textarea>
      </div>
    </div>
      -->
    <div class="form-group ungrid-row">
      <div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
        <label for="first-name" class="control-label top-pad" style="display:inline-block">Attachments:</label>
      </div>
      <div id="attachment-upload-label" class="ungrid-col dropZone" style="width:80%"
        (dragleave)="fileDragLeave($event)" (dragover)="fileDragOver($event)" (drop)="fileDrop($event,true)">
        <div class="form-control attachmentContainer" style="min-height:3em;height:auto">

          <div class="attachment" *ngFor="let attachment of d.model.cart.metadata.attachments">
            <fa-icon [icon]="d.filetypeIcon(attachment.name)"></fa-icon>
            {{attachment.name}}

            <button (click)="deleteAttachment(attachment.name)" class="btn btn-sm btn-danger btn-xs iconBtn"
              style="border-radius:15px" title="Remove Attachment">
              <fa-icon [icon]="d.faTimes"></fa-icon>

            </button>

          </div>


          <button class="btn btn-sm btn-primary pull-right" (click)="addAttachment()" style="margin-bottom:5px">
            <fa-icon [icon]="d.faPaperclip"></fa-icon>
            Add Attachment
          </button>

        </div>


      </div>
    </div>

  </div>

  <table class="cart-item-list" *ngIf="d.model.cart?.OrderItemSet">
    <thead>
      <tr>
        <th class="first-col"></th>
        <th>Item</th>
        <th class="text-center">Details</th>
        <th class="text-center" *ngIf="!d.model.jobSite">Shippable</th>
        <th class="text-center">Qty</th>
        <!--<th class="text-center">Confirmed Ship Date</th>-->
        <th class="text-center">Unit Price</th>
        <th class="text-center">Item Subtotal</th>
        <th class="text-center last-col"></th>
      </tr>
    </thead>
    <tbody>

      <tr *ngFor="let item of d.model.cart?.OrderItemSet; let itemId = index" [ngClass]="{'first-row': (itemId==0)}">
        <td class="first-col text-center">
          <img class="cart-thumb" [src]="item.metadata.product.ImageUrl|cms" (click)="d.zoomImg($event)" />

        </td>
        <td>


          <span class="ng-tooltip" *ngIf="item.metadata.color?.ImageUrl">
            <img src="{{item.metadata.color.ImageUrl|cms}}" class="color-menu-image" />
            <span>
              <img class="callout" src="/assets/images/callout.gif" />
              <img src="{{item.metadata.color.ImageUrl|cms}}" class="color-tooltip-image" />
            </span>
          </span>

          <b>{{item.Materialnumber}}</b> {{item.Productname}}
          <ng-container *ngIf="item.metadata?.TDLData?.isFullyCustom">- {{item.metadata.TDLData.name}}</ng-container>
          <ng-container *ngIf="item.Gauge">- {{item.Gauge}}G</ng-container>
          <ng-container *ngIf="item.Metal">- {{item.Metal|meta:'metalName'}}</ng-container>
          <ng-container *ngIf="item.PaintSystem&&item.ColorCode!='BARE'">- {{item.PaintSystem}}</ng-container>
          <ng-container *ngIf="item.ColorCode">- {{item.ColorCode|colordesc}}</ng-container>
          <ng-container *ngIf="item.metadata.warning"><b>{{item.metadata.warning}}</b></ng-container>
          <ng-container *ngIf="item.UphillSlope.length > 0">
            <ng-container *ngIf="item.DownhillSlope == 'NA'">- Combined Pitch {{item.UphillSlope}}:12</ng-container>
            <ng-container *ngIf="item.DownhillSlope != 'NA'">- Uphill Pitch {{item.UphillSlope}}:12 / Downhill Pitch
              {{item.DownhillSlope}}:12, </ng-container>
          </ng-container>

          <span *ngIf="item.CutlistProvide=='Y'">
            <br /><button style="margin-left:25px" class="btn btn-secondary btn-xs" (click)="openCutlist(item,itemId)">
              <fa-icon [icon]="d.faCut"></fa-icon>&nbsp;Modify Panel &amp; Cutlist
            </button>
          </span>

          <span *ngIf="item.metadata.TDLData && !d.isEmpty(item.metadata.TDLData)">
            <ng-container *ngIf="item.metadata.TDLData.isFullyCustom">
              <br /><button style="margin-left:25px" class="btn btn-secondary btn-xs"
                (click)="openCustomTrim(item,itemId)">
                <fa-icon [icon]="d.faPencilAlt"></fa-icon>&nbsp;Modify Custom Trim
              </button>
            </ng-container>
            <ng-container *ngIf="!item.metadata.TDLData.isFullyCustom">
              <br /><button style="margin-left:25px" class="btn btn-secondary btn-xs" (click)="openTrim(item,itemId)">
                <fa-icon [icon]="d.faPencilAlt"></fa-icon>&nbsp;Modify XY Trim
              </button>
            </ng-container>

          </span>
          <ng-container *ngIf="item.metadata.cartError">
            <br /><span class="cartError"><fa-icon [icon]="d.faExclamationCircle"
                style="margin-right:5px"></fa-icon>&nbsp;{{item.metadata.cartError.Message}}</span>
          </ng-container>

        </td>
        <td class="text-center">

          <span *ngIf="item.PackagingType" class="cart-detail-row">
            <b>Packaging:</b> {{item.PackagingType}}
          </span>

          <span *ngIf="item.metadata.pricingSetResult.DiscountAmt!='0.00'" class="cart-detail-row">
            <b>Discount:</b> {{item.metadata.pricingSetResult.DiscountAmt| currency:'USD':'symbol':'1.2-2'}} / unit
          </span>

          <span *ngIf="item.metadata.pricingSetResult.ShortcutExtra!='0.00'" class="cart-detail-row">
            <b>Shortcut Extra:</b> {{item.metadata.pricingSetResult.ShortcutExtra| currency:'USD':'symbol':'1.2-2'}}
          </span>
          <span *ngIf="item.metadata?.pricingSetResult.LLFixExtra!='0.00'" class="cart-detail-row">
            <b>Fixed LL Extra:</b> {{item.metadata.pricingSetResult.LLFixExtra| currency:'USD':'symbol':'1.2-2'}}
          </span>
          <span *ngIf="item.metadata.pricingSetResult.LLVarExtra!='0.00'" class="cart-detail-row">
            <b>Var. LL Extra:</b> {{item.metadata.pricingSetResult.LLVarExtra| currency:'USD':'symbol':'1.2-2'}}
          </span>

        </td>
        <td class="text-center" *ngIf="!d.model.jobSite">{{item.Requesteddelivery|sapdate}}</td>
        <td class="text-center">
          <input *ngIf="item.CutlistProvide!='Y'" [(ngModel)]="item.metadata.Quantity"
            [ngClass]="{'input-invalid': item.metadata.Quantity == 0}" debounceKeyUp [debounceTime]="1000"
            (ngModelChange)="updatePrice(item)" required type="text" class="form-control cart-input" id="qty"
            style="display:inline-block;text-align:center">
          <span *ngIf="item.CutlistProvide=='Y'">{{item.Quantity001}}</span>
        </td>

        <td class="text-center">
          {{item.metadata.pricingSetResult.NetPrice| currency:'USD':'symbol':'1.2-2'}}<br />
          per {{item.metadata.pricingSetResult.Unitofmeasure}} ({{item.metadata.pricingSetResult.Perunit}})

        </td>
        <td class="text-right bold rpad">
          {{item.metadata.pricingSetResult.ItemTotal| currency:'USD':'symbol':'1.2-2'}}
        </td>
        <td class="text-center last-col">

          <button (click)="removeItem(itemId)" class="btn btn-sm btn-danger btn-xs iconBtn" style="border-radius:15px"
            title="Remove Item">
            <fa-icon [icon]="d.faTimes"></fa-icon>
          </button>

        </td>
      </tr>

    </tbody>

    <tfoot>
      <tr *ngIf="d.model.jobSite">

        <th colspan="5" class="text-right gtotal">Subtotal:</th>
        <th class="text-right gtotal rpad"
          [innerHTML]="grandTotal()| currency:'USD':'symbol':'1.2-2'|meta:'pricingError'|safe"></th>
        <th></th>
      </tr>
      <tr *ngIf="d.model.jobSite">

        <th colspan="5" class="text-right gsubtotal">Job Site Delivery Fee (Freight):</th>
        <th class="text-right gsubtotal rpad">$125.00</th>
        <th></th>
      </tr>
      <tr>
        <th [attr.colspan]="d.model.jobSite ? 4 : 5"></th>
        <th class="text-right gtotal">Total:</th>
        <th class="text-right gtotal rpad"
          [innerHTML]="grandTotal(true)| currency:'USD':'symbol':'1.2-2'|meta:'pricingError'|safe"></th>
        <th></th>
      </tr>
    </tfoot>
  </table>












</div>

<footer>

  <div class="dist-cols-parent">
    <div class="dist-cols-child">

      <button class="btn btn-secondary" (click)="sortCart()"><fa-stack class="smallStack">
          <fa-icon [icon]="d.faSortUp" stackItemSize="1x"></fa-icon>
          <fa-icon [icon]="d.faSortDown" stackItemSize="1x"></fa-icon>
        </fa-stack>Sort Cart</button>
      &nbsp;
      <button class="btn btn-warning-secondary" (click)="emptyCart()">
        <fa-icon [icon]="d.faTrash" flip="horizontal"></fa-icon>Empty Cart
      </button>


    </div>

    <div class="dist-cols-child">

      <button class="btn btn-secondary" debounceClick [debounceTime]="1000" (onEvent)="saveE0002()"
        *ngIf="!d.model.jobSite"
        [ngClass]="{'disabled':(!d.model.accountInfo.CanCreateInquiry&&!d.model.accountInfo.IsItAdmin)}">
        <fa-icon [icon]="d.faSave"></fa-icon>Save as Template
      </button>
      &nbsp;&nbsp;
      <button class="btn btn-secondary" (click)="verifySave()"
        [ngClass]="{'disabled':(!d.model.accountInfo.CanCreateOrder&&!d.model.accountInfo.IsItAdmin)}">
        <fa-icon [icon]="d.faCheck"></fa-icon>
        <span *ngIf="!d.model.jobSite">Place Order</span>
        <span *ngIf="d.model.jobSite">Place Request</span>
      </button>




    </div>
  </div>




</footer>

<ng-template #emptycartModal>

  <div class="modal-header">
    <h4 class="modal-title">Really Empty Cart?</h4>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to remove all items from your cart?</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="emptyCartCancel()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Cancel</button>
    <button class="btn btn-secondary" (click)="emptyCartConfirm()"><fa-icon [icon]="d.faTrash"></fa-icon>Empty
      Cart</button>
  </div>

</ng-template>


<ng-template #verifysaveModal>


  <div class="modal-header">
    <h4 *ngIf="!d.model.jobSite" class="modal-title">Confirm Order</h4>
    <h4 *ngIf="d.model.jobSite" class="modal-title">Confirm Request</h4>
  </div>
  <div class="modal-body">
    <p *ngIf="!d.model.jobSite" style="font-size:1.2em">You are about to place an order that totals
      <b>{{grandTotal(true)| currency:'USD':'symbol':'1.2-2'}}</b>
    </p>
    <p *ngIf="d.model.jobSite" style="font-size:1.2em">You are about to place a Job Site Delivery Request that totals
      <b>{{grandTotal(true)| currency:'USD':'symbol':'1.2-2'}}</b>
    </p>

    <p *ngIf="!d.model.jobSite" style="font-size:1.2em">This order is subject to the <a
        href="http://www.ascbp.com/files/Order%20Forms/ecomtermsofuse.pdf" target="_blank"
        rel="noopener noreferrer nofollow">e-Commerce Terms of Use Policy</a></p>
    <p *ngIf="d.model.jobSite" style="font-size:1.2em">This Request is subject to the <a
        href="http://www.ascbp.com/files/Order%20Forms/ecomtermsofuse.pdf" target="_blank"
        rel="noopener noreferrer nofollow">e-Commerce Terms of Use Policy</a></p>

    <p style="font-size:1.2em">
      Your <b style="color:red">estimated ship date</b> will be <b>{{shipDate()|date:'fullDate'}}</b>, once material
      availability is confirmed.
    </p><br /><br />

    <p class="note" style="font-size: 1.2em; position: relative;top: -1.75em;">
      Please note: once your order has been placed, it can't be changed. Your order is processed &quot;as is&quot; to
      the order delivery system
    </p>

    <button class="btn btn-primary" (click)="printCart()">
      <fa-icon [icon]="d.faPrint"></fa-icon>Print Pre-Order Summary
    </button>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" [disabled]="saving" (click)="cancelSave()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Back to Review</button>
    <button class="btn btn-secondary" id="orderConfirmBtn" (click)="saveE0004()" (dblclick)="dblSave()"
      [disabled]="saving" data-dismiss="modal"><fa-icon [icon]="d.faCheck"></fa-icon>Confirm</button>
  </div>

</ng-template>


<ng-template #salesorderWarnChangesModal>

  <div class="modal-header">
    <h4 class="modal-title"><fa-icon [icon]="d.faQuestion"></fa-icon>&nbsp;&nbsp;Invalid Ship Date Found!</h4>
  </div>
  <div class="modal-body">
    <p>The shipment date of one or more items in your cart is no longer valid. Please review the following required
      updates:</p>

    <ul>
      <ng-container *ngFor="let orderMessage of orderMessages">
        <li [ngClass]="orderMessage.type" *ngIf="orderMessage.msg.length > 0" [innerHTML]="orderMessage.msg|safe"></li>
      </ng-container>
    </ul>

    <p>
      If these changes are okay, click <b>Agree</b> to modify the ship dates and place the order.<br />
      <br /> If not, click <b>Cancel</b> to return to your cart.
    </p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="denyDateChanges()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Cancel</button>
    <button class="btn btn-secondary" (click)="applyDateChanges(true)" data-dismiss="modal"><fa-icon
        [icon]="d.faCheck"></fa-icon>Agree to Ship Date Changes</button>
  </div>

</ng-template>


<ng-template #salesorderconfirmModal>

  <div class="modal-header">
    <h4 *ngIf="!d.model.jobSite" class="modal-title"><fa-icon
        [icon]="d.faFlagCheckered"></fa-icon>&nbsp;&nbsp;{{orderResults?.orderType|capitalize}} Placed!</h4>
    <h4 *ngIf="d.model.jobSite" class="modal-title"><fa-icon [icon]="d.faFlagCheckered"></fa-icon>&nbsp;&nbsp;Job Site
      Delivery Request Placed!</h4>
  </div>
  <div class="modal-body">
    <p *ngIf="!d.model.jobSite" style="font-size:1.2em">Thank you. Your {{orderResults?.orderType}} has been received,
      and your confirmation will arrive shortly. Your {{orderResults?.orderType}} number is:</p>
    <p *ngIf="d.model.jobSite" style="font-size:1.2em">Thank you. Your request has been received, and your confirmation
      will arrive shortly. Your {{orderResults?.orderType}} number is:</p>

    <p style="text-align:center;font-size:1.5em;font-weight:bold">{{orderResults?.SalesOrderNumber}}</p>

    <div class="orderMessage" *ngIf="orderMessages.length > 0">
      <div><b>Please Review the following messages about your order:</b><br /></div>
      <ul>
        <ng-container *ngFor="let orderMessage of orderMessages">
          <li *ngIf="orderMessage.msg.length == 0" [ngClass]="orderMessage.type">Error occured when attempting to save
            order in system, please contact your ASC BP Customer Service Representative</li>
          <li *ngIf="orderMessage.msg.length > 0" [ngClass]="orderMessage.type" [innerHTML]="orderMessage.msg|safe">
          </li>
        </ng-container>
      </ul>
      <div>If this is problematic, please contact your Customer Service Representative <b>immediately.</b></div>
    </div>

    <div class="paymentTermsInstructions" *ngIf="d.model.SoldTo.TermsOfPaymentKey == 'Z319'">
      <b>EMAIL OR FAX YOUR COMPLETED/SIGNED CHECK TO:</b> <a href="mailto:AR@ASCPROFILES.COM">AR@ASCPROFILES.COM</a> or
      253-896-8471, include the SALES ORDER NUMBER and <i>&quot;PAY BY FAX&quot;</i> written on your paperwork and/or
      check.<br /><br />Your order will be released to production upon receipt of check.
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="startOver()" data-dismiss="modal">OK</button>
  </div>

</ng-template>



<ng-template #cutlistModal>

  <div class="modal-header">


    <h4 class="modal-title">Modify Panel &amp; Cutlist</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true"
      (click)="cutlistModalRef.hide()"><fa-icon [icon]="d.faWindowClose"></fa-icon></button>


  </div>
  <div class="modal-body text-center">
    <div class="center-margin">
      <ng-container *ngIf="editPanel">
        <panel [panel]="editPanel" [semaphore]="-1"></panel>
        <cutlist [panel]="editPanel" [semaphore]="-1" (onUpdateCart)="onUpdateCart($event)"></cutlist>
      </ng-container>

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="cutlistModalRef.hide()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Cancel</button>
    <!--<button class="btn btn-secondary" (click)="saveUser()" [disabled]="false"><fa-icon [icon]="d.faCheck"></fa-icon>Save Changes</button>-->
  </div>

</ng-template>

<ng-template #addattachmentModal>
  <div class="dropZone" (dragleave)="fileDragLeave($event)" (dragover)="fileDragOver($event)"
    (drop)="fileDrop($event,true)">
    <div class="modal-header">

      <h4 class="modal-title">Add an Attachment</h4>
      <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="cancelAttachment()"
        [ngClass]="{'disabled':(uploading)}"><fa-icon [icon]="d.faWindowClose"></fa-icon></button>


    </div>
    <div class="modal-body" *ngIf="addingAttachment">

      <label id="attachment-upload-label" for="attachment-upload-input" class="wideInput form-control file-input"
        [ngClass]="{'disabled':(uploading),'uploading':(uploading)}">
        Click here to attach a file, or drop your file here
        <input (change)="fileChange($event);confirmAttachment(true)" id="attachment-upload-input" name="upload"
          type="file" style="display:none" [accept]="validExtensions">
      </label>

      <br />
      <span style="font-style:italic">Supported file types: jpg, png, pdf, doc &amp; docx.</span>


    </div>
    <div class="modal-footer">
      <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="cancelAttachment()"
        [ngClass]="{'disabled':(uploading)}"><fa-icon [icon]="d.faTimes"></fa-icon>Cancel</button>
      <!--
        <button class="btn btn-secondary" (click)="confirmAttachment()" [ngClass]="{'disabled':(!attachmentDialogFile||uploading)}">
          <fa-icon [icon]="d.faPaperclip"></fa-icon>Attach File
        </button>
          -->
    </div>
  </div>
</ng-template>

<ng-template #salesorderErrorModal>

  <div class="modal-header">

    <h4 class="modal-title">Submission Error</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="clearSaveError()"><fa-icon
        [icon]="d.faWindowClose"></fa-icon></button>

  </div>

  <div class="modal-body">
    {{orderError?.Message}}
    <ng-container *ngIf="orderError?.Messages?.length > 0">
      <br /><br />
      Please review the following line item errors:<br />
      <ul>
        <li *ngFor="let msg of orderError.Messages">{{msg}}</li>
      </ul>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="clearSaveError()"
      [ngClass]="{'disabled':(uploading)}"><fa-icon [icon]="d.faTimes"></fa-icon>Return to Cart</button>

  </div>

</ng-template>

<ng-template #printpricesModal>

  <div class="modal-header">
    <h4 class="modal-title">Display Prices?</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="closeCartModal()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon></button>

  </div>
  <div class="modal-body">
    <p>Include prices in printout?</p>
  </div>
  <div class="modal-footer dist-cols-parent">
    <button class="btn btn-secondary flexBtn dist-cols-child" data-dismiss="modal"
      (click)="printPrices=false;printCartConfirm()">
      <fa-stack>
        <fa-icon [icon]="d.faBan" stackItemSize="2x" style="color:red"></fa-icon>
        <fa-icon [icon]="d.faDollarSign" stackItemSize="1x"></fa-icon>
      </fa-stack> Hide Prices
    </button>
    <button class="btn btn-secondary flexBtn dist-cols-child" data-dismiss="modal"
      (click)="printPrices=true;printCartConfirm()">
      <fa-stack>
        <fa-icon [icon]="d.faCircleThin" stackItemSize="2x" style="color:green"></fa-icon>
        <fa-icon [icon]="d.faDollarSign" stackItemSize="1x"></fa-icon>
      </fa-stack>Show Prices
    </button>
  </div>

</ng-template>

<ng-template #trimModifyModal>

  <div class="modal-header">

    <h4 class="modal-title">Modify your XY Trim</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true"
      (click)="trimModifyModalRef.hide()"><fa-icon [icon]="d.faWindowClose"></fa-icon></button>

  </div>
  <div class="modal-body">

    <ng-container *ngIf="editTrim?.metadata?.TDLData">
      <!--<ng-container *ngIf="!editTrim?.metadata?.TDLData?.isFullyCustom">-->
      <trimeditfct [customtrim]="editTrim" [semaphore]="-1" (onUpdateCart)="onUpdateCart($event)"></trimeditfct>
      <!--</ng-container>-->
    </ng-container>

  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="trimModifyModalRef.hide()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Return to Cart</button>
  </div>

</ng-template>


<ng-template #customTrimModifyModal>

  <div class="modal-header">

    <h4 class="modal-title">Modify your Fully Custom Trim</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true"
      (click)="customTrimModifyModalRef.hide()"><fa-icon [icon]="d.faWindowClose"></fa-icon></button>

  </div>
  <div class="modal-body">

    <ng-container *ngIf="editTrim?.metadata?.TDLData">
      <!--<ng-container *ngIf="editTrim?.metadata?.TDLData?.isFullyCustom">-->
      <trimeditfct *ngIf="editTrim" [customtrim]="editTrim" [semaphore]="-1" (onUpdateCart)="onUpdateCart($event)">
      </trimeditfct>
      <!--</ng-container>-->
    </ng-container>

  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="customTrimModifyModalRef.hide()"><fa-icon
        [icon]="d.faTimes"></fa-icon>Return to Cart</button>
  </div>

</ng-template>