import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-trims',
  templateUrl: './trims.component.html',
  //styleUrls: ['./trims.component.scss']
})
export class TrimsComponent implements OnInit {

  constructor(public d: DataService, private router: Router, private route: ActivatedRoute) { }
  public colorFilter: string = "";
  public selectedTab: string = "std";
  public selectedTrim: any = {};
  public fullyCustomTrim: any = {};
  public semaphore: number = 0;


  @ViewChild("trimEditorModal", { static: true }) trimEditorModal: TemplateRef<any>;
  trimEditorModalRef: BsModalRef;

  @ViewChild("trimsModal", { static: true }) trimsModal: TemplateRef<any>;
  trimsModalRef: BsModalRef;


  ngOnInit() {

    this.d.model.TrimGauges = [];
    this.d.model.TrimGetFilterBaseColor = [];

    if (!this.d.model.hasOwnProperty("TrimCategorySet")) {
      this.d.get("/GetTrimsSet", {}).subscribe(result => {
        let data = result;
        Object.keys(data).forEach((key) => {
          this.d.model[key] = data[key];

          //like customerSetFiltered
          if (key == 'TrimCategorySet') {
            data[key].forEach((t) => {
              t.metadata = {
                product: {
                  //ImageUrl: (this.d.model.imageProxyUrl + "?contenttype=category&id=" + t.PictureId + "_NAV"),
                  ImageUrl: "/images/Category/" + t.PictureId + "_NAV.svg",
                },
                selected: false
              }
            });

          }
        });
        this.cfgFiltersBySrcPanel();
      });
    } else {
      this.cfgFiltersBySrcPanel();
    }

    //DEBUG: load a specific tab:
    //if (this.d.model.flags.dev_mode) this.selectTab('fct');
    //if (this.d.model.flags.dev_mode) this.selectTab('xyz');
  }

  navChange(e) {
    this.selectTab(e.nextId);
  }

  async selectTab(tabName) {
    console.log("selectTab: " + tabName);
    this.selectedTab = tabName;

    if (tabName == 'fct') {
      await this.d.initFctData();

      this.fullyCustomTrim = this.d.model.customtrim;

      this.fullyCustomTrim = {
        Quantity001: 1,
        metadata: {
          materialType: '',
          gauge: '',
          mins: this.d.model.TrimFullyCustomSet[0].HasPOPCapability,
          hemLength: 0,
          PictureId: 'FCB',
          //TrimFullyCustomSet: this.d.deref(this.d.model.TrimFullyCustomSet),
          TDLData: {
            "name": "My Fully Custom Trim",
            "paintedSide": "L",
            "startAngle": 0,
            "flanges": [{
              "type": "line",
              "value": 2
            }
            ],
            "canSelectPaintedSide": true,
            "isFullyCustom": true
          },
          hems: {
            startHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }],
            endHem: [{ name: "None", selected: false }, { name: "Flat", selected: true }, { name: "Open", selected: false }]
          },
          pricingSetResult: {
            NetPrice: '0.00',
            DiscountAmt: '0.00'
          }

        }
      }

    }

    /*
    //DEBUG: weird rendering bug
    this.fullyCustomTrim.metadata.TDLData = {
      "name": "Custom Double Hem OpenJamb",
      "paintedSide": "L",
      "startAngle": 170,
      "flanges": [
        {
          "type": "posHem",
          "value": 0.4375
        },
        {
          "type": "line",
          "value": 1.5,
          "name": "X"
        },
        {
          "type": "angle",
          "value": 90
        },
        {
          "type": "line",
          "value": 5.25,
          "name": "Y"
        }
      ],
      "canSelectPaintedSide": true,
      "isFullyCustom": true
    };
    */


    /*
    this.fullyCustomTrim.metadata.TDLData = {
  "name": "My Fully Custom Trim",
  "paintedSide": "L",
  "startAngle": -180,
  "flanges": [
    {
      "type": "posHook",
      "value": 0.5
    },
    {
      "type": "line",
      "value": 2
    },
    {
      "type": "angle",
      "value": -135
    },
    {
      "type": "line",
      "value": 2
    },
    {
      "type": "angle",
      "value": 135
    },
    {
      "type": "line",
      "value": 2
    },
    {
      "type": "negHook",
      "value": 0.5
    }
  ],
  "canSelectPaintedSide": true,
  "isFullyCustom": true
}
    */

    if (tabName == 'xyz') {
      await this.d.initXyData();

      //DEBUG: load a specifiic XY:
      if (this.d.model.flags.dev_mode) {
        //this.editTrim(_.find(this.d.model.TrimXYGroupSet,{PictureId:'FLASHXY'}));
        //this.editTrim(_.find(this.d.model.TrimXYGroupSet,{PictureId:'RIDCV'}));
      }

    }


  }


  closeTrimEditor() {
    this.trimEditorModalRef.hide();
    this.selectedTrim = false;
  }

  async editTrim(trimInfo) {

    //let debugOutput = '\r' + '\n' + trimInfo.PictureId + '\r' + '\n' + JSON.stringify(trimInfo.TDLData, null, 2) + '\r' + '\n';
    //this.d.copyToClipboard(debugOutput);


    this.selectedTrim = false;
    //let customTrim = this.d.deref(trimInfo);

    var selectedTrim: any = {
      "OfferingID": "",
      "Itemnumber": "", //"001000",
      "Product": "", //"EAVHXY_P",
      "Materialnumber": "", //"EAVHXY_P",
      "ColorCode": "", //"CANC",
      "Gauge": "", //"26",
      "CutlistProvide": "", //"T",
      "Productname": "", //"Custom Eave with Hemmed Drip Edge",
      "Metal": "", //"ZNAL",
      "Ctwt": "", //"AZ50",
      "Grade": "", //"33",
      "PaintSystem": "", //"DTXL",
      "Girth": "", //"6.375",
      "DownhillSlope": "", //"NA",
      "UphillSlope": "", //"5",
      "Requesteddelivery": "", //"20170511",
      "Unitofmeasure": "", // "PC",
      "Quantity001": "1", //"1.000",
      "Plant": "", //"MC03"
      "metadata": this.d.deref(trimInfo)
    }
    //selectedTrim.metadata.TDLData = this.transformFlanges(selectedTrim.metadata.TDLData);
    //let debugOutput = '\r' + '\n' + trimInfo.PictureId + '\r' + '\n' + JSON.stringify(newTdl, null, 2) + '\r' + '\n';
    //this.d.copyToClipboard(debugOutput);
    //console.dir(selectedTrim.metadata.TDLData);

    selectedTrim = await this.d.retreiveTrimMetadata(selectedTrim);

    //console.dir("HERE");
    //console.dir(selectedTrim);

    selectedTrim.metadata.pricingSetResult = {
      NetPrice: '0.00',
      NetPriceTotal: '0.00',
      DiscountPct: '0.00',
      DiscountAmt: '0.00',
      ItemSubTotal: '0.00',
      ItemTotal: '0.00'
    }


    this.selectedTrim = selectedTrim;


    var hemCount = 0;
    this.selectedTrim.metadata.TDLData.flanges.forEach((f) => {
      if (this.d.isHem(f)) {
        hemCount += 1;
        let hemConfig: any = this.selectedTrim.metadata.TrimXYSet["0"].HasPOPCapability.HemConfiguration.results.find(h => h.Hems == hemCount);
        f.value = Number(hemConfig.HemLength);
        //console.log("Hem " + hemCount +": " + f.value);
      }
    });
    //console.log("HEMCOUNT: " + hemCount);

    this.trimEditorModalRef = this.d.showModal(this.trimEditorModal, 'modal-xl modal-dialog-centered');
  }




  async cfgFiltersBySrcPanel() {

    if (this.d.model.srcPanel !== undefined && this.d.model.ProfileGroupSet !== undefined) {

      let targetProfileId = this.d.model.srcPanel.metadata.product.ProfileGroupId;
      for (var profile of this.d.model.ProfileGroupSet) {
        profile.selected = profile.ProfileGroupId == targetProfileId;
      }
      await this.d.delay(200);
      this.d.scrollToElement("trim_profile_" + targetProfileId);
      this.updateGauges();
      /*
      this.selectGauge(this.d.model.srcPanel.metadata.gauge.Gauge);
      
      let targetColorId = this.d.model.srcPanel.metadata.color.BasecolorId;
      for (var color of this.d.model.BOAccessoryGetFilterColorSetFiltered) {
        color.selected = color.BasecolorId == targetColorId;
      }
      
      
      
      this.d.scrollToElement("acc_color_" + targetColorId);
      this.d.toast("Filters set from Panel");
      delete this.d.model.srcPanel;
      */
    }
  }

  toggleTrims(setState: boolean) {

    for (var color of this.d.model.TrimCategorySet) {
      color.metadata.selected = setState;
    }
  }

  toggleProfiles(setState: boolean) {

    for (var profile of this.d.model.ProfileGroupSet) {
      profile.selected = setState;
    }
    this.updateGauges();
  }


  selectProfile(profile) {

    profile.selected = !profile.selected;

    this.updateGauges();
  }

  updateGauges() {


    this.d.model.TrimGauges = [];
    this.d.model.TrimGetFilterBaseColor = this.d.model.TrimGetFilterBaseColorFiltered = [];

    let profileGroupIds = this.d.model.ProfileGroupSet.filter(p => p.selected).map(p => p.ProfileGroupId).join('|');
    if (profileGroupIds.length == 0) return;

    this.d.get("/TrimGetFilterGauge?profiles=" + profileGroupIds, {}).subscribe(result => {
      let data = result;
      data.forEach((g) => {
        g.selected = (data.length == 1);
      });
      this.d.model.TrimGauges = data;
      if (data.length == 1) this.updateColors();

      if (this.d.model.srcPanel !== undefined) {
        this.selectGauge(this.d.model.srcPanel.metadata.gauge.Gauge);
      }

    });

  }

  selectGauge(gaugeId) {

    this.d.model.TrimGauges.forEach((g) => {
      g.selected = g.GaugeId == gaugeId;
    });
    this.updateColors();
  }

  updateColors() {


    this.updateColorFilter("");

    let profileGroupIds = this.d.model.ProfileGroupSet.filter(p => p.selected).map(p => p.ProfileGroupId).join('|');
    //console.dir(this.d.model.TrimGauges);
    let selGauge: any = this.d.model.TrimGauges.find(g => g.selected);


    this.d.get("/TrimGetFilterBaseColor?profiles=" + profileGroupIds + "&gauge=" + selGauge.GaugeId, {}).subscribe(result => {
      let data = result;
      data.forEach((c) => {
        if (c.ColorId.length > 0) {
          c.ImageUrl = "/images/PaintChips/" + c.ColorId + ".gif";
        }
      });
      if (data.length == 1) data[0].selected = true;
      this.d.model.TrimGetFilterBaseColor = data;
      this.updateColorFilter("");

      if (this.d.model.srcPanel !== undefined) {
        let targetColorId = this.d.model.srcPanel.metadata.color.BasecolorId;
        for (var color of this.d.model.TrimGetFilterBaseColorFiltered) {
          color.selected = color.BasecolorId == targetColorId;
        }
        setTimeout(() => {
          this.d.scrollToElement("trim_color_" + targetColorId);
          this.d.toast("Filters set from Panel");
          delete this.d.model.srcPanel;
        }, 500);

      }


    });
  }


  toggleColors(setState: boolean) {

    for (var color of this.d.model.TrimGetFilterBaseColorFiltered) {
      color.selected = setState;
    }
  }

  clearColorFilter() {

    this.colorFilter = '';
    this.updateColorFilter('');
    this.d.focusInput("trimColorFilter");
  }


  updateColorFilter(searchTerms) {


    switch (searchTerms) {
      case "":
        this.d.model.TrimGetFilterBaseColorFiltered = this.d.model.TrimGetFilterBaseColor.filter((color) => {
          return true;
        });
        break;

      case 'is:selected':
        this.d.model.TrimGetFilterBaseColorFiltered = this.d.model.TrimGetFilterBaseColor.filter((color) => {
          return color.selected;
        });
        break;

      default:
        let aSearchTerms = searchTerms != null ? searchTerms.toLowerCase().split(" ") : [];
        let minScore = aSearchTerms.length;
        this.d.model.TrimGetFilterBaseColorFiltered = this.d.model.TrimGetFilterBaseColor.filter((color) => {
          if (minScore == 0) return true;
          let score = 0;
          aSearchTerms.forEach((searchTerm) => {
            if (color.BasecolorDescription.toLowerCase().indexOf(searchTerm) > -1) score += 1;
          });
          return (score >= minScore);
        });
    }


  }


  getSlopesDesc(trimResult) {
    if (trimResult.CombinedSlopes.results.length > 0) return "Combined";
    if (trimResult.FreeDownHillSlopes.results.length > 0) return "Up/Down";
    return "";
  }

  displayResults() {


    let profileGroupIds = this.d.model.ProfileGroupSet.filter(p => p.selected).map(p => p.ProfileGroupId);
    let TrimCatIds = this.d.model.TrimCategorySet.filter(c => c.metadata.selected).map(p => p.TrimCatId);
    let GaugeIds = this.d.model.TrimGauges.filter(g => g.selected).map(p => p.GaugeId);
    let ColorIds = this.d.model.TrimGetFilterBaseColor.filter(b => b.selected).map(p => p.BasecolorId);

    let errors = [];
    if (profileGroupIds.length == 0) { errors.push("Profile"); this.d.showFilters = true }
    if (GaugeIds.length == 0) { errors.push("Gauge"); this.d.showFilters = true }
    if (ColorIds.length == 0) { errors.push("Color"); this.d.showFilters = true }
    if (TrimCatIds.length == 0) { errors.push("Trim"); if (errors.length == 1) this.d.hideFilters(); }
    if (errors.length > 0) return this.d.toast("Please select: " + errors.join(', '));

    let query: any = {
      TrimCatId: TrimCatIds,
      ProfileGroupId: profileGroupIds,
      GaugeId: GaugeIds,
      BaseColorId: ColorIds,
      ChannelId: ['20'],
      ShippingPlant: [this.d.model.ShipTo.SHIPPINGPLANT],
      ShippingModeId: [this.d.model.ShipMode.ShippingModeId]
    }

    this.d.post("/GetTrims", {}, query).subscribe(result => {
      let data = result;
      //console.log(JSON.stringify(data));

      this.d.model.TrimResultsCount = data.length;
      if (this.d.model.TrimResultsCount == 0) return this.d.toast("No trims found, please modify your search");

      var breaks = new RegExp('<br', 'g');


      //https://sbx.steelscape.com/AscEcomContent/GetImageBySapIdProxy.aspx?contenttype=trim&id=CH16_P
      data.forEach((r) => {
        let baseColor: any = this.d.model.TrimGetFilterBaseColor.find(b => b.BasecolorId == r.BaseColorId);
        r.metadata = {
          color: {
            ColorDescription: baseColor.BasecolorDescription,
            ImageUrl: (r.ColorId ? "/cms/images/PaintChips/" + r.ColorId + ".gif" : "")
          },
          product: {
            NAME: r.TrimId,
            Productname: r.Description,
            //ImageUrl: (this.d.model.imageProxyUrl + "?contenttype=trim&id=" + r.TrimId),
            ImageUrl: "/cms/images/Trim/" + r.TrimId + ".svg",
          },
          ShowPrice: false,
          Loading: true,
          UphillSlope: {},
          DownhillSlope: {},
          CombinedSlope: {},
          Quantity: 1
        };
        r.Compatibility = r.Compatibility.replace(breaks, ',<br');
        //r.SlopesDesc = (this.getSlopesDesc(r));
      });

      //data = this.d.alphaNumObjectSort(data, 'TrimProfileId');

      //https://sbx.steelscape.com/AscEcomContent/GetImageBySapIdProxy.aspx?contenttype=trim&id=ER2_B

      //group the results by category
      let allCats = data.map((d: any) => { return { TrimProfileId: d.TrimProfileId, TrimCatId: d.TrimCatId, TrimId: d.TrimId } });
      let cats: any[] = this.d.uniqBy(allCats, 'TrimProfileId');
      //cats = this.d.alphaNumObjectSort(cats, 'TrimProfileId');
      //console.log(cats);
      let results = [];
      cats.forEach((cat) => {
        var result = {
          TrimProfileId: (cat.TrimProfileId + ' ' + cat.TrimCatId),
          Expanded: true, //(cats.length == 1),
          //ImageUrl: this.d.model.imageProxyUrl + "?contenttype=trim&id=" + cat.TrimId,
          ImageUrl: "/cms/images/Trim/" + cat.TrimId + ".svg",
          Items: data.filter(i => i.TrimProfileId == cat.TrimProfileId)
        };
        /*
        if (result.Items[0].GaugeId.length > 0){
          result.ShowGauge = true;
          result.ColumnCount += 1;
        }
        if (result.Items[0].Compatibility.length > 0){
          result.ShowCompatibility = true;
          result.ColumnCount += 1;
        }
        */
        results.push(result);
      });
      this.d.model.TrimsResults = results;

      this.trimsModalRef = this.d.showModal(this.trimsModal, 'modal-xl modal-dialog-centered');
    });

  }

}
