<style>
  #trimsvg_xy, #trimsvg_fct {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
  }

  #sizer {
    border: 1px solid black;
    /*if display:none, getBBox() returns all zeros! */
    position: absolute;
    left: -2000px;
    right: -2000px;
  }

  #sliders input {
    width: 200px;
    display: inline-block;
  }
  /*
  .trimSide:hover {
    fill-opacity: 0.75;
  }*/
  #svgContainer, #depthContainer {
    position: relative;
    width: 400px;
    height: 400px;
  }

  #trimsvg_xy, #trimsvg_fct, #trim3d {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;
    height: 400px;
    z-index: 100;
  }

  #svgZoom {
    opacity: 0.2;
    z-index: 101;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

    #svgZoom button {
      margin-left: 1px !important;
    }

  #svgTranslate {
    opacity: 0.2;
    z-index: 101;
    position: absolute;
    top: 10px;
    right: 10px;
  }

    #svgTranslate td {
      padding: 0;
    }

    #svgTranslate button {
      border-radius: 10px;
    }

    #svgZoom:hover, #svgTranslate:hover {
      opacity: 1;
    }

  .hovered {
    fill: yellow;
  }

  .focused {
    fill: #006666;
  }

  .focusedText {
    fill: white;
    stroke-width: 0;
  }

  .psi {
    cursor: help;
  }

  .hidden {
    display: none;
  }

  .depthView2 {
    width: 400px;
    height: 400px;
    border: 1px solid #dddddd;
    display: inline-block;
  }

  .trim3d {
    border: 1px solid #dddddd;
  }

  .trim-render-tabs {
    position: relative;
    top: -1px;
    z-index: 102;
  }

    .trim-render-tabs li {
      width: 125px;
    }
</style>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="sizer" width="1000" height="1000">
  <g id="sizergroup" transform="translate(500,500)">
  </g>
</svg>
<ng-container *ngIf="tdlParser.model">





  <!--
    <div>
      <div><b>Viewbox:</b> {{tdlParser.model.viewBox}}</div>
      <div><b>Rect:</b> x:{{tdlParser.model.rectInfo.x}} y:{{tdlParser.model.rectInfo.y}} w:{{tdlParser.model.rectInfo.w}} h:{{tdlParser.model.rectInfo.h}}</div>
      <div><b>Transform:</b> {{getTransform()}}</div>
    </div>
  -->
  <div id="depthContainer" *ngIf="tdlParser.model.view=='3d'||tdlParser.model.view=='hd3d'">
    <div style="text-align:center;position:relative;top:180px">
      <fa-icon [icon]="d.faCog" [spin]="true" [fixedWidth]="true" size="3x" style="color:rgb(153, 194, 191)"></fa-icon>
      <span class="sr-only">Loading...</span>
    </div>

    <iframe id="trim3d" [attr.src]="tdlParser.model.depthUrl|safeurl" class="depthView" scrolling="no" style="border:1px solid #dddddd"></iframe>
  </div>


  <div id="svgContainer" [class.hidden]="tdlParser.model.view!='diagram'">



    <svg [attr.id]="renderid" #renderedSvg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0,0,1000,1000"
         (dblclick)="tdlParser.handleDblClick()"
         (click)="tdlParser.addLength($event,true)"
         (contextmenu)="tdlParser.contextMenu($event)"
         (mousedown)="tdlParser.startMove($event)"
         (mouseup)="tdlParser.endMove($event)"
         (wheel)="tdlParser.wheelZoom($event)"
         (mousemove)="tdlParser.mouseMove($event)"
         (mouseleave)="tdlParser.endMove($event,true)">

      <!--
           <rect x="0" y="0" width="1000" height="1000"
                 stroke="yellow" fill="yellow" opacity="0.25" />

           <circle [attr.cx]="tdlParser.model.translate.x" [attr.cy]="tdlParser.model.translate.y" fill="blue" [attr.r]="10" />
      -->
      <g [attr.transform]="tdlParser.getTransform()">

        <!--
        <rect x="0" y="0" width="1000" height="1000"
              stroke="blue"  fill="blue" opacity="0.25"/>
          -->
        <!-- angle indicators -->
        <path class="arc" *ngFor="let arc of tdlParser.model.arcs" [attr.d]="arc" stroke="black" fill="black" fill-opacity="0.1" [attr.stroke-width]="tdlParser.model.fontProps.fontSize/20" />

        <!--<div class="compass"></div> -->
        <!--
         <rect [attr.x]="tdlParser.model.rectInfo.x" [attr.y]="tdlParser.model.rectInfo.y" [attr.width]="tdlParser.model.rectInfo.w" [attr.height]="tdlParser.model.rectInfo.h"
               stroke="black" fill-opacity="0.25">
         </rect>
        -->
        <path class="dirArrow" [attr.d]="tdlParser.model.arrowPath" fill="#cccccc"/>

        <path class="trimSide" [attr.d]="tdlParser.model.lPath" [attr.fill]="tdlParser.model.lColor" />
        <path class="trimSide" [attr.d]="tdlParser.model.rPath" [attr.fill]="tdlParser.model.rColor" />
        
        <path *ngIf="tdlParser.model.showLabels" [attr.d]="tdlParser.model.rPathCmds" stroke="black" [attr.stroke-width]="tdlParser.model.fontProps.fontSize/40" fill="transparent" />
        <path *ngIf="tdlParser.model.showLabels" [attr.d]="tdlParser.model.lPathCmds" stroke="black" [attr.stroke-width]="tdlParser.model.fontProps.fontSize/40" fill="transparent" />
        
        <!--


                              <path [attr.d]="tdlParser.model.mPath" stroke="red" fill="transparent" />


                            <circle *ngFor="let point of tdlParser.model.lPoints; let first = first; let last = last"
                                    [attr.cx]="point.x" [attr.cy]="point.y" fill="green" [attr.r]="first ? 5 : 2" />}

                      <circle *ngFor="let point of tdlParser.model.mPoints; let first = first; let last = last" [attr.id]="point.id"
                             [attr.cx]="point.x" [attr.cy]="point.y" fill="#006666" [attr.r]="first ? 5 : 2" />
               <circle *ngFor="let point of tdlParser.model.rPoints; let first = first; let last = last" fill-opacity="0.5"
                       [attr.cx]="point.x" [attr.cy]="point.y" fill="orange"  [attr.r]="first ? 5 : 2" />}
        -->
        <rect *ngFor="let rect of tdlParser.model.rects" [attr.x]="rect.x" [attr.y]="rect.y" [attr.width]="rect.w" [attr.height]="rect.h"
              stroke="black" fill-opacity="0.25">
          <title>x:{{rect.x}},y:{{rect.y}} w:{{rect.w}},h:{{rect.h}}</title>
        </rect>
        <line *ngFor="let line of tdlParser.model.lines" stroke-linecap="round" 
              [attr.x1]="line.pt1.x" [attr.y1]="line.pt1.y" [attr.x2]="line.pt2.x" [attr.y2]="line.pt2.y"
              [attr.stroke-width]="tdlParser.model.fontProps.fontSize/20" stroke="black" />
        <g *ngIf="tdlParser.model.punchIntersections.length > 0">
          <line *ngFor="let line of tdlParser.model.punchLines" stroke-linecap="round"
                [attr.x1]="line.pt1.x" [attr.y1]="line.pt1.y" [attr.x2]="line.pt2.x" [attr.y2]="line.pt2.y"
                [attr.stroke-width]="tdlParser.model.fontProps.fontSize/20" stroke="blue" />
        </g>
        <!--
                <rect *ngFor="let text of tdlParser.model.texts" stroke="black" stroke-width="1" [attr.id]="text.rectid" [attr.x]="text.x" [attr.y]="text.y-text.h/2" [attr.width]="text.w" [attr.height]="text.h" fill="white" fill-opacity="0.6"></rect>

                <text *ngFor="let text of tdlParser.model.texts" [attr.id]="text.lblid" [attr.x]="text.x" [attr.y]="text.y+text.h/3"
                      [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                      [attr.font-stretch]="tdlParser.model.fontProps.fontStretch"
                      [attr.font-size]="tdlParser.model.fontProps.fontSize"
                      font-weight="900"
                      fill="white"
                      alignment-baseline="baseline">{{text.text}}</text>
        -->
        <!--
                <text *ngFor="let text of tdlParser.model.texts" [attr.id]="text.lblid" [attr.x]="text.x" [attr.y]="text.y+text.h/3"
                      [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                      [attr.font-stretch]="tdlParser.model.fontProps.fontStretch"
                      [attr.font-size]="tdlParser.model.fontProps.fontSize"
                      font-weight="700"
                      fill="black"
                      stroke="white"
                      [attr.stroke-width]="tdlParser.model.fontProps.fontSize/40"
                      alignment-baseline="baseline"
                      >{{text.text}}</text>
        -->

        <g *ngFor="let flange of customtrim.metadata.TDLData.flanges">
          <g *ngIf="flange.metadata?.text">
            <rect *ngIf="flange.metadata?.focused || flange.metadata?.hovered" stroke="black"
                  stroke-width="0"
                  [attr.x]="flange.metadata.text.x"
                  [attr.y]="flange.metadata.text.y-flange.metadata.text.h/2"
                  [attr.width]="flange.metadata.text.w"
                  [attr.height]="flange.metadata.text.h"
                  [class.hovered]="flange.metadata?.hovered"
                  [class.focused]="flange.metadata?.focused"
                  fill-opacity="0.6"></rect>

            <text *ngIf="tdlParser.model.showLabels" [attr.x]="flange.metadata.text.x"
                  [attr.y]="flange.metadata.text.y+flange.metadata.text.h/3"
                  [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                  [attr.font-stretch]="tdlParser.model.fontProps.fontStretch"
                  [attr.font-size]="tdlParser.model.fontProps.fontSize"
                  font-weight="700"
                  fill="black"
                  stroke="white"
                  [class.focusedText]="flange.metadata?.focused"
                  [attr.stroke-width]="tdlParser.model.fontProps.fontSize/40"
                  alignment-baseline="baseline">{{flange.metadata.text.text}}</text>

          </g>
        </g>

        <g *ngFor="let intersection of tdlParser.model.selfIntersections">
          
          <circle 
                  fill="red"
                  fill-opacity="0.7"
                  [attr.cx]="intersection.point.x" 
                  [attr.cy]="intersection.point.y" 
                  [attr.r]="tdlParser.model.fontProps.fontSize" />
           
          
        </g>

        <g *ngFor="let intersection of tdlParser.model.punchIntersections">

          <circle fill="blue"
                  fill-opacity="0.7"
                  [attr.cx]="intersection.point.x"
                  [attr.cy]="intersection.point.y"
                  [attr.r]="tdlParser.model.fontProps.fontSize" />


        </g>
















        <!--
              <circle *ngFor="let text of tdlParser.model.texts"
                      [attr.cx]="text.x" [attr.cy]="text.y" fill="blue" [attr.r]="3" />

                 <g *ngFor="let text of tdlParser.model.texts" [attr.transform]="translateFromPoint(text)">
                   <text  [attr.x]="0" [attr.y]="0" dy="0.35em"
                         [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                         [attr.font-stretch]="tdlParser.model.fontProps.fontStretch"
                         [attr.font-size]="tdlParser.model.fontProps.fontSize">
                     <tspan x="0" dy="1.2em">{{text.text}}</tspan>
                     <tspan x="0" dy="1.2em">{{text.text}}</tspan>
                   </text>
                 </g>-->
        <!--
                <circle *ngFor="let point of tdlParser.model.debugPoints"
                        [attr.cx]="point.x" [attr.cy]="point.y" fill="red" [attr.r]="10" />

                         <circle [attr.cx]="tdlParser.model.minpoint.x" [attr.cy]="tdlParser.model.minpoint.y" fill="blue" r="30"></circle>
                         <circle [attr.cx]="tdlParser.model.maxpoint.x" [attr.cy]="tdlParser.model.maxpoint.y" fill="green" r="30"></circle>

        -->

        <g *ngIf="customtrim.metadata.materialType=='P'" class="psi" [attr.transform]="this.tdlParser.model.pTranslate">
          <title>Painted Side Indicator</title>
          <g transform="scale(1) translate(0,16.5)">
            <path transform="translate(-12,-17) " d="M11.9,0c2.1,2.1,4.2,4.2,6.3,6.2c1.8,1.8,3.7,3.5,4.7,5.9c2,4.6,0.8,10.1-2.9,13.5c-3.8,3.5-9.5,4.1-14,1.6
	c-4.5-2.6-6.8-7.9-5.7-12.9c0.7-3,2.5-5.1,4.6-7.3C7.2,4.7,9.6,2.4,11.9,0z" fill="black" />
            <circle [attr.cx]="0" [attr.cy]="0" fill="white" r="10"></circle>
            <text [attr.transform]="'rotate('+(-tdlParser.model.pAngle-90)+') translate(-4.5,5.5)'" [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                  font-size="18"
                  fill="black" stroke="black" stroke-with="5" font-weight="900">P</text>
          </g>
        </g>

        <!--
        <circle [attr.cx]="tdlParser.model.pPoint.x" [attr.cy]="tdlParser.model.pPoint.y" fill="red" r="3" />
        -->
        
        
        <g *ngFor="let hem of this.tdlParser.model.closedHems" [attr.transform]="'translate('+hem.x+','+hem.y+')'">
          <g [attr.transform]="'rotate('+hem.angle+')'">
            <g [attr.transform]="'scale('+hem.scale+') translate(-556,-7.8)'">
              <path d="M103.86,298.39a6.22,6.22,0,0,1,1.68-2,4.46,4.46,0,0,1,2.14-.85,4.29,4.29,0,0,1,3.49,1c1,.83,1.59,2.37,1.85,4.61l.92,7.9c.25,2.24,0,3.89-.74,4.94a4.33,4.33,0,0,1-3.17,1.81,4.25,4.25,0,0,1-3.12-.69,7.3,7.3,0,0,1-2.13-3.71,5.71,5.71,0,0,0-1.61-2.8A13,13,0,0,0,97.59,306a19.9,19.9,0,0,0-6.84-.45,16.61,16.61,0,0,0-7.46,2.71,16.87,16.87,0,0,0-5.48,6.62,17.48,17.48,0,0,0-1.56,10l.7,6a14.73,14.73,0,0,0,6.19,10.65q5.4,3.92,14.06,2.92a24.12,24.12,0,0,0,8.6-2.43,13.38,13.38,0,0,0,4.08-3.74,10.63,10.63,0,0,1,2.05-2.14,3.82,3.82,0,0,1,1.81-.65,4.82,4.82,0,0,1,5.22,4.17,6.47,6.47,0,0,1-1.43,4.31,19.05,19.05,0,0,1-6.55,5.82,33.19,33.19,0,0,1-12.65,3.95,32.25,32.25,0,0,1-15.28-1.67,23.86,23.86,0,0,1-10.13-7.54,23.54,23.54,0,0,1-5.25-12.39l-.73-6.3a28.36,28.36,0,0,1,1.8-13.95A24.17,24.17,0,0,1,77,301.14a25.47,25.47,0,0,1,12.31-4.88,29.73,29.73,0,0,1,7.67.06A23.41,23.41,0,0,1,103.86,298.39Z" transform="translate(-27.15 -246.49)" />
              <path d="M142,301l4.22,36.41,20.07-2.33-1-8.81c-.25-2.24,0-3.88.74-4.94a4.41,4.41,0,0,1,3.22-1.82,4.32,4.32,0,0,1,3.5,1c1,.85,1.59,2.4,1.85,4.64l2.1,18.11-44.07,5.12c-2.24.26-3.88,0-4.94-.74a4.7,4.7,0,0,1-.78-6.72q1.27-1.45,4.64-1.85l5.41-.62-4.23-36.41-5.41.63c-2.24.26-3.88,0-4.93-.74a4.73,4.73,0,0,1-.79-6.76q1.28-1.45,4.63-1.85l20.17-2.3q3.36-.39,4.93.72A4.26,4.26,0,0,1,153.1,295a4.35,4.35,0,0,1-1,3.55q-1.27,1.46-4.63,1.85Z" transform="translate(-27.15 -246.49)" />
              <path d="M229.88,309.24q1.44,12.39-5.48,21.55t-17.27,10.35a24.39,24.39,0,0,1-17.58-4.9q-10.25-7.41-11.82-20.94t6.7-23.13a24.37,24.37,0,0,1,16-8.81q10.35-1.2,19.19,6.12T229.88,309.24Zm-9.31,1.08a20.06,20.06,0,0,0-6.76-13.46q-5.76-5-12.31-4.2c-4.32.5-8,2.81-11,6.93a21.86,21.86,0,0,0,3.26,28c3.84,3.3,7.93,4.71,12.26,4.2s8-2.8,11-6.89A20,20,0,0,0,220.57,310.32Z" transform="translate(-27.15 -246.49)" />
              <path d="M248.87,332.52a8.2,8.2,0,0,1-1.76,2.09,3.77,3.77,0,0,1-1.79.62,4.45,4.45,0,0,1-3.6-1c-1-.85-1.58-2.39-1.83-4.59l-.73-6.26q-.39-3.36.73-4.94a4.86,4.86,0,0,1,6.11-1.29,5.52,5.52,0,0,1,2,2.69,7.83,7.83,0,0,0,1.54,2.6,14.5,14.5,0,0,0,5,2.32,18.58,18.58,0,0,0,7.52.6,17.11,17.11,0,0,0,9.88-4.1,5.22,5.22,0,0,0,2-4.65,5.12,5.12,0,0,0-1.6-3.1,9,9,0,0,0-4.28-2.06,63.93,63.93,0,0,0-8.35-.92,44.08,44.08,0,0,1-11.93-2.06,15.52,15.52,0,0,1-6.82-4.91,14.71,14.71,0,0,1-3.19-7.59q-.76-6.68,4.23-12.33T256,276.9a27.08,27.08,0,0,1,6.74,0,19.09,19.09,0,0,1,5.84,1.78,5.29,5.29,0,0,1,3.43-2.19,4.29,4.29,0,0,1,3.5,1.05q1.45,1.28,1.85,4.59l.81,7c.26,2.24,0,3.88-.74,4.94a4.36,4.36,0,0,1-3.17,1.82,4.62,4.62,0,0,1-3.12-.7,5.25,5.25,0,0,1-1.85-2.91,8.77,8.77,0,0,0-1.66-3.17,10.09,10.09,0,0,0-4.28-2.45,14.9,14.9,0,0,0-6.23-.47,12.51,12.51,0,0,0-7.69,3.26q-2.66,2.69-2.36,5.22a5.51,5.51,0,0,0,1.59,3.2A7.88,7.88,0,0,0,252.5,300a68,68,0,0,0,8.93,1,56.18,56.18,0,0,1,11.34,1.81,15.34,15.34,0,0,1,7,4.53,14.45,14.45,0,0,1,3.53,8.24,13.84,13.84,0,0,1-3.57,11.59q-5.82,6.19-15.8,7.36a30.81,30.81,0,0,1-7.62-.06A31.53,31.53,0,0,1,248.87,332.52Z" transform="translate(-27.15 -246.49)" />
              <path d="M306.3,305l1.59,13.66,23.74-2.76-.59-5q-.39-3.36.74-4.93a4.68,4.68,0,0,1,6.72-.78q1.46,1.28,1.85,4.63L342,324.1l-43.81,5.08c-2.24.26-3.88,0-4.94-.74a4.67,4.67,0,0,1-.76-6.72q1.31-1.46,4.62-1.84l1.45-.17-4.22-36.4-1.46.17c-2.24.25-3.88,0-4.94-.74a4.75,4.75,0,0,1-.78-6.77c.86-1,2.39-1.59,4.63-1.85l41.77-4.8,1.64,14.16q.39,3.31-.72,4.89a4.29,4.29,0,0,1-3.19,1.82,4.43,4.43,0,0,1-3.54-1q-1.47-1.26-1.84-4.62l-.57-4.85-21.7,2.52,1.56,13.43,8.17-1c-.28-2.42-.24-4,.14-4.75a4.51,4.51,0,0,1,3.74-2.69,4.33,4.33,0,0,1,3.5,1q1.46,1.28,1.84,4.64l1.25,10.71c.24,2,.15,3.42-.25,4.17a4.7,4.7,0,0,1-7.91.83c-.54-.64-.94-2.19-1.23-4.64Z" transform="translate(-27.15 -246.49)" />
              <path d="M352.41,313.46l-4.22-36.4a6.46,6.46,0,0,1-4.51-.84,4.71,4.71,0,0,1-.76-6.72c.85-1,2.4-1.59,4.63-1.85L367,265.43a24.68,24.68,0,0,1,9.07.65,20.43,20.43,0,0,1,7.45,3.46,30,30,0,0,1,4.78,4.87,30.67,30.67,0,0,1,4,6.39,27.31,27.31,0,0,1,2.05,8.09l.51,4.45a26.16,26.16,0,0,1-.78,10.7,21.34,21.34,0,0,1-4.38,8,26.86,26.86,0,0,1-5.61,5,28,28,0,0,1-11.51,3.5L354,322.71q-3.36.39-4.94-.74a4.72,4.72,0,0,1-.78-6.74A6.08,6.08,0,0,1,352.41,313.46Zm9.31-1.08,9.89-1.15c3.7-.43,6.42-1.28,8.18-2.56a14.61,14.61,0,0,0,4.92-5.95,16.78,16.78,0,0,0,.89-8.57l-.51-4.41a17.18,17.18,0,0,0-2.58-7.52c-2.15-3.24-4.41-5.38-6.75-6.41a16.11,16.11,0,0,0-8.46-1L357.49,276Z" transform="translate(-27.15 -246.49)" />
              <path d="M111.38,401.59,91.5,403.9l1.57,13.48,1.45-.17q3.36-.39,4.94.74a4.37,4.37,0,0,1,1.82,3.21,4.32,4.32,0,0,1-1,3.51q-1.27,1.45-4.64,1.85l-11.39,1.32q-3.36.39-4.94-.74a4.37,4.37,0,0,1-1.82-3.21,4.45,4.45,0,0,1,1.76-4.21c.67-.57,2.19-1,4.55-1.22l-4.23-36.4A5.73,5.73,0,0,1,75.67,381a4.69,4.69,0,0,1-.58-6.58c.84-1,2.39-1.59,4.63-1.85l9.49-1.06q3.36-.39,4.94.72a4.71,4.71,0,0,1,.78,6.74q-1.28,1.46-4.64,1.85l-1.45.17,1.59,13.61,19.87-2.31-1.58-13.61-1.46.17c-2.23.25-3.88,0-4.93-.74a4.75,4.75,0,0,1-.79-6.76c.86-1,2.4-1.59,4.64-1.85l9.5-1.06q3.36-.39,4.93.71a4.32,4.32,0,0,1,1.83,3.2,4.4,4.4,0,0,1-.89,3.32,5.77,5.77,0,0,1-3.52,1.93l4.23,36.4c2.38-.28,3.95-.23,4.7.14a4.5,4.5,0,0,1,2.69,3.74,4.32,4.32,0,0,1-1,3.5q-1.28,1.45-4.64,1.85l-11.4,1.33c-2.24.25-3.88,0-4.93-.74a4.41,4.41,0,0,1-1.82-3.22,4.3,4.3,0,0,1,1.06-3.5q1.31-1.47,4.62-1.85l1.45-.17Z" transform="translate(-27.15 -246.49)" />
              <path d="M147.4,397.22,149,410.89l23.74-2.76-.59-5c-.25-2.24,0-3.88.74-4.94a4.39,4.39,0,0,1,3.22-1.82,4.32,4.32,0,0,1,3.5,1c1,.86,1.59,2.4,1.85,4.64l1.66,14.34-43.8,5.09q-3.36.39-4.93-.74a4.35,4.35,0,0,1-1.83-3.21,4.29,4.29,0,0,1,1.07-3.51c.86-1,2.4-1.59,4.61-1.84l1.46-.18-4.23-36.4-1.46.17q-3.36.39-4.93-.73a4.74,4.74,0,0,1-.79-6.77c.85-1,2.4-1.59,4.63-1.85l41.78-4.8,1.64,14.16q.38,3.31-.72,4.89a4.33,4.33,0,0,1-3.19,1.82,4.41,4.41,0,0,1-3.55-1c-1-.83-1.58-2.37-1.85-4.61l-.56-4.86-21.7,2.52,1.56,13.44,8.17-.95c-.28-2.42-.23-4,.15-4.75a4.47,4.47,0,0,1,3.73-2.69,4.32,4.32,0,0,1,3.5,1q1.45,1.28,1.84,4.64L165,395.92c.24,2,.15,3.42-.25,4.17a4.55,4.55,0,0,1-3.65,2.59,4.49,4.49,0,0,1-4.25-1.76c-.54-.65-.95-2.19-1.24-4.64Z" transform="translate(-27.15 -246.49)" />
              <path d="M196.56,377.25,199.78,405l3.36-.39q3.36-.39,4.94.73a4.39,4.39,0,0,1,1.82,3.22,4.32,4.32,0,0,1-1,3.5q-1.28,1.45-4.64,1.85L192,415.32q-3.36.39-4.94-.74a4.71,4.71,0,0,1-.8-6.71,6.29,6.29,0,0,1,4.21-1.8l-4.23-36.41a5.75,5.75,0,0,1-3.87-1.06,4.73,4.73,0,0,1-.59-6.58c.86-1,2.4-1.59,4.64-1.85L196.65,359l15.92,26.53,9.18-29.49,10.31-1.16q3.36-.39,4.93.72a4.26,4.26,0,0,1,1.82,3.19,4.38,4.38,0,0,1-.89,3.33,5.85,5.85,0,0,1-3.52,1.92l4.24,36.41a6.2,6.2,0,0,1,4.51.81,4.44,4.44,0,0,1,1.8,3.19,4.32,4.32,0,0,1-1,3.5c-.85,1-2.4,1.59-4.64,1.85L227,411.26c-2.22.26-3.85,0-4.9-.75a4.37,4.37,0,0,1-1.82-3.21,4.29,4.29,0,0,1,1.05-3.5c.85-1,2.38-1.59,4.59-1.85l3.4-.4-3.22-27.73L218.23,399l-8.08.94Z" transform="translate(-27.15 -246.49)" />
              <path d="M140.94,246.49c-62.07,0-114,52.25-113.79,114.24C27,422.6,78.63,474.28,140.51,474.08a112.05,112.05,0,0,0,56.55-15.58c.38-.22.75-.45,1.12-.68l.09.14,53.93-31.08L432.27,323.09l8.32-4.79,13.53-7.8,55.46-32-3.9-6.76L501.79,265l-55.36,31.9-13.57,7.82h0l-.59.34L238.12,417,221,426.88,191.28,444c-17,8.86-32.56,14.41-50.77,14.49a98.3,98.3,0,0,1-97.76-97.75A100,100,0,0,1,66.9,296h0a97.93,97.93,0,0,1,74-33.88H587.4v-15.6" transform="translate(-27.15 -246.49)" />
            </g>
          </g>
        </g>
        
        <g *ngFor="let hem of this.tdlParser.model.openHooks" [attr.transform]="'translate('+hem.x+','+hem.y+')'">
          <g [attr.transform]="'rotate('+hem.angle+')'">
            <g [attr.transform]="'scale('+hem.scale+') translate(-448.69,-7.8)'">
              <path d="M264.48,299.3q0,12.48-7.93,20.78t-18.36,8.29a24.43,24.43,0,0,1-16.91-6.9Q212,312.92,212,299.3t9.33-22.22a24.43,24.43,0,0,1,16.91-6.9q10.42,0,18.36,8.3T264.48,299.3Zm-9.37,0a20.07,20.07,0,0,0-5.17-14.15q-5.16-5.59-11.75-5.6t-11.7,5.62a21.85,21.85,0,0,0,0,28.23q5.16,5.6,11.7,5.6t11.75-5.58A20,20,0,0,0,255.11,299.3Z" transform="translate(-144.15 -246.49)" />
              <path d="M288.93,308v9.65h9.47q3.38,0,4.82,1.3a4.39,4.39,0,0,1,1.44,3.41,4.33,4.33,0,0,1-1.44,3.36q-1.44,1.31-4.82,1.3H278.1c-2.26,0-3.86-.43-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.28,4.28,0,0,1,1.46-3.36q1.45-1.31,4.8-1.3h1.46V281H278.1c-2.26,0-3.86-.44-4.82-1.3a4.76,4.76,0,0,1,0-6.82c1-.86,2.56-1.3,4.82-1.3l21.58.05q9.49,0,15.06,5.19a16.63,16.63,0,0,1,5.55,12.63,16.81,16.81,0,0,1-1.87,7.78,17.72,17.72,0,0,1-4.73,5.41A22,22,0,0,1,297.8,308Zm0-9.37h8.69q6.12,0,9.71-2.76a8.17,8.17,0,0,0,3.59-6.65,7.38,7.38,0,0,0-2.9-5.76c-1.94-1.65-4.7-2.47-8.3-2.47H288.93Z" transform="translate(-144.15 -246.49)" />
              <path d="M340.91,303.87v13.76h23.91v-5.07q0-3.39,1.3-4.83a4.38,4.38,0,0,1,3.41-1.44,4.29,4.29,0,0,1,3.35,1.44q1.31,1.44,1.31,4.83V327H330.07q-3.37,0-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.29,4.29,0,0,1,1.47-3.36q1.46-1.31,4.79-1.3h1.47V281h-1.47c-2.25,0-3.86-.44-4.82-1.3a4.76,4.76,0,0,1,0-6.82c1-.86,2.57-1.3,4.82-1.3l42.06.05v14.26c0,2.22-.43,3.82-1.28,4.78a4.3,4.3,0,0,1-3.38,1.44,4.44,4.44,0,0,1-3.41-1.42q-1.31-1.43-1.3-4.8V281H340.91V294.5h8.23c0-2.44.23-4,.68-4.71a4.52,4.52,0,0,1,4-2.24,4.33,4.33,0,0,1,3.36,1.44c.86,1,1.3,2.57,1.3,4.82V304.6a8,8,0,0,1-.73,4.12,4.71,4.71,0,0,1-8-.1c-.45-.7-.68-2.28-.68-4.75Z" transform="translate(-144.15 -246.49)" />
              <path d="M395,289.29v28.34h3.38q3.38,0,4.82,1.3a4.39,4.39,0,0,1,1.44,3.41,4.33,4.33,0,0,1-1.44,3.36q-1.44,1.31-4.82,1.3h-12.3q-3.37,0-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.34,4.34,0,0,1,1.42-3.33,6.22,6.22,0,0,1,4.39-1.33V281h-1.47c-2.25,0-3.86-.44-4.82-1.3a4.76,4.76,0,0,1,0-6.82c1-.86,2.57-1.3,4.82-1.3l10.84.05,23.95,37.57V281h-3.38c-2.26,0-3.86-.44-4.82-1.3a4.76,4.76,0,0,1,0-6.82c1-.86,2.56-1.3,4.82-1.3l12.29.05c2.26,0,3.87.42,4.83,1.28a4.3,4.3,0,0,1,1.44,3.38,4.44,4.44,0,0,1-1.42,3.38,6.13,6.13,0,0,1-4.34,1.33v46h-9.28Z" transform="translate(-144.15 -246.49)" />
              <path d="M248.43,377.39h-20V391h1.46q3.39,0,4.83,1.3a4.4,4.4,0,0,1,1.43,3.41A4.35,4.35,0,0,1,234.7,399q-1.44,1.31-4.83,1.3H218.4c-2.26,0-3.86-.43-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.45,4.45,0,0,1,2.24-4c.73-.48,2.28-.71,4.66-.68V354.31a5.85,5.85,0,0,1-3.73-1.51,4.74,4.74,0,0,1,.19-6.61c1-.87,2.56-1.3,4.82-1.3l9.55.05c2.26,0,3.87.42,4.83,1.28a4.32,4.32,0,0,1,1.43,3.38,4.38,4.38,0,0,1-1.43,3.4q-1.44,1.31-4.83,1.31h-1.46V368h20V354.31H247c-2.26,0-3.86-.44-4.82-1.31a4.75,4.75,0,0,1,0-6.81c1-.87,2.56-1.3,4.82-1.3l9.55.05c2.26,0,3.87.42,4.83,1.28a4.3,4.3,0,0,1,1.44,3.38,4.39,4.39,0,0,1-1.26,3.2,5.85,5.85,0,0,1-3.73,1.51V391c2.41,0,4,.23,4.67.68a4.52,4.52,0,0,1,2.24,4,4.33,4.33,0,0,1-1.44,3.36q-1.44,1.31-4.83,1.3H247c-2.26,0-3.86-.43-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.28,4.28,0,0,1,1.46-3.36q1.46-1.31,4.8-1.3h1.46Z" transform="translate(-144.15 -246.49)" />
              <path d="M320.66,372.64q0,12.48-7.93,20.77t-18.36,8.3a24.45,24.45,0,0,1-16.91-6.9q-9.33-8.55-9.33-22.17t9.33-22.22a24.45,24.45,0,0,1,16.91-6.9q10.42,0,18.36,8.3T320.66,372.64Zm-9.37,0a20,20,0,0,0-5.17-14.15q-5.16-5.6-11.75-5.6c-4.35,0-8.26,1.87-11.7,5.62a21.89,21.89,0,0,0,0,28.23c3.44,3.73,7.35,5.6,11.7,5.6s8.31-1.86,11.75-5.58A19.94,19.94,0,0,0,311.29,372.64Z" transform="translate(-144.15 -246.49)" />
              <path d="M376.84,372.64q0,12.48-7.93,20.77t-18.36,8.3a24.45,24.45,0,0,1-16.91-6.9q-9.33-8.55-9.32-22.17t9.32-22.22a24.45,24.45,0,0,1,16.91-6.9q10.43,0,18.36,8.3T376.84,372.64Zm-9.37,0a20,20,0,0,0-5.17-14.15q-5.16-5.6-11.75-5.6t-11.7,5.62a21.89,21.89,0,0,0,0,28.23q5.17,5.6,11.7,5.6t11.75-5.58A19.94,19.94,0,0,0,367.47,372.64Z" transform="translate(-144.15 -246.49)" />
              <path d="M397,380.59V391h3.39q3.38,0,4.82,1.3a4.38,4.38,0,0,1,1.44,3.41,4.33,4.33,0,0,1-1.44,3.36q-1.44,1.31-4.82,1.3H386.12c-2.26,0-3.86-.43-4.82-1.3a4.39,4.39,0,0,1-1.44-3.41,4.28,4.28,0,0,1,1.46-3.36q1.46-1.31,4.8-1.3h1.46V354.31h-1.46c-2.26,0-3.86-.44-4.82-1.31a4.75,4.75,0,0,1,0-6.81c1-.87,2.56-1.3,4.82-1.3l14.22.05c2.25,0,3.86.42,4.82,1.28a4.3,4.3,0,0,1,1.44,3.38,4.35,4.35,0,0,1-1.44,3.4q-1.44,1.31-4.82,1.31H397V368.2l16.64-14.3a7.51,7.51,0,0,1-2-2,4.35,4.35,0,0,1-.55-2.24,4.49,4.49,0,0,1,1.44-3.47q1.44-1.34,4.82-1.33l9.56.05c2.25,0,3.86.42,4.82,1.28a4.3,4.3,0,0,1,1.44,3.38,4.39,4.39,0,0,1-1.44,3.38,6.5,6.5,0,0,1-4.5,1.33L410.35,369a29.76,29.76,0,0,1,8.84,8.22A58.09,58.09,0,0,1,426.53,391H429q3.34,0,4.78,1.3a4.72,4.72,0,0,1,0,6.77q-1.41,1.31-4.8,1.3h-8.5a141.89,141.89,0,0,0-6.4-13.35,30.44,30.44,0,0,0-5.37-7.52,19.37,19.37,0,0,0-6.06-3.77Z" transform="translate(-144.15 -246.49)" />
              <line x1="448.69" x2="448.69" y2="15.6" />
              <path d="M444.38,246.49H235.29c-49.55,0-90.68,39.56-91.14,89.11a90,90,0,0,0,89.94,90.77H444.33a0,0,0,0,0,.05-.05v-15.5a0,0,0,0,0-.05-.05H235.12c-41.74,0-76.09-33.94-75.36-75.67a74.44,74.44,0,0,1,74.33-73h358.7a0,0,0,0,0,.05,0v-15.5a0,0,0,0,0-.05,0Z" transform="translate(-144.15 -246.49)" />
            </g>
          </g>
        </g>


      </g>
      <!--
          <g *ngIf="customtrim.metadata.materialType=='P'" [attr.transform]="this.tdlParser.model.pTranslate">
            <title>Painted Side Indicator</title>
            <path transform="translate(-12,-17) " d="M11.9,0c2.1,2.1,4.2,4.2,6.3,6.2c1.8,1.8,3.7,3.5,4.7,5.9c2,4.6,0.8,10.1-2.9,13.5c-3.8,3.5-9.5,4.1-14,1.6
      c-4.5-2.6-6.8-7.9-5.7-12.9c0.7-3,2.5-5.1,4.6-7.3C7.2,4.7,9.6,2.4,11.9,0z" fill="black" />
            <circle [attr.cx]="0" [attr.cy]="0" fill="white" r="10"></circle>
            <text [attr.transform]="'rotate('+(-tdlParser.model.pAngle-90)+') translate(-4.5,5.5)'"  [attr.font-family]="tdlParser.model.fontProps.fontFamily"
                  font-size="18"
                  fill="black" stroke="black" stroke-with="5" font-weight="900" >P</text>
          </g>


          <line x1="0" y1="0" x2="1000" y2="1000" style="stroke:rgb(255,0,0);stroke-width:2" />
          <line x1="1000" y1="0" x2="0" y2="1000" style="stroke:rgb(255,0,0);stroke-width:2" />

        <circle [attr.cx]="tdlParser.model.translate.x" [attr.cy]="tdlParser.model.translate.y" fill="red" [attr.r]="20">
          <title>G x:{{tdlParser.model.translate.x}} y:{{tdlParser.model.translate.y}}</title>
        </circle>
          -->




    </svg>

    <div class="btn-group" role="group" id="svgZoom">
      <button title="Zoom Out (Wheel Down)" type="button" class="btn btn-secondary iconBtn" (click)="tdlParser.svgZoomOut()"><fa-icon [icon]="d.faMinus"></fa-icon></button>
      <button title="Zoom Reset" type="button" class="btn btn-secondary iconBtn" (click)="tdlParser.resetScaleZoom()"><fa-icon [icon]="d.faExpand"></fa-icon></button>
      <button title="Zoom In (Wheel Up)" type="button" class="btn btn-secondary iconBtn" (click)="tdlParser.svgZoomIn()"><fa-icon [icon]="d.faPlus"></fa-icon></button>
    </div>

    <div id="svgTranslate">
      <table>
        <tr>
          <td>&nbsp;</td>
          <td><button title="Look Up (Right-Click Drag)" type="button" class="btn btn-xs btn-secondary iconBtn" (click)="tdlParser.svgTranslate(0,50)"><td><fa-icon [icon]="d.faCaretUp"></fa-icon></td></button></td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td><button title="Look Left (Right-Click Drag)" type="button" class="btn btn-xs btn-secondary iconBtn" (click)="tdlParser.svgTranslate(50,0)"><fa-icon [icon]="d.faCaretLeft"></fa-icon></button></td>
          <td><fa-icon [icon]="d.faEye"></fa-icon></td>
          <td><button title="Look Right (Right-Click Drag)" type="button" class="btn btn-xs btn-secondary iconBtn" (click)="tdlParser.svgTranslate(-50,0)"><fa-icon [icon]="d.faCaretRight"></fa-icon></button></td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td><button title="Look Down (Right-Click Drag)" type="button" class="btn btn-xs btn-secondary iconBtn" (click)="tdlParser.svgTranslate(0,-50)"><fa-icon [icon]="d.faCaretDown"></fa-icon></button></td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </div>

  </div>

  <ul class="nav nav-tabs nav-tabs-bottom htabs">
    <li class="nav-item">
      <a class="nav-link" [class.active]="tdlParser.model.view=='diagram'" (click)="tdlParser.switchView('diagram')">
        <img class="svgIcon" src="/assets/images/2d.svg" style="margin-right:3px">Diagram</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="tdlParser.model.view=='3d'" (click)="tdlParser.switchView('3d')">
        <img class="svgIcon" style="margin-right:3px" src="/assets/images/3d.svg">3D</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="tdlParser.model.view=='hd3d'" (click)="tdlParser.switchView('hd3d')">
        <img class="svgIcon" style="margin-right:3px" src="/assets/images/3d.svg">3D<b>&nbsp;HD</b></a>
    </li>
    
  </ul>

  <!--
  <input [(ngModel)]="tdlParser.model.minpoint.x" type="number"/><input [(ngModel)]="tdlParser.model.minpoint.y" type="number" />
  <br />
  <input [(ngModel)]="tdlParser.model.maxpoint.x" type="number" /><input [(ngModel)]="tdlParser.model.maxpoint.y" type="number" />


  <div id="sliders">
    <input type="range" min="0" max="1000" (change)="updateViewbox()" [(ngModel)]="tdlParser.model.vb.min.x">{{tdlParser.model.vb.min.x}}<br />
    <input type="range" min="0" max="1000" (change)="updateViewbox()" [(ngModel)]="tdlParser.model.vb.min.y">{{tdlParser.model.vb.min.y}}<br />
    <input type="range" min="0" max="1000" (change)="updateViewbox()" [(ngModel)]="tdlParser.model.vb.max.x">{{tdlParser.model.vb.max.x}}<br />
    <input type="range" min="0" max="1000" (change)="updateViewbox()" [(ngModel)]="tdlParser.model.vb.max.y">{{tdlParser.model.vb.max.y}}<br />
  </div>
    -->

</ng-container>