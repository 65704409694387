import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-printout',
  templateUrl: './printout.component.html',
  styleUrls: ['./printout.component.scss']
})
export class PrintoutComponent implements OnInit {

  constructor(public d: DataService) { }

  ngOnInit() {
  }

  printHash() {
    return this.d.MD5(JSON.stringify(this.d.model.cart));

  }

}

/*

import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Component, Injectable, Pipe, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';
import { DataService } from '../../data.service';


@Component({
    selector: 'printout',
    template: require('./printout.component.html'),
    encapsulation: ViewEncapsulation.None //disables Shadow DOM, so we can style SharePoint HTML
})
export class PrintoutComponent {
    constructor(private dataSvc: DataService, private router: Router, private route: ActivatedRoute) { }

   

    ngOnInit() {
        //let ctrl = this;
        //console.log("PrintoutComponent init!");
        
    }

    printHash(){
      return this.dataSvc.MD5(JSON.stringify(this.dataSvc.model.cart));
      
    }


}
*/