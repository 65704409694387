import { NgModule } from '@angular/core';
import { DragModalDirective } from './DragModal.directive';
import { DebounceKeyupDirective } from "./debounce-keyup.directive";
import { DebounceClickDirective } from "./debounce-click.directive";
@NgModule({
	imports: [],
	declarations: [DragModalDirective, DebounceKeyupDirective, DebounceClickDirective],
	exports: [DragModalDirective, DebounceKeyupDirective, DebounceClickDirective]
})
export class DirectivesModule { }
