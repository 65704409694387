<div id="toast"></div>


<div class="imageZoomMask" id="imageZoomMask" (click)="d.model.imageZoomUrl=''" *ngIf="d.model.imageZoomUrl.length>0">
  <div class="imageZoom">
    <img [src]="d.model.imageZoomUrl|cms" />
    <!--|safeurl-->
  </div>
</div>


<div class="loadingMask noselect" id="loadingMask" unselectable="on" [class.active]="d.ajaxCount">
  <div class="loading">
    <img src="/assets/images/swishes.svg" />
  </div>
  <div class="circle-loading">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="0.5" stroke-miterlimit="10" />
    </svg>
  </div>
</div>

<div id="updatingVersion" *ngIf="d.updatingVersion">
  <div class="vertical-center">
    <table>
      <tr>
        <td>Updating, please wait...</td>
      </tr>
      <tr>
        <td>
          <img src="assets/images/updatingVersion.svg">
        </td>
      </tr>
    </table>
  </div>

</div>

<ng-container *ngIf="d.activeRouteIs('/login')">
  <router-outlet></router-outlet>
</ng-container>


<ng-container *ngIf="!d.activeRouteIs('/login')">
  <header class="appheader" (keydown)="appKeyDown($event)" (keyup)="appKeyUp($event)">
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-default" id="mainMenu" ngbDropdownToggle>
            <fa-icon [icon]="d.faBars"></fa-icon>Menu
          </button>
          <div ngbDropdownMenu aria-labelledby="mainMenu">


            <button ngbDropdownItem (click)="router.navigate(['/'])">
              <fa-icon [icon]="d.faHome"></fa-icon>Home
            </button>
            <button ngbDropdownItem [class.disabled]="d.productDisabled('CF')"
              (click)="d.checkPreReqsAndRoute(['SoldTo','ShipTo'], '/panels/CF')">
              <fa-icon [icon]="d.faSquare" style="color:#80898a"></fa-icon>Concealed Fastener Panels
            </button>
            <button ngbDropdownItem [class.disabled]="d.productDisabled('EF')"
              (click)="d.checkPreReqsAndRoute(['SoldTo','ShipTo'],'/panels/EF')">
              <fa-icon [icon]="d.faSquare" style="color:#a84f34"></fa-icon>Exposed Fastener Panels
            </button>
            <button ngbDropdownItem [class.disabled]="d.productDisabled('TRIM')"
              (click)="d.checkPreReqsAndRoute(['SoldTo','ShipTo'],'/trims')">
              <fa-icon [icon]="d.faSquare" style="color:#6b8d76"></fa-icon>Trims
            </button>
            <button ngbDropdownItem (click)="d.checkPreReqsAndRoute(['SoldTo','ShipTo'],'/accessories')">
              <fa-icon [icon]="d.faSquare" style="color:#268dbf"></fa-icon>Accessories
            </button>

            <div class="dropdown-divider"></div>
            <button ngbDropdownItem (click)="d.checkPreReqsAndRoute(['SoldTo'], '/orders/history')">
              <fa-icon [icon]="d.faWpforms"></fa-icon>Online Order History
            </button>
            <button ngbDropdownItem (click)="d.checkPreReqsAndRoute(['SoldTo'], '/orders/templates')">
              <fa-icon [icon]="d.faPuzzlePiece"></fa-icon>Saved Templates
            </button>
            <div class="dropdown-divider"></div>

            <div class="d-block" ngbDropdown #videoMenu="ngbDropdown" (mouseenter)="videoMenu.open()"
              (mouseleave)="videoMenu.close()" display="dynamic" placement="right-top">
              <button class="dropdown-item-parent" id="dropdownManual" ngbDropdownAnchor (click)="videoMenu.toggle()">
                <fa-icon [icon]="d.faVideo"></fa-icon>Video Training
              </button>
              <div class="dropdown-item-child" ngbDropdownMenu aria-labelledby="dropdownManual">
                <a ngbDropdownItem href="https://www.youtube.com/embed/1UBQHpHlxao?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>1 - Sign Up and Log On (3:49)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/7AMaVssESbU?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>2 - User Management (2:50)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/nVuWEnZvnSw?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>3 - Panels and Cutlist (3:34)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/Zc23Q03vEOM?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>4 - Standard Trims (2:13)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/DJm_JtYPmkY?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>5 - Custom Trims (6:22)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/5YneytcbISY?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>6 - Accessories (2:15)
                </a>
                <a ngbDropdownItem href="https://www.youtube.com/embed/bAB0LfX-z08?autoplay=1&rel=0" target="_blank">
                  <fa-icon [icon]="d.faFilm"></fa-icon>7 - Cart, Templates and Order History (4:56)
                </a>
              </div>
            </div>

            <div class="dropdown-divider"></div>

            <a ngbDropdownItem href="https://www.ascbp.com/" target="_blank"><img src="/assets/images/swishes.svg"
                class="menu-item-svg whiteBg roundIcon" />ASC BP Home Page</a>
            <a ngbDropdownItem href="https://www.ascprofiles.com/online-payments/" target="_blank">
              <fa-icon [icon]="d.faDollarSign"></fa-icon>Online Payments
            </a>
            <a ngbDropdownItem href="https://www.ascbp.com/contact-information/" target="_blank">
              <fa-icon [icon]="d.faShare"></fa-icon>Contact Us
            </a>
            <a ngbDropdownItem href="https://www.ascbp.com/distributor-center/" target="_blank">
              <fa-icon [icon]="d.faShare"></fa-icon>Distributor Resources
            </a>

            <div class="d-block" ngbDropdown #contactMenu="ngbDropdown" (mouseenter)="contactMenu.open()"
              (mouseleave)="contactMenu.close()" display="dynamic" placement="right-top">
              <button class="dropdown-item-parent" id="dropdownManual" ngbDropdownAnchor (click)="contactMenu.toggle()">
                <fa-icon [icon]="d.faConciergeBell"></fa-icon>Customer Care
              </button>
              <div class="dropdown-item-child" ngbDropdownMenu aria-labelledby="dropdownManual">
                <a ngbDropdownItem href="https://www.ascbp.com/resource-center/literature-sample-product-request/"
                  target="_blank">
                  <fa-icon [icon]="d.faWpforms"></fa-icon>Literature Requests and Samples
                </a>
                <a ngbDropdownItem
                  href="https://www.ascbp.com/resource-center/metal-roofing-colors/finish-warranty-request/"
                  target="_blank">
                  <fa-icon [icon]="d.faWpforms"></fa-icon>Finish Warranty Request
                </a>
                <a ngbDropdownItem href="https://ascbp.com/files/Order%20Forms/termsandconditions.pdf" target="_blank">
                  <fa-icon [icon]="d.faFilePdf"></fa-icon>Terms and Conditions
                </a>
                <a ngbDropdownItem
                  href="https://ascbp.com/files/Order%20Forms/Pitch%20and%20Flash%20XY%20-%20Job%20Aides.pdf"
                  target="_blank">
                  <fa-icon [icon]="d.faFilePdf"></fa-icon>Pitch and Flash XY - Job Aides
                </a>
                <a ngbDropdownItem href="https://ascbp.com/files/Order%20Forms/Flash%20X%20Y.pdf" target="_blank">
                  <fa-icon [icon]="d.faFilePdf"></fa-icon>Flash X Y
                </a>

              </div>
            </div>

            <div class="d-block" ngbDropdown #socialMenu="ngbDropdown" (mouseenter)="socialMenu.open()"
              (mouseleave)="socialMenu.close()" display="dynamic" placement="right-top">
              <button class="dropdown-item-parent" id="dropdownManual" ngbDropdownAnchor (click)="socialMenu.toggle()">
                <fa-icon [icon]="d.faShareAlt"></fa-icon>Social Media Links
              </button>
              <div class="dropdown-item-child" ngbDropdownMenu aria-labelledby="dropdownManual">
                <a ngbDropdownItem href="https://www.facebook.com/ascbp" target="_blank">
                  <fa-icon [icon]="d.faFacebookSquare" class="facebookIcon"></fa-icon>Facebook
                </a>
                <a ngbDropdownItem href="https://twitter.com/ASCBldgProducts" target="_blank">
                  <fa-icon [icon]="d.faTwitterSquare" class="twitterIcon"></fa-icon>Twitter
                </a>
                <a ngbDropdownItem href="https://www.linkedin.com/company/asc-building-products" target="_blank">
                  <fa-icon [icon]="d.faLinkedIn" class="linkedInIcon"></fa-icon>LinkedIn
                </a>




                <a ngbDropdownItem href="https://www.youtube.com/channel/UC30tJWVJ6iZFr64A-K39aaw" target="_blank"
                  rel="noopener noreferrer nofollow" title="YouTube">
                  <fa-icon [icon]="d.faLinkedIn" class="youtubeIcon"></fa-icon>YouTube
                </a>
                <a ngbDropdownItem href="https://www.instagram.com/asc_building_products/" target="_blank"
                  rel="noopener noreferrer nofollow" title="Instagram">
                  <fa-icon [icon]="d.faInstagram" class="instagramIcon"></fa-icon>Instagram
                </a>
                <a ngbDropdownItem href="https://www.pinterest.com/ascbuildingproducts/" target="_blank"
                  rel="noopener noreferrer nofollow" title="Pinterest">
                  <fa-icon [icon]="d.faPintrest" class="pinterestIcon"></fa-icon>Pinterest
                </a>
                <a ngbDropdownItem href="https://www.houzz.com/pro/asc-building-products/asc-building-products"
                  target="_blank" rel="noopener noreferrer nofollow" title="Houzz">
                  <fa-icon [icon]="d.faHouzz" class="houzzIcon"></fa-icon>Houzz
                </a>















              </div>
            </div>

            <a ngbDropdownItem href="https://asc.renoworks.com/" target="_blank">
              <fa-icon [icon]="d.faEye"></fa-icon>Product Visualizer
            </a>

            <div class="dropdown-divider"></div>

            <a ngbDropdownItem href="http://www.ascbp.com/files/Order%20Forms/ecomtermsofuse.pdf" target="_blank">
              <fa-icon [icon]="d.faFilePdf"></fa-icon>E-Comm Terms of Use Policy
            </a>
            <a ngbDropdownItem href="http://www.ascbp.com/files/Order%20Forms/termsandconditions.pdf" target="_blank">
              <fa-icon [icon]="d.faFilePdf"></fa-icon>Terms and Conditions of Sale
            </a>
            <a ngbDropdownItem href="https://www.ascprofiles.com/privacy-policy/" target="_blank">
              <fa-icon [icon]="d.faFilePdf"></fa-icon>Privacy Policy
            </a>

            <div class="dropdown-divider" *ngIf="d.debugVisible"></div>
            <div class="d-block" *ngIf="d.debugVisible" ngbDropdown #debugMenu="ngbDropdown"
              (mouseenter)="debugMenu.open()" (mouseleave)="debugMenu.close()" display="dynamic" placement="right-top">
              <button class="dropdown-item-parent" id="dropdownDebug" ngbDropdownAnchor (click)="debugMenu.toggle()">
                <fa-icon [icon]="d.faBug"></fa-icon>Debug
              </button>
              <div class="dropdown-item-child" ngbDropdownMenu aria-labelledby="dropdownDebug">

                <button ngbDropdownItem (click)="decrementDates()">
                  <fa-icon [icon]="d.faReplyAll"></fa-icon>Decrement Cart Dates 1 Week
                </button>

                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="debugModel()">
                  <fa-icon [icon]="d.faBug"></fa-icon>Display Model
                </button>
                <button ngbDropdownItem (click)="cacheModel()">
                  <fa-icon [icon]="d.faSave"></fa-icon>Cache Model
                </button>
                <button ngbDropdownItem (click)="loadModel()">
                  <fa-icon [icon]="d.faUpload"></fa-icon>Load Model
                </button>
                <button ngbDropdownItem (click)="clearModel()">
                  <fa-icon [icon]="d.faTrash"></fa-icon>Clear Cached Model
                </button>

                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="enableAjaxDebug()">
                  <fa-icon [icon]="d.faEye"></fa-icon>Enable Ajax Debug
                </button>

                <div class="dropdown-divider"></div>
                <button ngbDropdownItem (click)="showLocalStorage()">
                  <fa-icon [icon]="d.faEye"></fa-icon>Show LocalStorage
                </button>
                <button ngbDropdownItem (click)="clearLocalStorage()">
                  <fa-icon [icon]="d.faTrash"></fa-icon>Clear LocalStorage
                </button>
                <button ngbDropdownItem (click)="d.am.clearUser()">
                  <fa-icon [icon]="d.faUser"></fa-icon>Clear User
                </button>

              </div>
            </div>








          </div>
        </div>
        <a routerLink="/home"><img class="eNav-logo navbar-brand" style="overflow: visible"
            src="/assets/images/ascbplogo.svg"></a>
        <div class="toolbar-spacer"></div>


        <!--
        <a mat-button [routerLink]="['profile']">Profile</a>
        -->
        <span class="social-buttons">
          <a href="https://www.facebook.com/ascbp" target="_blank" class="btnSocial" rel="noopener noreferrer nofollow"
            title="Facebook">
            <fa-icon [icon]="d.faFacebookSquare" class="facebookIcon"></fa-icon>
          </a>
          <a href="https://twitter.com/ASCBldgProducts" target="_blank" class="btnSocial"
            rel="noopener noreferrer nofollow" title="Twitter">
            <fa-icon [icon]="d.faTwitterSquare" class="twitterIcon"></fa-icon>
          </a>
          <a href="https://www.linkedin.com/company/asc-building-products" target="_blank" class="btnSocial"
            rel="noopener noreferrer nofollow" title="LinkedIn">
            <fa-icon [icon]="d.faLinkedIn" class="linkedInIcon"></fa-icon>
          </a>

          <a href="https://www.youtube.com/channel/UC30tJWVJ6iZFr64A-K39aaw" target="_blank" class="btnSocial"
            rel="noopener noreferrer nofollow" title="YouTube">
            <fa-icon [icon]="d.faYoutube" class="youtubeIcon"></fa-icon>
          </a>
          <a href="https://www.instagram.com/asc_building_products/" target="_blank" class="btnSocial"
            rel="noopener noreferrer nofollow" title="Instagram">
            <div class="insta-icon"></div>
            <!--
            <fa-icon [icon]="d.faInstagram" class="instagramIcon"></fa-icon>-->
          </a>
          <a href="https://www.pinterest.com/ascbuildingproducts/" target="_blank" class="btnSocial"
            rel="noopener noreferrer nofollow" title="Pinterest">
            <fa-icon [icon]="d.faPintrest" class="pinterestIcon"></fa-icon>
          </a>
          <a href="https://www.houzz.com/pro/asc-building-products/asc-building-products" target="_blank"
            class="btnSocial" rel="noopener noreferrer nofollow" title="Houzz">
            <fa-icon [icon]="d.faHouzz" class="houzzIcon"></fa-icon>
          </a>
        </span>


        <div ngbDropdown class="d-inline-block" *ngIf="d.model.accountInfo">
          <button type="button" id="userMenu" class="btn btn-default" ngbDropdownToggle>
            <fa-icon [icon]="d.faUserCircle"></fa-icon><span
              class="firstName">{{d.model.accountInfo.FirstName||"..."}}</span> <span
              class="lastName">{{d.model.accountInfo.LastName}}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="userMenu" class="dropdown-menu dropdown-menu-end">

            <a *ngIf="d.model.accountInfo.IsUserManager" ngbDropdownItem routerLink="/manage-users"
              routerLinkActive="active">
              <fa-icon [icon]="d.faUsers"></fa-icon>Manange Users
            </a>
            <div *ngIf="d.model.accountInfo.IsUserManager" class="dropdown-divider"></div>


            <div class="dropdown-header">
              <button *ngIf="d.model.customerSet.length > 1" (click)="changeSoldto()"
                class="btn btn-primary btn-xs float-md-end">Change</button>
              <fa-icon [icon]="d.faBuilding"></fa-icon>&nbsp;Sold To:
            </div>
            <div class="menu-detail" *ngIf="d.model.SoldTo.Soldto"><b>{{ d.model.SoldTo.Soldto }} - {{
                d.model.SoldTo.SearchTerm }}</b><br />{{ d.model.SoldTo.Name }}<br />{{ d.model.SoldTo.City }}, {{
              d.model.SoldTo.State }}</div>


            <div class="dropdown-divider"></div>
            <div class="dropdown-header">
              <button *ngIf="d.model.customerSet.length > 1" (click)="changeShipto()"
                class="btn btn-primary btn-xs float-md-end">Change</button>
              <fa-icon [icon]="d.faMapMarker"></fa-icon>&nbsp;Ship To:
            </div>
            <span *ngIf="d.model.jobSite" style="background-color:yellow">JOBSITE MODE</span>
            <div class="menu-detail" *ngIf="d.model.ShipTo.NAME1">

              <b>{{d.model.ShipTo.SHIPTO}} - {{d.model.ShipTo.NAME1}}</b><br />
              {{d.model.ShipTo.STRAS}}<br />
              {{d.model.ShipTo.ORT01}}<span *ngIf="d.model.ShipTo.ORT02">, {{d.model.ShipTo.ORT02}}</span>,
              {{d.model.ShipTo.REGIO}} {{d.model.ShipTo.PSTLZ}}
            </div>
            <div class="menu-detail" *ngIf="d.model.ShipMode.Name">
              Shipping Plant:
              <fa-icon [icon]="d.faTruck" flip="horizontal" *ngIf="d.model.ShipTo.DeliveryOptions == 10"></fa-icon>
              <fa-icon [icon]="d.faIndustry" *ngIf="d.model.ShipTo.DeliveryOptions == 20"></fa-icon>
              <b>{{d.model.ShipTo.SHIPPINGPLANT}}</b>
            </div>
            <div class="menu-detail" *ngIf="d.model.ShipMode.Name">
              <span *ngIf="d.model.ShipTo.DeliveryOptions == '10'">Shipping Method:</span>
              <span *ngIf="d.model.ShipTo.DeliveryOptions == '20'">Pick Up Via:</span>
              <b>{{d.model.ShipMode.Name}} ({{d.model.ShipMode.ShippingModeId}})</b>
              {{d.model.currentCarrierName}}
            </div>

            <div class="dropdown-divider"></div>
            <button ngbDropdownItem
              (click)="this.logoutModalRef = this.d.showModal(logoutModal,'modal-sm modal-dialog-centered')">
              <fa-icon [icon]="d.faPowerOff"></fa-icon>Log Out: <b>{{d.model.accountInfo.UserName}}</b>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="approute" *ngIf="d?.modelReady">
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>

</ng-container>
<app-printout id="cartprint" *ngIf="d.activeRouteIs('/cart')"></app-printout>






<ng-template #changeSoldtoModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="d.faBuilding"></fa-icon>Sold To
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="changeSoldtoModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">


    <p>
      To continue, please select the customer to be sold to:
    </p>

    <div class="input-group">
      <input type="text" class="form-control" #soldtoSearchTermsInput id="soldtoSearchTermsInput"
        placeholder="Search Sold Tos..." [(ngModel)]="soldtoSearchTerms"
        (ngModelChange)="selectedSoldto=-1;d.searchSoldTo(soldtoSearchTerms)" (keydown)="soldToscrollToTop();">
      <span class="btn input-group-addon btn-secondary" title="Repeat last search" (click)="repeatSearch()">
        <fa-icon [icon]="d.faBuilding"></fa-icon>
      </span>
    </div>
    <br />

    <ng-container *ngIf="d.model.customerSetFiltered?.length > 0">

      <div class="dialog-scroll-content" style="overflow-y:inherit">

        <cdk-virtual-scroll-viewport #soldToVirtualScroll appendOnly itemSize="50" class="example-viewport"
          style="height:150px;border:1px solid #ccc">
          <div *cdkVirtualFor="let cust of d.model.customerSetFiltered" class="scroll-Item"
            [ngClass]="{selected: (selectedSoldto == cust.Soldto)}" (click)="selectSoldto(cust.Soldto)">
            <fa-icon [icon]="d.faBuilding"></fa-icon>&nbsp;<strong>{{ cust.Soldto }} - {{ cust.SearchTerm }}</strong>
            <br />&nbsp;&nbsp;&nbsp;&nbsp;{{ cust.Name }}, {{ cust.City }}, {{ cust.State }}
          </div>
        </cdk-virtual-scroll-viewport>

      </div>

    </ng-container>

    <div *ngIf="d.model.accountInfo.IsInternal">
      <br />
      <div>If an order is created, email order confirmation to:</div>
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="d.model.cart.ECommEmail">
        <div class="input-group-btn">
          <button type="button" class="btn dropdown-toggle btn-secondary" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">@ <span class="caret"></span></button>
          <ul class="dropdown-menu dropdown-menu-right drop-up" style="max-height:200px;overflow:auto">
            <li><a (click)="d.model.cart.ECommEmail=d.model.accountInfo.UserName">{{d.model.accountInfo.UserName}}</a>
            </li>
            <li *ngFor="let email of shiptoEmails"><a (click)="d.model.cart.ECommEmail=email">{{email}}</a></li>
          </ul>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="setSelectedSoldTo()" [disabled]="setSoldToIsDisabled()">
      <fa-icon [icon]="d.faCheck"></fa-icon>Set Sold To
    </button>

  </div>
</ng-template>



<ng-template #changeShiptoModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="d.faMapMarker"></fa-icon>Select Ship To <span *ngIf="d.model.SoldTo">for
        {{d.model.SoldTo.Soldto}} - {{d.model.SoldTo.SearchTerm}}</span>
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="changeShiptoModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">


    <p>
      To calculate prices, the destination and shipping method must be selected:
    </p>
    <div class="dialog-scroll-content" style="overflow-y:inherit">

      <div *ngIf="d.model.shipToList.routes.length > 0" class="dialog-scroll-inline-header">Standard Route Delivery
      </div>
      <ng-container *ngFor="let shipto of d.model.shipToList.routes">
        <div class="scroll-item" (click)="d.model.ShipTo=shipto;autoSelectShipMode()" (dblclick)="setSelectedShipTo()"
          [ngClass]="{selected: (d.model.ShipTo.SHIPTO == shipto.SHIPTO)}">

          <table>
            <tr>
              <td style="text-align:center;">
                <fa-icon [icon]="d.faTruck" flip="horizontal" style="font-size:2em;color:#6a220e"></fa-icon>
                <br /><b>{{shipto.SHIPPINGPLANT}}</b>
              </td>
              <td>
                <b>{{shipto.NAME1}} ({{shipto.SHIPTO}})</b><br />
                {{shipto.STRAS}}<br />
                {{shipto.ORT01}}<span *ngIf="shipto.ORT02">, {{shipto.ORT02}}</span>, {{shipto.REGIO}} {{shipto.PSTLZ}}
              </td>
            </tr>
          </table>


        </div>
      </ng-container>

      <div *ngIf="d.model.shipToList?.willcall.length > 0" class="dialog-scroll-inline-header">Will Call ASC Plants
      </div>
      <ng-container *ngFor="let shipto of d.model.shipToList.willcall">
        <div class="scroll-item" (click)="d.model.ShipTo=shipto;autoSelectShipMode()" (dblclick)="setSelectedShipTo()"
          [ngClass]="{selected: (d.model.ShipTo.SHIPTO == shipto.SHIPTO)}">

          <table>
            <tr>
              <td style="text-align:center;">
                <fa-icon [icon]="d.faIndustry" style="font-size:2em;color:#00665E"></fa-icon>
                <br /><b>{{shipto.SHIPPINGPLANT}}</b>
              </td>
              <td>
                <b>{{shipto.NAME1}} ({{shipto.SHIPTO}})</b><br />
                {{shipto.STRAS}}<br />
                {{shipto.ORT01}}<span *ngIf="shipto.ORT02">, {{shipto.ORT02}}</span>, {{shipto.REGIO}} {{shipto.PSTLZ}}
              </td>
            </tr>
          </table>

        </div>
      </ng-container>



      <div *ngIf="d.model.shipToList?.jobsitePreApproved.length > 0" class="dialog-scroll-inline-header">Job Sites
        (Pre-Approved)</div>
      <ng-container *ngFor="let shipto of d.model.shipToList.jobsitePreApproved">
        <div class="scroll-item" (click)="d.model.ShipTo=shipto;autoSelectShipMode()" (dblclick)="setSelectedShipTo()"
          [ngClass]="{selected: (d.model.ShipTo.SHIPTO == shipto.SHIPTO)}">

          <table>
            <tr>
              <td style="text-align:center;">
                <fa-icon [icon]="d.faLocationArrow" style="font-size:2em;color:#946504"></fa-icon>
                <br /><b>{{shipto.SHIPPINGPLANT}}</b>
              </td>
              <td>
                <b>{{shipto.NAME1}} ({{shipto.SHIPTO}})</b><br />
                {{shipto.STRAS}}<br />
                {{shipto.ORT01}}<span *ngIf="shipto.ORT02">, {{shipto.ORT02}}</span>, {{shipto.REGIO}} {{shipto.PSTLZ}}
              </td>
            </tr>
          </table>

        </div>
      </ng-container>

    </div>
    <br />

    <div style="text-align:right"
      *ngIf="d.model.ShipTo.DeliveryOptions == '10' || d.model.ShipTo.DeliveryOptions == '20'">
      <div class="form-group">
        <span style="font-weight:bold">
          <span *ngIf="d.model.ShipTo.DeliveryOptions == '10'">Shipping Method:</span>
          <span *ngIf="d.model.ShipTo.DeliveryOptions == '20'">Pick Up Via:</span>
        </span>


        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-default" id="shipMode-select" ngbDropdownToggle>
            <span *ngIf="!d.model.ShipMode.Name">Select...</span>
            <span *ngIf="d.model.ShipMode.Name">{{d.model.ShipMode.Name}}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="shipMode-select">
            <ng-container *ngFor="let opt of d.model.deliveryOptionSet">
              <button ngbDropdownItem *ngIf="opt.DeliveryOptionId == d.model.ShipTo.DeliveryOptions"
                [class.disabled]="deliveryOptionDisabled(opt)"
                (click)="d.model.ShipMode=opt;focusCarrier(opt)">{{opt.Name}}</button>
            </ng-container>

          </div>
        </div>

        <!--
        <div class="btn-group">
          <button type="button" class="btn btn-default dropdown-toggle" *ngIf="d.model.ShipTo.DeliveryOptions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{d.model.ShipMode.Name}} ({{d.model.ShipMode.ShippingModeId}})<span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right drop-up">
            <ng-container *ngFor="let opt of d.model.deliveryOptionSet">
              <li *ngIf="opt.DeliveryOptionId == d.model.ShipTo.DeliveryOptions" [ngClass]="{'disabled':deliveryOptionDisabled(opt)}" >
                <a (click)="d.model.ShipMode=opt;focusCarrier(opt)">{{opt.Name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>

      -->
      </div>

      <div class="form-group" *ngIf="d.model.ShipMode.ShippingModeId == 'CARR'">
        <div class="input-group">
          <span class="input-group-text">Carrier Name</span>
          <input [(ngModel)]="d.model.currentCarrierName" #carrierName id="carrierName"
            placeholder="Your carrier's name and/or description" type="text" class="form-control" />
        </div>
      </div>

    </div>




  </div>
  <div class="modal-footer">

    <ng-container *ngIf="d.model.flags.enable_jobsite">
      <button class="btn btn-secondary pull-left" (click)="d.openJobsiteModal()">
        <fa-icon [icon]="d.faLocationArrow"></fa-icon>Job Site Delivery Request
      </button>
    </ng-container>

    <button class="btn btn-secondary" (click)="setSelectedShipTo()" [disabled]="setShipToIsDisabled()">
      <fa-icon [icon]="d.faCheck"></fa-icon>Set Ship To
    </button>


  </div>
</ng-template>


<ng-template #carrModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="d.faIndustry"></fa-icon>&nbsp;Will Call Customer Agent Agreement
    </h4>

  </div>
  <div class="modal-body">
    <strong style="font-size:1.3em;color:red">Attention!!</strong><br>

    You have chosen the <strong>Customer Agent</strong> option for shipping.<br /><Br />Please note it is <strong><u
        style="color:red">YOUR</u></strong> responsibility to:
    <ul>
      <li>Contact your preferred carrier and schedule the pick-up.</li>
      <li>Ensure the carrier has the proper vehicle.</li>
      <li>Ensure the carrier can handle the weight of the order.</li>
      <li>Ensure the carrier can handle the longest length of the order (when applicable).</li>
      <li>Ensure carrier arrives during normal Will Call hours.
        <br><em style="color:red">Consult your local branch for Will Call hours.</em>
      </li>
    </ul>
    All products ordered today are available for pick up after <b style="color:red">2 business days</b>,<br />except
    Skyline&reg; panels, which are available after <strong style="color:red">3 business days</strong>.
    <br><br>Click <strong>AGREE</strong> below to acknowledge that you have read, and will comply with the above
    statement.
  </div>
  <div class="modal-footer">
    <button class="btn btn-lg btn-warning-secondary" (click)="agreeToCarr(false)" title="You will be logged out">
      <fa-icon [icon]="d.faTimes"></fa-icon>DO NOT AGREE
    </button>
    <button class="btn btn-lg btn-secondary" (click)="agreeToCarr(true)" style="width:33%">
      <fa-icon [icon]="d.faCheck"></fa-icon>AGREE
    </button>
  </div>
</ng-template>


<ng-template #willCallWarningModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="d.faIndustry"></fa-icon>&nbsp;Will Call: Important Note!
    </h4>
  </div>
  <div class="modal-body">

    <div class="paymentTermsInstructions">
      Will Call orders must be picked up within <b>10 business days</b> of order completion.<br /><br />Orders not
      picked up within 10 days will be delivered on the <b>next available route truck.</b>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissWillCallWarningModal()" data-dismiss="modal">
      <fa-icon [icon]="d.faCheck"></fa-icon>&nbsp;Got it!
    </button>
  </div>
</ng-template>


<ng-template #spokaneLeadTimeModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <fa-icon [icon]="d.faIndustry"></fa-icon>&nbsp;Spokane: Lead Time Change!
    </h4>
  </div>
  <div class="modal-body">

    <div class="paymentTermsInstructions">
      Starting <b>7/15</b>, lead-times will change from 4 days to <b>3 days</b>.
      To accommodate this change, the <b>cutoff time</b> to submit orders will change from midnight to <b>3&nbsp;PM</b>.
      <br><br>
      For further information, please contact your outside sales rep or customer service at 800-776-8771
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="dismissSpokaneLeadTimeModal()" data-dismiss="modal">
      <fa-icon [icon]="d.faCheck"></fa-icon>&nbsp;I Understand
    </button>
  </div>
</ng-template>




<ng-template #logoutModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Really Log Out?</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="logoutModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you want to log out?
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" (click)="logoutModalRef.hide()">
      <fa-icon [icon]="d.faTimes"></fa-icon>Cancel
    </button>
    <button class="btn btn-secondary" (click)="d.am.logout()">
      <fa-icon [icon]="d.faPowerOff"></fa-icon>Log Out
    </button>
  </div>
</ng-template>



<ng-template #agreementModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">User Agreement</h4>
  </div>
  <div class="modal-body" [innerHTML]="d.model.html.terms|safe">

  </div>
  <div class="modal-footer">


    <div class="dist-cols-parent" style="width:100%">
      <div class="dist-cols-child">
        <button class="btn btn-lg btn-warning-secondary fw-bold" title="You will be logged out"
          (click)="d.agreeToTerms(false)">
          <fa-icon [icon]="d.faTimes"></fa-icon>DO NOT AGREE
        </button>
      </div>

      <div class="dist-cols-child">
        <button class="btn btn-lg btn-secondary fw-bold" (click)="d.agreeToTerms(true)">
          <fa-icon [icon]="d.faCheck"></fa-icon>AGREE
        </button>
      </div>
    </div>



  </div>
</ng-template>



<ng-template #errorModal>
  <div class="modal-header error">
    <h4 class="modal-title pull-left">Unexpected Error</h4>
  </div>
  <div class="modal-body errorDisplay">
    <div id="errorReportFrame">
      <div class="border">
        <div id="inputForm">
          <p class="title">Sorry, that wasn't supposed to happen!</p>
          In order to help us track down the issue and fix it,
          would you be willing to tell us what you were doing just before this error occured?
          <br />
          <textarea id="errorText" [ngModel]="d.errorMsg.errorUserDescription"
            (ngModelChange)="d.errorMsg.errorUserDescription = $event"></textarea>
          <!-- how is this different from [(ngModel)]="d.errorMsg.errorUserDescription" ? -->

          <div class="rightAlign">
            <button type="submit" id="submitBtn" (click)="d.closeErrorDialog()">Submit Error Report</button>
          </div>
        </div>
        <div id="formSubmitted" style="display:none">
          <p class="title">Thank you for your feedback!</p>
          We will do our best to find the cause of this error, and if possible get a bug fix out ASAP!
        </div>
      </div>
    </div>
    <b>Status: {{d.errorMsg.status}}: {{d.errorMsg.statusText}}<br>{{d.errorMsg.message}}</b>

    <br>
    <span class="errorJson" *ngIf="d.errorMsg?._body" [innerHTML]="d.errorMsg?._body|truncate: 1800 |safe"></span>

  </div>
  <div class="modal-footer error">


    <div class="dist-cols-parent" style="width:100%">
      <div class="dist-cols-child">
        {{d.errorMsg.url}}
      </div>

      <div class="dist-cols-child">
        <button class="btn btn-warning-secondary" (click)="d.closeErrorDialog()">
          <fa-icon [icon]="d.faTimes"></fa-icon>Close Error
        </button>
      </div>
    </div>



  </div>
</ng-template>