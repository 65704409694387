<header>

	<div class="dist-cols-parent">
		<div class="dist-cols-child">

			<button (click)="addUser()" type="button" *ngIf="!d.model.accountInfo.IsCsr" class="btn btn-secondary"
				data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<fa-icon [icon]="d.faUserPlus"></fa-icon>Add User
			</button>

			<ul class="list-group">
				<li class="list-group-item list-group-item-header"><label><input type="checkbox"
							[(ngModel)]="hideDisabled" />Hide Disabled</label></li>
			</ul>

		</div>


		<div class="dist-cols-child pageTitle">
			<fa-icon [icon]="d.faUsers"></fa-icon>Manage Users
		</div>

		<div class="dist-cols-child">
			<div class="input-group">
				<input type="text" class="form-control" placeholder="Search users..." [(ngModel)]="searchTerms"
					(ngModelChange)="search()">
				<span class="btn input-group-addon btn-secondary" (click)="searchTerms='';search()"><fa-icon
						[icon]="d.faTimes"></fa-icon></span>
				<span class="btn input-group-addon btn-secondary"><fa-icon [icon]="d.faSearch"></fa-icon></span>
			</div>
		</div>
	</div>

</header>
<div class="routecontent">

	<!--
	<cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport" style="border:2px solid green">
		<div *cdkVirtualFor="let user of users" class="example-item">Email: {{user.UserName}}</div>
	</cdk-virtual-scroll-viewport>
	-->

	<div class="scrollable-table" *ngIf="d.model.accountInfo">
		<table class="table table-striped table-header-rotated">
			<thead>
				<tr>
					<th (click)="sort('FirstName')">First <fa-icon *ngIf="asc && sc=='FirstName'"
							[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='FirstName'" [icon]="d.faSortDown"></fa-icon>
					</th>
					<th (click)="sort('LastName')">Last <fa-icon *ngIf="asc && sc=='LastName'"
							[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='LastName'" [icon]="d.faSortDown"></fa-icon>
					</th>
					<th (click)="sort('UserName')">E-Mail <fa-icon *ngIf="asc && sc=='UserName'"
							[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='UserName'" [icon]="d.faSortDown"></fa-icon>
					</th>
					<th (click)="sort('CustomerIds')">Sold To <fa-icon *ngIf="asc && sc=='CustomerIds'"
							[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='CustomerIds'" [icon]="d.faSortDown"></fa-icon>
					</th>
					<th class="red-fade" (click)="sort('IsDisabled')" class="rotate-45">
						<div class="red-fade"><span>Disabled <fa-icon *ngIf="asc && sc=='IsDisabled'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='IsDisabled'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('IsItAdmin')" class="rotate-45" *ngIf="columnFilter('IsItAdmin')">
						<div class="asc-fade"><span>IT Admin <fa-icon *ngIf="asc && sc=='IsItAdmin'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='IsItAdmin'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('IsFiAdmin')" class="rotate-45" *ngIf="columnFilter('IsFiAdmin')">
						<div class="asc-fade"><span>FI Admin <fa-icon *ngIf="asc && sc=='IsFiAdmin'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='IsFiAdmin'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('IsCsr')" class="rotate-45" *ngIf="columnFilter('IsCsr')">
						<div class="asc-fade"><span>CSR <fa-icon *ngIf="asc && sc=='IsCsr'" [icon]="d.faSortUp"></fa-icon><fa-icon
									*ngIf="!asc && sc=='IsCsr'" [icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('IsCorpAdmin')" class="rotate-45" *ngIf="columnFilter('IsCorpAdmin')">
						<div class="blue-fade"><span>Comp Admin <fa-icon *ngIf="asc && sc=='IsCorpAdmin'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='IsCorpAdmin'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('IsBranchAdmin')" class="rotate-45" *ngIf="columnFilter('IsBranchAdmin')">
						<div class="blue-fade"><span>Branch Admin <fa-icon *ngIf="asc && sc=='IsBranchAdmin'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='IsBranchAdmin'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('CanCreateInquiry')" class="rotate-45" *ngIf="columnFilter('CanCreateInquiry')">
						<div class="orange-fade"><span>Create Inquiry <fa-icon *ngIf="asc && sc=='CanCreateInquiry'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='CanCreateInquiry'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
					<th (click)="sort('CanCreateOrder')" class="rotate-45" *ngIf="columnFilter('CanCreateOrder')">
						<div class="orange-fade"><span>Create Order <fa-icon *ngIf="asc && sc=='CanCreateOrder'"
									[icon]="d.faSortUp"></fa-icon><fa-icon *ngIf="!asc && sc=='CanCreateOrder'"
									[icon]="d.faSortDown"></fa-icon></span></div>
					</th>
				</tr>
			</thead>
			<tbody class="hlrows">
				<ng-container *ngFor="let user of users">
					<tr *ngIf="filterUser(user)" (click)="popUser(user)" [ngClass]="{'selected':(user.selected)}">
						<td class="al">{{user.FirstName}}</td>
						<td class="al">{{user.LastName}}</td>
						<td class="al">{{user.UserName}}</td>
						<td class="al">
							<span style="display:block;white-space:nowrap" *ngFor="let cust of user.soldTolist">
								<fa-icon [icon]="d.faBuilding"></fa-icon> {{ cust.Soldto }}
								<span *ngIf="cust.Name != '?'">- {{ cust.SearchTerm }} / {{ cust.Name }}, {{ cust.City }}, {{ cust.State
									}}</span>
							</span>
						</td>
						<td title="Disabled" class="red-fade"><fa-icon [icon]="d.faCheckCircle" class="red"
								*ngIf="user.IsDisabled"></fa-icon></td>
						<td title="IT Admin" class="asc-fade" *ngIf="columnFilter('IsItAdmin')"><fa-icon [icon]="d.faCheckCircle"
								class="asc" *ngIf="user.IsItAdmin"></fa-icon></td>
						<td title="FI Admin" class="asc-fade" *ngIf="columnFilter('IsFiAdmin')"><fa-icon [icon]="d.faCheckCircle"
								class="asc" *ngIf="user.IsFiAdmin"></fa-icon></td>
						<td title="CSR" class="asc-fade" *ngIf="columnFilter('IsCsr')"><fa-icon [icon]="d.faCheckCircle" class="asc"
								*ngIf="user.IsCsr"></fa-icon></td>
						<td title="Comp Admin" class="blue-fade" *ngIf="columnFilter('IsCorpAdmin')"><fa-icon
								[icon]="d.faCheckCircle" class="blue" *ngIf="user.IsCorpAdmin"></fa-icon></td>
						<td title="Branch Admin" class="blue-fade" *ngIf="columnFilter('IsBranchAdmin')"><fa-icon
								[icon]="d.faCheckCircle" class="blue" *ngIf="user.IsBranchAdmin"></fa-icon></td>
						<td title="Can Create Template" class="orange-fade" *ngIf="columnFilter('CanCreateInquiry')"><fa-icon
								[icon]="d.faCheckCircle" class="orange" *ngIf="user.CanCreateInquiry"></fa-icon></td>
						<td title="Can Create Order" class="orange-fade" *ngIf="columnFilter('CanCreateOrder')"><fa-icon
								[icon]="d.faCheckCircle" class="orange" *ngIf="user.CanCreateOrder"></fa-icon></td>
					</tr>
				</ng-container>
			</tbody>
		</table>

	</div>
</div>
<footer>
	<app-std-footer></app-std-footer>
</footer>


<ng-template #userModal>
	<div class="modal-header">
		<h4 class="modal-title pull-left"><span *ngIf="editUser.IsNew"><fa-icon [icon]="d.faUserPlus"></fa-icon>Add New
				User: </span><fa-icon *ngIf="!editUser.IsNew" [icon]="d.faUser"></fa-icon>{{editUser.FirstName}}
			{{editUser.LastName}}</h4>
		<button type="button" (click)="userModalRef.hide()" class="closeBtn pull-right" aria-label="Close"
			aria-hidden="true">
			<fa-icon [icon]="d.faWindowClose"></fa-icon>
		</button>
	</div>
	<div class="modal-body">

		<div class="form-group ungrid-row">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">First Name:</label>
			</div>
			<div class="ungrid-col" style="width:80%">
				<input [(ngModel)]="editUser.FirstName" required type="text" class="form-control" id="first-name"
					style="display:inline-block" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
					(paste)="autoConfig()">
			</div>
		</div>

		<div class="form-group ungrid-row">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">Last Name:</label>
			</div>
			<div class="ungrid-col" style="width:80%">
				<input [(ngModel)]="editUser.LastName" required type="text" class="form-control" id="last-name"
					style="display:inline-block" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
			</div>
		</div>

		<div class="form-group ungrid-row">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">E-Mail:</label>
			</div>
			<div class="ungrid-col" style="width:80%">
				<span [attr.title]="editUser.EmailStatic">
					<input [(ngModel)]="editUser.UserName" [disabled]="!editUser.IsNew"
						placeholder="Permanent, unique email address for user" type="email" class="form-control" id="user-name"
						style="display:inline-block" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
						pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required>
				</span>
			</div>
		</div>

		<div class="form-group ungrid-row" *ngIf="!(userIsInternal(editUser))">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">Sold To<span
						*ngIf="editUser.soldTolist && editUser.soldTolist.length > 0">
						({{editUser.soldTolist.length}})</span>:</label>
			</div>
			<div class="ungrid-col">

				<ul class="list-group lgScroll" style="max-height:105px;">
					<li class="list-group-item" *ngFor="let cust of editUser.soldTolist">
						<fa-icon [icon]="d.faBuilding"></fa-icon> {{ cust.Soldto }}
						<span *ngIf="cust.Name != '?'">- {{ cust.Name }} / {{ cust.SearchTerm }}, {{ cust.City }}, {{ cust.State
							}}</span>

						<button *ngIf="(!d.model.accountInfo.IsCsr)" (click)="removeShipTo(editUser,cust)"
							class="btn btn-sm pull-right btn-danger btn-xs btnRoundIcon" title="Remove Sold To">
							<fa-icon [icon]="d.faTimes"></fa-icon>
						</button>

					</li>
					<li class="list-group-item" *ngIf="editUser.soldTolist && editUser.soldTolist.length == 0">
						<span style="font-style:italic;color:gray">No Sold To specified...</span>
					</li>
				</ul>

			</div>
			<div class="ungrid-col" style="width:37px" *ngIf="(!d.model.accountInfo.IsCsr)">
				<button (click)="selectSoldto()" class="btn pull-right btn-primary btnRoundIcon" title="Add Sold To">

					<fa-icon [icon]="d.faPlus"></fa-icon>
				</button>
			</div>
		</div>

		<div class="form-group ungrid-row">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">Flags:</label>
			</div>
			<div class="ungrid-col">
				<ul class="list-group">
					<li class="list-group-item list-group-item-danger"><label><input type="checkbox"
								[(ngModel)]="editUser.IsDisabled" />Disabled</label></li>


					<li class="list-group-item list-group-item-success" *ngIf="!(userIsExternal(editUser))"><label><input
								type="checkbox" [(ngModel)]="editUser.IsItAdmin" />IT Admin</label></li>
					<li class="list-group-item list-group-item-success" *ngIf="!(userIsExternal(editUser))"><label><input
								type="checkbox" [(ngModel)]="editUser.IsFiAdmin" />FI Admin</label></li>
					<li class="list-group-item list-group-item-success" *ngIf="!(userIsExternal(editUser))"><label><input
								type="checkbox" [(ngModel)]="editUser.IsCsr" />CSR</label></li>

					<li class="list-group-item list-group-item-info"
						*ngIf="(!d.model.accountInfo.IsCsr)&&(!userIsInternal(editUser)&&(!editUser.IsBranchAdmin))&&(!d.model.accountInfo.IsCorpAdmin)&&(!d.model.accountInfo.IsBranchAdmin)">
						<label><input type="checkbox" [(ngModel)]="editUser.IsCorpAdmin" />Company Admin</label>
					</li>
					<li class="list-group-item list-group-item-info"
						*ngIf="(!d.model.accountInfo.IsCsr)&&(!userIsInternal(editUser)&&(!editUser.IsCorpAdmin))&&(!d.model.accountInfo.IsBranchAdmin)">
						<label><input type="checkbox" [(ngModel)]="editUser.IsBranchAdmin" />Branch Admin</label>
					</li>

					<li class="list-group-item list-group-item-warning"
						*ngIf="(!d.model.accountInfo.IsCsr)&&(!userIsInternal(editUser))"><label><input type="checkbox"
								[(ngModel)]="editUser.CanCreateInquiry" />Can Create Template</label></li>
					<li class="list-group-item list-group-item-warning"
						*ngIf="(!d.model.accountInfo.IsCsr)&&(!userIsInternal(editUser))"><label><input type="checkbox"
								[(ngModel)]="editUser.CanCreateOrder" />Can Create Order</label></li>
				</ul>
			</div>
			<div class="ungrid-col" style="width:40%">
				&nbsp;
			</div>
		</div>

		<div class="form-group ungrid-row" *ngIf="editUser.IsNew">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">

			</div>
			<div class="ungrid-col" style="width:80%">

			</div>
		</div>
	</div>

	<div class="modal-footer">


		<div class="dist-cols-child">


			<div class="pull-left" style="color:white;text-align:left;display:inline-block;padding-right:5px"
				*ngIf="editUser.IsNew">
				Send welcome email to:
			</div>
			<div class="pull-left" style="color:white;text-align:left;display:inline-block" *ngIf="editUser.IsNew">
				<!--
					<label (click)="selfSend=false"><input type="radio" [attr.checked]="!selfSend ? true : null" />&nbsp;User ({{editUser.UserName}})</label><br />
												<label (click)="selfSend=true"><input type="radio" [attr.checked]="selfSend ? true : null" />&nbsp;Me ({{d.model.accountInfo.UserName}})</label>
												-->

				<span (click)="selfSend=false" class="self-send" [ngClass]="{'active':(!selfSend)}">
					<fa-icon *ngIf="!selfSend" [icon]="d.faCheckCircle"></fa-icon><fa-icon *ngIf="selfSend"
						[icon]="d.faCircleThin"></fa-icon>&nbsp;User ({{editUser.UserName}})
				</span>
				<br />
				<span (click)="selfSend=true" class="self-send" [ngClass]="{'active':(selfSend)}">
					<fa-icon *ngIf="selfSend" [icon]="d.faCheckCircle"></fa-icon><fa-icon *ngIf="!selfSend"
						[icon]="d.faCircleThin"></fa-icon>&nbsp;Me ({{d.model.accountInfo.UserName}})
				</span>

			</div>

			<div class="pull-left" *ngIf="!editUser.IsNew">
				<button (click)="verifyTriggerPasswordReset()" class="btn btn-warning"><fa-icon
						[icon]="d.faEnvelope"></fa-icon>Resend Welcome Email</button>
			</div>

		</div>


		<div class="dist-cols-child">

			<button class="btn btn-warning-secondary" (click)="userModalRef.hide()" data-dismiss="modal"><fa-icon
					[icon]="d.faTimes"></fa-icon>Cancel</button>&nbsp;
			<span [attr.title]="strUserErrors">
				<button class="btn btn-secondary" data-dismiss="modal" (click)="saveUser()"
					[disabled]="userIsInvalid()"><fa-icon [icon]="d.faCheck"></fa-icon>Save Changes</button>
			</span>
		</div>




	</div>
</ng-template>


<ng-template #soldtoModal>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Add Sold To</h4>
		<button type="button" (click)="soldtoModalRef.hide()" class="closeBtn pull-right" aria-label="Close"
			aria-hidden="true">
			<fa-icon [icon]="d.faWindowClose"></fa-icon>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group ungrid-row">
			<div class="ungrid-col" style="text-align:right;width:20%;padding-right:5px;">
				<label for="first-name" class="control-label" style="display:inline-block">Search:</label>
			</div>
			<div class="ungrid-col" style="width:80%">
				<input [(ngModel)]="shiptoSearchTerms" (ngModelChange)="d.searchSoldTo(shiptoSearchTerms)" type="text"
					class="form-control" id="user-name" style="display:inline-block">
			</div>
		</div>

		<div class="dialog-scroll-content">

			<cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport"
				style="height:300px;border:1px solid #ccc">
				<div *cdkVirtualFor="let cust of d.model.customerSetFiltered" class="scroll-Item"
					[ngClass]="{selected: (cust.Soldto == selectedShipto)}" (click)="selectedShipto=cust.Soldto">
					<strong>{{ cust.Soldto }} - {{ cust.SearchTerm }}</strong>
					<br />&nbsp;&nbsp;{{ cust.Name }}&nbsp;&nbsp;{{ cust.City }}, {{ cust.State }}
				</div>
			</cdk-virtual-scroll-viewport>

		</div>

	</div>
	<div class="modal-footer">
		<button class="btn btn-warning-secondary" (click)="soldtoModalRef.hide()" data-dismiss="modal"><fa-icon
				[icon]="d.faTimes"></fa-icon>Cancel</button>
		<button class="btn btn-secondary" (click)="addSelectedShipTo()" [disabled]="selectedShipto.length == 0"><fa-icon
				[icon]="d.faCheck"></fa-icon>Add Selected</button>
	</div>
</ng-template>


<ng-template #passwordResetModal>
	<div class="modal-header">
		<h4 class="modal-title pull-left">Really Resend Welcome Email?</h4>
		<button type="button" class="btn-close close pull-right" aria-label="Close" (click)="passwordResetModalRef.hide()">
			<fa-icon [icon]="d.faWindowClose"></fa-icon>
		</button>
	</div>
	<div class="modal-body">
		Really resend Welcome to MyASCProfilesPortal welcome email?
	</div>
	<div class="modal-footer">
		<button class="btn btn-warning-secondary" (click)="passwordResetModalRef.hide()"><fa-icon
				[icon]="d.faTimes"></fa-icon>Cancel</button>
		<button class="btn btn-secondary" (click)="triggerPasswordReset()"><fa-icon [icon]="d.faEnvelope"></fa-icon>Send
			it!</button>
	</div>
</ng-template>