import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  //styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  @ViewChild("cutlistModal", { static: true }) cutlistModal: TemplateRef<any>;
  cutlistModalRef: BsModalRef;

  @ViewChild("customTrimModifyModal", { static: true }) customTrimModifyModal: TemplateRef<any>;
  customTrimModifyModalRef: BsModalRef;

  @ViewChild("addattachmentModal", { static: true }) addattachmentModal: TemplateRef<any>;
  addattachmentModalRef: BsModalRef;

  @ViewChild("verifysaveModal", { static: true }) verifysaveModal: TemplateRef<any>;
  verifysaveModalRef: BsModalRef;

  @ViewChild("printpricesModal", { static: true }) printpricesModal: TemplateRef<any>;
  printpricesModalRef: BsModalRef;

  @ViewChild("emptycartModal", { static: true }) emptycartModal: TemplateRef<any>;
  emptycartModalRef: BsModalRef;

  @ViewChild("salesorderErrorModal", { static: true }) salesorderErrorModal: TemplateRef<any>;
  salesorderErrorModalRef: BsModalRef;

  @ViewChild("salesorderWarnChangesModal", { static: true }) salesorderWarnChangesModal: TemplateRef<any>;
  salesorderWarnChangesModalRef: BsModalRef;

  @ViewChild("salesorderconfirmModal", { static: true }) salesorderconfirmModal: TemplateRef<any>;
  salesorderconfirmModalRef: BsModalRef;

  @ViewChild("trimModifyModal", { static: true }) trimModifyModal: TemplateRef<any>;
  trimModifyModalRef: BsModalRef;


  constructor(public d: DataService, public router: Router, private route: ActivatedRoute) { }

  public PurchaseOrder: string;
  public JobName: string;
  public ShippingInstructions: string;
  public timeStamp: string = "";
  public printPrices: boolean = true;
  public printTotal: string = '0.00';
  public printSubTotal: string = '0.00';
  public editPanel: any;
  public editTrim: any;
  public reverseSort: boolean = false;
  public orderResults: any;
  public attachmentDialogFile: any;
  public addingAttachment: boolean = false;
  public uploading: boolean = false;
  public saving: boolean = false;
  public orderError: any;
  public orderMessages: any;
  public simStage: number = 0;
  public userStatus: string;
  public simData: any;
  public validExtensions = '.jpg,.png,.pdf,.doc,.docx';

  ngOnInit() {

    this.orderError = null;

    this.orderMessages = [];
    window['cart'] = this;

    if (this.d.model.cart === undefined) return this.router.navigate(['/home']);

    if (this.d.model.cart.OrderItemSet.length == 0) {
      this.d.toast("Your cart is empty!");
      this.router.navigate(['/home']);
    }

    this.reverseSort = false;
    this.d.cartRef = this; //print component needs this reference
    /*
    this.route.params.subscribe(function (result) {
      //console.log("route: ", result);
    });*/
    setTimeout(() => {
      //$("#cart-po").focus();
      this.d.focusInput("cart-po");
    }, 1000);
    //this.d.model.cart.PO = "Test: " + new Date().toLocaleString(); 

  }
  printCart() {
    //$("#printpricesModal").modal('show');
    this.printpricesModalRef = this.d.showModal(this.printpricesModal, 'modal-dialog-centered');
  }
  closeCartModal() {
    //$("#printpricesModal").modal('hide');
    this.printpricesModalRef?.hide();
  }
  async printCartConfirm() {


    let aD = new Date().toString().split(/\(|\:/); //new Date().toString().split('(');
    aD[3] = aD[3].replace(/[a-z ]/g, '');
    this.timeStamp = aD[0] + ":" + aD[1] + " (" + aD[3];
    this.printSubTotal = this.grandTotal();
    this.printTotal = this.grandTotal(true);

    setTimeout(window.print, 1000);

    await this.d.delay(200);
    this.closeCartModal();

  }
  /*
  toggleDoSimulate(){
    this.doSimulate = !this.doSimulate;
  }
  */
  poTimeStamp() {

    let ts = this.d.currentDateTime();
    if (this.d.model.cart.PO.length > 0) ts = " " + ts;
    this.d.model.cart.PO += ts;
    if (this.d.model.cart.PO.length > 35) {
      this.d.model.cart.PO = this.d.model.cart.PO.substring(0, 35);
      this.d.toast("PO Max length: 35 characters");
    }
  }

  grandTotal(includeJobsiteFreightCharge = false) {

    let total = 0;
    if (!this.d.model.hasOwnProperty("cart")) return "0.00";
    this.d.model.cart.OrderItemSet.forEach((i) => {
      total += parseFloat(i.metadata.pricingSetResult.ItemTotal);
    });
    if (includeJobsiteFreightCharge) {
      if (this.d.model.jobSite) {
        total += 125;
      }
    }
    return total.toFixed(2);
  }

  debugCart() {

    console.dir(this.d.model);
  }

  emptyCart() {
    //$('#emptycartModal').modal('show');
    this.emptycartModalRef = this.d.showModal(this.emptycartModal);
  }
  emptyCartConfirm() {

    //$('#emptycartModal').modal('hide');
    this.emptycartModalRef?.hide();
    this.d.model.cart = this.d.initCart();
    this.router.navigate(['/home']);
    this.d.toast("Your cart has been emptied");
  }
  emptyCartCancel() {
    //$('#emptycartModal').modal('hide');
    this.emptycartModalRef?.hide();
  }

  startOver() {


    this.d.model.cart = this.d.initCart();
    this.d.model.ShipTo = {};
    this.d.model.SoldTo = {};
    this.d.model.ShipMode = {};
    this.d.model.shipToList = { "routes": [], "willcall": [], "jobsitePreApproved": [] }
    this.d.model.jobSite = false;
    this.d.model.jobSiteForm = this.d.initJobsite();

    //todo: clear shipto and soldto
    this.router.navigate(['/home']);

    //get rid of any and all modals that may get stuck?
    setTimeout(() => {

      //$("#loadingMask").fadeOut(100);
      // $("#verifysavemodal").fadeOut(100);
      this.verifysaveModalRef?.hide();
      this.salesorderconfirmModalRef?.hide();
      //$("#salesorderconfirmmodal").fadeOut(100);
      //$(".modal-backdrop.in.fv-modal-stack").remove();

    }, 100);

    this.d.toast("Your cart has been emptied");
  }

  removeItem(itemId) {

    //console.log("removeItem: " + itemId);
    this.d.model.cart.OrderItemSet.splice(itemId, 1);
  }

  shipDate() {

    if (this.d.model.cart.OrderItemSet.length == 0) return undefined;
    let result = new Date();
    //TODO: an accessory with no Requesteddelivery triggered an error on November 9th
    this.d.model.cart.OrderItemSet.forEach((item) => {
      if (item.hasOwnProperty("Requesteddelivery")) {
        let compDate = this.d.sapToJsDate(item.Requesteddelivery);
        if (compDate > result) result = compDate;
      }
    });
    return result;
  }

  jsDateToSap(date) {
    var d = ('0' + date.getDate()).slice(-2);
    var m = ('0' + (date.getMonth() + 1)).slice(-2);
    var y = date.getFullYear();
    return [y, m, d].join();
  }



  sortCart() {


    this.d.model.cart.OrderItemSet.sort((a, b) => {

      let aPrefix = a.CutlistProvide == 'Y' ? 'A' : 'B';
      let bPrefix = b.CutlistProvide == 'Y' ? 'A' : 'B';

      if ((aPrefix + a.Materialnumber.toUpperCase()) < (bPrefix + b.Materialnumber.toUpperCase())) {
        return -1;
      } else {
        return 1;
      }

    });
    if (this.reverseSort) this.d.model.cart.OrderItemSet.reverse();

    this.reverseSort = !this.reverseSort;
  }

  openCutlist(item, itemId) {
    item.Itemnumber = itemId;

    this.editPanel = this.d.deref(item); //because we don't want to mess with item in cart until we confirm changes
    //$('#cutlistModal').modal('show');
    this.cutlistModalRef = this.d.showModal(this.cutlistModal);
  }



  async openTrim(item, itemId) {
    //console.log("openTrim, itemId = " + itemId);
    item.metadata.cartIndex = itemId;

    this.editTrim = {};
    await this.d.delay(250);
    //console.log("OpenTrim startangle before:" + item.metadata.TDLData.startAngle);
    //console.dir(item);
    this.editTrim = this.d.deref(item);
    //console.log("OpenTrim startangle after:" + this.editTrim.metadata.TDLData.startAngle);
    //console.dir(this.editTrim);
    //$('#trimModifyModal').modal('show');

    this.trimModifyModalRef = this.d.showModal(this.trimModifyModal, 'modal-xl modal-dialog-centered');

  }
  async openCustomTrim(item, itemId) {

    //console.log("openCustomTrim, itemId = " + itemId);
    item.metadata.cartIndex = itemId;

    this.editTrim = {};
    await this.d.delay(250);
    this.editTrim = this.d.deref(item);
    //console.dir(this.editTrim);
    //$('#customTrimModifyModal').modal('show');
    this.customTrimModifyModalRef = this.d.showModal(this.customTrimModifyModal, 'modal-xl modal-dialog-centered');
  }

  updatePriceDebounced = this.d.debounce(this.updatePrice, 300);

  updatePrice(item) {


    item.metadata.pricingSetResult = {
      NetPrice: '0.00',
      Perunit: '0.00',
      ItemTotal: '0.00'
    };

    //item.metadata.Quantity = parseInt(item.metadata.Quantity, 10);

    //item.metadata.pricingSetResult.ItemTotal = parseFloat(item.metadata.pricingSetResult.NetPrice) * item.metadata.Quantity;



    let fixedQty = Math.abs(parseInt(item.metadata.Quantity, 10));
    if (isNaN(fixedQty)) fixedQty = 0;
    item.metadata.Quantity = fixedQty;

    if (fixedQty == 0) return;



    item.metadata.pricingPayload.ReqQty = item.metadata.Quantity;
    item.Quantity001 = item.metadata.Quantity.toString();
    /*
    item.metadata.pricingPayload = {
      "ReqQty": item.metadata.Quantity,
      "SoldTo": this.d.model.SoldTo.Soldto,
      "Materialnumber": item.Materialnumber,
      "Colorcode": item.Colorcode,
      "Gauge": item.Gauge,
      "Ctwt": item.Ctwt,
      "PaintSystem": item.PaintSystem,
      "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
      "DownhillSlope": item.DownhillSlope,
      "UphillSlope": item.UphillSlope,
      "Type": "T", //doesn't matter if it's really a buyout, this value is ignored!
      "Metal": item.Metal,
      "Grade": item.Grade,
      "ShipTo": this.d.model.ShipTo.SHIPTO,
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
      "Girth":0.0
    }
    */

    this.d.post("/GetPricingSet", {}, item.metadata.pricingPayload).subscribe((result) => {
      item.metadata.pricingSetResult = result;
    });

  }


  onUpdateCart(event) {
    this.cutlistModalRef?.hide();
    this.trimModifyModalRef?.hide();
    this.customTrimModifyModalRef?.hide();
    //$('#cutlistModal').modal('hide');
    //$('#trimModifyModal').modal('hide');
    //$('#customTrimModifyModal').modal('hide');
    this.editPanel = null;
    this.editTrim = null;
  }

  async addAttachment() {

    this.addingAttachment = true;
    //$('#addattachmentModal').modal('show');
    this.addattachmentModalRef = this.d.showModal(this.addattachmentModal);
    //await this.d.delay(500);
    //$("#attachment-upload-input").trigger('click');
    /*
    for(var i = 0; i < 10; i++){
      await this.d.delay(100);
      var uploadEl = document.getElementById("attachment-upload-input");
      if (uploadEl !== undefined){
        await this.d.delay(100);
        $(uploadEl).trigger('click');
        i = 5;
      }
    }*/
    //setTimeout(()=>{$('#attachment-upload-input').trigger('click');},150);
    //this.d.model.cart.metadata.attachments.push({documents:[]});
  }

  fileDragLeave(event) {
    let srcElement: HTMLElement = (event.srcElement || event.target);
    srcElement.classList.remove("hovered");
    //$("#attachment-upload-label").removeClass("hovered");
    //this.d.removeClassById("attachment-upload-label", "hovered");
    event.preventDefault();
  }

  fileDragOver(event) {

    let srcElement: HTMLElement = (event.srcElement || event.target);
    srcElement.classList.add("hovered");
    //this.d.addClassById("attachment-upload-label", "hovered");
    //$("#attachment-upload-label").addClass("hovered");
    event.preventDefault();
  }
  fileDrop(event, animFlag: boolean) {

    event.preventDefault();
    let srcElement: HTMLElement = (event.srcElement || event.target);
    srcElement.classList.remove("hovered");
    if (event.dataTransfer.files.length > 0) {
      this.attachmentDialogFile = event.dataTransfer.files[0];
      if (this.attachmentDialogFile.name.indexOf(".") == -1) {
        this.d.toast('Cannot upload a folder');
      } else {
        this.confirmAttachment(animFlag);
      }
    } else {
      this.d.toast('The item you selected does not appear to be a file');
    }
  }
  fileChange(event) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.attachmentDialogFile = fileList[0];
    }
  }
  cancelAttachment() {

    this.addingAttachment = false;
    this.attachmentDialogFile = null;
    this.addattachmentModalRef?.hide();
  }


  async confirmAttachment(doAnimate: boolean) {



    let ext = this.attachmentDialogFile.name.split('.').pop();
    let allowedExts = 'jpg,JPG,jpeg,JPEG,png,PNG,pdf,PDF,doc,DOC,docx,DOCX'.split(',');
    if (allowedExts.indexOf(ext) == -1) {
      this.d.toast('File type "' + ext + '" is not supported');
      //$('#addattachmentModal').modal('hide');
      this.addattachmentModalRef?.hide();
    } else {
      let alreadyExists = this.d.model.cart.metadata.attachments.find((f) => { return f.name == this.attachmentDialogFile.name });
      if (alreadyExists !== undefined) {
        this.d.toast("That file is already attached");
        //$('#addattachmentModal').modal('hide');
        this.addattachmentModalRef?.hide();
      } else {
        this.uploading = true;
        if (doAnimate) await this.d.delay(1000);
        this.uploading = false;
        //$('#addattachmentModal').modal('hide');
        this.addattachmentModalRef?.hide();
        this.d.model.cart.metadata.attachments.push(this.attachmentDialogFile);
      }
    }

    this.cancelAttachment();
  }
  deleteAttachment(fileName) {

    this.d.model.cart.metadata.attachments = this.d.model.cart.metadata.attachments.filter((f) => { return f.name != fileName });
  }
  getExt(index) {

    return this.d.model.cart.metadata.attachments[index].name.split('.')[1];
  }
  countZeros() {

    return this.d.model.cart.OrderItemSet.filter((item) => { return item.metadata.Quantity == 0 });
  }

  verifySave() {


    if (this.countZeros().length > 0) return this.d.toast("At least one of your items is missing a quantity");

    if (this.d.model.cart.PO.length == 0) {
      //$("#cart-po").focus();
      this.d.focusInput("cart-po");
      return this.d.toast("Please fill in Purchase Order");
    }

    //$('#verifysaveModal').modal('show');
    this.verifysaveModalRef = this.d.showModal(this.verifysaveModal);
  }
  cancelSave() {
    //$('#verifysaveModal').modal('hide');
    this.verifysaveModalRef?.hide();
  }
  dblSave() {
    return this.d.toast("Please do not double click");
  }


  save = this.d.debounce(this.doSave, 300);

  saveE0002() {
    this.doSave('E0002');
  }

  saveE0004() {
    this.doSave('s');
  }

  doSave(UserStatus) {

    this.verifysaveModalRef?.hide(); // No reason to keep the modal on screen, adds risk that the order will be re-submitted!

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur(); //prevent resubmission should someone press enter!
    }

    this.orderMessages = [];

    if (this.saving) return this.d.toast("Saving already in progress, please wait...");
    this.saving = true;

    if (this.countZeros().length > 0) {
      this.saving = false;
      return this.d.toast("At least one of your items is missing a quantity");
    }

    if (this.d.model.cart.PO.length == 0) {
      //$("#cart-po").focus();
      this.d.focusInput("cart-po");
      this.saving = false;
      return this.d.toast("Please fill in Purchase Order");
    }
    this.d.model.cart.OrderItemSet.forEach((o, i) => {
      // o.metadata.cartError = null;
      o.Itemnumber = ((i + 1) * 1000).toString().padStart(6, '0');
    });
    //we make a copy of the cart, because we have to change a few things for it to be valid
    //if for some reason the sales order create fails, we want to keep the cart intact

    //let payload = this.d.deref(this.d.model.cart); //does not preserve blobs!

    let payload = this.d.cloneDeep(this.d.model.cart);

    let Requesteddelivery = this.d.jsToSapDate(this.shipDate());

    //Cet the itemnumber, generate the cutlists, and remove the metadata
    let svgSet = [];
    let tdlSet = [];

    payload.CutlistSet = [];
    payload.LogEntries = [];

    delete payload.metadata;
    payload.OrderItemSet.forEach((o, i) => {

      o.Colorcode = o.ColorCode;
      delete o.ColorCode;
      if (o.hasOwnProperty("Girth")) o.Girth = o.Girth.toString();
      o.Quantity001 = o.Quantity001.toString();
      o.Requesteddelivery = Requesteddelivery;

      if (o.metadata.hasOwnProperty("cutlist")) {
        o.metadata.cutlist.forEach((c) => {
          //CutlistSet takes a single array of cuts, but Cutlistnum has to match the source Itemnumber for it to match it
          payload.CutlistSet.push({
            "Notes": "", //no longer used, but required
            "BundlingNote": "",  //no longer used, but required
            "Cutlistnum": o.Itemnumber,
            "Quantity": c.Quantity,
            "Feet": c.Feet,
            "Inches": c.Inches,
            "Fractions": c.Fractions
          });
        });
      }

      if (o.metadata.hasOwnProperty("svg") && o.metadata.hasOwnProperty("TDLData")) {

        o.metadata.TDLData.flanges.forEach((f) => {
          delete f.metadata;
        });
        tdlSet.push({ item: o.Itemnumber, tdl: o.metadata.TDLData });
        svgSet.push({ item: o.Itemnumber, svg: o.metadata.svg });
        //console.log('added to svgSet', o.metadata.svg);
      }

      if (o.metadata.hasOwnProperty('cartError')) {
        payload.LogEntries.push({
          SalesOrderNumber: '',
          Type: o.metadata.cartError.Type,
          Itemnumber: o.Itemnumber,
          Message: o.metadata.cartError.Message,
        });
      }

      delete o.metadata;
    });

    console.log("UserStatus: ", UserStatus);
    if (UserStatus == null) {
      // we have just clicked okay on the date change dialog, or we are resubmitting without simulating
      this.simStage == 1; // not going to simulate again
      payload.SalesOrderSimulation = "";
    } else {
      // we have clicked confirm on submit order
      this.userStatus = UserStatus; // cache UserStatus for potential next use
      this.simStage == 0; // doing simulation
      payload.SalesOrderSimulation = "VALIDATE.DELIVERY.DATES";
    }

    payload.AttachmentCount = 0;
    payload.CustomerID = this.d.model.SoldTo.Soldto; //50080
    payload.CustomerName = this.d.model.SoldTo.Name; //"ALLIED BUILDING PRODUCTSS.."
    //payload.PO - already bound to model
    payload.OrderDate = this.d.jsToSapDate(new Date()); //right now!
    payload.SHIP_TYPE = this.d.model.ShipMode.ShippingType; //Z1
    payload.INCOTERMS1 = this.d.model.ShipMode.Incoterm;
    payload.INCOTERMS2 = this.d.model.ShipMode.Incoterm2;
    payload.UserStatus = this.userStatus;
    payload.OrderItemCount = payload.OrderItemSet.length;
    payload.ShipToAddressLine1 = this.d.model.ShipTo.STRAS;
    payload.ShipToAddressLine2 = this.d.model.ShipTo.ORT01;
    payload.ShipToCity = this.d.model.ShipTo.ORT02;
    payload.RequestedDate = Requesteddelivery;
    payload.ShipToContact = this.d.model.accountInfo.FirstName + " " + this.d.model.accountInfo.LastName; //used to be CustomerName!
    payload.ShipToCountry = this.d.model.ShipTo.LAND1;
    payload.ShipToRegion = this.d.model.ShipTo.REGIO;
    payload.ShipToPostalCode = this.d.model.ShipTo.PSTLZ;
    payload.ShipToPartnerID = this.d.model.ShipTo.SHIPTO;
    //payload.ShipmentInstruction - already bound to model
    payload.ShipmentInstruction = payload.ShipmentInstruction.replace(/\n|\r/g, " ");
    //payload.JobName - already bound to model
    payload.ECommUser = this.d.model.SoldTo.Soldto + "_99"; //The 99 means it came from ecomm
    payload.DeliveryOptionId = this.d.model.ShipTo.DeliveryOptions; //"10"
    payload.ShippingModeId = this.d.model.ShipMode.ShippingModeId;
    payload.RoutePostfix = this.d.model.ShipMode.RoutePostfix;
    if (payload.RoutePostfix === null) payload.RoutePostfix = "";
    payload.CarrierName = this.d.model.hasOwnProperty("currentCarrierName") ? this.d.model.currentCarrierName : "";
    payload.WillCall = (this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : "";
    payload.ECommEmail = this.d.model.accountInfo.UserName; //TODO: this should be the email selected in the SoldTo dialog
    if (this.d.model.jobSite && this.d.model.ShipTo.DeliveryOptions == '30') {
      //var jsdd = {... this.d.model.jobSiteForm};
      var jsdd = this.d.deref(this.d.model.jobSiteForm);
      delete jsdd.UnloadingMethod.html;
      delete jsdd.TruckEntryToJobsite.html;
      delete jsdd.errors;
      payload.JobSiteDeliveryData = "<![CDATA[" + JSON.stringify(jsdd, null, 2) + "]]>";
      console.log(JSON.stringify(jsdd, null, 2));
    }

    console.log("SALESORDER CREATE PAYLOAD:");
    //console.dir(payload);
    console.log(JSON.stringify(payload, null, 2));

    let formData = new FormData();
    this.d.model.cart.metadata.attachments.forEach((file) => {
      formData.append("attachment_" + file.name, file, "attachment_" + file.name);
      payload.AttachmentCount += 1;
    });

    this.d.model.cart.metadata.jobsiteAttachments.forEach((file) => {
      formData.append("jobsiteAttachment_" + file.name, file, "jobsiteAttachment_" + file.name);
      payload.AttachmentCount += 1;
    });

    formData.append('payload', new Blob([JSON.stringify(payload, null, 2)]));

    /*
    we also need to append any SVG drawings and TDL data we may have
    */

    tdlSet.forEach((o, i) => {
      //console.log('blobbing tdl', o.tdl);
      formData.append('tdl_' + o.item, new Blob([JSON.stringify(o.tdl, null, 2)]));
    });
    svgSet.forEach((o, i) => {
      //console.log('blobbing svg', o);
      formData.append('svg_' + o.item, new Blob([o.svg]));
    });
    this.orderError = null;

    if (UserStatus != null) {
      // not a simultion, this will create a record
      this.d.toast("Saving in progress, please wait... [" + UserStatus + "]");
    }

    this.d.postForm("/SalesOrderSave", {}, formData, false).subscribe(result => {

      let data = result;
      this.saving = false;

      // gather error data
      this.orderError = data;
      this.orderError.Messages = [];
      if (!this.d.isNil(data.OrderItemSet) && !this.d.isNil(data.OrderItemSet.results)) {
        data.OrderItemSet.results.forEach((i) => {
          console.log(i.Itemnumber + ": " + i.Message);
          if (i.Message != "") {
            let targetItem = this.d.model.cart.OrderItemSet.find(o => o.Itemnumber == i.Itemnumber);
            if (!this.d.isNil(targetItem)) {
              targetItem.metadata.cartError = i.Message;
              if (this.orderError.Messages.indexOf(i.Message) == -1) {
                this.orderError.Messages.push(i.Itemnumber + ": " + i.Message);
              }
            }
          }
        });
      }

      // Gather HEADER date shifts
      if (data.Message.length > 0) {
        this.orderMessages.push({ type: 'headMsg', msg: '<b>Order Message:</b> &quot;' + data.Message + '&quot;' });
      }

      if (data.RequestedDate !== undefined && data.RequestedDate.length > 0 && data.RequestedDate != payload.RequestedDate && data.RequestedDate.length) {
        this.orderMessages.push({
          type: 'headMsg', msg: '<b>Order ship date updated:</b> to '
            + this.d.sapToJsDate(data.RequestedDate).toDateString() + " <i>("
            + this.d.sapToJsDate(payload.RequestedDate).toDateString() + " was requested)</i>"
        });
      }

      // Gather ITEM date shifts
      if (!this.d.isNil(data.OrderItemSet) && !this.d.isNil(data.OrderItemSet.results)) {
        data.OrderItemSet.results.forEach((i) => {

          if (i.Message != "") {
            this.orderMessages.push({ type: 'itemmMsg', msg: '<b>Item ' + i.Itemnumber + ' Message:</b> &quot;' + i.Message + '&quot;' });
          } else {
            if (i.OldDeliveryDate != "" && i.OldDeliveryDate != i.Requesteddelivery) {
              this.orderMessages.push({
                type: 'itemMsg', msg: '<b>Item ' + i.Itemnumber + ' ship date updated:</b> to '
                  + this.d.sapToJsDate(i.Requesteddelivery).toDateString() + " <i>("
                  + this.d.sapToJsDate(i.OldDeliveryDate).toDateString() + " was requested)</i>"
              });
            }
          }
        });
      }

      if (data.SalesOrderNumber == "" && this.simStage == 0) {
        // we are simulating, and dates need to change:
        this.simData = data;
        if (this.orderMessages.length == 0) {
          // no messages generated during simulation, do it for real
          this.doSave(null);
        } else {
          //$('#verifysaveModal').modal('hide');
          this.verifysaveModalRef?.hide();
          setTimeout(() => {
            if (this.orderMessages.length == 1) {
              // $('#salesorderErrorModal').modal('show');
              this.salesorderErrorModalRef = this.d.showModal(this.salesorderErrorModal);
            } else {
              this.salesorderWarnChangesModalRef = this.d.showModal(this.salesorderWarnChangesModal);
              //$('#salesorderWarnChangesModal').modal('show');
            }
          }, 500);
        }


      } else {
        // this is the real deal, check for issues:
        if (data.SalesOrderNumber == "") {
          // we have a problem
          console.error("SalesOrderSave failed:")
          console.dir(data);

          let formData = new FormData();
          formData.append('SalesOrderSaveFailed', new Blob(['Failed']));
          formData.append('SalesOrderSavePayload', new Blob([data]));
          formData.append('model', new Blob([JSON.stringify(this.d.model, null, 2)]));
          formData.append('runlog', new Blob([this.d.runLog.join('\r\n')]));
          formData.append('route', new Blob([window.location.href]));
          formData.append('userAgent', new Blob([window.navigator.userAgent]));
          this.d.postForm("/ReportUIError", {}, formData, true).subscribe(result => {
            let data = result;
            console.dir(data);
            console.log("Submitted cart for analysis");
          });

          this.cancelSave();
          //$('#verifysaveModal').modal('hide');
          this.verifysaveModalRef?.hide();
          setTimeout(() => {
            //$('#salesorderErrorModal').modal('show');
            this.salesorderErrorModalRef = this.d.showModal(this.salesorderErrorModal);
          }, 500);
        } else {
          // ALL GOOD!
          data.UserStatus = payload.UserStatus; //why doesn't SAP return it?
          this.orderResults = data;
          //TEMPLATE: E0002
          //   QUOTE: E0003
          //   ORDER: E0004
          this.orderResults.orderType = (payload.UserStatus == "E0004") ? "order" : "template";
          //$('#verifysaveModal').modal('hide');
          this.verifysaveModalRef?.hide();
          //$('#salesorderconfirmModal').modal('show');
          this.salesorderconfirmModalRef = this.d.showModal(this.salesorderconfirmModal);
        }
      }
    }, error => {
      //this should show the error modal, not just re-enable the save button!
      this.saving = false;
    });

  }

  denyDateChanges() {
    //$('#salesorderWarnChangesModal').modal('hide');
    this.salesorderWarnChangesModalRef?.hide();
  }

  applyDateChanges(continueOrder: boolean) {


    // We don't have to modify the Header ship date, because that is a function of the items

    // Update item RequestedDates
    if (!this.d.isNil(this.simData.OrderItemSet) && !this.d.isNil(this.simData.OrderItemSet.results)) {
      this.simData.OrderItemSet.results.forEach((i) => {

        let targetItem = this.d.model.cart.OrderItemSet.find(o => o.Itemnumber == i.Itemnumber);
        if (!this.d.isNil(targetItem)) {
          targetItem.Requesteddelivery = i.Requesteddelivery;
        }
      });
    }

    //$('#salesorderWarnChangesModal').modal('hide');
    this.salesorderWarnChangesModalRef?.hide();
    if (continueOrder) {
      this.doSave(null); // passing null lets us know to skip the simulation
    }
  }

  clearSaveError() {
    //$('#salesorderErrorModal').modal('hide');
    this.salesorderErrorModalRef?.hide();
  }

}
