
import { Component, Input, Injectable, Pipe, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'
//import { Observable } from 'rxjs/Rx';
//import { Subscription } from 'rxjs/Subscription';
import { DataService } from '../../data.service';


@Component({
  selector: 'trimprice',
  templateUrl: './trimprice.component.html',
  encapsulation: ViewEncapsulation.None //disables Shadow DOM, so we can style SharePoint HTML
})
export class TrimPriceComponent {

  constructor(public d: DataService, private router: Router) { }
  @Input() trim;

  ngOnInit() {

    //console.log("TrimPriceComponent init!");
    //console.dir(this.trim);

    setTimeout(() => {
      this.trim.metadata.CombinedSlope = {};
      this.trim.metadata.UphillSlope = {};
      this.trim.metadata.DownhillSlope = {};


      //Initial Qty pricing won't work if a slope is required but not selected:
      if (this.trim.CombinedSlopes.results.length > 0) this.trim.metadata.CombinedSlope = this.trim.CombinedSlopes.results[0];
      if (this.trim.FreeDownHillSlopes.results.length > 0) this.trim.metadata.DownhillSlope = this.trim.FreeDownHillSlopes.results[0];
      if (this.trim.FreeUpHillSlopes.results.length > 0) this.trim.metadata.UphillSlope = this.trim.FreeUpHillSlopes.results[0];

      this.updatePrice();
      let inputId = this.trim.TrimId + '_' + this.trim.ColorId;
      this.d.focusInput(inputId);
    }, 1);

  }

  reprice = this.d.debounce(this.updatePrice, 300);

  selectInputContents(e) {
    let srcElement = (e.srcElement || e.target);
    srcElement.value.length;
    srcElement.setSelectionRange(0, srcElement.value.length);
  }

  updatePrice() {
    //console.log("updatePrice!");

    this.trim.metadata.pricingSetResult = { NetPrice: '0.00' };
    this.trim.metadata.Quantity = Math.abs(parseInt(this.trim.metadata.Quantity, 10));
    if (isNaN(this.trim.metadata.Quantity)) this.trim.metadata.Quantity = 0;
    this.trim.metadata.pricingPayload = {
      "ReqQty": this.trim.metadata.Quantity,
      "SoldTo": this.d.model.SoldTo.Soldto,
      "Materialnumber": this.trim.TrimId,
      "Colorcode": this.trim.ColorId,
      "Gauge": this.trim.GaugeId,
      "PaintSystem": this.trim.PaintSystemId,
      "ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
      "DownhillSlope": '',
      "UphillSlope": '',
      "Type": "T",
      "Metal": this.trim.MetalId,
      "Grade": this.trim.GradeId,
      "ShipTo": this.d.model.ShipTo.SHIPTO,
      "ShippingModeId": this.d.model.ShipMode.ShippingModeId,
      "WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
      "Ctwt": this.trim.CoatingWtId,
      "Girth": 0,
      "Hems": 0,
      "Strokes": 0,
      "Pitch": 0
    }
    if (this.trim.metadata.CombinedSlope.hasOwnProperty("Description")) {
      this.trim.metadata.pricingPayload.DownhillSlope = this.trim.metadata.CombinedSlope.Downhill;
      this.trim.metadata.pricingPayload.UphillSlope = this.trim.metadata.CombinedSlope.Uphill;
    }
    //still need to add Up and Down 


    this.trim.metadata.Loading = true;
    this.d.post("/GetPricingSet", {}, this.trim.metadata.pricingPayload, false).subscribe((result) => {
      this.trim.metadata.pricingSetResult = result;
      //NetPrice is the unit price for some reason
      //ItemTotal is the sum of all units
      if (parseFloat(this.trim.metadata.pricingSetResult.NetPrice) == 0) {
        if (this.trim.metadata.pricingSetResult.MessageText.length > 0) {
          this.d.toast(this.trim.metadata.pricingSetResult.MessageText);
        } else {
          this.d.toast("Pricing error, please contact your ASC BP Customer Service Representative");
        }
      }

      this.trim.metadata.pricingSetResult.ItemTotal = parseFloat(this.trim.metadata.pricingSetResult.NetPrice) * this.trim.metadata.Quantity;
      this.trim.Requesteddelivery = this.trim.metadata.pricingSetResult.DeliveryDate;

      this.trim.metadata.Loading = false;
    });

  }


  addToCart(event) {

    //console.dir(event);
    //console.log("Adding Trim To Cart:");
    //console.dir(this.trim);

    let orderItem = {
      "OfferingID": "", //always blank unless a panel
      "Itemnumber": "001000", //reindexed in cart
      "Product": this.trim.TrimId, //"CH16_P",
      "Materialnumber": this.trim.TrimId, //"CH16_P",
      "ColorCode": this.trim.ColorId, //"RUSC",
      "Gauge": this.trim.GaugeId, //"26",
      "CutlistProvide": "T", //never Y unless a panel
      "Productname": this.trim.Description, //"UPHILL CHIMNEY PTD (CH16_P)",
      "Metal": this.trim.MetalId, //"ZNAL",
      "Ctwt": this.trim.CoatingWtId,
      "Grade": this.trim.GradeId, //"33",
      "PaintSystem": this.trim.PaintSystemId, //"DTXL",
      "Girth": "0", //only used for custom trims and XYs
      "UphillSlope": this.trim.metadata.UphillSlope.hasOwnProperty("Slope") ? this.trim.metadata.UphillSlope.Slope : "",
      "DownhillSlope": this.trim.metadata.DownhillSlope.hasOwnProperty("Slope") ? this.trim.metadata.DownhillSlope.Slope : "",

      //"UphillSlope" : this.trim.metadata.pricingPayload.UphillSlope ,//"0.5",
      //"DownhillSlope" :  this.trim.metadata.pricingPayload.DownhillSlope, //"NA",
      "Requesteddelivery": this.trim.metadata.pricingSetResult.DeliveryDate,//"20170130",
      "Unitofmeasure": this.trim.metadata.pricingSetResult.Unitofmeasure, //"PC",
      "Quantity001": (this.trim.metadata.Quantity + ".000"), //"2.000",
      "Plant": this.trim.metadata.pricingPayload.ShipPlant, //"SA03",
      "metadata": this.trim.metadata
    }
    if (this.trim.metadata.CombinedSlope.hasOwnProperty("Uphill")) {
      orderItem.UphillSlope = this.trim.metadata.CombinedSlope.Uphill;
      orderItem.DownhillSlope = this.trim.metadata.CombinedSlope.Downhill;
    }
    //console.log("After:");
    //console.dir(orderItem);
    //this.d.flyToElement($(event.srcElement), $('cartbutton'));
    this.d.addToCartAnim();
    this.d.model.cart.OrderItemSet.push(this.d.deref(orderItem));
    return this.d.toast("Item Added To Cart");
  }

}
