<header class="routeheader ">

  <div class="dist-cols-parent">
    <div class="dist-cols-child">

      &nbsp;
    </div>

    <div class="dist-cols-child pageTitle">
      <fa-icon [icon]="d.faPlay"></fa-icon>Configure {{panel.metadata.product.NAME}}
    </div>

    <div class="dist-cols-child">
      <cartbutton></cartbutton>
    </div>
  </div>

</header>
<div class="routecontent configure-content">


  <div *ngIf="panel && panel.metadata?.product?.OFFERING_ID">


    <div class="container" style="margin:0;padding:0">
      <div class="col-sm-2" style="padding:0;text-align:center">

        <img class="panel-image float-md-start" src="{{panel.metadata?.product.ImageUrl|cms}}" />

      </div>
      <div class="col-sm-10">
        <span class="panel-title">{{panel.metadata.product.NAME}}</span><br />
        <span class="panel-id">{{panel.metadata.product.MATERIAL_NUMBER}} /
          {{panel.metadata.product.PaintSystem}}</span> <br />

        <panel [panel]="panel"></panel>


      </div>



    </div>

    <div class="chipWarning">REPRESENTATION OF COLORS MAY VARY DUE TO DISPLAY LIMITATIONS. Sample color chips are
      available <a target="_blank" href="https://www.ascbp.com/resource-center/literature-sample-product-request/"
        rel="noopener noreferrer nofollow">upon request</a>. Consult your ASC Building Products representative for more
      information.</div>

    <span style="font-weight:bold">Cut List:</span>


    <div *ngIf="!panel.metadata.gauge.GaugeDescription"
      [ngClass]="(panel.metadata.gauge.GaugeDescription) ? '' : 'disabled'">
      <cutlist [panel]="panel" [semaphore]="semaphore"></cutlist>
    </div>
    <div *ngIf="panel.metadata.gauge.GaugeDescription">
      <cutlist [panel]="panel" [semaphore]="semaphore"></cutlist>
    </div>
  </div>

</div>

<footer>

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <div ngbDropdown placement="top-start">
        <button type="button" class="btn btn-secondary" id="catalogsBtn" ngbDropdownToggle><fa-icon
            [icon]="d.faFilePdf"></fa-icon>Catalogs</button>
        <div ngbDropdownMenu aria-labelledby="catalogsBtn">
          <a *ngFor="let cl of d.catalogLinks" ngbDropdownItem href="{{cl.href}}" target="_blank"
            rel="noopener noreferrer nofollow"><fa-icon [icon]="d.faFilePdf"></fa-icon><span
              [innerHTML]="cl.html | safe"></span></a>
        </div>
      </div>

    </div>

    <div class="dist-cols-child"
      [class.disabled]="(!panel.metadata.color.hasOwnProperty('ColorCode'))||(!panel.metadata.gauge.hasOwnProperty('Gauge'))">
      <button class="btn btn-secondary" title="Find matching accessories" (click)="goTrims()">
        <fa-icon [icon]="d.faShare"></fa-icon>Trims
      </button>
      &nbsp;
      <button class="btn btn-secondary" title="Find matching trims" (click)="goAccessories()">
        <fa-icon [icon]="d.faShare"></fa-icon>Accessories
      </button>
    </div>
  </div>




</footer>