//import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Component, Input, Injectable, Pipe, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'
//import { Observable } from 'rxjs/Rx';
//import { Subscription } from 'rxjs/Subscription';
import { DataService } from '../../data.service';

@Component({
	selector: 'accprice',
	templateUrl: './accprice.component.html',
	encapsulation: ViewEncapsulation.None //disables Shadow DOM, so we can style SharePoint HTML
})
export class AccPriceComponent {
	constructor(public d: DataService, private router: Router) { }
	@Input() accessory;

	ngOnInit() {

		//console.log("AccPriceComponent init!");
		//console.dir(this.accessory);
		setTimeout(() => {
			this.updatePrice();
		}, 1);


		setTimeout(() => {
			let inputId = 'acc_' + this.d.stripNonAlphaNumeric(this.accessory.BuyOutProfileId); //WHY do these have special characters in the first place?
			this.d.focusInput(inputId);

		}, 150);

	}

	reprice = this.d.debounce(this.updatePrice, 300);

	clearPrice() {
		this.accessory.metadata.pricingSetResult = { NetPriceTotal: '0.00' };
	}

	selectInputContents(e) {
		let srcElement = (e.srcElement || e.target);
		srcElement.value.length;
		srcElement.setSelectionRange(0, srcElement.value.length);
	}

	async updatePrice() {

		this.accessory.metadata.pricingSetResult = { NetPriceTotal: '0.00' };
		let fixedQty = Math.abs(parseInt(this.accessory.metadata.Quantity, 10));
		if (isNaN(fixedQty)) fixedQty = 0;
		this.accessory.metadata.Quantity = fixedQty;

		if (fixedQty == 0) return;

		this.accessory.metadata.pricingPayload = {
			"ReqQty": this.accessory.metadata.Quantity,
			"SoldTo": this.d.model.SoldTo.Soldto,
			"Materialnumber": this.accessory.BuyoutId,
			"Colorcode": "",
			"Gauge": "",
			"PaintSystem": "",
			"ShipPlant": this.d.model.ShipTo.SHIPPINGPLANT,
			"DownhillSlope": "",
			"UphillSlope": "",
			"Type": "",
			"Metal": "",
			"Grade": "",
			"ShipTo": this.d.model.ShipTo.SHIPTO,
			"ShippingModeId": this.d.model.ShipMode.ShippingModeId,
			"WillCall": ((this.d.model.ShipTo.DeliveryOptions == "20") ? "X" : ""),
			"Ctwt": "",
			"Girth": 0.0,
			"Hems": 0,
			"Strokes": 0,
			"Pitch": 0
		}



		this.accessory.Loading = true;

		this.d.post("/GetPricingSet", {}, this.accessory.metadata.pricingPayload, true).subscribe((result) => {

			this.accessory.metadata.pricingSetResult = result;
			this.accessory.metadata.pricingSetResult.ReqQty = fixedQty;

			//NetPrice is the unit price for some reason
			//ItemTotal is the sum of all units
			if (parseFloat(this.accessory.metadata.pricingSetResult.NetPrice) == 0) {
				if (this.accessory.metadata.pricingSetResult.MessageText.length > 0) {
					this.d.toast(this.accessory.metadata.pricingSetResult.MessageText);
				} else {
					this.d.toast("Pricing error, please contact your ASC BP Customer Service Representative");
				}
			}
			/*
			this.accessory.metadata.pricingSetResult.ItemTotal = parseFloat(this.accessory.metadata.pricingSetResult.NetPrice) * this.accessory.metadata.Quantity;
			*/
			this.accessory.Requesteddelivery = this.accessory.metadata.pricingSetResult.DeliveryDate;

			this.accessory.metadata.Loading = false;
		});

	}


	addToCart() {

		//console.log("Before:");
		//console.dir(this.accessory);
		if (this.accessory.metadata.pricingSetResult.NetPriceTotal == '0.00') {
			return;
		}
		let orderItem = {
			"OfferingID": "",
			"Itemnumber": "001000", //needs to be changed on salesorder create
			"Product": this.accessory.BuyoutId,//"RIV1/8_BARE",
			"Materialnumber": this.accessory.BuyoutId,// "RIV1/8_BARE",
			"ColorCode": this.accessory.ColorId, //"BARE",
			"Gauge": this.accessory.GaugeId,
			"CutlistProvide": "B", //only panels set Y
			"Productname": this.accessory.Description, //"1/8\" RIVET BARE (250CT) (RIV1/8_BARE)",
			"Metal": "",
			"Grade": "",
			"Girth": "0", //only used for custom trims and XYs
			"Ctwt": "",
			"PaintSystem": "",
			"UphillSlope": "",
			"DownhillSlope": "",
			"Requesteddelivery": this.accessory.metadata.pricingSetResult.DeliveryDate,
			"Unitofmeasure": this.accessory.metadata.pricingSetResult.Unitofmeasure, //"BAG",
			"Quantity001": (this.accessory.metadata.Quantity + ".000"), // "1.000",
			"Plant": this.accessory.ShippingPlant, //"SA03",
			"metadata": this.accessory.metadata
		}

		//console.log("After:");
		//console.dir(orderItem);

		this.d.addToCartAnim();
		this.d.model.cart.OrderItemSet.push(this.d.deref(orderItem));
		return this.d.toast("Item Added To Cart");
	}

	addBtnDisabled() {
		if (this.accessory.metadata.Loading) return true;
		if (this.accessory.metadata.pricingSetResult == null) return true;
		if (this.accessory.metadata.pricingSetResult.NetPriceTotal == '0.00') return true;
		if (this.accessory.metadata.Quantity != this.accessory.metadata.pricingPayload.ReqQty) return true;
		if (this.accessory.metadata.pricingPayload.ReqQty != this.accessory.metadata.pricingSetResult.ReqQty) return true;
		return false;
	}

}
