<div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (navChange)="switchTab($event)">
  <div *ngFor="let tab of tabs; index as i; " [ngbNavItem]="i">
    <a ngbNavLink>{{tab.title}}</a>
    <ng-template ngbNavContent>
      <img *ngIf="tab.imageUrl" class="float-md-start" src="{{tab.imageUrl | cms}}" class="expand-image" />
      <span class="panel-description" [innerHTML]="tab.detail | safe"></span>
    </ng-template>
  </div>
</div>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
<!--<pre>{{tabs|json}}</pre>-->