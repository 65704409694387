<div *ngIf="customtrim" (contextmenu)="d.noAction($event)">

  <ng-container *ngIf="customtrim.metadata">


    <div style="margin-top:10px;margin-bottom:10px">
      <span *ngIf="!isFCT" style="font-weight:bold;font-size:1.3em">{{customtrim.metadata.Name}}</span>
      <input *ngIf="isFCT" [(ngModel)]="customtrim.metadata.TDLData.name" id="fctname" class="form-control"
        placeholder="Custom Trim Name" style="font-size:1.3em;font-weight:bold;display:inline-block;width:300px;" />
      <span style="font-weight:normal;position:relative;top:-0.1em">({{customtrim.metadata.PictureId}})</span>
    </div>


    <div class="trimLeftCol">

      <trimrender *ngIf="initialized" [renderid]="'trimsvg_fct'" [(customtrim)]="customtrim"
        (wheel)="d.noAction($event)"></trimrender>

      <div class="dist-cols-parent" style="width:400px;margin-top: 10px;">
        <div class="dist-cols-child">
          <b>Labels:</b>
          &nbsp;&nbsp;
          <div class="input-group input-group-sm">
            <button title="Retract leg labels" class="btn btn-outline-secondary btn-secondary iconBtn"
              (click)="setLabelDistance(-1)">
              <fa-icon [icon]="d.faMinus"></fa-icon>
            </button>
            <button title="Extend leg labels" class="btn btn-outline-secondary btn-secondary iconBtn"
              (click)="setLabelDistance(1)">
              <fa-icon [icon]="d.faPlus"></fa-icon>
            </button>
          </div>
          &nbsp;&nbsp;
          <label style="white-space:nowrap" title="Switch leg labels to the other side">
            <input [checked]="customtrim.metadata.TDLData.labelFlip"
              style="top: 1px;margin-top: 3px;position: relative;left: 7px;" type="checkbox"
              (change)="toggleLabelFlip()" />
            <b>Flip</b>
          </label>

        </div>
        <div class="dist-cols-child" *ngIf="initialized">
          <div class="input-group input-group-sm" [class.disabled]="customtrim.metadata.foundLockVert"
            style="display:contents;max-width:200px">
            <span class="input-group-text">Rotate</span>
            <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button" title="Rotate Left 15 deg"
              (mousedown)="spinnerStart('rotNeg',null,'fct_rot')" (mouseup)="spinnerStop()"
              (mouseleave)="spinnerStop()">
              <fa-icon [icon]="d.faUndo"></fa-icon>
            </button>

            <input [(ngModel)]="customtrim.metadata.TDLData.startAngle" id="fct_rot" name="fct_rot"
              style="width:75px; text-align:center" (focus)="selectInputContents($event)"
              (ngModelChange)="fixRotation()" type="text" class="form-control input-degree"
              aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">

            <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button" title="Rotate Right 15 deg"
              (mousedown)="spinnerStart('rotPos',null,'fct_rot')" (mouseup)="spinnerStop()"
              (mouseleave)="spinnerStop()">
              <fa-icon [icon]="d.faRedo"></fa-icon>
            </button>

          </div>
        </div>
      </div>

      <div class="form-inline">
        <div class="form-group" style="margin-left:auto;margin-right:auto;margin:10px;">

        </div>


      </div>


      <div style="font-size:smaller;font-style:italic;width:400px;text-align:left">
        <b>Note:</b> If the angle and length labels are not clearly visible, your trim may not pass inspection.
        If it helps increase legibility, please consider
        <span style="text-decoration:underline">rotating</span> the drawing and/or <span
          style="text-decoration:underline">flipping</span> the leg labels, and <span
          style="text-decoration:underline">avoid overlapping</span> with -/+.
      </div>
      <br>
      <!--
        <div *ngIf="d.model.accountInfo && d.model.accountInfo.IsItAdmin" class="adminBtns">
          <button (click)="debug()">Debug</button>
          <button (click)="copyTdl()">Copy TDL</button>
          <button (click)="downloadSvg()">Download</button>
          <button (click)="d.trimrenderRef.toggleLabels()">Toggle Labels</button>
        </div>
        -->
    </div>
    <div class="trimMidCol">

      <div class="form-group">
        <b class="trimformlabel">Gauge:</b>
        <ng-container *ngIf="isFCT">
          <div class="btn-group" [class.disabled]="!d.model.TrimFullyCustomGetFilterGauge">
            <label *ngFor="let gauge of d.model.TrimFullyCustomGetFilterGauge" (click)="selectGauge(gauge.GaugeId)"
              class="btn btn-default text-left radio-btn filter-option"
              [class.active]="customtrim.Gauge==gauge.GaugeId">
              <fa-stack>
                <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
                <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E"
                  *ngIf="customtrim.Gauge==gauge.GaugeId"></fa-icon>
              </fa-stack>
              {{gauge.GaugeDescription}}
            </label>
          </div>
        </ng-container>

        <ng-container *ngIf="!isFCT">
          <div class="btn-group" [class.disabled]="!customtrim.metadata.TrimXYGroupFilterGauge">
            <label *ngFor="let gauge of customtrim.metadata.TrimXYGroupFilterGauge" (click)="selectGauge(gauge.GaugeId)"
              class="btn btn-default text-left radio-btn filter-option"
              [class.active]="customtrim.Gauge==gauge.GaugeId">
              <fa-stack>
                <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
                <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E"
                  *ngIf="customtrim.Gauge==gauge.GaugeId"></fa-icon>
              </fa-stack>
              {{gauge.GaugeDescription}}
            </label>
          </div>
        </ng-container>


      </div>

      <div [class.disabled]="!customtrim.Gauge">

        <div class="form-group" style="margin-top:10px;">
          <b class="trimformlabel">Color:</b>
          <div class="btn-group">

            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-default disabled"
                *ngIf="!customtrim.metadata.TrimXYGroupFilterBaseColor">
                ...
              </button>
              <button type="button" class="btn btn-default" id="xy-color-select" ngbDropdownToggle
                *ngIf="customtrim.metadata.TrimXYGroupFilterBaseColor">
                <span *ngIf="!customtrim.metadata?.color?.BasecolorDescription">Select Color...</span>
                <span *ngIf="customtrim.metadata?.color?.BasecolorDescription">
                  &nbsp;
                  <img src="{{customtrim.metadata.color.ImageUrl|cms}}" class="color-menu-image" />
                  {{customtrim.metadata?.color?.BasecolorDescription}}
                </span>
              </button>
              <div ngbDropdownMenu class="scrolldrop">
                <ng-container *ngFor="let color of customtrim.metadata.TrimXYGroupFilterBaseColor">
                  <button ngbDropdownItem (click)="selectColor(color)"
                    [ngClass]="{'selected':customtrim.metadata.color==color}" title="{{color.BasecolorId}}">
                    <img src="{{color.ImageUrl|cms}}" class="color-menu-image" />{{color.BasecolorDescription}}</button>
                </ng-container>
              </div>
            </div>

          </div>
        </div>

        <div class="form-group"
          [class.disabled]="customtrim.metadata.materialType!='P' || !customtrim.metadata.TDLData.canSelectPaintedSide">
          <b class="trimformlabel">Paint On:</b>


          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-default" id="paint-side-select" ngbDropdownToggle>
              <span *ngIf="customtrim.metadata.TDLData.paintedSide=='L'">Default Side</span>
              <span *ngIf="customtrim.metadata.TDLData.paintedSide=='R'">Opposite Side</span>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="setPaintedSide('L')"
                [ngClass]="{'selected':customtrim.metadata.TDLData.paintedSide=='L'}">Default Side</button>
              <button ngbDropdownItem (click)="setPaintedSide('R')"
                [ngClass]="{'selected':customtrim.metadata.TDLData.paintedSide=='R'}">Opposite Side</button>
            </div>

          </div>

        </div>

        <div class="form-group" *ngIf="customtrim.metadata.PictureId=='FLASHXY'" style="margin-top:5px">
          <b class="trimformlabel">&nbsp;</b>


          <div
            style="display:inline-block;max-width:260px;padding:5px;border:1px solid #cc9;background-color:#ffc;font-size:smaller;font-style:italic">
            <b>Note: </b>The default 'Paint On' side for FLASHXY has been recently changed. Please check final drawing
            to ensure the painted side is accurate
          </div>

        </div>



      </div>


      <div class="flangeForm" [class.disabled]="!customtrim.metadata.color">
        <div *ngIf="isFCT||d.isHem(customtrim.metadata.TDLData.flanges[0])">
          <b class="trimformlabel">Start Hem:</b>

          <div *ngIf="isFCT" ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-default" id="xy-color-select" ngbDropdownToggle>
              <ng-container *ngIf="d.isHem(customtrim.metadata.TDLData.flanges[0])">
                <img [attr.src]="'/assets/images/' + customtrim.metadata.TDLData.flanges[0].type + '.svg'"
                  class="hemIcon svgIcon" />{{customtrim.metadata.TDLData.flanges[0].type|meta:'hemDescription'}}
              </ng-container>
              <ng-container *ngIf="!d.isHem(customtrim.metadata.TDLData.flanges[0])">
                None
              </ng-container>
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="setHem(true,'')"
                [class.selected]="!d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])">
                None
              </button>
              <ng-container *ngFor="let hemType of hemTypes" (click)="setHem(true,hemType)">
                <button ngbDropdownItem (click)="setHem(true,hemType)"
                  [class.selected]="customtrim.metadata.TDLData.flanges[0].type == hemType">
                  <img [attr.src]="'/assets/images/'+hemType+'.svg'"
                    class="hemIcon svgIcon" />{{hemType|meta:'hemDescription'}}
                </button>
              </ng-container>
            </div>
          </div>
          <span *ngIf="d.isHem(customtrim.metadata.TDLData.flanges[0])">
            <img *ngIf="!isFCT" class="svgIcon"
              [attr.src]="'/assets/images/'+customtrim.metadata.TDLData.flanges[0].type+'.svg'">
            {{customtrim.metadata.TDLData.flanges[0].value}}&quot;
          </span>
          <br />
        </div>
        <div style="margin-top:10px;margin-bottom:10px;">

          <div class="flangeList" [class.reverseRow]="customtrim.metadata.PictureId=='FLASHXY'">

            <ng-container
              *ngFor="let flange of customtrim.metadata.TDLData.flanges; index as i; first as first; last as last;">

              <ng-container *ngIf="flange.type == 'line' || flange.type == 'angle'">

                <div *ngIf="flange.type=='line'" class="form-group" (mouseover)="hover(flange)"
                  (mouseout)="unhover(flange)">

                  <div class="input-group" *ngIf="isFCT || flange.name">

                    <span class="input-group-text" [class.linelabelParent]="canRemoveAt(i)" style="width:37px">
                      <span class="linelabel">{{flange.name ?? '─'}}
                        <span *ngIf="flange.flags=='lockVert'" title="Segment Orientation Locked Vertically"
                          style="margin-left:10px">
                          <fa-icon class="lockVertIcon" [icon]="d.faLock" size="xs"></fa-icon>
                        </span>
                      </span>
                      <button (click)="removeAt(i)" title="Remove this length and following angle"
                        class="btn btn-sm btn-danger btn-xs lineremovebtn lineremove iconBtn"
                        style="border-radius:15px">
                        <fa-icon [icon]="d.faTimes"></fa-icon>
                      </button>
                    </span>

                    <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button"
                      title="Rotate Left 15 deg" (mousedown)="spinnerStart('lenNeg',flange,'line_'+i)"
                      (mouseup)="spinnerStop()" (mouseleave)="spinnerStop()">
                      <fa-icon [icon]="d.faCaretDown"></fa-icon>
                    </button>

                    <input [(ngModel)]="flange.value" [attr.id]="'line_'+i" [attr.name]="'line_'+i"
                      style="text-align:center" (focus)="selectInputContents($event,flange)" (blur)="blur(flange)"
                      (ngModelChange)="checkLengthD(flange,'line_'+i)" type="text" class="form-control input-inch">

                    <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button"
                      title="Rotate Right 15 deg" (mousedown)="spinnerStart('lenPos',flange,'line_'+i)"
                      (mouseup)="spinnerStop()" (mouseleave)="spinnerStop()">
                      <fa-icon [icon]="d.faCaretUp"></fa-icon>
                    </button>

                  </div>
                  <div *ngIf="!flange.name && !isFCT" class="input-group">

                    <span class="input-group-text flangelabel"><span
                        class="float-start">─</span>{{flange.value|meta:'formatFraction'}}"</span>
                  </div>

                </div>

                <div *ngIf="flange.type=='angle'" class="form-group" (mouseover)="hover(flange)"
                  (mouseout)="unhover(flange)">




                  <div class="input-group" *ngIf="isFCT || flange.name">

                    <ng-container *ngIf="flange.name">

                      <span class="input-group-text">{{flange.name}}</span>
                      <select class="form-select" style="padding-right: 25px;" (change)="flangeChange(flange,$event)">
                        <option class="text-right"
                          *ngFor="let slope of customtrim?.metadata.TrimXY?.TrimXYSlopeCombinedSet"
                          [value]="slope.Degree" [selected]="slope.Degree == flange.value">
                          {{slope.Description|meta:'slopeDeg'}}
                        </option>
                      </select>

                    </ng-container>

                    <ng-container *ngIf="!flange.name">

                      <span class="input-group-text lbl no-padding linelabelRoot linelabelParent"
                        style="width:35px;text-align:center;">
                        <span class="linelabel">∠</span>
                        <button (click)="swapAngle(i)" title="Change Angle Direction"
                          class="btn btn-primary iconBtn btn-xs lineremovebtn lineremove" style="border-radius:15px">
                          <fa-icon [icon]="d.faRandom"></fa-icon>
                        </button>
                        <!--
                          <button (click)="swapAngle(i)" title="Change Angle Direction" class="btn btn-sm btn-primary btn-xs lineremovebtn lineremove" style="border-radius:15px">
                            <span class="fa fa-random"></span>
                          </button>
                          -->
                      </span>


                      <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button"
                        title="Contract Angle" (mousedown)="spinnerStart('angNeg',flange,'angle_'+i)"
                        (mouseup)="spinnerStop()" (mouseleave)="spinnerStop()">
                        <img class="svgIcon" src="/assets/images/contract.svg" />
                      </button>

                      <input [(ngModel)]="flange.value" [attr.id]="'angle_'+i" [attr.name]="'angle_'+i"
                        [class.angleError]="altAngles.length > 0" style="width:50px; text-align:center"
                        (focus)="selectInputContents($event,flange)" (blur)="blur(flange)"
                        (ngModelChange)="checkAngle(flange,'angle_'+i)" type="text" class="form-control input-degree"
                        [ngbTooltip]="badAngleTip" #tleft="ngbTooltip" tooltipClass="helpTip angleError pulseErrorText"
                        placement="top" [autoClose]="false" triggers="manual">

                      <ng-template #badAngleTip>
                        <div>
                          Not available.<br>Closest alternative<span *ngIf="altAngles.length>1">s</span>:<br>
                          <button *ngFor="let altAngle of altAngles" style="margin-left:3px; margin-right: 3px"
                            (click)="fixAngle(altAngle)"
                            class="btn btn-outline-secondary btn-secondary iconBtn">{{altAngle}} &deg;</button>
                        </div>
                      </ng-template>

                      <button class="btn btn-outline-secondary btn-secondary iconBtn" type="button" title="Expand Angle"
                        (mousedown)="spinnerStart('angPos',flange,'angle_'+i)" (mouseup)="spinnerStop()"
                        (mouseleave)="spinnerStop()">
                        <img class="svgIcon" src="/assets/images/expand.svg" />
                      </button>
                    </ng-container>
                  </div>
                  <div *ngIf="!flange.name && !isFCT" class="input-group">

                    <span class="input-group-text flangelabel"><span
                        class="float-start">∠</span>{{flange.value}}°</span>
                  </div>

                </div>

                <br *ngIf="flange.type == 'angle' && !last" />

              </ng-container>
            </ng-container>

            <ng-container *ngIf="canRemove()">
              <button class="btn btn-danger btn-sm" (click)="removeLength()"
                style="height: 2.7em;vertical-align: top;margin-bottom:5px;">
                <fa-icon [icon]="d.faTimes"></fa-icon> Remove Length
              </button>
            </ng-container>
          </div>

          <ng-container *ngIf="isFCT">
            <br /><button class="btn btn-primary" (click)="addLength()">
              <fa-icon [icon]="d.faPlus"></fa-icon> Add Length
            </button>
          </ng-container>
        </div>


        <b *ngIf="d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])"
          class="trimformlabel">End Hem:</b>
        <div *ngIf="isFCT" ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-default" id="xy-color-select" ngbDropdownToggle>
            <ng-container
              *ngIf="d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])">
              <img
                [attr.src]="'/assets/images/' + customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1].type + '.svg'"
                class="hemIcon svgIcon flipH" />{{customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1].type|meta:'hemDescription'}}
            </ng-container>
            <ng-container
              *ngIf="!d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])">
              None
            </ng-container>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="setHem(false,'')"
              [class.selected]="!d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])">
              None
            </button>
            <ng-container *ngFor="let hemType of hemTypes" (click)="setHem(false,hemType)">
              <button ngbDropdownItem (click)="setHem(false,hemType)"
                [class.selected]="customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1].type == hemType">
                <img [attr.src]="'/assets/images/'+hemType+'.svg'"
                  class="hemIcon svgIcon flipH" />{{hemType|meta:'hemDescription'}}
              </button>
            </ng-container>
          </div>
        </div>
        <span *ngIf="d.isHem(customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1])">
          <img *ngIf="!isFCT" class="svgIcon flipH"
            [attr.src]="'/assets/images/'+customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1].type+'.svg'">
          {{customtrim.metadata.TDLData.flanges[customtrim.metadata.TDLData.flanges.length-1].value}}&quot;
        </span>

      </div>
      <!--end of flange editor-->

      <ng-container *ngIf="initialized">
        <b>Total Strokes: </b>{{customtrim.metadata.totalStrokes}}<br />
        <b>Total Girth: </b>{{customtrim.metadata.totalGirth|meta:'formatFraction'}}"<br />
      </ng-container>

      <ng-container *ngIf="isFCT">
        <ng-container *ngIf="d.model.TrimFullyCustomSet[0].HasPOPCapability">
          <ng-container
            *ngIf="customtrim.metadata.totalGirth > d.model.TrimFullyCustomSet[0].HasPOPCapability.GirthMax">
            <br /><span style="color:red">Invalid! Max Total Girth:
              {{d.model.TrimFullyCustomSet[0].HasPOPCapability.GirthMax|meta:'formatFraction'}}"</span>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isFCT">
        <ng-container
          *ngIf="customtrim.metadata.totalGirth > customtrim.metadata.TrimXYSet[0].HasPOPCapability.GirthMax">
          <br /><span style="color:red">Invalid! Max Total Girth:
            {{customtrim.metadata.TrimXYSet[0].HasPOPCapability.GirthMax|meta:'formatFraction'}}"</span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="customtrim.metadata.totalGirth < minGirth()">
        <br /><span style="color:red">Invalid! Minimum Total Girth: {{minGirth().toString()
          |meta:'formatFraction'}}"</span>
      </ng-container>
      <ng-container *ngIf="initialized">
        <ng-container *ngIf="d.trimrenderRef?.model.selfIntersections.length > 0">
          <br /><span style="color:red">Invalid! Trim intersects self. Please alter design.</span>
        </ng-container>
        <ng-container *ngIf="d.trimrenderRef?.model.punchIntersections.length > 0">
          <br /><span style="color:blue">Due to tight manufacturing tolerances, trim design<br />will be flagged and
            held
            for manual review</span>
        </ng-container>
      </ng-container>


    </div>
    <div class="trimRightCol">


      <div class="input-group" style="width:150px;display:inline-flex; margin-bottom:10px">

        <span class="input-group-text">Quantity</span>


        <input [(ngModel)]="customtrim.Quantity001" id="qty" name="qty" type="number" class="form-control"
          style="text-align:center;" (ngModelChange)="checkQty()">


      </div>





      <table *ngIf="initialized">
        <tr>
          <td>
            <button class="btn btn-primary" (click)="pricingSet()" style="float:right"
              [ngClass]="{'disabled':(!isValid())}">
              <fa-icon [icon]="d.faSync"></fa-icon>
              Check Price
            </button><br />
          </td>
        </tr>
        <tr>
          <td>
            <table class="cutlist-total-table">
              <tr>
                <td>&nbsp;</td>
                <td><b>Unit&nbsp;Price</b></td>
                <td><b>Total</b></td>
              </tr>
              <tr class="sub-total">
                <td><b>Price:</b></td>
                <td> {{customtrim?.metadata?.pricingSetResult.NetPrice|currency:'USD':'symbol':'1.2-2'|demoprice}}</td>
                <td> {{customtrim?.metadata?.pricingSetResult.NetPriceTotal|currency:'USD':'symbol':'1.2-2'|demoprice}}
                </td>
              </tr>
              <tr *ngIf="customtrim?.metadata?.pricingSetResult.DiscountAmt!='0.00'">
                <td><b>Discount:</b></td>
                <td>{{customtrim?.metadata?.pricingSetResult.DiscountPct|discountpercent}}</td>
                <td>
                  {{customtrim?.metadata?.pricingSetResult.DiscountAmt|currency:'USD':'symbol':'1.2-2'|demoprice}}
                </td>
              </tr>
              <tr
                *ngIf="customtrim?.metadata?.pricingSetResult.ItemSubTotal!=customtrim?.metadata?.pricingSetResult.ItemTotal">
                <td colspan="2"><b>Subtotal:</b></td>
                <td> {{customtrim?.metadata?.pricingSetResult.ItemSubTotal|currency:'USD':'symbol':'1.2-2'|demoprice}}
                </td>
              </tr>
              <tr class="sub-total">
                <td colspan="2"><b>Total:</b></td>
                <td> {{customtrim?.metadata?.pricingSetResult.ItemTotal|currency:'USD':'symbol':'1.2-2'|demoprice}}</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <button (click)="addToCart()" *ngIf="semaphore!=-1" class="btn btn-primary" style="float:right"
              [ngClass]="{'disabled':((customtrim?.metadata.pricingSetResult.NetPrice=='0.00')||(!isValid()))}">
              <fa-icon [icon]="d.faCartPlus"></fa-icon>
              Add to Cart
            </button>
            <button (click)="updateCart()" (mouseenter)="rerender()" *ngIf="semaphore==-1" class="btn btn-primary"
              style="float:right"
              [ngClass]="{'disabled':((customtrim?.metadata.pricingSetResult.NetPrice=='0.00')||(!isValid()))}">
              <fa-icon [icon]="d.faCartArrowDown"></fa-icon>
              Update Cart
            </button>

          </td>
        </tr>
      </table>

    </div>

    <!--
    <table class="trim-edit-layout">
      <tr>
        <td style="text-align:center;">

</td>
        <td style="padding-right:10px;padding-left:10px">


        </td>
        <td style="text-align:right">




        </td>


      </tr>
    </table>
    <pre>{{customtrim.metadata.TDLData|json}}</pre>-->
  </ng-container>
</div>


<ng-template #pbCollisonWarningModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Press Brake Collison Warning!</h4>

  </div>
  <div class="modal-body">
    <b>Warning!</b><br><br>
    This design does not provide enough room for the machine that bends the metal, called a Press Brake.<br><br>
    As a result, it is likely that this design will not pass inspection. It is reccomended that you add more space
    between bends, and clear the
    <span style="color:blue">blue warning symbols</span> before adding this trim to your cart.
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" (click)="pbCollisonWarningModalRef.hide()">
      <fa-icon [icon]="d.faCheck"></fa-icon>Understood
    </button>

  </div>
</ng-template>