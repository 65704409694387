<header class="routeheader">

	<div class="dist-cols-parent">
		<div class="dist-cols-child">

			&nbsp;
		</div>

		<div class="dist-cols-child pageTitle">
			<fa-icon [icon]="d.faHome"></fa-icon>Home
		</div>

		<div class="dist-cols-child">
			<cartbutton></cartbutton>
		</div>
	</div>

</header>
<div class="routecontent">

	<div class='some-page-wrapper' *ngIf="d.modelReady">
		<div class='flexRow' style="position: relative; left: -10px">

			<div class="flexColumn" *ngFor="let catGroup of d.model.categorySet">
				<ng-container *ngFor="let cat of catGroup">
					<a class="cat-tile" (click)="tileClick(cat)" [ngClass]="{'shipNA': d.productDisabled(cat.CATEGORY_ID)}">
						<div class="cat-tile-container">
							<div class="cat-tile-title">{{cat.NAME}}</div>
							<div class="cat-tile-img"><img src="{{cat.ImageUrl|cms}}" /></div>

						</div>
					</a>
				</ng-container>

			</div>
		</div>
	</div>




	<!-- https://codepen.io/drews256/pen/bjzpvd -->
	<div class='some-page-wrapper'>
		<div class='row'>
			<div class='column'>
				<div>
					<img class="home-logo" style="overflow: visible" src="/assets/images/MyASCProfilesPortal.svg"><br><br>
					<div [innerHTML]="d.model.html.messages | safe" class="welcomeText"></div>
					<div style="margin-top:10px; text-align:center"> <button class="btn btn-primary"
							(click)="d.openInNewTab('https://www.ascbp.com/distributor-center/')">
							<fa-icon [icon]="d.faShare"></fa-icon> Distributor Resources
						</button></div>
				</div>
			</div>
			<div class='column'>
				<div>
					<div class="announcmentsHeader">&nbsp;<fa-icon [icon]="d.faBullhorn"></fa-icon>&nbsp;&nbsp;Announcements</div>
					<div class="announcmentsBody" [innerHTML]="d.model.html.announcements | safe"></div>

				</div>

				<br />
				<div>
					<div class="announcmentsHeader">&nbsp;<fa-icon [icon]="d.faComment"></fa-icon>&nbsp;&nbsp;Feedback</div>
					<div class="announcmentsBody">
						We welcome your questions, suggestions, and bug reports<br />
						<div class="form-group" style="margin-top:10px;">
							<select id="comment-type" class="form-control form-select form-select-sm" required
								[(ngModel)]="commentType" (ngModelChange)="selectedCommentType()" name="power"
								style="background-color:#eee">
								<option selected="selected" disabled value="">Select Feedback Type...</option>
								<option *ngFor="let type of commentTypes" [value]="type">{{type}}</option>
							</select>
						</div>
						<textarea id="comment-text" placeholder="Type your comment here" class="form-control"
							style="margin-bottom:10px;background-color:#eee" [(ngModel)]="commentText"></textarea>
						<div class="text-right" (click)="submitComment()">
							<button class="btn btn-primary">Submit</button>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>
	<div *ngIf="d.model.accountInfo && d.model.accountInfo.IsItAdmin" class="adminBtns">

		<button (click)="d.model.cart.OrderItemSet.push({});d.addToCartAnim()">addToCartAnim</button>
		<button (click)="expireCache()">Expire Cache</button>

		<button (click)="testServiceError()">Test Service Error</button>

		<button (click)="testSapError()">Test SAP Error</button>
		<button (click)="throwError()">Throw Error</button>
		<button (click)="throwErrors()">Throw Errors</button>

		<!--
		<textarea id="errorText" [(ngModel)]="d.randomText"></textarea>
		<pre>{{d.randomText}}</pre>

      <trimrender></trimrender>
    <button (click)="resetPasswords()">Reset Passwords</button>
    <button (click)="ShipToGetAll()">ShipToGetAll</button>
    <button (click)="dataSvc.toast('Product added successfully',2000)">Toast!</button>
  
    <button (click)="testSpDirect()">testSpDirect()</button>
    <button (click)="UserGoLive()">UserGoLive()</button>
    <button (click)="UserSendWelcome()">UserSendWelcome()</button>
-->
		<!--///sap/opu/odata/sap/ZBUSTRAN_ATTACHMENT_SRV/SOConfirmationPDFSet(SalesOrderNumber='18000318',SoldTo='50405')/$value-->
		<!--<a target="_blank" href="/api/Endpoint/OrderConfirmationPDF?salesorderNumber=18000318&soldTo=50405">Get SO PDF</a>-->
	</div>
</div>
<footer>
	<app-std-footer></app-std-footer>
</footer>



<ng-template #feedbackModal>
	<div class="modal-header">
		<h4 class="modal-title">Thank You!</h4>
		<button type="button" class="btn-close close pull-right" aria-label="Close" (click)="feedbackModalRef.hide()">
			<fa-icon [icon]="d.faWindowClose"></fa-icon>
		</button>
	</div>
	<div class="modal-body">
		We have received your feedback, and will get in touch with you about it soon.
	</div>
	<div class="modal-footer">

		<button class="btn btn-secondary" (click)="feedbackModalRef.hide()">OK</button>
	</div>
</ng-template>