import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';

import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  //styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild("feedbackModal", { static: true }) feedbackModal: TemplateRef<any>;
  feedbackModalRef: BsModalRef;

  commentText: string = "";
  commentType: string = "";
  commentTypes: string[] = [];

  constructor(public d: DataService) {

    this.commentTypes = [
      "CSR Question",
      "Site Suggestion",
      "Bug Report"
    ];
  }

  ngOnInit(): void {

  }

  setLoginDisplay() {

  }
  async submitComment() {

    if (this.commentType.length == 0) {

      this.d.selectFocus("comment-type");
      return this.d.toast("Please first select select Feedback Type");
    }
    if (this.commentText.length == 0) {
      this.d.focusInput("comment-text");
      return this.d.toast("Please first type a comment.");
    }
    let results = await this.d.inlinePost("/SubmitComment", {}, { commentType: this.commentType, commentText: this.commentText });
    //console.log(results);

    this.feedbackModalRef = this.d.showModal(this.feedbackModal, "modal-xs modal-dialog-centered");
  }

  selectedCommentType() {
    this.d.focusInput("comment-text");
  }


  tileClick(tile) {

    this.d.targetAction = tile.CATEGORY_ID;

    switch (tile.CATEGORY_ID) {
      case 'CF':
        this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], '/panels/' + tile.CATEGORY_ID);
        break;
      case 'EF':
        this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], '/panels/' + tile.CATEGORY_ID);
        break;
      case 'TRIM':
        this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], '/trims');
        break;
      case 'BUYOUT':
        this.d.checkPreReqsAndRoute(['SoldTo', 'ShipTo'], '/accessories');
        break;
      default:
        console.log(tile.CATEGORY_ID + ": NOT IMPLEMENTED");
    }

  }



  testServiceError() {

    this.d.get("/TestError", {}).subscribe(result => {
      console.log("TestError returned");
    });
  }
  testSapError() {

    this.d.get("/TestSapError", {}).subscribe(result => {
      console.log("TestSapError returned");
    });
  }
  throwErrors() {
    setTimeout(() => {
      console.log("Throwing Error 1!");
      throw new Error("This is an error, 1 of 2!");
    }, 100);
    setTimeout(() => {
      console.log("Throwing Error 2!");
      throw new Error("This is an error, 2 of 2!");
    }, 100);

  }
  throwError() {
    throw new Error('something went wrong');
  }
  expireCache() {
    this.d.get("/ExpireCache", {}).subscribe(result => {
      console.log("ExpireCache returned");
    });
  }


}