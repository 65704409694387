import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trimrender',
  templateUrl: './trimrender.component.html',
  styleUrls: ['./trimrender.component.scss']
})
export class TrimrenderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
