import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  //styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit {


  constructor(public d: DataService) { }
  public FilterByColor: boolean = false;
  public FilterByGauge: boolean = false;
  public FilterByProfile: boolean = false;
  public colorFilter: string = "";

  @ViewChild("accessoriesModal", { static: true }) accessoriesModal: TemplateRef<any>;
  accessoriesModalRef: BsModalRef;

  async ngOnInit() {
    this.d.hideFilters();

    if (!this.d.model.hasOwnProperty("BOAccessorySet")) {

      let data = await this.d.inlineGet("/GetAccessoriesSet");
      Object.keys(data).forEach((key) => {
        this.d.model[key] = data[key];

        //like customerSetFiltered
        if (key == 'BOAccessoryGetFilterColorSet') {
          data[key].forEach((c) => {
            //<img class="cart-thumb" src="{{item.metadata.product.ImageUrl}}" />
            if (c.ColorId.length > 0) {
              c.ImageUrl = "/images/PaintChips/" + c.ColorId + ".gif";
            }

          });
          this.updateColorFilter("");

        }

      });
      console.log("ajax done, calling cfgFiltersBySrcPanel");
      this.cfgFiltersBySrcPanel();
      //});
    } else {

      for (var accessory of this.d.model.BOAccessorySet) {
        if (accessory.selected) {
          this.FilterByColor = accessory.Configuration.FilterByColor;
          this.FilterByGauge = accessory.Configuration.FilterByGauge;
          this.FilterByProfile = accessory.Configuration.FilterByProfile;
        }
      }
      console.log("other done, calling cfgFiltersBySrcPanel");
      this.cfgFiltersBySrcPanel();
    }

  }


  ngOnDestroy(): void {
    this.closeAccessoriesModal();
  }
  async cfgFiltersBySrcPanel() {



    if (this.d.model.srcPanel !== undefined
      && this.d.model.srcPanel.hasOwnProperty("mtsPanel")
      && this.d.model.srcPanel.hasOwnProperty("gauge")) {


      this.selectAccessory("MTS_PANELS");
      console.log(this.d.model.srcPanel);
      this.selectGauge(this.d.model.srcPanel.gauge);
      for (var color of this.d.model.BOAccessoryGetFilterColorSetFiltered) {
        color.selected = color.BasecolorId == 1; //bare
      }
      this.displayResults()


      delete this.d.model.srcPanel;


    } else if (this.d.model.srcPanel !== undefined && this.d.model.ProfileGroupSet !== undefined && this.d.model.BOAccessoryGetFilterColorSetFiltered !== undefined) {

      let targetProfileId = this.d.model.srcPanel.metadata.product.ProfileGroupId;
      for (var profile of this.d.model.ProfileGroupSet) {
        profile.selected = profile.ProfileGroupId == targetProfileId;
      }

      this.selectGauge(this.d.model.srcPanel.metadata.gauge.Gauge);

      let targetColorId = this.d.model.srcPanel.metadata.color.BasecolorId;
      for (var color of this.d.model.BOAccessoryGetFilterColorSetFiltered) {
        color.selected = color.BasecolorId == targetColorId;
      }


      await this.d.delay(200);
      this.d.scrollToElement("acc_profile_" + targetProfileId);
      this.d.scrollToElement("acc_color_" + targetColorId);
      this.d.toast("Filters set from Panel");
      delete this.d.model.srcPanel;
    }
  }

  selectGauge(gaugeId) {

    for (var gauge of this.d.model.BOAccessoryFilterGaugeSet) {
      gauge.selected = gauge.GaugeId == gaugeId;
    }
  }

  selectAccessory(accessoryId) {

    for (var accessory of this.d.model.BOAccessorySet) {
      if (accessory.AccessoryId == accessoryId) {
        accessory.selected = true;
        console.log(accessory.Configuration);
        this.FilterByColor = accessory.Configuration.FilterByColor;
        this.FilterByGauge = accessory.Configuration.FilterByGauge;
        this.FilterByProfile = accessory.Configuration.FilterByProfile;

        if (this.FilterByColor || this.FilterByGauge || this.FilterByProfile) {
          setTimeout(() => {
            this.d.showFilters = true;
          }, 150);

        }


      } else {
        accessory.selected = false
      }
    }
  }

  updateColorFilter(searchTerms) {


    if (this.d.model.BOAccessoryGetFilterColorSet === undefined) return; //on slow machines, clearColorFilter may run before the service has returned the colors!

    switch (searchTerms) {
      case "":
        this.d.model.BOAccessoryGetFilterColorSetFiltered = this.d.model.BOAccessoryGetFilterColorSet.filter((color) => {
          return true;
        });
        break;

      case 'is:selected':
        this.d.model.BOAccessoryGetFilterColorSetFiltered = this.d.model.BOAccessoryGetFilterColorSet.filter((color) => {
          return color.selected;
        });
        break;

      default:
        let aSearchTerms = searchTerms != null ? searchTerms.toLowerCase().split(" ") : [];
        let minScore = aSearchTerms.length;
        this.d.model.BOAccessoryGetFilterColorSetFiltered = this.d.model.BOAccessoryGetFilterColorSet.filter((color) => {
          if (minScore == 0) return true;
          let score = 0;
          aSearchTerms.forEach((searchTerm) => {
            if (color.BasecolorDescription.toLowerCase().indexOf(searchTerm) > -1) score += 1;
          });
          return (score >= minScore);
        });
    }


  }

  toggleColors(setState: boolean) {

    for (var color of this.d.model.BOAccessoryGetFilterColorSetFiltered) {
      color.selected = setState;
    }
  }

  toggleProfiles(setState: boolean) {

    for (var profile of this.d.model.ProfileGroupSet) {
      profile.selected = setState;
    }
  }

  clearColorFilter() {

    this.colorFilter = '';
    this.updateColorFilter('');
    this.d.focusInput("accColorFilter");
  }

  parcelCheck(acc) {
    //ShippingModeId: "PARC"

    if (this.d.model.ShipMode.ShippingModeId == 'PARC') {
      return (acc.AccessoryId == 'FLATSHEET' || acc.AccessoryId == 'LIGHTPANELS' || acc.AccessoryId == 'MTS_PANELS');
    } else {
      return false;
    }

  }

  closeAccessoriesModal() {
    if (this.accessoriesModalRef !== undefined) this.accessoriesModalRef.hide();
    this.d.model.BuyoutResults = [];
    this.d.model.BuyoutResultsCount = 0;
  }

  displayResults() {


    //check that all required selections have been made.
    let clickFilters = false;

    let selectedAcc = this.d.model.BOAccessorySet.find(c => c.selected); //find selects a single match
    console.log(this.d.model.BOAccessorySet);
    console.log(selectedAcc);
    if (selectedAcc === undefined) return this.d.toast("Nothing selected"); //TODO: Bug fix? for: "Unable to get property 'AccessoryId' of undefined or null reference",

    let query: any = {
      AccessoryId: [selectedAcc.AccessoryId],
      ChannelId: ['20'],
      ShippingPlant: [this.d.model.ShipTo.SHIPPINGPLANT],//'SA03' ,
      ShippingModeId: [this.d.model.ShipMode.ShippingModeId] //'STD'
    }

    let errors = [];

    //grab colors
    if (selectedAcc.Configuration.FilterByColor) {
      let colors = this.d.model.BOAccessoryGetFilterColorSetFiltered.filter(c => c.selected); //filter gets an array
      if (colors.length == 0) {
        errors.push("Color");
        //this.d.showFilters = true;
        clickFilters = true;
      } else {
        //query.BaseColorId = _.map(colors, 'BasecolorId');
        console.log(colors);
        query.BaseColorId = colors.map(c => c.BasecolorId);
      }
    }

    //grab gauge
    if (selectedAcc.Configuration.FilterByGauge) {
      let gauge = this.d.model.BOAccessoryFilterGaugeSet.find(c => c.selected);
      if (gauge === undefined) {
        errors.push("Gauge");
        //this.d.showFilters = true;
        clickFilters = true;
      } else {
        query.GaugeId = [gauge.GaugeId];
      }
    }

    //grab profiles
    if (selectedAcc.Configuration.FilterByProfile) {
      let profiles = this.d.model.ProfileGroupSet.filter(c => c.selected);
      if (profiles.length == 0) {
        errors.push("Profile");
        //this.d.showFilters = true;
        clickFilters = true;
      } else {
        query.ProfileGroupId = profiles.map(p => p.ProfileGroupId);
      }
    }

    if (errors.length > 0) {
      if (clickFilters) {
        this.d.clickElement("filterBtn");
      }
      return this.d.toast("Please select: " + errors.join(', '));
    }

    console.log(JSON.stringify(query, null, 4));

    this.d.post("/GetBuyouts", {}, query).subscribe(result => {
      let data = result;
      this.d.model.BuyoutResultsCount = data.length;
      if (this.d.model.BuyoutResultsCount == 0) return this.d.toast("No accessories found, please modify your search");

      data.forEach((r) => {
        //let colorInfo = _.find(this.d.model.BOAccessoryGetFilterColorSet,(c)=>{return c.BasecolorId == r.BaseColorId;});
        r.metadata = {
          color: {
            ImageUrl: (r.ColorId ? "/images/PaintChips/" + r.ColorId + ".gif" : ""),
            //ColorDescription: colorInfo.BasecolorDescription
          },
          product: {
            NAME: r.BuyoutId,
            Productname: r.Description,
            //ImageUrl: (this.d.model.imageProxyUrl + "?contenttype=category&id=" + r.BOCategoryId),
            ImageUrl: "/images/Category/" + r.BOCategoryId + ".jpg",
          },
          ImageUrl: (r.ColorId ? "/images/PaintChips/" + r.ColorId + ".gif" : ""),
          ShowPrice: false,
          Loading: true,
          Quantity: 1
        };
      });

      //group the results by category
      let cats = [...new Set(data.map(d => d.BOCategoryId))];
      let results = [];
      cats.forEach(cat => {
        var result = {
          BOCategoryId: cat,
          Expanded: (cats.length == 1),
          Category: selectedAcc.Categories.results.find(c => c.BOCategoryId == cat),
          //ImageUrl: this.d.model.imageProxyUrl + "?contenttype=category&id=" + cat,
          ImageUrl: "/images/Category/" + cat + ".jpg",
          ShowGauge: false,
          ShowCompatibility: false,
          ColumnCount: 2,
          Items: data.filter(c => c.BOCategoryId == cat)
        };
        if (result.Items[0].GaugeId.length > 0) {
          result.ShowGauge = true;
          result.ColumnCount += 1;
        }
        if (result.Items[0].Compatibility.length > 0) {
          result.ShowCompatibility = true;
          result.ColumnCount += 1;
        }
        results.push(result);
      });
      this.d.model.BuyoutResults = results;

      this.accessoriesModalRef = this.d.showModal(this.accessoriesModal, 'modal-xl modal-dialog-centered');
    });

  }

}
