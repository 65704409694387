import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';
//var _: any = require("lodash");

@Pipe({ name: 'colordesc' })
export class ColorDescPipe implements PipeTransform {
	constructor(private d: DataService) { }

	transform(value: string): any {

		return this.d.colordescM(value);
	}
}