<header class="routeheader">

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <ng-container *ngIf="selectedTab!='std'">
        &nbsp;
      </ng-container>
      <ng-container *ngIf="selectedTab=='std'">
        <div ngbDropdown class="d-inline-block">
          <button type="button" id="filterBtn" class="btn btn-secondary filter-toggle-btn" ngbDropdownToggle>
            <fa-icon [icon]="d.faFilter"></fa-icon>Filters
          </button>
          <div ngbDropdownMenu aria-labelledby="filterMenu">
            <ng-container *ngTemplateOutlet="filters"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>


    <div class="dist-cols-child pageTitle">
      <fa-icon [icon]="d.faSquare" style="color:#6b8d76"></fa-icon>Select Trims
    </div>

    <div class="dist-cols-child">
      <cartbutton></cartbutton>
    </div>
  </div>

</header>


<div class="routecontent">

  <div ngbNav #nav="ngbNav" class="nav-tabs htabs" (navChange)="navChange($event)">



    <div [ngbNavItem]="'std'">
      <a ngbNavLink>Standard Trims</a>
      <ng-template ngbNavContent>

        <div style="display: flex;">
          <div class="filterMenuBody">
            <ng-container *ngTemplateOutlet="filters"></ng-container>
          </div>
          <div id="trim-body" class="body-cards belowtabs" (click)="d.hideFilters()">

            <div style="margin-top:15px;margin-bottom:8px;vertical-align:middle;margin-right:15px">
              <div style="font-weight:bold;padding-top:5px;padding-left:5px;display:inline-block;">Trims:</div>
              <button class="btn btn-primary btn-sm float-end" type="button" (click)="toggleTrims(false)">None</button>
              <button class="btn btn-primary btn-sm float-end" type="button" style="margin-right:2px"
                (click)="toggleTrims(true)">All</button>
            </div>
            <div *ngFor="let trim of d.model.TrimCategorySet" (click)="trim.metadata.selected = !trim.metadata.selected"
              class="btn btn-default acc-trim-card" [ngClass]="{'active':(trim.metadata.selected)}">

              <div class="cardHead">
                <fa-stack class="cardCtrl">
                  <fa-icon [icon]="d.faSquareThin" stackItemSize="2x" *ngIf="!trim.metadata.selected"></fa-icon>
                  <fa-icon [icon]="d.faCheckSquare" stackItemSize="2x" style="color:#00665E"
                    *ngIf="trim.metadata.selected"></fa-icon>
                </fa-stack>
                <div class="cardDesc">
                  {{trim.Description}}
                </div>
              </div>
              <img src="{{trim.metadata.product.ImageUrl|cms}}" width="128" height="128"
                style="border:1px solid #ccc;margin-top:5px;background-color:white" />

            </div>
          </div>

        </div>




      </ng-template>
    </div>
    <div [ngbNavItem]="'xyz'">
      <a ngbNavLink>XY Trims</a>
      <ng-template ngbNavContent>

        <div style="margin-top:5px">Click the trim you would like to configure:</div>
        <div *ngFor="let xyTrim of d.model.TrimXYGroupSet" class="btn btn-default acc-trim-card"
          [ngClass]="{'disabled':(!xyTrim.hasTdl)}" (click)="editTrim(xyTrim)" style="width:180px;white-space:normal">


          <div class="cardHead" style="height:38px">
            <div class="cardDesc topAligned">
              {{xyTrim.Description}}
            </div>
          </div>
          <img src="{{xyTrim.ImageUrl|cms}}" width="160" height="160"
            style="border:1px solid #ccc;margin-top:5px;background-color:white" />
          <div class="text-end" style="width:160">{{xyTrim.TrimXYGroupId}}</div>
        </div>


      </ng-template>
    </div>
    <div [ngbNavItem]="'fct'">
      <a ngbNavLink>Fully Custom Trims</a>
      <ng-template ngbNavContent>

        <!-- I think templates must not actually disappear from the DOM when not in use! -->
        <ng-container *ngIf="selectedTab =='fct'">
          <trimeditfct *ngIf="fullyCustomTrim?.metadata" [customtrim]="fullyCustomTrim"></trimeditfct>
        </ng-container>






      </ng-template>
    </div>
  </div>
  <div [ngbNavOutlet]="nav"></div>


</div>


<footer>

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <div ngbDropdown placement="top-start">
        <button type="button" class="btn btn-secondary" id="catalogsBtn" ngbDropdownToggle>
          <fa-icon [icon]="d.faFilePdf"></fa-icon>Catalogs
        </button>
        <div ngbDropdownMenu aria-labelledby="catalogsBtn">
          <a *ngFor="let cl of d.catalogLinks" ngbDropdownItem href="{{cl.href}}" target="_blank"
            rel="noopener noreferrer nofollow">
            <fa-icon [icon]="d.faFilePdf"></fa-icon><span [innerHTML]="cl.html | safe"></span>
          </a>
        </div>
      </div>

    </div>

    <div class="dist-cols-child">
      <button type="button" class="btn btn-secondary" (click)="displayResults()" *ngIf="selectedTab=='std'">
        <fa-icon [icon]="d.faExternalLinkSquareAlt" flip="horizontal"></fa-icon>Show Selected Trims
      </button>
    </div>
  </div>




</footer>



<ng-template #filters>

  <div class="filterMenu">

    <div>
      <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
        <div style="font-weight:bold;padding-top:5px;padding-left:5px;display:inline-block;">Profile:</div>
        <button class="btn btn-primary btn-sm float-end" type="button" (click)="toggleProfiles(false)">None</button>
        <button class="btn btn-primary btn-sm float-end" type="button" style="margin-right:2px"
          (click)="toggleProfiles(true)">All</button>
      </div>

      <div style="height:200px;overflow-y:auto;width:100%;border:1px solid #ccc">
        <label *ngFor="let profile of d.model.ProfileGroupSet" (click)="selectProfile(profile)"
          class="text-left radio-btn filter-option" [class.active]="profile.selected"
          id="trim_profile_{{profile.ProfileGroupId}}">

          <fa-stack>
            <fa-icon [icon]="d.faSquareThin" stackItemSize="2x" *ngIf="!profile.selected"></fa-icon>
            <fa-icon [icon]="d.faCheckSquare" stackItemSize="2x" style="color:#00665E" *ngIf="profile.selected">
            </fa-icon>
          </fa-stack>
          {{profile.Name}}
        </label>
      </div>
    </div>

    <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
      <div style="padding-top:5px;padding-left:5px;display:inline-block;">
        <span style="font-weight:bold;">Gauge:</span><span *ngIf="!(d.model.TrimGauges?.length > 0)"
          style="color:darkgray">
          &nbsp;Select a Profile&nbsp;<fa-icon [icon]="d.faArrowCircleUp"></fa-icon>
        </span>
      </div>
    </div>

    <div class="btn-group" *ngIf="d.model.TrimGauges?.length > 0">
      <label *ngFor="let gauge of d.model.TrimGauges" (click)="selectGauge(gauge.GaugeId)"
        class="text-left radio-btn filter-option" [ngClass]="{'active':(gauge.selected)}">
        <fa-stack>
          <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
          <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E" *ngIf="gauge.selected"></fa-icon>
        </fa-stack>

        {{gauge.GaugeDescription}}
      </label>
    </div>


    <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
      <div style="padding-top:5px;padding-left:5px;display:inline-block;">
        <span style="font-weight:bold;">Color:</span><span *ngIf="!(d.model.TrimGetFilterBaseColor?.length > 0)"
          style="color:darkgray">
          &nbsp;Select a Gauge&nbsp;<fa-icon [icon]="d.faArrowCircleUp"></fa-icon>
        </span>
      </div>
      <div *ngIf="d.model.TrimGetFilterBaseColor?.length > 0" style="display:inline">
        <button class="btn btn-primary btn-sm float-end" type="button" (click)="toggleColors(false)">None</button>
        <button class="btn btn-primary btn-sm float-end" type="button" style="margin-right:2px"
          (click)="toggleColors(true)">All</button>
        <div class="input-group float-end " style="width:120px;margin-right:2px">
          <input [(ngModel)]="colorFilter" (ngModelChange)="updateColorFilter($event)" id="trimColorFilter"
            class="form-control input-sm" type="text" placeholder="Search 🔍" />
          <span class="input-group-btn">
            <button class="btn btn-default btn-sm btn-gradient" type="button" (click)="clearColorFilter()"
              title="Clear Search">X</button>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="d.model.TrimGetFilterBaseColor?.length > 0"
      style="height:200px;overflow-y:auto;width:100%;border:1px solid #ccc">
      <label *ngFor="let color of d.model.TrimGetFilterBaseColorFiltered" (click)="color.selected = !color.selected"
        class="text-left radio-btn filter-option" [ngClass]="{'active':(color.selected)}"
        id="trim_color_{{color.BasecolorId}}">
        <img title="BasecolorId: {{color.BasecolorId}}" src="{{color.ImageUrl|cms}}" class="color-menu-image float-end"
          (click)="d.zoomImg($event)" />
        <fa-stack>
          <fa-icon [icon]="d.faSquareThin" stackItemSize="2x" *ngIf="!color.selected"></fa-icon>
          <fa-icon [icon]="d.faCheckSquare" stackItemSize="2x" style="color:#00665E" *ngIf="color.selected"></fa-icon>
        </fa-stack>
        <span class="ng-tooltip">
          {{color.BasecolorDescription}}&nbsp;
        </span>

      </label>
    </div>

  </div>
</ng-template>


<ng-template #trimsModal>

  <div class="modal-header">
    <h4 class="modal-title">Products found: {{d.model.TrimResultsCount}}</h4>
    <button type="button" class="btn-close close float-end" aria-label="Close" (click)="trimsModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>

  </div>
  <div class="container"></div>
  <div class="modal-body">

    <div *ngFor="let cat of d.model.TrimsResults">
      <img src="{{cat.ImageUrl|cms}}" class="trim-product-image" style="float:left;vertical-align:top"
        (click)="d.zoomImg($event)" />
      <table class="trimcat">
        <tr>
          <td></td>
          <td>
            <h3>{{cat.TrimProfileId}}</h3>
            <table class="table trim-result-table">
              <thead>
                <tr>
                  <th class="t-desc">
                    Description
                  </th>
                  <th class="t-color">
                    Color
                  </th>
                  <th class="t-gauge">
                    Gauge
                  </th>
                  <th class="t-ctwt">
                    CTWT
                  </th>
                  <th class="t-grade">
                    Grade
                  </th>
                  <th class="t-compat">
                    Compatibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of cat.Items">
                  <tr style="vertical-align:top" class="accessory-heading-row"
                    (click)="item.metadata.ShowPrice = !item.metadata.ShowPrice"
                    [ngClass]="{'expanded':item.metadata.ShowPrice}">
                    <td class="t-desc"><img *ngIf="item.metadata.color.ImageUrl.length > 0" title="{{item.ColorId}}"
                        src="{{item.metadata.color.ImageUrl|cms}}" style="margin-right:5px"
                        class="color-menu-image" /><b>{{item.TrimId}}</b> {{item.Description}}</td>
                    <td class="t-color">{{item.ColorId|colordesc}}</td>
                    <td class="t-gauge">{{item.GaugeId}}</td>
                    <td class="t-ctwt">{{item.CoatingWtId}}</td>
                    <td class="t-grade">{{item.GradeId}}</td>
                    <td class="t-compat" [innerHTML]="item.Compatibility|safe"></td>
                  </tr>
                  <tr *ngIf="item.metadata.ShowPrice" style="border-top-width:0px" class="accessory-pricing-row">
                    <td colspan="6" style="border-top-width:0px">
                      <trimprice [trim]="item"></trimprice>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </td>
        </tr>
      </table>

      <hr />

    </div>



  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="trimsModalRef.hide()">
      <fa-icon [icon]="d.faTimes"></fa-icon>Continue Shopping
    </button>

  </div>
</ng-template>



<ng-template #trimEditorModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Configure your XY Trim</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeTrimEditor()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    <trimeditfct *ngIf="selectedTrim.metadata" [customtrim]="selectedTrim" [semaphore]="semaphore"></trimeditfct>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning-secondary" (click)="closeTrimEditor()">
      <fa-icon [icon]="d.faTimes"></fa-icon>Continue Shopping
    </button>

  </div>
</ng-template>