<header class="routeheader">

	<div class="dist-cols-parent">
		<div class="dist-cols-child">


      <div ngbDropdown class="d-inline-block">
        <button type="button" id="filterBtn" class="btn btn-secondary filter-toggle-btn" ngbDropdownToggle><fa-icon [icon]="d.faFilter"></fa-icon>Filters</button>
        <div ngbDropdownMenu aria-labelledby="filterMenu">
          <ng-container *ngTemplateOutlet="filters"></ng-container>
        </div>
      </div>

		</div>
		

    <div class="dist-cols-child pageTitle">
      <fa-icon [icon]="d.faSquare" style="color:#268dbf"></fa-icon>Select Accessories
		</div>

		<div class="dist-cols-child">
			<cartbutton></cartbutton>
		</div>
	</div>

</header>

<div class="routecontent" style="flex-direction: row;">

  <div class="filterMenuBody">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </div>


  <div class="body-cards" (click)="d.hideFilters()">
    <div style="font-weight:bold;margin-top:5px;padding-left:5px">Accessory:</div>

    <div *ngFor="let accessory of d.model.BOAccessorySet"
         (click)="selectAccessory(accessory.AccessoryId);"
         (dblclick)="displayResults()"
         class="btn btn-default acc-trim-card"
         [class.active]="accessory.selected" [class.shipNA]="parcelCheck(accessory)">

         <div class="cardHead">
          <fa-stack class="cardCtrl">
            <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
            <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E" *ngIf="accessory.selected"></fa-icon>
          </fa-stack>
          <div class="cardDesc">
            {{accessory.Description}}
          </div>
        </div>

        <div>
          <img class="acc-trim-card-img" src="{{accessory.ImageUrl | cms}}" />
        </div>
    </div>
  </div>



</div>

<footer>

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <div ngbDropdown placement="top-start">
        <button type="button" class="btn btn-secondary" id="catalogsBtn" ngbDropdownToggle><fa-icon [icon]="d.faFilePdf"></fa-icon>Catalogs</button>
        <div ngbDropdownMenu aria-labelledby="catalogsBtn">
          <a *ngFor="let cl of d.catalogLinks" ngbDropdownItem href="{{cl.href}}" target="_blank" rel="noopener noreferrer nofollow"><fa-icon [icon]="d.faFilePdf"></fa-icon><span [innerHTML]="cl.html | safe"></span></a>
        </div>
      </div>
  
    </div>
    
    <div class="dist-cols-child">
      <button type="button" class="btn btn-secondary" (click)="displayResults()" >
        <fa-icon [icon]="d.faExternalLinkSquareAlt" flip="horizontal"></fa-icon>Show Selected Accessories</button>
    </div>
  </div>




</footer>


<ng-template #filters>

  <div class="filterMenu">

    <div [class.disabled] ="!FilterByProfile">
      <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
        <div style="font-weight:bold;padding-top:5px;padding-left:5px;display:inline-block;">Profile:</div>
        <button class="btn btn-primary btn-sm float-end" type="button" (click)="toggleProfiles(false)">None</button>
        <button class="btn btn-primary btn-sm float-end" type="button" style="margin-right:2px" (click)="toggleProfiles(true)">All</button>
      </div>
      <!-- 
        class="btn-group btn-group-vertical"
        class="btn btn-default text-left radio-btn filter-option"
      -->
      <div style="height:200px;overflow-y:auto;width:100%;border:1px solid #ccc" >
        <label *ngFor="let profile of d.model.ProfileGroupSet" (click)="profile.selected = !profile.selected"
                id="acc_profile_{{profile.ProfileGroupId}}"
              [class.active] = "profile.selected" class="text-left radio-btn filter-option">


          <fa-stack>
            <fa-icon [icon]="d.faSquareThin" stackItemSize="2x" *ngIf="!profile.selected"></fa-icon>
            <fa-icon [icon]="d.faCheckSquare" stackItemSize="2x" style="color:#00665E" *ngIf="profile.selected"></fa-icon>
          </fa-stack>
          {{profile.Name}}
        </label>
      </div>
    </div>


    <div [ngClass]="{'disabled':(!FilterByGauge)}">

      <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
        <div style="font-weight:bold;padding-top:5px;padding-left:5px;display:inline-block;">Gauge:</div>

      </div>

      <div class="btn-group">
        <label *ngFor="let gauge of d.model.BOAccessoryFilterGaugeSet" (click)="selectGauge(gauge.GaugeId)"
              class="btn btn-default text-left radio-btn filter-option"
              [class.active] = "gauge.selected">
          <fa-stack>
            <fa-icon [icon]="d.faCircleThin" stackItemSize="2x"></fa-icon>
            <fa-icon [icon]="d.faCircle" stackItemSize="1x" style="color:#00665E" *ngIf="gauge.selected"></fa-icon>
          </fa-stack>
          {{gauge.GaugeId}}
        </label>
      </div>
    </div>

    <div [class.disabled]="!FilterByColor">

      <div style="margin-top:5px;margin-bottom:8px;vertical-align:middle">
        <div style="font-weight:bold;padding-top:5px;padding-left:5px;display:inline-block;">Color:</div>
        <button class="btn btn-primary btn-sm float-end" type="button" (click)="toggleColors(false)">None</button>
        <button class="btn btn-primary btn-sm float-end" type="button" style="margin-right:2px" (click)="toggleColors(true)">All</button>
        <div class="input-group float-end " style="width:120px;margin-right:2px">
          <input [(ngModel)]="colorFilter" (ngModelChange)="updateColorFilter($event)" id="accColorFilter" class="form-control input-sm" type="text" placeholder="Search 🔍" />
          <span class="input-group-btn">
            <button class="btn btn-default btn-sm btn-gradient" type="button" (click)="clearColorFilter()" title="Clear Search">X</button>
          </span>
        </div>
      </div>


      <div style="height:200px;overflow-y:auto;width:100%;border:1px solid #ccc">
        <label *ngFor="let color of d.model.BOAccessoryGetFilterColorSetFiltered" (click)="color.selected = !color.selected"
              class="text-left radio-btn filter-option"
              [class.active]="color.selected" id="acc_color_{{color.BasecolorId}}">
          <img title="BasecolorId: {{color.BasecolorId}}" src="{{color.ImageUrl | cms}}" (click)="d.zoomImg($event)" class="color-menu-image float-end" />
          <fa-stack>
            <fa-icon [icon]="d.faSquareThin" stackItemSize="2x" *ngIf="!color.selected"></fa-icon>
            <fa-icon [icon]="d.faCheckSquare" stackItemSize="2x" style="color:#00665E" *ngIf="color.selected"></fa-icon>
          </fa-stack>
          <span class="ng-tooltip">
            {{color.BasecolorDescription}}&nbsp;
          </span>
        </label>
      </div>

    </div>
  </div>

</ng-template>

<ng-template #accessoriesModal>
  <div class="modal-header">
    <h4 class="modal-title  float-start">Products found: {{d.model.BuyoutResultsCount}}</h4>
    <button type="button" class="btn-close close float-end" aria-label="Close" (click)="accessoriesModalRef.hide()">
      <fa-icon [icon]="d.faWindowClose"></fa-icon>
    </button>
  </div>
  <div class="modal-body">
    
    

    <div class="media-list">
      <div class="media" *ngFor="let cat of d.model.BuyoutResults">
        <div class="media-left">
          <img class="media-object" src="{{cat.ImageUrl | cms}}" height="64" width="64">
        </div>
        <div class="media-body">
          <div (click)="cat.Expanded = !cat.Expanded" style="cursor:pointer">
            <h3>
              <fa-icon [icon]="d.faPlusSquare" *ngIf="!cat.Expanded"></fa-icon>
              <fa-icon [icon]="d.faMinusSquare" *ngIf="cat.Expanded"></fa-icon>

              <span style="text-decoration:underline">{{cat.Category.Description}} ({{cat.Items.length}})</span>
            </h3>
          </div>

          <table *ngIf="cat.Expanded" class="table">
            <thead>
              <tr>
                <th>
                  Description
                </th>
                <th>
                  Product ID
                </th>
                <th *ngIf="cat.ShowCompatibility">
                  Compatible With
                </th>
                <th *ngIf="cat.ShowGauge">
                  Gauge
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of cat.Items">
                <tr class="accessory-heading-row" (click)="item.metadata.ShowPrice = !item.metadata.ShowPrice" [class.expanded] ="item.metadata.ShowPrice">
                  <td>
                    <img *ngIf="item.metadata.ImageUrl.length > 0" src="{{item.metadata.ImageUrl|cms}}" class="color-menu-image" style="margin-right:5px" />{{item.Description}}
                  </td>
                  <td>
                    {{item.BuyoutId}}
                  </td>
                  <td *ngIf="cat.ShowCompatibility" [innerHTML]="item.Compatibility|safe">
                    
                  </td>
                  <td *ngIf="cat.ShowGauge">
                    {{item.GaugeId}}
                  </td>
                </tr>
                <tr *ngIf="item.metadata.ShowPrice" style="border-top-width:0px" class="accessory-pricing-row">
                  <td [attr.colspan]="cat.ColumnCount" style="border-top-width:0px">
                    <accprice [accessory]="item"></accprice>
                  </td>
                </tr>
              </ng-container>

            </tbody>
          </table>

        </div>
        <hr />
      </div>
    </div>


  </div>
  <div class="modal-footer">
    
    <button class="btn btn-warning-secondary" data-dismiss="modal" (click)="closeAccessoriesModal()"><fa-icon [icon]="d.faTimes"></fa-icon>Continue Shopping</button>
     
  </div>
</ng-template> 