import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'discountpercent' })
export class DiscountPercentPipe implements PipeTransform {
	transform(value: string): any {
		/*
		console.log(value);
		let newVal = parseInt(value, 10);
		console.log(newVal);
		let newNewVal = Math.abs(newVal);
		console.log(newNewVal);
		let newNewNewVal = newNewVal + "%";
		console.log(newNewNewVal);
		*/
		let pct = (Math.abs(parseInt(value, 10))) + '%';

		return (pct == 'NaN%') ? '0.00' : pct;
		//return newNewNewVal;
	}
}