//Angular built-ins:
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"; // Import 
import { ScrollingModule, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { AppRoutingModule } from './app-routing.module';

// Authentication:
// https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-convert-app-to-be-multi-tenant
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/multi-tenant.md
// https://docs.microsoft.com/en-us/azure/active-directory-b2c/enable-authentication-angular-spa-app
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular'; // Import MsalInterceptor
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// UI Libraries:
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';

// Custom services:
import { AuthProvider } from './AuthProvider';
import { DataService } from './data.service';
import { AuthManager } from './authmanager';

// Components:
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { StdFooterComponent } from './components/std-footer/std-footer.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AccessoriesComponent } from './components/accessories/accessories.component';
import { AccPriceComponent } from './components/accessories/accprice.component';
import { TrimsComponent } from './components/trims/trims.component';
import { TrimrenderComponent } from './components/trimrender/trimrender.component';
import { PanelsComponent } from './components/panels/panels.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { CartComponent } from './components/cart/cart.component';
import { CartButtonComponent } from './components/cart/cartbutton.component';
import { LoginComponent } from './components/login/login.component';
import { TabsetComponent } from './components/tabset/tabset.component';
import { CutlistComponent } from './components/cutlist/cutlist.component';
import { PanelComponent } from './components/panel/panel.component';

// Directives and Pipes:
import { DirectivesModule } from './directives/directives'
import { PipesModule } from './pipes/pipes.module';
import { TrimPriceComponent } from './components/trims/trimprice.component';
import { TrimRenderComponent } from './components/trims/trimrender.component';
import { TrimEditComponent } from './components/trims/trimedit.component';
import { TrimEditFctComponent } from './components/trims/trimeditfct.component';
import { PrintoutComponent } from './components/printout/printout.component';

@Injectable()
export default class MyErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    // The true paramter tells Angular to rethrow exceptions, so operations like 'bootstrap' will result in an error
    // when an error happens. If we do not rethrow, bootstrap will always succeed.
    super();
  }

  handleError(error) {
    console.log("handleError TRIGGERED!");
    let d = this.injector.get(DataService);
    d.processError(error);

    // delegate to the default handler
    super.handleError(error);
  }

}

const ap = new AuthProvider();

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ManageUsersComponent,
    StdFooterComponent,
    OrdersComponent,
    AccessoriesComponent,
    AccPriceComponent,
    TrimsComponent,
    TrimrenderComponent,
    PanelsComponent,
    ConfigureComponent,
    CartComponent,
    CartButtonComponent,
    LoginComponent,
    TabsetComponent,
    CutlistComponent,
    PanelComponent,
    TrimPriceComponent,
    TrimRenderComponent,
    TrimEditComponent,
    TrimEditFctComponent,
    PrintoutComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    HttpClientModule,
    FontAwesomeModule,
    ScrollingModule,
    DirectivesModule,
    PipesModule,
    ModalModule.forRoot(),

    MsalModule.forRoot(
      ap.msalInstance(), ap.guardConfig(), ap.interceptorConfig()),/*
     
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: ''
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: (window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1),
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: []//'user.read'
      }
    },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([]) // not authorized = no token passed
      }), */
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard,
    DataService,
    { provide: ErrorHandler, useClass: MyErrorHandler }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }