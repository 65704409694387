<header class="routeheader">

  <div class="dist-cols-parent">
    <div class="dist-cols-child">

      &nbsp;
    </div>

    <div class="dist-cols-child pageTitle">
      <span *ngIf="panelType == 'CF'"><fa-icon [icon]="d.faSquare" style="color:#80898a"></fa-icon>Concealed Fastener
        Panels</span>
      <span *ngIf="panelType == 'EF'"><fa-icon [icon]="d.faSquare" style="color:#a84f34"></fa-icon>Exposed Fastener
        Panels</span>
    </div>

    <div class="dist-cols-child">
      <cartbutton></cartbutton>
    </div>
  </div>

</header>
<div class="routecontent">


  <div class="panel-table" *ngIf="d.model.panels && d.model.panelChunks[panelType]">
    <div class="panel-tr" *ngFor="let panelGroup of d.model.panelChunks[panelType]">
      <div class="panel-td" *ngFor="let panel of panelGroup">


        <ng-container [ngSwitch]="panel.MATERIAL_NUMBER">


          <button *ngSwitchCase="'2.5C26_BARE'" class="btn btn-primary btn-lg" style="float:right"
            [ngClass]="{'alaska-only': (d.model.ShipTo.SHIPPINGPLANT != 'AN03')}"
            title="{{ (d.model.ShipTo.SHIPPINGPLANT != 'AN03') ? 'Stock available in Alaska Only,\r\n\r\nMade to order is available instead' : ''}}"
            (click)="mtsPanelsGo('26')">
            <fa-icon [icon]="d.faPlay"></fa-icon>26G
          </button>

          <button *ngSwitchDefault class="btn btn-primary btn-lg" style="float:right"
            [ngClass]="{'alaska-only': (panel.ShippingNotValid == 'X')}"
            title="{{ panel.ShippingNotValid == 'X' ? 'Selected shipping unavailable for product' : ''}}"
            (click)="selectPanel(panel)">
            <fa-icon [icon]="d.faPlay"></fa-icon>Configure
          </button>

        </ng-container>


        <span class="panel-title">{{panel.NAME}}</span><br />
        <span class="panel-id">{{panel.MATERIAL_NUMBER}} / {{panel.PaintSystem}} (Min: {{panel.MinCutlistLength}}&quot;,
          Max: {{panel.MaxCutlistLength}}&quot;)</span>

        <br />

        <app-tabset [tabs]="[
          {imageUrl:panel.ImageUrl, title:'Description', detail: panel.Description},
          {title: 'Features', detail: panel.Features},
        ]"></app-tabset>


      </div>
    </div>
  </div>


</div>

<footer>

  <div class="dist-cols-parent">
    <div class="dist-cols-child">
      <div ngbDropdown placement="top-start">
        <button type="button" class="btn btn-secondary" id="catalogsBtn" ngbDropdownToggle><fa-icon
            [icon]="d.faFilePdf"></fa-icon>Catalogs</button>
        <div ngbDropdownMenu aria-labelledby="catalogsBtn">
          <a *ngFor="let cl of d.catalogLinks" ngbDropdownItem href="{{cl.href}}" target="_blank"
            rel="noopener noreferrer nofollow"><fa-icon [icon]="d.faFilePdf"></fa-icon><span
              [innerHTML]="cl.html | safe"></span></a>
        </div>
      </div>

    </div>

    <div class="dist-cols-child">
      <ul class="unpad">
        <li><a href="http://www.ascbp.com/files/Order%20Forms/ecomtermsofuse.pdf" rel="noopener noreferrer nofollow"
            target="_blank">E-Commerce Terms of Use Policy</a></li>
        <li><a href="http://www.ascbp.com/files/Order%20Forms/termsandconditions.pdf" rel="noopener noreferrer nofollow"
            target="_blank">Terms and Conditions of Sale</a></li>
      </ul>
    </div>
  </div>




</footer>