<div class="dist-cols-parent">
  <div class="dist-cols-child">

    <span class="pageTitle truncate">© {{d.copyrightYear}} ASC&nbsp;</span>
    <span class="pageTitle truncate">Building Products</span>

  </div>

  <div class="dist-cols-child">
    <ul class="unpad">
      <li><a href="http://www.ascbp.com/files/Order%20Forms/ecomtermsofuse.pdf" rel="noopener noreferrer nofollow"
          target="_blank">E-Commerce Terms of Use Policy</a></li>
      <li><a href="http://www.ascbp.com/files/Order%20Forms/termsandconditions.pdf" rel="noopener noreferrer nofollow"
          target="_blank">Terms and Conditions of Sale</a></li>
    </ul>
  </div>
</div>