import { ModuleWithProviders } from "@angular/core";
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from "@azure/msal-angular";
import { MsalModule } from "@azure/msal-angular/msal.module";
import { HttpClient } from '@angular/common/http';
import { EventType, InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { AuthProvider } from "./AuthProvider";
import { DataService } from "./data.service";
import { AuthState } from "./models/AuthState.enum";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

export class AuthManager {
	public ap = new AuthProvider();

	constructor(
		private d: DataService) {
		console.log("AuthManager Init!");

		var authSrc = localStorage.getItem('authSrc');
		if (authSrc != null) {
			this.d.authService.instance.addEventCallback((event) => { this.handleLogonEvent(event) });
		}
		var msalConfig = this.ap.msalInstance().getConfiguration();
		if (msalConfig.auth.clientId.length == 0) {
			// fresh application run!
			console.log("MSAL is in an unconfigured state");
			this.d.model.user.authState = AuthState.Anonymous;
			this.d.router.navigate(['login']);
			this.d.updateLoader(-1);
		}
	}


	showAuthStatus() {
		console.log(JSON.stringify(this.ap.msalInstance(), null, 4));
	}
	handleLogonEvent(event) {

		this.d.updateLoader(1);
		var accounts = this.d.authService.instance.getAllAccounts();

		switch (event.eventType) {
			case (EventType.HANDLE_REDIRECT_START):
				console.log("MSAL Check in progress...");
				break;
			case (EventType.HANDLE_REDIRECT_END):
				console.log("MSAL Check completed.");
				if (event.payload) console.log("payload account: ", event.payload.account);
				if (accounts.length > 0) {
					this.setUser();
				} else {
					this.clearUser();
				}
				break;
			case (EventType.LOGOUT_END):
				console.log("LOGOUT_END event");
				this.d.clearStorage();
				this.clearUser();
				break;
			case (EventType.ACQUIRE_TOKEN_START):
				//console.log("ACQUIRE_TOKEN_START");
				// normally occurs every time we talk to the server
				break;
			case (EventType.ACQUIRE_TOKEN_SUCCESS):
				//console.log("ACQUIRE_TOKEN_SUCCESS");
				// normally occurs every time we talk to the server
				break;
			default:
				// Something unusual happened!
				console.log(event);
		}
		this.d.updateLoader(-1);

	}

	login(authSrc: string) {

		this.d.clearStorage(); // clean start
		localStorage.setItem('authSrc', authSrc);
		this.d.authService.instance = this.ap.msalInstance();
		this.d.authService.instance.addEventCallback((event) => { this.handleLogonEvent(event) });

		const account = this.d.authService.instance.getActiveAccount();
		if (!account) {
			console.log("Redirecting to login page...");

			this.d.authService.instance.handleRedirectPromise().then(authResult => {
				console.log("handleRedirectPromise: ", authResult);
				const account = this.d.authService.instance.getActiveAccount();
				if (account) {
					this.setUser();
				} else {
					this.d.authService.instance.loginRedirect();
				}
			}).catch(err => {
				// TODO: Handle errors
				console.log(err);
			});
		} else {
			this.setUser();
		}


	}

	logout() {
		this.d.authService.logoutRedirect({
			postLogoutRedirectUri: environment.baseRef
		});
	}


	setUser() {

		var accounts = this.d.authService.instance.getAllAccounts();
		if (accounts.length > 0) {
			this.d.authService.instance.setActiveAccount(accounts[0]);

			// Avoid NG0100 (Expression has changed after it was checked):
			setTimeout(() => {
				this.d.model.user.authState = AuthState.Authenticated;
				this.d.model.user.token = accounts[0]; //loginInfo = JSON.stringify(accounts, null, 4);

				this.d.updateLoader(-1); //requires an init value of ajaxCount to be 1 in data.service.ts
				this.d.initData();
				if (this.d.activeRouteIs('/login')) {
					this.d.router.navigate(['home']);
				}


			}, 1);
		} else {
			console.error("setUser found no accounts!");
		}

	}

	clearUser() {
		this.d.authService.instance.setActiveAccount(null);
		this.d.model.user.authState = AuthState.Anonymous;
		this.d.model.user.token = null;
		this.d.model.user.info = null;
		this.d.router.navigate(['login']);
		this.d.updateLoader(-1);  //requires an init value of ajaxCount to be 1 in data.service.ts
	}



}