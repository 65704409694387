import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from '../data.service';

@Pipe({ name: 'meta' })
export class MetaPipe implements PipeTransform {
	constructor(private d: DataService) { }

	transform(value: string, operation: string): any {
		//console.log("meta pipe, operation: " + operation + ", 
		switch (operation) {
			case "stripNonAlphaNumeric": return this.d.stripNonAlphaNumeric(value);
			case "pricingError": return this.pricingError(value);
			case "metalName": return this.d.getMetalName(value);
			case "formatFraction": return this.d.formatFraction(value);
			case "hemDescription": return this.hemDescription(value);
			case "matDescription": return this.matDescription(value);
			case "stripDecimal": return this.stripDecimal(value);
			case "stripWs": return this.stripWs(value);
			case "slopeDeg": return this.slopeDeg(value);
		}
	}
	stripWs(value: string) {
		return value.replace(/\n|\r/g, " ");
	}
	stripDecimal(value: string) {
		return value.toString().replace('.000', '');

	}
	pricingError(value: string) {
		if (value == "$0.00") {
			return '<span style="color:red">$-.--</span>';
		} else {
			return value;
		}

	}

	slopeDeg(value) {
		return value.replace(" ( ", " (").replace(" deg )", "°)");
	}

	hemDescription(flangeType) {
		switch (flangeType) {
			case "posHem": return "Hem";
			case "posHook": return "Hook";
			case "negHem": return "Hem (Reversed)";
			case "negHook": return "Hook (Reversed)";
			default: return "No Hem";
		}
	}

	matDescription(matId) {
		switch (matId) {
			case "B": return "Bare";
			case "P": return "Painted";
			default: return matId;
		}
	}

}