<header class="routeheader">

	<div class="dist-cols-parent">
		<div class="dist-cols-child">
	
      <div ngbDropdown class="d-inline-block">
        <button type="button" id="filterBtn" class="btn btn-secondary filter-toggle-btn" ngbDropdownToggle>
          <fa-icon [icon]="d.faFilter"></fa-icon>Recent {{typeName}}s
        </button>
        <div ngbDropdownMenu aria-labelledby="filterMenu">
          <ng-container *ngTemplateOutlet="filters"></ng-container>
        </div>
      </div>
		</div>

		<div class="dist-cols-child pageTitle">
      <span *ngIf="orderType == 'history'"><fa-icon [icon]="d.faWpforms"></fa-icon>Online Order History</span>
      <span *ngIf="orderType == 'templates'"><fa-icon [icon]="d.faPuzzlePiece"></fa-icon>Saved Templates</span>
		</div>

		<div class="dist-cols-child">
			<cartbutton></cartbutton>
		</div>
	</div>

</header>


<div class="routecontent" style="flex-direction: row;">
	
  <div class="filterMenuBody">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
  </div>


  <div class="body-cards" (click)="d.hideFilters()">
    <br>
    <div ngbNav #nav="ngbNav" class="nav-tabs htabs" (navChange)="navChange($event)">



      <div [ngbNavItem]="'summary'">
        <a ngbNavLink>
          <fa-icon [icon]="d.faWpforms" *ngIf="orderType=='history'"></fa-icon>
          <fa-icon [icon]="d.faPuzzlePiece" *ngIf="orderType=='templates'"></fa-icon>
          {{typeName}} Summary</a>
        <ng-template ngbNavContent>
        <div class="orders-content" *ngIf="selectedOrder?.Salesordernumber">
         
          <table class="order-header-cols" >
            <tbody>
              <tr>
                <td>
                  <table>
                    <tr *ngIf="selectedOrder.Customerid"><td class="so-detail-label">Customer ID:</td><td>{{selectedOrder.Customerid}}</td></tr>
                    <tr *ngIf="selectedOrder.Customername">
                      <td class="so-detail-label">Customer:</td>
                      <td>
                        <ng-container *ngIf="selectedOrder.Shiptopartnerid">{{selectedOrder.Shiptopartnerid|removeleadingzeros}} - </ng-container>{{selectedOrder.Customername}}
                      </td>
                    </tr>
                    <tr *ngIf="selectedOrder.Shiptocontact"><td class="so-detail-label">Ship to Contact:</td><td>{{selectedOrder.Shiptocontact}}</td></tr>
                    <tr *ngIf="selectedOrder.ECommEmail"><td class="so-detail-label">E-Comm User:</td><td><a href="mailto:{{selectedOrder.ECommEmail}}">{{selectedOrder.ECommEmail}}</a></td></tr>
                    <tr *ngIf="selectedOrder.Shiptoaddressline1"><td class="so-detail-label">Address:</td><td>{{selectedOrder.Shiptoaddressline1}}</td></tr>
                    <tr *ngIf="selectedOrder.Shiptoaddressline2"><td class="so-detail-label"></td><td>{{selectedOrder.Shiptoaddressline2}}</td></tr>
                    <tr *ngIf="selectedOrder.Shiptocity"><td class="so-detail-label">City:</td><td>{{selectedOrder.Shiptocity}}</td></tr>
                    <tr *ngIf="selectedOrder.Shiptocountry"><td class="so-detail-label">Country:</td><td>{{selectedOrder.Shiptocountry}}</td></tr>
                    <tr *ngIf="selectedOrder.Shiptoregion"><td class="so-detail-label">Ship to Region:</td><td>{{selectedOrder.Shiptoregion}}</td></tr>
                    <tr *ngIf="selectedOrder.Shiptopostalcode"><td class="so-detail-label">Ship to Postal Code:</td><td>{{selectedOrder.Shiptopostalcode}}</td></tr>
                    <tr *ngIf="selectedOrder.Shippingincoterms"><td class="so-detail-label">Shipping INCO Terms:</td><td>{{selectedOrder.Shippingincoterms}}</td></tr>
                    <tr *ngIf="selectedOrder.Shippingcarrier"><td class="so-detail-label">Shipping Carrier:</td><td>{{selectedOrder.Shippingcarrier}}</td></tr>
                    <tr *ngIf="selectedOrder.ShiptopartneridNew"><td class="so-detail-label">Ship to Partner ID New:</td><td>{{selectedOrder.ShiptopartneridNew}}</td></tr>
                  </table>
                </td>
                <td class="order-number-po">
                  <table>

                    <tr *ngIf="selectedOrder.Salesordernumber"><td class="so-detail-label">{{typeName}} Number:</td><td>{{selectedOrder.Salesordernumber}}</td></tr>
                    <tr *ngIf="selectedOrder.Po"><td class="so-detail-label">Customer PO:</td><td>{{selectedOrder.Po}}</td></tr>
                    <tr *ngIf="selectedOrder.JobName"><td class="so-detail-label">Job Name:</td><td>{{selectedOrder.JobName}}</td></tr>
                    <tr *ngIf="selectedOrder.Orderdate"><td class="so-detail-label">Order Date:</td><td>{{selectedOrder.Orderdate|sapdate}}</td></tr>
                    <tr *ngIf="selectedOrder.Requesteddate"><td class="so-detail-label">Requested Date:</td><td>{{selectedOrder.Requesteddate|sapdate}}</td></tr>
                    <tr *ngIf="selectedOrder.Salesordersimulation"><td class="so-detail-label">Salesorder Simulation?:</td><td>{{selectedOrder.Salesordersimulation}}</td></tr>
                    <tr *ngIf="selectedOrder.Promotioncode"><td class="so-detail-label">Promotion Code:</td><td>{{selectedOrder.Promotioncode}}</td></tr>
                    <tr *ngIf="selectedOrder.Paymentterms"><td class="so-detail-label">Payment Terms:</td><td>{{selectedOrder.Paymentterms}}</td></tr>
                    <tr *ngIf="selectedOrder.Singleshipmentindicator"><td class="so-detail-label">Single Shipment Indicator:</td><td>{{selectedOrder.Singleshipmentindicator}}</td></tr>
                    <tr *ngIf="selectedOrder.Distributionchannel"><td class="so-detail-label">Distribution Channel:</td><td>{{selectedOrder.Distributionchannel}}</td></tr>
                    <tr *ngIf="selectedOrder.Division"><td class="so-detail-label">Division:</td><td>{{selectedOrder.Division}}</td></tr>
                    <!--<tr *ngIf="selectedOrder.Shipmentinstruction"><td class="so-detail-label">Shipment Instruction:</td><td>{{selectedOrder.Shipmentinstruction}}</td></tr>-->
                    <tr *ngIf="selectedOrder.Notestoreceiver"><td class="so-detail-label">Notes to Receiver:</td><td>{{selectedOrder.Notestoreceiver}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress1"><td class="so-detail-label">Address:</td><td>{{selectedOrder.Formattedaddress1}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress2"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress2}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress3"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress3}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress4"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress4}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress5"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress5}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress6"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress6}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress7"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress7}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress8"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress8}}</td></tr>
                    <tr *ngIf="selectedOrder.Formattedaddress9"><td class="so-detail-label"></td><td>{{selectedOrder.Formattedaddress9}}</td></tr>
                    <tr *ngIf="selectedOrder.ShipType"><td class="so-detail-label">Ship Type:</td><td>{{selectedOrder.ShipType}}</td></tr>
                    <tr *ngIf="selectedOrder.Incoterms1"><td class="so-detail-label">INCO Terms:</td><td>{{selectedOrder.Incoterms1}}</td></tr>
                    <tr *ngIf="selectedOrder.Incoterms2"><td class="so-detail-label"></td><td>{{selectedOrder.Incoterms2}}</td></tr>
                    <tr *ngIf="selectedOrder.DummySoHeaderIncl"><td class="so-detail-label">Dummy SO Header Incl?:</td><td>{{selectedOrder.DummySoHeaderIncl}}</td></tr>
                    <tr *ngIf="selectedOrder.Version"><td class="so-detail-label">Version:</td><td>{{selectedOrder.Version}}</td></tr>


                  </table>
                </td>
                <td>
                  <table>

                    <tr *ngIf="selectedOrder.Status"><td class="so-detail-label">Status:</td><td>{{selectedOrder.Status}}</td></tr>
                    <tr *ngIf="selectedOrder.DeliveryStatus"><td class="so-detail-label">Delivery:</td><td>{{selectedOrder.DeliveryStatus}}</td></tr>
                    <tr *ngIf="selectedOrder.InvoiceStatus"><td class="so-detail-label">Invoice:</td><td>{{selectedOrder.InvoiceStatus}}</td></tr>
                    <tr *ngIf="selectedOrder.Blockedstatus"><td class="so-detail-label">Blocked:</td><td>{{selectedOrder.Blockedstatus}}</td></tr>
                    <tr *ngIf="selectedOrder.Userstatus"><td class="so-detail-label">User:</td><td>{{selectedOrder.Userstatus}}</td></tr>
                    <tr *ngIf="selectedOrder.SHIPPINGSTATUSCODE"><td class="so-detail-label">Shipping:</td><td>{{selectedOrder.SHIPPINGSTATUSCODE}}</td></tr>
                    <tr *ngIf="selectedOrder.OVERALLSTATUSCODE"><td class="so-detail-label">Overall Status Code:</td><td>{{selectedOrder.OVERALLSTATUSCODE}}</td></tr>



                  </table>
                </td>
              </tr>
            </tbody>
          </table>


          <hr />
          <table style="display:inline-block" class="float-end">
            <tr *ngIf="selectedOrder.Grandtotal"><td class="so-detail-label">Grand Total:</td><td>{{selectedOrder.Grandtotal| currency:'USD':'symbol':'1.2-2'}}</td></tr>


          </table>

          <div id="order-items">

            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Item</th>
                  <th>Description</th>
                  <th class="text-right">Qty</th>
                  <th class="text-right">Unit Price</th>
                  <th class="text-right">Net Amount</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of selectedOrder.OrderItemSet.results">
                  <tr>
                    <td class="first-col text-center">
                      <img class="cart-thumb" [src]="item.metadata?.product.ImageUrl" (click)="d.zoomImg($event)" />

                    </td>
                    <td>{{item.itemnumber|removeleadingzeros}}</td>
                    <td>

                      <ng-container *ngIf="item.ValidForImport=='X'">
                        <button title="Copy to Cart" *ngIf="item.CutlistSet.results.length == 0" (click)="copyToCart(item)" class="btn btn-primary btn-sm float-end iconBtn">
                          <fa-icon [icon]="d.faCartPlus"></fa-icon>
                        </button>
                        <button title="Configure Panel" *ngIf="item.CutlistSet.results.length > 0" (click)="configurePanel(item)" class="btn btn-primary btn-sm float-end iconBtn">
                          <fa-icon [icon]="d.faPlay"></fa-icon>
                        </button>
                      </ng-container>
                      <button class="btn btn-sm float-end iconBtn invalid-reason" *ngIf="item.InvalidReason" title="{{item.InvalidReason}}" (click)="displayInvalidReason(item)">
                        <fa-icon [icon]="d.faBan"></fa-icon>
                      </button>


                      <span class="ng-tooltip" *ngIf="item.metadata.color?.ImageUrl">
                        <img src="{{item.metadata.color.ImageUrl}}" class="color-menu-image" />
                        <span>
                          <img class="callout" src="/assets/images/callout.gif" />
                          <img src="{{item.metadata.color.ImageUrl}}" class="color-tooltip-image" />
                        </span>
                      </span>

                      <b>{{item.Materialnumber}}</b> {{item.Productname}}
                      <ng-container *ngIf="item.metadata?.TDLData?.isFullyCustom">- {{item.metadata.TDLData.name}}</ng-container>

                      <ng-container *ngIf="item.Gauge">- {{item.Gauge}}G</ng-container>
                      <ng-container *ngIf="item.Metal">- {{item.Metal|meta:'metalName'}}</ng-container>
                      <ng-container *ngIf="item.PaintSystem&&item.Colorcode!='BARE'">- {{item.PaintSystem}}</ng-container>
                      <ng-container *ngIf="item.Colorcode">- {{item.Colorcode|colordesc}}</ng-container>
                      <ng-container *ngIf="item.UphillSlope.length > 0">
                        <ng-container *ngIf="item.DownhillSlope == 'NA'">- Combined Pitch {{item.UphillSlope}}:12</ng-container>
                        <ng-container *ngIf="item.DownhillSlope != 'NA'">- Uphill Pitch {{item.UphillSlope}}:12 / Downhill Pitch {{item.DownhillSlope}}:12, </ng-container>
                      </ng-container>
                      <!--
                      <ng-container *ngIf="item.TdlData">- <span style="border:1px solid gray;background-color:#ccc;font-family:'Courier New'" title="{{item.TdlData}}">TDLData</span></ng-container>
                      -->


                      <span *ngIf="item.CutlistSet?.results">
                        <br />
                        <span class="order-cutlist-item" *ngFor="let cut of item.CutlistSet.results">
                          <span>{{cut.Quantity}}</span>
                          <span>@&nbsp;</span>
                          <span *ngIf="cut.Feet!='00'">{{cut.Feet|removeleadingzeros}}&apos;</span>
                          <span *ngIf="cut.Inches!='000'">{{cut.Inches|removeleadingzeros}}&quot;</span>
                          <span *ngIf="cut.Fractions!='00'">{{cut.Fractions|removeleadingzeros}}/16</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-right">{{item.Quantity001}}</td>

                    <td class="text-right">{{item.Unitprice| currency:'USD':'symbol':'1.2-2'}} / {{item.Unitofmeasure}}</td>
                    <td class="text-right">{{item.Netamount| currency:'USD':'symbol':'1.2-2'}}</td>
                  </tr>

                </ng-container>

              </tbody>
            </table>
          </div>

          <table style="display:inline-block" class="float-end">
            <tr *ngIf="selectedOrder.Grandtotal"><td class="so-detail-label">Grand Total:</td><td>{{selectedOrder.Grandtotal| currency:'USD':'symbol':'1.2-2'}}</td></tr>


          </table>
          
        </div>
        </ng-template>
      </div>

      <div [ngbNavItem]="'history'" *ngIf="orderType=='history'">
        <a ngbNavLink><fa-icon [icon]="d.faFilePdf"></fa-icon>Order Confirmation PDF</a>
        <ng-template ngbNavContent>
          
          <ng-container [ngSwitch]="pdfState">
            <!-- the same view can be shown in more than one case -->
            <ng-container *ngSwitchCase="0">
              <div class="loader" style="z-index:200"></div>
              Loading PDF...
            </ng-container>

            <ng-container *ngSwitchCase="1">
              <iframe id="pdf-iframe" style="width:100%;height:100%;border:0;z-index:100" [src]="pdfUrl|safeurl"></iframe>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <div class="noPdf">
                <h3>Order Confirmation not yet generated</h3>
                Please contact your ASC BP Customer Service Representative<br>
                <button class="btn btn-primary" (click)="loadOrder(orderId)">Try Again</button>
              </div>
            </ng-container>
          </ng-container>
           
        </ng-template>
      </div>


    </div>
    <div [ngbNavOutlet]="nav"></div>

  </div>


</div>

<footer>
	<div class="dist-cols-parent">
		<div class="dist-cols-child">
	
			&nbsp;
		</div>
		
		<div class="dist-cols-child">
			
      <button class="btn btn-secondary"  *ngIf="orderType=='history'" [class.disabled]="!pdfReady" (click)="downloadPDF()"><fa-icon [icon]="d.faDownload"></fa-icon>Download PDF</button>
      &nbsp; 
      <button class="btn btn-secondary pull-right" *ngIf="orderType=='templates'" (click)="addAllToCart()" [ngClass]="{'disabled':(!addAllActive)}">
        <fa-icon [icon]="d.faCartPlus"></fa-icon>
        Add All to Cart
      </button>
		</div>
	</div>
</footer>

<ng-template #filters>
  <div class="filterMenu">
    <div *ngFor="let order of orderList" class="order-list-item" (click)="router.navigate(['/orders/'+orderType+'/'+order.Vbeln])"
         [ngClass]="{'active':(selectedOrder?.Salesordernumber == order.Vbeln)}">
      <span style="font-size:1.2em;font-weight:bold">{{order.Vbeln}}</span> / {{order.Bstnk}}<br />
      <b>Status:</b> {{order.Overall}}<br />
      <b>Date:</b> {{order.Vdatu}}<br />
      <b>Total:</b> {{order.Netwr| currency:'USD':'symbol':'1.2-2'}}
    </div>
  </div>
</ng-template>